import axiosService from './AxiosService';

export class FamilyProfileInviteService {
   _resourceName = 'family-profile-invite';

   async addFamilyProfileInvite(familyProfileInvite) {
      return await axiosService.create(`/v1.0/${this._resourceName}`, familyProfileInvite);
   }

   async getFamilyProfileInvites() {
      return await axiosService.readList(`/v1.0/${this._resourceName}`);
   }

   async acceptProfileInvite(acceptInviteId) {
      return await axiosService.create(`/v1.0/${this._resourceName}/${acceptInviteId}`, acceptInviteId);
   }

   async deleteFamilyProfileInvite(id) {
      return await axiosService.delete(`/v1.0/${this._resourceName}`, id);
   }

   static get instance() { return familyProfileInviteService }
}

const familyProfileInviteService = new FamilyProfileInviteService();

export default familyProfileInviteService;
