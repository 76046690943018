import React, { useState, useEffect, useRef } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import systemLookupService from '../data/SystemLookupService';
import assetService from '../data/AssetService';
import AssetDialog, { initialState as assetFormInitialState } from '../components/AssetDialog';
import EnhancedTable from '../components/EnhancedTable';
import Button from '@mui/material/Button';
import { AssetColConfig, PropertyColConfig, AutoColConfig, MotoColConfig, 
   WatercraftColConfig, RVColConfig, ArtColConfig, EquipmentColConfig, FurnitureColConfig, 
   HeirloomColConfig, FirearmColConfig } from '../components/TableColumnConfigs';
import WidgetContainer from '../components/WidgetContainer';
import PubSub from 'pubsub-js';
import LimitReachedAlert from '../components/LimitReachedAlert';
import { ArrowBack, Add, DirectionsCar, Chair, House, 
   TwoWheeler, DirectionsBoat, AirportShuttle, 
   Construction, Whatshot, MilitaryTech, QuestionMark, ColorLens }  from '@mui/icons-material';
import subscriptionService from '../data/SubscriptionService';
import PeopleIcon from '@mui/icons-material/People';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import BeneficiariesDialog from '../components/BeneficiariesDialog';

const AssetsPage = () => {
   const assetDialogRef = useRef();
   const beneficiariesDialogRef = useRef();

   let { assetTypeId } = useParams();

   const [assets, setAssets] = useState({ data: [], count: 0, limits: {}, loading: true });
   const [assetTypes, setAssetTypes] = useState({ data: [], loading: false });
   const [addressStatesProvinces, setAddressStatesProvinces] = useState({ data: [], loading: false });
   const [currentSubscriptionStatus, setCurrentSubscriptionStatus] = useState({ data: {}, loading: false });

   useEffect(() => {
      getAssets(assetTypeId);
      getAssetTypes();
      getAddressStatesProvinces();
      getCurrentSubscriptionStatus();
   }, []);

   const history = useHistory();

   const getAssets = async (assetTypeId) => {
      setAssets({ data: [], count: 0, limits: {}, loading: true });
      const _assets = await assetService.getAssets(assetTypeId);
      setAssets({ data: groupAssetsByType({..._assets}).data, count: _assets.data.length, limits: _assets.limits, loading: false });
   };

   const groupAssetsByType = (assets) => {
      let types = [];
      let data = {};
      for(let asset of assets.data) {
         let assetType = asset.assetType.name;
         if(types.indexOf(assetType) === -1) {
            types.push(assetType);
            data[assetType] = [];
         }
         data[assetType].push(asset);
      }
      assets.data = data;
      return assets;
   };

   const getAssetTypes = async () => {
      setAssetTypes({ data: [], loading: true });
      const assetTypes = await systemLookupService.getAssetTypes();
      setAssetTypes({ data: assetTypes, loading: false });
   };

   const getAddressStatesProvinces = async () => {
      setAddressStatesProvinces({ data: [], loading: true });
      const addressStatesProvinces = await systemLookupService.getAddressStatesProvinces();
      setAddressStatesProvinces({ data: addressStatesProvinces, loading: false });
   };

   const addSaveAsset = async (asset) => {
      if(asset.id) {
         setAssets({ ...assets, loading: true });
         await assetService.putAsset(asset);
         setAssetDialogOpen(false);
      } else {
         if(asset.fileName)
            PubSub.publish('nonBlockingLoader.addMessage', { id: asset.fileName, text: `Uploading ${asset.fileName}...` });

         setAssetDialogOpen(false);
         await assetService.addAsset(asset);

         if(asset.fileName)
            PubSub.publish('nonBlockingLoader.removeMessage', asset.fileName);
      }

      getAssets(assetTypeId);
   };

   const handleDeleteAssetClick = async (item) => {
      setAssets({ ...assets, loading: true });
      await assetService.deleteAsset(item.id);
      getAssets(assetTypeId);
   };

   const handleAddClick = () => {
      setAssetDialogOpen(true);
      if (assetDialogRef.current)
         assetDialogRef.current.setState(assetFormInitialState);
   };

   const handleEditAssetClick = async (asset) => {
      await setAssetDialogOpen(true);
      if (assetDialogRef.current)
         assetDialogRef.current.setState(asset);
   };

   // current subscription status
   const getCurrentSubscriptionStatus = async () => {
      setCurrentSubscriptionStatus({data: {}, loading: true});
      const subscriptionStatus = await subscriptionService.getCurrentStatus();
      setCurrentSubscriptionStatus({data: subscriptionStatus, loading: false});
   };

   // todo: these should use assetType.id rather than assetType.name
   const getAssetIcon = (assetType) => {
      switch (assetType) {
         case 'Property':
            return House;
         case 'Auto':
            return DirectionsCar;
         case 'Motorcycle/ATV':
            return TwoWheeler;
         case 'Watercraft':
            return DirectionsBoat;
         case 'Recreational Vehicle (RV)':
            return AirportShuttle;
         case 'Art':
            return ColorLens;
         case 'Equipment':
            return Construction;
         case 'Furniture':
            return Chair;
         case 'Heirloom':
            return MilitaryTech;
         case 'Firearm':
            return Whatshot;
         default:
            return QuestionMark;
      }
   };

   // todo: these should use assetType.id rather than assetType.name
   const getColConfigByType = (assetType) => {
      switch (assetType) {
         case 'Property':
            return PropertyColConfig;
         case 'Auto':
            return AutoColConfig;
         case 'Motorcycle/ATV':
            return MotoColConfig;
         case 'Watercraft':
            return WatercraftColConfig;
         case 'Recreational Vehicle (RV)':
            return RVColConfig;
         case 'Art':
            return ArtColConfig;
         case 'Equipment':
            return EquipmentColConfig;
         case 'Furniture':
            return FurnitureColConfig;
         case 'Heirloom':
            return HeirloomColConfig;
         case 'Firearm':
            return FirearmColConfig;
         default:
            return AssetColConfig;
      }
   };

   const [assetDialogOpen, setAssetDialogOpen] = useState(false);

   const handleSeeAllClick = async () => {
      await getAssets();
      history.push('/assets');
   };

   const [beneficiariesDialogOpen, setBeneficiariesDialogOpen] = useState(false);

   const handleManageBeneficiariesClick = async (item) => {
      if (beneficiariesDialogRef.current)
         await beneficiariesDialogRef.current.init(item
      );
      setBeneficiariesDialogOpen(true);
   };

   const onAddAddOtherContactBeneficiary = async (item, otherContact) => {
      await assetService.postOtherContactBeneficiaryFor(item.id, otherContact.id);
   };

   const handleBeneficiariesDialogClose = async () => {
      await getAssets(assetTypeId);
      setBeneficiariesDialogOpen(false);
   };

   return (
      <div>
         <Button
            variant="text"
            component={Link}
            to="/my-records"
            startIcon={<ArrowBack />}
         >
            Back
         </Button>
         {typeof setAssetDialogOpen === 'function' ? <Button
            disabled={assets.count >= assets.limits.asset}
            variant="contained"
            size="small"
            startIcon={<Add />}
            onClick={handleAddClick}
            sx={{ml: 'auto', float: 'right'}}
         >
            Add
         </Button> : null}
            
         { assetTypeId ? <Button
            variant="text"
            onClick={handleSeeAllClick}
         >See All</Button> : null }
         {Object.keys(assets.data).map((key, index) => {
            return (
                  <WidgetContainer
                     key={index}
                     title={key}
                     icon={getAssetIcon(key)}
                     color="#00a5de"
                  >
                     {/* todo: maybe here just show the alert instead of an empty EnhancedTable? */}
                     <EnhancedTable
                        loading={assets.loading}
                        emptyMessage="No assets have been added yet."
                        data={assets.data[key]}
                        colConfig={getColConfigByType(key)}
                        rowActions={[{
                           toolTip: 'Manage Beneficiaries',
                           icon: <PeopleIcon />,
                           badgeProp: 'otherContactCount',
                           onClick: handleManageBeneficiariesClick,
                        },{
                           toolTip: 'Edit',
                           icon: <EditIcon />,
                           onClick: handleEditAssetClick,
                        },{
                           toolTip: 'Delete',
                           icon: <DeleteIcon />,
                           confirmDialogConfig: {
                              title: "Delete Asset?",
                              description: "Are you sure you want to delete this asset?",
                              dismissButtonLabel: "No",
                              affirmButtonLabel: "Yes",
                           },
                           onClick: handleDeleteAssetClick,
                        }]}
                     />
                  </WidgetContainer>
            );
         })}

         {Object.keys(assets.data).length === 0 ? 
         <WidgetContainer
            title={`Assets`}
            icon={QuestionMark}
            color="#00a5de"
            sx={{ marginBottom: '10px !important'  }}
         >
            <EnhancedTable
               loading={assets.loading}
               emptyMessage="No assets have been added yet."
               data={[]}
               colConfig={AssetColConfig}
            />
         </WidgetContainer> : null}

         {assets.count >= assets.limits.asset ? <LimitReachedAlert
            resourceName="Assets"
            currentSubscriptionTier={currentSubscriptionStatus.data}
         /> : null}

         <AssetDialog
            ref={assetDialogRef}
            loading={assets.loading}
            open={assetDialogOpen}
            handleClose={() => setAssetDialogOpen(false)}
            handleAddSaveAsset={addSaveAsset}
            assetTypes={assetTypes.data}
            addressStatesProvinces={addressStatesProvinces.data}
            allowPhotoUpload={(assets.limits && assets.limits.assetPhoto === 1 ) ? true : false}
         />

         <BeneficiariesDialog
            ref={beneficiariesDialogRef}
            open={beneficiariesDialogOpen}
            handleClose={handleBeneficiariesDialogClose}
            onAddOtherContactBeneficiary={onAddAddOtherContactBeneficiary}
            onGetBeneficiariesForItem={(assetId) => assetService.getBeneficiariesFor(assetId)}
            onDeleteOtherContactBeneficiary={(id) => assetService.deleteOtherContactBeneficiary(id)}
            onGetOtherContactsForItem={(assetId) => assetService.getOtherContactsForAsset(assetId)}
         />
      </div>
   );
};

export default AssetsPage;
