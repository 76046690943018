import React, { Fragment, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import LoginMenu from './api-authorization/LoginMenu';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import HelpIcon from '@mui/icons-material/Help';
import BugReportIcon from '@mui/icons-material/BugReport';
// import { copyToClipboard } from '../utils/clipboard';
import authService from './api-authorization/AuthorizeService';
import HouseIcon from '@mui/icons-material/House';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Badge } from '@mui/material';
import NotificationList from './NotificationList';
import notificationService from '../data/NotificationService';
import trusteeInviteService from '../data/TrusteeInviteService';
import familyProfileInviteService from '../data/FamilyProfileInviteService';
import trustorService from '../data/TrustorService';
import Tooltip from '@mui/material/Tooltip';
import YesNoDialog from './YesNoDialog';
import GavelIcon from '@mui/icons-material/Gavel';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import LoadingButton from '@mui/lab/LoadingButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListIcon from '@mui/icons-material/List';
import profileService from '../data/ProfileService';
import SnowshoeingIcon from '@mui/icons-material/Snowshoeing';
import PubSub from 'pubsub-js';
import SearchIcon from '@mui/icons-material/Search';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import firebaseMessageRegistrationService from '../data/FirebaseMessageRegistrationService';
import AvatarInitials from './AvatarInitials';
// import MausoleumIconWhite from './MausoleumIconWhite';
import AlertDialog from './AlertDialog';
import { ApplicationPaths } from './api-authorization/ApiAuthorizationConstants';
import MuseumIcon from '@mui/icons-material/Museum';
import ReportBugDialog from './ReportBugDialog';
import ListItemIcon from '@mui/material/ListItemIcon';
import bugReportService from '../data/BugReportService';
import supportInquiryService from '../data/SupportInquiryService'
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import SupportInquiryDialog from './SupportInquiryDialog';

const TopAppBar = () => {
   let subscription;
   const [isAuthenticated, setIsAuthenticated] = useState(false);
   const [userName, setUserName] = useState(null);
   const [basicProfile, setBasicProfile] = useState({ data: {}, loading: false });
   const [notifications, setNotifications] = useState({ data: [], loading: false });
   const [notificationMode, setNotificationMode] = useState('push');

   const history = useHistory();

   const checkProfileExists = async () => {
      // var pushNotificationSub = PubSub.subscribe('notification.push-on-list', pushNotificationOnList);
      subscription = authService.subscribe(() => populateState());
      setBasicProfile({ data: {}, loading: true });
      await populateState();
      return () => authService.unsubscribe(subscription);
   };

   useEffect(() => {
      async function init() {
         return await checkProfileExists();
      }
      init();
   }, []);

   useEffect(() => {
      const profileUpdatedSub = PubSub.subscribe('profile.updated', profileUpdated);
      return () => PubSub.unsubscribe(profileUpdatedSub);
   }, []);

   useEffect(() => {
      const reloadNotificationsSub = PubSub.subscribe('profile.reload-notifications', getMyNotifications);
      return () => PubSub.unsubscribe(reloadNotificationsSub);
   }, []);

   useEffect(() => {
      const reloadTrustorsSub = PubSub.subscribe('profile.reload-trustors', getIsTrustee);
      return () => PubSub.unsubscribe(reloadTrustorsSub);
   }, []);

   useEffect(() => {
      const sessionExpiredSub = PubSub.subscribe('auth.session-expired', () => setSessionExpirationAcknowledgeDialogOpen(true));
      return () => PubSub.unsubscribe(sessionExpiredSub);
   }, []);

   useEffect(() => {
      let fiveMinuteInterval;
      if(notificationMode === 'poll')
         fiveMinuteInterval = setInterval(() => {
            PubSub.publish('profile.reload-notifications');
         }, 5 * 60 * 1000);

      return () => fiveMinuteInterval ? clearInterval(fiveMinuteInterval) : null;
   }, [notificationMode]);

   useEffect(() => {
      let fiveSecondInterval;
      if(notificationMode === 'poll')
      fiveSecondInterval = setInterval(() => {
            PubSub.publish('profile.reload-stripe-subscription-status');
            // todo: check token expiration and warn user if it expires soon, using similar mechanism
         }, 5 * 1000);

      return () => fiveSecondInterval ? clearInterval(fiveSecondInterval) : null;
   }, [notificationMode]);

   useEffect(() => {
      const showSnackbarSub = PubSub.subscribe('notification.show-snackbar', setupSnackbar);
      return () => PubSub.unsubscribe(showSnackbarSub);
   }, []);

   const initNav = () => {
      getMyNotifications();
      getIsTrustee();
   };

   const profileUpdated = (msg, data) => {
      getBasicProfile();
   };

   // user menu
   const populateState = async () => {
      const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
      setIsAuthenticated(isAuthenticated);

      if(isAuthenticated) {
         initAfterAuth();

         const profileExists = await profileService.getProfileExists();
         if (profileExists)
            getBasicProfile();

         initNav();
      }

      setUserName(user && user.name);
   };

   const initAfterAuth = () => {
      const messaging = getMessaging();
      getToken(messaging, {
         vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
         // serviceWorkerRegistration: 
      }).then(async (currentToken) => {
         if (currentToken) {
            await firebaseMessageRegistrationService.registerToken(currentToken);
            setNotificationMode('push');
            registerFCMhandler(messaging);
         } else {
            console.log('No registration token available. Request permission to generate one.');
         }
      }).catch((err) => {
         switch(err.code) {
            case 'messaging/permission-blocked':
               console.log('setting to poll mode');
               setNotificationMode('poll');
               break;
            default:
               console.log('An error occurred while retrieving token. ', err);;
         }
      });

      syncStripeSubscription();
   };

   const registerFCMhandler = (messaging) => {
      onMessage(messaging, (payload) => {
         console.log('Firebase message received: ', payload);

         switch(payload.data.type) {
            case 'notification':
               // PubSub.publish('notification.push-on-list', {
               //    id: payload.messageId,
               //    title: payload.data.title,
               //    text: payload.data.text
               // });
               // break;
               PubSub.publish('profile.reload-notifications');
               break;
            // case 'deceased-report':
            //    PubSub.publish('profile.reload-notifications');
            //    break;
            case 'subscription-change':
               PubSub.publish('profile.reload-stripe-subscription-status');
               break;
            default:
               console.log(`unhandled message type ${payload.data.type}`);
         }
      });
   };

   const syncStripeSubscription = async () => {
      await profileService.syncStripeSubscription();
   };

   const getBasicProfile = async () => {
      setBasicProfile({ data: {}, loading: true });
      const basicProfile = await profileService.getBasicProfile();
      setBasicProfile({ data: basicProfile, loading: false });
   };

   // notifications
   const getMyNotifications = async () => {
      setNotifications({ data: [], loading: true });
      const notifications = await notificationService.getMyNotifications();
      setNotifications({ data: notifications, loading: false });
   };

   const [inviteToAccept, setInviteToAccept] = useState(null);

   const handleAcceptInviteClick = (notification) => {
      handleNotificationMenuClose();
      setInviteToAccept(notification);

      switch(notification.type) {
         case 'trustee-invite':
            // todo: using a promise may be a much more elegant way to handle this mechanism
            setAcknowledgeTrusteeResponsibilityDialogOpen(true);
            break;
         case 'family-profile-invite':
            setAcknowledgeMergeProfileDialogOpen(true);
            break;
         default:
            console.log('Unhandled notification type: ', notification.type);
      }
   };

   const handleRefuteDeceasedReportClick = async (notification) => {
      setNotifications({ data: [], loading: true });
      await notificationService.refuteDeceasedReport(notification.id);

      initNav();
   };

   const handleDismissNotificationClick = async (notification) => {
      setNotifications({ data: [], loading: true });

      switch(notification.type) {
         case 'trustee-invite':
            await notificationService.deleteTrusteeInvite(notification.referenceId);
            break;
         case 'family-profile-invite':
            await notificationService.deleteFamilyProfileInvite(notification.referenceId);
            break;
         default:
            await notificationService.deleteNotification(notification.id);
            break;
      }

      initNav();
   };

   const handleVisitLinkClick = (notification) => {
      handleNotificationMenuClose();

      if(notification.type !== 'link')
         return;

      if(notification.referenceId.startsWith('http'))
      navigateToExternalLink(notification.referenceId);
      else
         history.push(notification.referenceId);
   };

   const navigateToLinkFromSupportMenu = (url) => {
      handleSupportMenuClose();
      navigateToExternalLink(url);
   };

   const navigateToExternalLink = (url) => {
      window.open(url, '_blank');
   };

   // const pushNotificationOnList = async (msg, notification) => {
   //    setNotifications(currState => ({...currState, data: [...currState.data].concat(notification)}));
   // };

   // trustee invites
   const [acknowledgeTrusteeResponsibilityDialogOpen, setAcknowledgeTrusteeResponsibilityDialogOpen] = useState(false);

   const handleAcknowledgeTrusteeInviteResponsibility = async () => {
      setAcknowledgeTrusteeResponsibilityDialogOpen(false);
      setNotifications({ data: [], loading: true });
      await trusteeInviteService.acceptTrusteeInvite(inviteToAccept.referenceId);
      setInviteToAccept(null);

      initNav();
   };

   // family profile invites
   const [acknowledgeMergeProfileDialogOpen, setAcknowledgeMergeProfileDialogOpen] = useState(false);

   const handleAcknowledgeMergeProfile = async () => {
      setAcknowledgeMergeProfileDialogOpen(false);
      setNotifications({ data: [], loading: true });
      await familyProfileInviteService.acceptProfileInvite(inviteToAccept.referenceId);
      setInviteToAccept(null);

      initNav();
   };

   // trustors
   const [isTrustee, setIsTrustee] = useState(false);
   const [trustorProfiles, setTrustorProfiles] = useState({ data: [], loading: false });

   const getIsTrustee = async () => {
      const isTrustee = await trustorService.getIsTrustee();
      setIsTrustee(isTrustee);

      if(isTrustee)
         getTrustorProfiles();
   };

   const getTrustorProfiles = async () => {
      setTrustorProfiles({ data: [], loading: true });
      const trustorProfiles = await trustorService.getMyTrustorProfiles();
      setTrustorProfiles({ data: trustorProfiles, loading: false });
   };

   const [trustorMenuAnchorEl, setTrustorMenuAnchorEl] = useState(null);
   const handleTrustorMenuOpen = (event) => {
      setTrustorMenuAnchorEl(event.currentTarget);
   };
   const handleTrustorMenuClose = () => {
      setTrustorMenuAnchorEl(null);
   };

   const handleViewTrusteeFinalWishesClick = (id) => {
      handleTrustorMenuClose();
      history.push(`/tomb/${id}`);
   };

   // notification menu state
   const [notificationMenuAnchorEl, setNotificationMenuAnchorEl] = useState(null);
   const handleNotificationMenuOpen = (event) => {
      setNotificationMenuAnchorEl(event.currentTarget);
   };
   const handleNotificationMenuClose = () => {
      setNotificationMenuAnchorEl(null);
   };

   // help & support menu
   const [supportMenuAnchorEl, setSupportMenuAnchorEl] = useState(null);
   const handleSupportMenuOpen = (event) => {
      setSupportMenuAnchorEl(event.currentTarget);
   };
   const handleSupportMenuClose = () => {
      setSupportMenuAnchorEl(null);
   };

   // const handleCopyJWTToClipboardClick = async () => {
   //    const token = await authService.getAccessToken();
   //    copyToClipboard(token);
   //    handleSupportMenuClose(null);
   // };

   const [bugReportLoading, setBugReportLoading] = useState(false);
   const [reportBugDialogOpen, setReportBugDialogOpen] = useState(false);

   const [supportInquiryLoading, setSupportInquiryLoading] = useState(false);
   const [supportInquiryDialogOpen, setSupportInquiryDialogOpen] = useState(false);

   const handleOpenReportBugDialog = () => {
      setReportBugDialogOpen(true);
      handleSupportMenuClose(null);
   };
   
   const handleReportBug = async (bugReport) => {
      setBugReportLoading(true);
      await bugReportService.addBugReport(bugReport);
      setSnackBarOptions({
         open: true,
         severity: 'success',
         message: 'Bug Report Successfully Submitted.'
      });
      setBugReportLoading(false);
      setReportBugDialogOpen(false);
   };

   const handleOpenSupportInquiryDialog = () => {
      setSupportInquiryDialogOpen(true);
      handleSupportMenuClose(null);
   };
   
   const handleSupportInquiry = async (supportInquiry) => {
      setSupportInquiryLoading(true);
      await supportInquiryService.addSupportInquiry(supportInquiry);
      setSnackBarOptions({
         open: true,
         severity: 'success',
         message: 'Support Inquiry Successfully Submitted.'
      });
      setSupportInquiryLoading(false);
      setSupportInquiryDialogOpen(false);
   };

   // session acknowledgement dialog
   const [sessionExpirationAcknowledgeDialogOpen, setSessionExpirationAcknowledgeDialogOpen] = useState(false);

   const handleSessionExpirationAcknowledge = () => {
      setSessionExpirationAcknowledgeDialogOpen(false);

      history.push({pathname: ApplicationPaths.LogOut, state: { local: true }});
   };

   // todo: can we define this more globally?
   const Alert = React.forwardRef(function Alert(props, ref) {
      return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
   });

   const [snackBarOptions, setSnackBarOptions] = React.useState({
      open: false,
      autoHideDuration: 6000,
      severity: 'success',
      message: ''
   });

   const setupSnackbar = (event, options) => {
      setSnackBarOptions(options);
   };

   const handleSnackbarClose = (event, reason) => {
      if (reason === 'clickaway')
         return;

      setSnackBarOptions({...snackBarOptions, open: false});
   };

   const renderMainNavItems = () => {
      return (<Fragment>
         {basicProfile.data.id ? <Fragment>
         <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <Button
               variant="link"
               component={Link}
               to="/"
               // onClick={handleCloseNavMenu}
               startIcon={<SnowshoeingIcon />}>
               My Journey
            </Button>
            <Button
               variant="link"
               component={Link}
               to="/my-records"
               startIcon={<HouseIcon />}>
               My Records
            </Button>
            <Button
               variant="link"
               component={Link}
               to="/final-wishes"
               startIcon={<FavoriteBorderIcon />}>
               Final Wishes
            </Button>
            <Button
               variant="link"
               component={Link}
               to="/mausoleum"
               // startIcon={<MausoleumIconWhite sx={{ height: '20px' }} />}>
               startIcon={<MuseumIcon />}>
               Mausoleum
            </Button>
            <Button
               variant="link"
               component={Link}
               to="/mausolea-search"
               startIcon={<SearchIcon />}>
               Search Mausolea
            </Button>

            {isTrustee ? <Fragment>
               <LoadingButton
                  loading={trustorProfiles.loading}
                  aria-label="trustors"
                  aria-controls="trustor-menu"
                  aria-haspopup="true"
                  variant="link"
                  onClick={handleTrustorMenuOpen}
                  startIcon={<GavelIcon />}>
                  Trustors
               </LoadingButton>

               <Menu
                  id="trustor-menu"
                  anchorEl={trustorMenuAnchorEl}
                  anchorOrigin={{
                     vertical: 'bottom',
                     horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                     vertical: 'top',
                     horizontal: 'right',
                  }}
                  open={Boolean(trustorMenuAnchorEl)}
                  onClose={handleTrustorMenuClose}
               >
                  <Box sx={{ flexGrow: 1 }}>
                     {/* todo: move this into a component */}
                     <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        {trustorProfiles.data.map((profile) => {
                           return profile.users.map((user, index) => (
                              <Fragment key={user.id}>
                                 <ListItem
                                    alignItems="flex-start"
                                    secondaryAction={
                                       <Tooltip title={`View ${user.firstName}'s Tomb`}>
                                          <span>
                                             <IconButton
                                                onClick={() => handleViewTrusteeFinalWishesClick(user.id)}
                                                edge="end"
                                                aria-label={`View ${user.firstName}'s Tomb`}
                                             >
                                                <ListIcon />
                                             </IconButton>
                                          </span>
                                       </Tooltip>
                                    }
                                 >
                                    <ListItemAvatar>
                                       <AvatarInitials basicProfile={user} />
                                    </ListItemAvatar>
                                    <ListItemText
                                       primary={`${user.firstName} ${user.lastName}`}
                                       secondary={
                                       <Fragment>
                                          <Typography
                                          sx={{ display: 'inline' }}
                                          component="span"
                                          variant="body2"
                                          color="text.primary"
                                          >
                                             {user.reportedDeceasedAt ? 'Reported Deceased' : ''}
                                          </Typography>
                                       </Fragment>
                                       }
                                    />
                                 </ListItem>
                                 { index !== profile.users.length - 1 ? <Divider variant="inset" component="li" /> : null }
                              </Fragment>
                           ));
                        })}
                     </List>
                  </Box>
               </Menu>
            </Fragment> : null }
         </Box>

         {/* notification menu */}
         <Tooltip title="Notifications">
            <span>
               <IconButton
                  size="large"
                  aria-label="notifications"
                  aria-controls="notifications-menu"
                  aria-haspopup="true"
                  onClick={handleNotificationMenuOpen}
                  color="inherit"
               >
                  <Badge badgeContent={notifications.data.length} color="primary">
                     <NotificationsIcon />
                  </Badge>
               </IconButton>
            </span>
         </Tooltip>
         <Menu
            id="notifications-menu"
            anchorEl={notificationMenuAnchorEl}
            anchorOrigin={{
               vertical: 'bottom',
               horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
               vertical: 'top',
               horizontal: 'right',
            }}
            open={Boolean(notificationMenuAnchorEl)}
            onClose={handleNotificationMenuClose}
         >
            <NotificationList
               loading={notifications.loading}
               notifications={notifications.data}
               onAcceptInvite={handleAcceptInviteClick}
               onRefuteDeceasedReport={handleRefuteDeceasedReportClick}
               onDismiss={handleDismissNotificationClick}
               onVisitLink={handleVisitLinkClick}
            />
         </Menu>
         </Fragment> : null }

         {/* support menu */}
         <Tooltip title="Help & Support">
            <span>
               <IconButton
                  size="large"
                  aria-label="help and support"
                  aria-controls="help-support-menu"
                  aria-haspopup="true"
                  onClick={handleSupportMenuOpen}
                  color="inherit"
               >
                  <HelpIcon />
               </IconButton>
            </span>
         </Tooltip>
         <Menu
            id="help-support-menu"
            anchorEl={supportMenuAnchorEl}
            anchorOrigin={{
               vertical: 'bottom',
               horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
               vertical: 'top',
               horizontal: 'right',
            }}
            open={Boolean(supportMenuAnchorEl)}
            onClose={handleSupportMenuClose}
         >
            <MenuItem onClick={() => navigateToLinkFromSupportMenu('https://www.youtube.com/@LifeTomb/featured')}>
               <ListItemIcon>
                  <OndemandVideoIcon fontSize="small" />
               </ListItemIcon>
               Help Videos
            </MenuItem>
            <MenuItem onClick={() => navigateToLinkFromSupportMenu('https://lifetomb.com/about')}>
               <ListItemIcon>
                  <QuestionAnswerIcon fontSize="small" />
               </ListItemIcon>
               FAQ
            </MenuItem>
            <MenuItem onClick={handleOpenReportBugDialog}>
               <ListItemIcon>
                  <BugReportIcon fontSize="small" />
               </ListItemIcon>
               Report Bug / Issue
            </MenuItem>
            <MenuItem onClick={handleOpenSupportInquiryDialog}>
               <ListItemIcon>
                  <HelpIcon fontSize="small" />
               </ListItemIcon>
               Create Support Inquiry
            </MenuItem>
            {/* <MenuItem onClick={handleCopyJWTToClipboardClick}>Copy JWT</MenuItem> */}
            {/* <MenuItem onClick={() => PubSub.publish('auth.session-expired')}>Pub Session Expiration</MenuItem> */}
         </Menu>
      </Fragment>);
   };

   return (
      <Fragment>
         <AppBar position="sticky" color="secondary">
            <Container maxWidth="xl">
               <Toolbar disableGutters>
                  <Typography
                     variant="h6"
                     noWrap
                     component="div"
                     sx={{ mr: 2, flexGrow: 1, display: { xs: 'none', md: 'flex' } }}
                  >
                     <Box component="img" src="/logo_token_blue_solid_96.png" alt="LifeTomb" sx={{ position: 'absolute', top: '4px', background: '#3b434c', borderRadius: '100%', padding: '8px' }} />
                  </Typography>

                  <Typography
                     variant="h6"
                     noWrap
                     component="div"
                     sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
                  >
                     <Box component="img" src="/logo_token_blue_solid_96.png" alt="LifeTomb" sx={{ position: 'absolute', top: '4px', background: '#3b434c', borderRadius: '100%', padding: '8px' }} />
                  </Typography>

                  {isAuthenticated ? renderMainNavItems() : null}

                  <LoginMenu
                     isAuthenticated={isAuthenticated}
                     userName={userName}
                     basicProfile={basicProfile.data}
                  />
               </Toolbar>
            </Container>
         </AppBar>

         <YesNoDialog
            open={acknowledgeTrusteeResponsibilityDialogOpen}
            title="Accept the responsibility of being a trustee?"
            description="As a trustee, you will need to accept certain responsibilities in the event of this profile holder's death."
            dismissButtonLabel="Cancel"
            affirmButtonLabel="Accept"
            handleDismiss={() => setAcknowledgeTrusteeResponsibilityDialogOpen(false)}
            handleAffirm={handleAcknowledgeTrusteeInviteResponsibility}
         />

         <YesNoDialog
            open={acknowledgeMergeProfileDialogOpen}
            title="Join Profile &amp; Merge Records?"
            description="If you choose to join this profile, your records will be merged."
            dismissButtonLabel="Cancel"
            affirmButtonLabel="Join &amp; Merge"
            handleDismiss={() => setAcknowledgeMergeProfileDialogOpen(false)}
            handleAffirm={handleAcknowledgeMergeProfile}
         />

         <AlertDialog
            open={sessionExpirationAcknowledgeDialogOpen}
            title="Session Expired"
            description="Your session has expired. Please re-enter your credentials to keep your account secure."
            affirmButtonLabel="Ok"
            handleAffirm={handleSessionExpirationAcknowledge}
         />

         <ReportBugDialog
            open={reportBugDialogOpen}
            handleClose={() => setReportBugDialogOpen(false)}
            handleReportBug={handleReportBug}
            loading={bugReportLoading}
         />

         <SupportInquiryDialog
            open={supportInquiryDialogOpen}
            handleClose={() => setSupportInquiryDialogOpen(false)}
            handleSupportInquiry={handleSupportInquiry}
            loading={supportInquiryLoading}
         />

         <Snackbar open={snackBarOptions.open} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}} autoHideDuration={snackBarOptions.autoHideDuration} onClose={handleSnackbarClose}>
            <Alert onClose={handleSnackbarClose} severity={snackBarOptions.severity}>
               {snackBarOptions.message}
            </Alert>
         </Snackbar>
      </Fragment>
   );
};

export default TopAppBar;
