import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '../utils/useQuery';
import Box from '@mui/material/Box';
import LoaderIcon from '../components/LoaderIcon';
import Alert from '@mui/material/Alert';
import subscriptionService from '../data/SubscriptionService';
import SubscriptionPicker from '../components/SubscriptionPicker';
import systemLookupService from '../data/SystemLookupService';

const StartSubscriptionPage = () => {
   const query = useQuery();

   const [currentSubscriptionStatus, setCurrentSubscriptionStatus] = useState({data: null, loading: false});
   const [subscriptionTiers, setSubscriptionTiers] = useState({ data: [], loading: false });
   const [subscriptionSessionLoading, setSubscriptionSessionLoading] = useState(false);

   let subscriptionTier = query.get('subscription-tier') || null;
   let subscriptionPurchaseResult = query.get('subscription-purchase-result') || null;

   useEffect(() => {
      getCurrentSubscriptionStatus();
      getSubscriptionTiers();
   }, [subscriptionTier, subscriptionPurchaseResult]);

   const history = useHistory();

   const getCurrentSubscriptionStatus = async () => {
      setCurrentSubscriptionStatus({data: null, loading: true});
      const subscriptionStatus = await subscriptionService.getCurrentStatus();
      setCurrentSubscriptionStatus({data: subscriptionStatus, loading: false});

      if(subscriptionStatus.id === 'free' && subscriptionTier && subscriptionTier !== 'free')
         initSubscription();
   };

   const getSubscriptionTiers = async () => {
      setSubscriptionTiers({ data: [], loading: true });
      const subscriptionTiers = await systemLookupService.getSubscriptionTiers();
      setSubscriptionTiers({ data: subscriptionTiers, loading: false });
   };

   const initSubscription = async () => {
      setSubscriptionSessionLoading(true);
      const subscriptionSession = await subscriptionService.postCheckoutSession(subscriptionTier, `start-subscription-${subscriptionTier}`);

      window.location.href = subscriptionSession.checkoutURL;
   };

   const handleNewOrChangeSubscription = (newTier) => history.push(`/start-subscription?subscription-tier=${newTier}`);

   if (currentSubscriptionStatus.loading || subscriptionTiers.loading)
      return <LoaderIcon text="Verifiying subscription status..." />;
   else if(subscriptionTier && currentSubscriptionStatus.data && !subscriptionSessionLoading)
      return <LoaderIcon text={`Subscription Status: ${currentSubscriptionStatus.data.name}`} />
   else if(subscriptionTier && subscriptionSessionLoading)
      return <LoaderIcon text={"Loading billing portal..."} />
   else if(!subscriptionTier && subscriptionPurchaseResult === 'cancelled' && currentSubscriptionStatus.data)
      return (<Box sx={{ flexGrow: 1 }}>
         <SubscriptionPicker
            subscriptionStatus={currentSubscriptionStatus.data}
            subscriptionTiers={subscriptionTiers.data}
            isStripeCustomer={false}
            onNewSubscription={handleNewOrChangeSubscription}
            onChangeSubscription={handleNewOrChangeSubscription}
            onCancelSubscription={() => console.log('onCancelSubscription')}
            onContinueWithoutChange={() => history.push('/get-started-optional?page=1')}
            onBillingPortalClick={() => console.log('onCancelSubscription')}
         />
      </Box>);
   else
      return (<Box sx={{ flexGrow: 1 }}>
         <Alert severity="error">Invalid parameters provided.</Alert>
      </Box>);
};

export default StartSubscriptionPage;
