import axiosService from './AxiosService';

export class BugReportService {
   _resourceName = 'bug-report';

   async addBugReport(bugReport) {
      return await axiosService.create(`/v1.0/${this._resourceName}`, bugReport);
   }

   static get instance() { return bugReportService }
}

const bugReportService = new BugReportService();

export default bugReportService;
