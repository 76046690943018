import axiosService from './AxiosService';

export class AddressService {
   _resourceName = 'address';

   async getSavedAddresses() {
      return await axiosService.readObject(`/v1.0/${this._resourceName}`);
   }

   static get instance() { return addressService }
}

const addressService = new AddressService();

export default addressService;
