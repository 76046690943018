import axiosService from './AxiosService';

export class SupportInquiryService {
   _resourceName = 'support-inquiry';

   async addSupportInquiry(supportInquiry) {
      return await axiosService.create(`/v1.0/${this._resourceName}`, supportInquiry);
   }

   static get instance() { return supportInquiryService }
}

const supportInquiryService = new SupportInquiryService();

export default supportInquiryService;
