import React, { useState, forwardRef, useImperativeHandle, Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import LoaderIcon from './LoaderIcon';
import TextAreaWithLimit from './TextAreaWithLimit';
import { MenuItem, Select } from '@mui/material';
import systemLookupService from '../data/SystemLookupService';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';

const BioQuestionDialog = forwardRef((props, ref) => {
   const initialState = {
      id: ref.id,
      answer: ref.answer,
   };
   const [formValues, setFormValues] = useState(initialState);

   const [questions, setQuestions] = useState({ data: [], loading: false });
   const [selectedQuestion, setSelectedQuestion] = useState('select-question');

   // validation
   const [formValidation, setFormValidation] = useState({});
   const formIsValid = () => {
      let errors = {};

      if (formValues.answer.length < 2)
         errors.answer = 'Ansser must be at least two characters.';

      setFormValidation(errors);

      return Object.keys(errors).length === 0;
   };

   // control validation, state from parent component
   useImperativeHandle(ref, () => ({
      setState(state) {
         setFormValues(state);

         setSelectedQuestion((state && state.question && state.question.id) || 'select-question');
      },

      async loadQuestions (question) {
         await getQuestions(question);
      },
   }));

   // lookups
   const getQuestions = async (question) => {
      setQuestions({ data: [], loading: true });
      var questions = '';
      if(question) {
         questions = await systemLookupService.getBioQuestions();
      }
      else
         questions = await systemLookupService.getFilteredBioQuestions();
      setQuestions({ data: questions, loading: false });
   };

   const handleAddSaveBioQuestionButtonClick = () => {
      if (formIsValid()) {
         props.handleAddSaveBioQuestion({
            id: formValues.id,
            question: questions.data.find(element => element.id === selectedQuestion),
            answer: formValues.answer,
         });
      }

      setFormValues({ ...initialState });
      setSelectedQuestion('select-question');
   };

   const answerMaxLength = 4000;

   return (
      <Dialog open={props.open} onClose={props.handleClose}>
         <DialogTitle>{formValues.id ? 'Edit' : 'Add'} Bio Question</DialogTitle>
         <DialogContent>
            {!props.loading ? <Box component="form">
               <Grid container spacing={1}>
                  <Grid item xs={12}>
                     <DialogContentText>
                        <b>Select a question from the drop down:</b>
                     </DialogContentText>
                  </Grid>
                  <Grid item md={12}>
                     <FormControl fullWidth>
                        <Select
                           id="bio-question-select"
                           value={selectedQuestion}
                           onChange={event => setSelectedQuestion(event.target.value)}
                           disabled={formValues.id ? true : false}
                        >
                           <MenuItem value="select-question">--- Select Question ---</MenuItem>
                           {questions.data.map(question => (<MenuItem key={question.id} value={question.id}>{question.name}</MenuItem>))}
                        </Select>
                     </FormControl>
                  </Grid>
               
                  <Fragment>
                     <Grid item xs={12}>
                        <DialogContentText>
                           <b>Answer</b>
                        </DialogContentText>
                     </Grid>
                     <Grid item xs={12}>
                        <FormControl fullWidth>
                           <TextAreaWithLimit
                              id="answer-input"
                              label="Answer"
                              maxLength={answerMaxLength} 
                              value={formValues.answer} 
                              onChange={event => setFormValues({ ...formValues, answer: event.target.value })}
                              error={formValidation.answer ? true : false}
                              helperText={formValidation.answer}
                              rows={3}
                           />
                        </FormControl>
                     </Grid>
                  </Fragment>
               </Grid>
            </Box> : <LoaderIcon />}
         </DialogContent>
         <DialogActions>
            <Button variant="outlined" startIcon={<CancelIcon />} onClick={props.handleClose}>Cancel</Button>
            <Button variant="contained" startIcon={<CheckIcon />} disabled={selectedQuestion === 'select-question'} onClick={handleAddSaveBioQuestionButtonClick}>{formValues.id ? 'Save' : 'Add Question'}</Button>
         </DialogActions>
      </Dialog>
   );
});

BioQuestionDialog.propTypes = {
   open: PropTypes.bool.isRequired,
   handleClose: PropTypes.func.isRequired,
   handleAddSaveBioQuestion: PropTypes.func.isRequired,
};

export default BioQuestionDialog;
