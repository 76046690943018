// Public Mausoleum

import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import profileService from '../data/ProfileService';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LoaderIcon from '../components/LoaderIcon';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import mausoleumService, { parseMausoleumDates } from '../data/MausoleumService';
import CommentWidget from '../components/CommentWidget';
import Typography from '@mui/material/Typography';
import PhotoBrowser from '../components/PhotoBrowser';
import MausoleumHeader from '../components/MausoleumHeader';
import BioQuestionWidget from '../components/BioQuestionWidget';

const MausoleumDetailPage = () => {
   let { mausoleumId } = useParams();

   const [mausoleum, setMausoleum] = useState({ data: {}, loading: true });
   const [mausoleumComments, setMausoleumComments] = useState({ data: [], limits: {}, loading: true });
   const [mausoleumPhotos, setMausoleumPhotos] = useState({ data: [], loading: true });

   useEffect(() => {
      checkProfileExists();
   }, [mausoleumId]);

   const history = useHistory();

   const checkProfileExists = async () => {
      const profileExists = await profileService.getProfileExists();
      if (profileExists) {
         getMausoleum(mausoleumId);
         getMausoleumComments(mausoleumId);
         getMausoleumPhotos(mausoleumId);
         getMausoleumBioAnswers(mausoleumId);
      } else {
         // todo: either move this somewhere generic and/or add to other pages
         //go to "get started wizard"
         history.push('/get-started');
      }
   };

   // mausoleum
   const getMausoleum = async (mausoleumId) => {
      setMausoleum({ data: {}, loading: true });
      const mausoleum = await mausoleumService.getMausoleum(mausoleumId);
      setMausoleum({ data: parseMausoleumDates(mausoleum), loading: false });
   };

   const handleFavoriteMausoleumClick = async () => {
      setMausoleum({ ...mausoleum, loading: true });

      if(mausoleum.data.isFavorite)
         await mausoleumService.unfavorite(mausoleum.data.id);
      else
         await mausoleumService.favorite(mausoleum.data.id);

      getMausoleum(mausoleumId);
   };

   // comments
   const getMausoleumComments = async (mausoleumId) => {
      setMausoleumComments({ data: [], loading: true });
      const comments = await mausoleumService.getMausoleumComments(mausoleumId);
      setMausoleumComments({ data: comments.data, limits: comments.limits, loading: false });
   };

   const handleAddComment = async (comment) => {
      setMausoleumComments({...mausoleumComments, loading: true });
      await mausoleumService.addComment(mausoleumId, comment);
      getMausoleumComments(mausoleumId);
   };

   const handleReportComment = async (commentId) => {
      setMausoleumComments({...mausoleumComments, loading: true });
      await mausoleumService.reportComment(commentId);
      getMausoleumComments(mausoleumId);
   };

   // mausoleum photos
   const getMausoleumPhotos = async (mausoleumId) => {
      setMausoleumPhotos({ data: [], loading: true });
      const mausoleumPhotos = await mausoleumService.getMausoleumPhotos(mausoleumId);
      setMausoleumPhotos({ data: mausoleumPhotos, loading: false });
   };

   // bio questions
   const [bioAnswers, setBioAnswers] = useState({ data: [], loading: true });

   const getMausoleumBioAnswers = async (mausoleumId) => {
      setBioAnswers({ data: [], loading: true });
      const bioAnswers = await mausoleumService.getMausoleumBioAnswers(mausoleumId);
      setBioAnswers({ data: bioAnswers, loading: false });
   };

   if(mausoleum.loading)
      return (<LoaderIcon />)

   return (<Box sx={{ flexGrow: 1 }}>
      <Card sx={{ borderRadius: '0.9em' }} elevation={3}>
         <MausoleumHeader
            loading={mausoleum.loading}
            mausoleum={mausoleum.data}
            onFavoriteToggle={handleFavoriteMausoleumClick}
            allowEditBanner={false}
         />
         {mausoleum.data.isDeceased ? <Grid container spacing={1}>
            
            <Grid item xs={12} md={6}>
               <CardContent>
                  <Grid container spacing={1}>
                     <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom component="div" sx={{ borderBottom: '3px solid #00a5de' }}>
                           Photos
                        </Typography>
                     </Grid>
                  </Grid>
                  <Grid container spacing={1} sx={{pt: 1}}>
                     <PhotoBrowser
                        photos={mausoleumPhotos.data}
                        emptyMessage="This mausoleum doesn't have any photos."
                     />
                  </Grid>
               </CardContent>
            </Grid>
            <Grid item xs={12} md={6}>
               <CardContent>
                  <Grid container spacing={1}>
                     <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom component="div" sx={{ borderBottom: '3px solid #00a5de' }}>
                           More About Me
                        </Typography>
                     </Grid>
                     <Grid item xs={12} maxHeight={"30em"} sx={{overflowY: 'auto'}}>
                        <BioQuestionWidget
                           answers={bioAnswers.data}
                           loading={bioAnswers.loading}
                           columns={2}
                        />
                     </Grid>
                  </Grid>
               </CardContent>
            </Grid>
            <Grid item xs={12}>
               <CardContent>
                  <Grid container spacing={1}>
                     <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom component="div" sx={{ borderBottom: '3px solid #00a5de' }}>
                           Comments
                        </Typography>
                     </Grid>
                     <Grid item xs={12} maxHeight={"30em"} sx={{overflowY: 'auto'}}>
                        <CommentWidget
                           comments={mausoleumComments.data}
                           onAddComment={handleAddComment}
                           onReportComment={handleReportComment}
                           loading={mausoleumComments.loading}
                           allowAddingComments={(mausoleumComments.limits && mausoleumComments.limits.mausoleumComment === 1) ? true : false}
                        />
                     </Grid>
                  </Grid>
               </CardContent>
            </Grid>
         </Grid> : null}
      </Card>
   </Box>);
};

export default MausoleumDetailPage;
