import axiosService from './AxiosService';

export class AssetService {
   _resourceName = 'asset';

   async addAsset(asset) {
      return await axiosService.create(`/v1.0/${this._resourceName}`, asset);
   }

   async getAssets(assetTypeId) {
      return await axiosService.readList(`/v1.0/${this._resourceName}/search${(assetTypeId ? `/${assetTypeId}` : '')}`);
   }

   async deleteAsset(id) {
      return await axiosService.delete(`/v1.0/${this._resourceName}`, id);
   }

   async getAssetCounts() {
      return await axiosService.readList(`/v1.0/${this._resourceName}/count`);
   }

   async putAsset(asset) {
      return await axiosService.update(`/v1.0/${this._resourceName}`, asset);
   }

   async getBeneficiariesFor(assetId) {
      return await axiosService.readList(`/v1.0/${this._resourceName}/${assetId}/beneficiaries`);
   }

   async getOtherContactsForAsset(assetId) {
      return await axiosService.readList(`/v1.0/${this._resourceName}/${assetId}/other-contacts`);
   }

   async postOtherContactBeneficiaryFor(assetId, otherContactId) {
      return await axiosService.create(`/v1.0/${this._resourceName}/${assetId}/other-contact-beneficiary/${otherContactId}`);
   }

   async deleteOtherContactBeneficiary(id) {
      return await axiosService.delete(`/v1.0/${this._resourceName}/other-contact-beneficiary`, id);
   }

   static get instance() { return assetService }
}

const assetService = new AssetService();

export default assetService;
