import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Carousel from 'react-material-ui-carousel'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
   '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
   },
   '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
   },
}));

const PhotoBrowser = (props) => {
   const [openPhoto, setOpenPhoto] = useState({isOpen: false, photo: null});

   const handleClickPhoto = (photo) => {
      if(typeof props.selectPhoto === 'function')
         props.selectPhoto(photo.id);
      else
         setOpenPhoto({isOpen: true, photo: photo});
   };

   const handleClose = () => {
      setOpenPhoto({isOpen: false, photo: openPhoto.photo});
   };

   return (
      <Fragment>
         <Grid item xs={12}>
            {props.photos.length === 0 ? <Alert severity="info">{props.emptyMessage ? props.emptyMessage : 'No photos to display'}</Alert> : <ImageList
               sx={{ width: 'auto', maxHeight: "30em", padding: 1}} cols={3}>
                  {props.photos.map((photo) => (
                     <ImageListItem key={photo.id} sx={{ cursor: 'pointer', border: photo.selected ? '3px solid #00a5de' : 'none' }}>
                        <img
                           src={photo.base64PhotoScaled}
                           srcSet={photo.base64PhotoScaled}
                           alt={photo.caption}
                           loading="lazy"
                           onClick={()=>{handleClickPhoto(photo)}}
                        />
                     </ImageListItem>
                  ))}
            </ImageList>}
         </Grid>
         <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={openPhoto.isOpen}
            PaperProps={{
               style: {
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                  width: '100%'
               },
            }}
         >
            <DialogContent dividers>
               {openPhoto.photo ?
                  <Carousel 
                     navButtonsAlwaysVisible
                     autoPlay={false}
                     animation='slide'
                     index={props.photos.indexOf(openPhoto.photo)} 
                     sx={{textAlign:'center', justifyContent:'center'}}>
                     {
                        props.photos.map((photo) => (
                           <figure key={photo.id} className='carousel-image'>
                              <img
                                 width="100%"
                                 height="100%"
                                 src={photo.base64PhotoScaled}
                                 srcSet={photo.base64PhotoScaled}
                                 alt={photo.caption}
                                 loading="lazy"
                              />
                              {photo.caption ?
                              <figcaption>{photo.caption}</figcaption> : ''}
                           </figure>
                        ))
                     }
                  </Carousel>
                  // <ImageList cols={1}>
                  //    <ImageListItem key={openPhoto.photo.id}>
                  //       <img
                  //          src={openPhoto.photo.base64PhotoScaled}
                  //          srcSet={openPhoto.photo.base64PhotoScaled}
                  //          alt={openPhoto.photo.caption}
                  //          loading="lazy"
                  //       />
                  //       {openPhoto.photo.caption ?
                  //       <ImageListItemBar
                  //          title={openPhoto.photo.caption}
                  //          sx={{
                  //             backgroundColor: '#00000099',
                  //             borderTop: '2px solid #fff',
                  //          }}
                  //       /> : ''}
                  //       </ImageListItem>
                  // </ImageList>
               : ''}
            </DialogContent>
         </BootstrapDialog>
      </Fragment>
   );
};

PhotoBrowser.propTypes = {
   photos: PropTypes.arrayOf(PropTypes.object),
   emptyMessage: PropTypes.string,
   selectPhoto: PropTypes.func,
};

export default PhotoBrowser;
