import React from 'react';
import { Box } from '@mui/material';

const MausoleumIconBlue = (props) => {
   return (
      <Box {...props}>
         <img style={{width: 'inherit', height: 'inherit', display: 'flex'}} src="./mausoleum-icon-blue.svg"/>
      </Box>
   );
};

export default MausoleumIconBlue;
