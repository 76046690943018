import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import EnhancedTable from '../components/EnhancedTable';
import { BeneficiaryColConfig } from '../components/TableColumnConfigs';
import CheckIcon from '@mui/icons-material/Check';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import DeleteIcon from '@mui/icons-material/Delete';
import ImportantContactListItem from '../components/ImportantContactListItem';
import Alert from '@mui/material/Alert';
import MUILink from '@mui/material/Link';

const BeneficiariesDialog = forwardRef((props, ref) => {
   const history = useHistory();

   const [item, setItem] = useState(null);
   const [beneficiaries, setBeneficiaries] = useState({ otherContacts: [], trustees: [], loading: true });
   const [otherContacts, setOtherContacts] = useState({ data: [], loading: true });

   // control validation, state from parent component
   useImperativeHandle(ref, () => ({
      async init (item) {
         setItem(item);
         await getOtherContacts(item.id);
         await getBeneficiariesForItem(item.id);
      },
   }));

   const getOtherContacts = async (itemId) => {
      setOtherContacts({ data: [], loading: true });
      const otherContacts = await props.onGetOtherContactsForItem(itemId);
      setOtherContacts({ data: otherContacts, loading: false });
   };

   const getBeneficiariesForItem = async (itemId) => {
      setBeneficiaries({ otherContacts: [], trustees: [], loading: true });
      const beneficiaries = await props.onGetBeneficiariesForItem(itemId);
      setBeneficiaries({
         ...beneficiaries,
         loading: false
      });
   };

   const [otherContactAnchorEl, setOtherContactAnchorEl] = React.useState(null);

   const handleAddOtherContact = (event) => {
      setOtherContactAnchorEl(event.currentTarget);
   };
  
   const handleCloseOtherContactPopOver = () => {
      setOtherContactAnchorEl(null);
   };
  
   const openContactPopOverOpen = Boolean(otherContactAnchorEl);
   const otherContactPopOverId = openContactPopOverOpen ? 'add-other-contact-popover' : undefined;

   const handleAddOtherContactBeneficiary = async (otherContact) => {
      await props.onAddOtherContactBeneficiary(item, otherContact);
      handleCloseOtherContactPopOver();
      await getBeneficiariesForItem(item.id);
      await getOtherContacts(item.id);
   };

   const handleDeleteOtherContactBeneficiary = async (beneficiary) => {
      setBeneficiaries({ ...beneficiaries, loading: true });
      await props.onDeleteOtherContactBeneficiary(beneficiary.id);
      await getBeneficiariesForItem(item.id);
      await getOtherContacts(item.id);
   };

   const handleNavigateToContacts = () => {
      history.push('/contacts');
   };

   return (
      <Dialog
         open={props.open}
         onClose={props.handleClose}
         fullWidth={true}
         // maxWidth={DialogProps['maxWidth']}
         maxWidth={'md'}
         scroll="body"
      >
         {/* todo: build a nice card here that shows the asset/institution you're managing beneficiaries for */}
         <DialogTitle>Manage Beneficiaries for Asset {item ? item.identifier : null}</DialogTitle>

         <DialogContent>
            {/* <LTIconButton
               disabled={beneficiaries.loading}
               tooltipText="Add Important Contact"
               ariaLabel="add important contact"
               onClick={handleAddOtherContact}
               icon={<AddIcon />}
            /> */}
            <Button aria-describedby={otherContactPopOverId} variant="contained" onClick={handleAddOtherContact}>
               Add Important Contact
            </Button>
            <Popover
               id={otherContactPopOverId}
               open={openContactPopOverOpen}
               anchorEl={otherContactAnchorEl}
               onClose={handleCloseOtherContactPopOver}
               anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
               }}
            >
               <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                  <nav aria-label="other contacts">
                     {otherContacts.data.length ? <List>
                        {otherContacts.data.map((otherContact) => <ImportantContactListItem
                           key={otherContact.id}
                           contact={otherContact}
                           handleImportantContactClick={handleAddOtherContactBeneficiary}
                        />)}
                     </List> : <Alert severity="info">There are no other contacts available which have not been assigned. Add more in <MUILink onClick={handleNavigateToContacts} sx={{cursor: 'pointer'}}>contacts</MUILink>.</Alert>}
                  </nav>
               </Box>
            </Popover>

            <EnhancedTable
               loading={beneficiaries.loading}
               emptyMessage="No beneficiaries have been added yet."
               data={beneficiaries.otherContacts}
               colConfig={BeneficiaryColConfig}
               rowActions={[{
                  toolTip: 'Delete',
                  icon: <DeleteIcon />,
                  confirmDialogConfig: {
                     title: "Delete Beneficiary?",
                     description: "Are you sure you want to delete this beneficiary?",
                     dismissButtonLabel: "No",
                     affirmButtonLabel: "Yes",
                  },
                  onClick: handleDeleteOtherContactBeneficiary,
               }]}
            />
         </DialogContent>
         <DialogActions>
            <Button variant="outlined" startIcon={<CheckIcon />} onClick={props.handleClose}>Close</Button>
         </DialogActions>
      </Dialog>
   );
});

BeneficiariesDialog.propTypes = {
   open: PropTypes.bool.isRequired,
   handleClose: PropTypes.func.isRequired,
   onAddOtherContactBeneficiary: PropTypes.func.isRequired,
   onGetBeneficiariesForItem: PropTypes.func.isRequired,
   onDeleteOtherContactBeneficiary: PropTypes.func.isRequired,
   onGetOtherContactsForItem: PropTypes.func.isRequired,
};

export default BeneficiariesDialog;
