import axiosService from './AxiosService';

export class DiaryEntryService {
   _resourceName = 'diary-entry';

   async getDiaryEntries(year) {
      return await axiosService.readList(`/v1.0/${this._resourceName}/search`, `/${year}`);
   }

   async postDiaryEntry(diaryEntry) {
      return await axiosService.create(`/v1.0/${this._resourceName}`, diaryEntry);
   }

   async putDiaryEntry(diaryEntry) {
      return await axiosService.update(`/v1.0/${this._resourceName}`, diaryEntry);
   }

   async deleteDiaryEntry(id) {
      return await axiosService.delete(`/v1.0/${this._resourceName}`, id);
   }

   static get instance() { return diaryEntryService }
}

const diaryEntryService = new DiaryEntryService();

export default diaryEntryService;
