import axiosService from './AxiosService';

export class JourneyPhotoService {
   _resourceName = 'journey-photo';

   async postJourneyPhoto(journeyPhoto) {
      return await axiosService.create(`/v1.0/${this._resourceName}`, journeyPhoto);
   }

   async putJourneyPhoto(journeyPhoto) {
      return await axiosService.update(`/v1.0/${this._resourceName}`, journeyPhoto);
   }

   async deleteJourneyPhoto(id) {
      return await axiosService.delete(`/v1.0/${this._resourceName}`, id);
   }

   async toggleShowInMausoleum(id) {
      return await axiosService.updateFlag(`/v1.0/${this._resourceName}`, 'show-on-mausoleum', id);
   }

   static get instance() { return journeyPhotoService }
}

const journeyPhotoService = new JourneyPhotoService();

export default journeyPhotoService;
