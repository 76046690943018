import React, { useState, useRef, Fragment, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import formatISO from 'date-fns/formatISO';
import parseISO from 'date-fns/parseISO';
import LoaderIcon from './LoaderIcon';
import journeyMonthService from '../data/JourneyMonthService';
import PhotoUploader from './PhotoUploader';
import TextAreaWithLimit from './TextAreaWithLimit';
import DateField from './DateField';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';

const JourneyMonthDialog = (props) => {
   const photoUploaderRef = useRef();

   const initialState = {
      forMonth: props.forMonth || null,
      summary: '',
   };
   const [formValues, setFormValues] = useState(initialState);
   const [monthCheck, setMonthCheck] = useState({ monthExists: false, monthChecked: false, loading: false });

   useEffect(() => {
      setFormValues({forMonth: props.forMonth});
      handleForMonthChange(props.forMonth);
   }, [props.forMonth]);

   // validation
   const [formValidation, setFormValidation] = useState({});
   const formIsValid = () => {
      let errors = {};

      if (!formValues.forMonth)
         errors.forMonth = 'For Month must be provided.';
      if (formValues.summary && formValues.summary.length < 2)
         errors.summary = 'Summary must be at least two characters if provided.';

      // validate photos to be uploaded
      let photosValid = false;
      if(photoUploaderRef.current)
         photosValid = photoUploaderRef.current.validate();

      setFormValidation(errors);

      return Object.keys(errors).length === 0 && photosValid;
   };

   const cleanData = (formValues) => {
      return {
         ...formValues,
         summary: formValues.summary ? formValues.summary : null,
         forMonth: formatISO(formValues.forMonth),
      };
   };

   const handleAddJourneyMonthButtonClick = () => {
      if (formIsValid() && photoUploaderRef.current) {
         props.handleAddJourneyMonth({
            ...cleanData(formValues),
         }, photoUploaderRef.current.getPhotos());

         photoUploaderRef.current.reset()
         setFormValues({ ...initialState });
         handleForMonthChange(null);
      }
   };

   const handleForMonthChange = async (newValue) => {
      if(!newValue)
         return;

      const isoMonth = formatISO(newValue, { representation: 'date' });
      setMonthCheck({ monthExists: false, monthChecked: false, loading: true });
      setMonthCheck({ monthExists: (await journeyMonthService.getJourneyMonthExists(isoMonth)).monthExists, monthChecked: true, loading: false });
      setFormValues({ ...formValues, forMonth: parseISO(isoMonth) });
   };

   return (
      <Dialog open={props.open} onClose={props.handleClose}>
         <DialogTitle>Add Month to Your Journey</DialogTitle>
         <DialogContent>
            {!props.loading ? <Box component="form">
               <Grid container spacing={1}>
                  <Grid item xs={12}>
                     <DialogContentText>
                        <b>Which month would you like to remember?</b>
                     </DialogContentText>
                  </Grid>
                  <Grid item md={12}>
                     <FormControl fullWidth>
                        <DateField
                           label="Journey Month"
                           value={formValues.forMonth}
                           onChange={handleForMonthChange}
                           error={(!monthCheck.loading && monthCheck.monthExists && monthCheck.monthChecked) ? 'This month has already been added to your journey.' : null}
                           views={['year', 'month']}
                        />
                     </FormControl>
                  </Grid>
               
                  {monthCheck.loading ? <LoaderIcon /> : null}
                  {(!monthCheck.loading && !monthCheck.monthExists && monthCheck.monthChecked && formValues.forMonth) ? <Fragment>
                     <Grid item xs={12}>
                        <DialogContentText>
                           <b>Summarize the month's highlights.</b>
                        </DialogContentText>
                     </Grid>
                     <Grid item xs={12}>
                        <FormControl fullWidth>
                           <TextAreaWithLimit
                              id="summary-input"
                              label="Summary"
                              maxLength={4000}
                              value={formValues.summary}
                              onChange={event => setFormValues({ ...formValues, summary: event.target.value })}
                              error={formValidation.summary ? true : false}
                              helperText={formValidation.summary}
                              rows={3}
                           />
                        </FormControl>
                     </Grid>
                     <Grid item xs={12}>
                        <DialogContentText>
                           <b>Select up to {props.resourceLimits.journeyPhoto} photo memories for this month.</b>
                        </DialogContentText>
                     </Grid>
                     <Grid item xs={12}>
                        <PhotoUploader
                           ref={photoUploaderRef}
                           width={420}
                           height={420}
                           buttonText="Select Photos"
                           multiple={props.resourceLimits.journeyPhoto}
                           enableCaptions={true}
                           enableMausoleumSwitch={true}
                        />
                     </Grid>

                     
                  </Fragment> : null}
               </Grid>
            </Box> : <LoaderIcon />}
         </DialogContent>
         <DialogActions>
            <Button variant="outlined" startIcon={<CancelIcon />} onClick={props.handleClose}>Cancel</Button>
            <Button
               variant="contained"
               startIcon={<CheckIcon />}
               onClick={handleAddJourneyMonthButtonClick}>
                  Add Month
               </Button>
         </DialogActions>
      </Dialog>
   );
};

JourneyMonthDialog.propTypes = {
   forMonth: PropTypes.instanceOf(Date),
   loading: PropTypes.bool.isRequired,
   open: PropTypes.bool.isRequired,
   handleClose: PropTypes.func.isRequired,
   handleAddJourneyMonth: PropTypes.func.isRequired,
   resourceLimits: PropTypes.object.isRequired,
};

export default JourneyMonthDialog;
