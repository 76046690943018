import React, { useState, useEffect, useRef } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import systemLookupService from '../data/SystemLookupService';
import institutionService from '../data/InstitutionService';
import InstitutionDialog from '../components/InstitutionDialog';
import EnhancedTable from '../components/EnhancedTable';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { InstitutionColConfig } from '../components/TableColumnConfigs';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import WidgetContainer from '../components/WidgetContainer';
import LimitReachedAlert from '../components/LimitReachedAlert';
import subscriptionService from '../data/SubscriptionService';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const InstitutionsPage = () => {
   const institutionDialogRef = useRef();

   let { institutionTypeId } = useParams();

   const [institutions, setInstitutions] = useState({ data: [], limits: {}, loading: false });
   const [institutionTypes, setInstitutionTypes] = useState({ data: [], loading: false });
   const [addressStatesProvinces, setAddressStatesProvinces] = useState({ data: [], loading: false });
   const [currentSubscriptionStatus, setCurrentSubscriptionStatus] = useState({ data: {}, loading: false });

   useEffect(() => {
      getInstitutions(institutionTypeId);
      getInstitutionTypes();
      getAddressStatesProvinces();
      getCurrentSubscriptionStatus();
   }, []);

   const history = useHistory();

   const getInstitutions = async (institutionTypeId) => {
      setInstitutions({ data: [], limits: {}, loading: true });
      const institutions = await institutionService.getInstitutions(institutionTypeId);
      setInstitutions({ data: institutions.data, limits: institutions.limits, loading: false });
   };

   const getInstitutionTypes = async () => {
      setInstitutionTypes({ data: [], loading: true });
      const institutionTypes = await systemLookupService.getInstitutionTypes();
      setInstitutionTypes({ data: institutionTypes, loading: false });
   };

   const getAddressStatesProvinces = async () => {
      setAddressStatesProvinces({ data: [], loading: true });
      const addressStatesProvinces = await systemLookupService.getAddressStatesProvinces();
      setAddressStatesProvinces({ data: addressStatesProvinces, loading: false });
   };

   const addSaveInstitution = async (institution) => {
      setInstitutions({ ...institutions, loading: true });

      if(institution.id)
         await institutionService.putInstitution(institution);
      else
         await institutionService.addInstitution(institution);

      getInstitutions(institutionTypeId);
      setInstitutionDialogOpen(false);
   };

   const deleteInstitution = async (institution) => {
      setInstitutions({ ...institutions, loading: true });
      await institutionService.deleteInstitution(institution.id);
      getInstitutions(institutionTypeId);
   };

   const handleAddClick = () => {
      setInstitutionDialogOpen(true);
      if (institutionDialogRef.current)
         institutionDialogRef.current.setState({
            id: null,
            companyName: '',
            website: '',
            contactName: '',
            phone: '',
            email: '',
            accountIdentifier: '',
            description: ''
         });
   };

   const editInstitution = async (institution) => {
      await setInstitutionDialogOpen(true);
      if (institutionDialogRef.current)
         institutionDialogRef.current.setState(institution);
   };

   // current subscription status
   const getCurrentSubscriptionStatus = async () => {
      setCurrentSubscriptionStatus({data: {}, loading: true});
      const subscriptionStatus = await subscriptionService.getCurrentStatus();
      setCurrentSubscriptionStatus({data: subscriptionStatus, loading: false});
   };

   // dialog states
   const [institutionDialogOpen, setInstitutionDialogOpen] = useState(false);

   const handleSeeAllClick = async () => {
      await getInstitutions();
      history.push('/institutions');
   };

   return (
      <div>
         <Button
            variant="text"
            component={Link}
            to="/my-records"
            startIcon={<ArrowBackIcon />}
         >
            Back
         </Button>

         { institutionTypeId ? <Button
            variant="text"
            onClick={handleSeeAllClick}
         >See All</Button> : null }

         <WidgetContainer
            title={`Financial Info`}
            icon={AttachMoneyIcon}
            color="#00a5de"
         >
            <EnhancedTable
               loading={institutions.loading}
               emptyMessage="No financial info has been added yet."
               data={institutions.data}
               colConfig={InstitutionColConfig}
               handleItemAdd={handleAddClick}
               itemAddDisabled={institutions.data.length >= institutions.limits.institution}
               rowActions={[{
                  toolTip: 'Edit',
                  icon: <EditIcon />,
                  onClick: editInstitution,
               },{
                  toolTip: 'Delete',
                  icon: <DeleteIcon />,
                  confirmDialogConfig: {
                     title: "Delete Financial Info?",
                     description: "Are you sure you want to delete this financial info?",
                     dismissButtonLabel: "No",
                     affirmButtonLabel: "Yes",
                  },
                  onClick: deleteInstitution,
               }]}
            />

            {institutions.data.length >= institutions.limits.institution ? <LimitReachedAlert
               resourceName="Financial Info"
               currentSubscriptionTier={currentSubscriptionStatus.data}
            /> : null}
         </WidgetContainer>

         <InstitutionDialog
            ref={institutionDialogRef}
            open={institutionDialogOpen}
            handleClose={() => setInstitutionDialogOpen(false)}
            handleAddSaveInstitution={addSaveInstitution}
            institutionTypes={institutionTypes.data}
            addressStatesProvinces={addressStatesProvinces.data}
         />
      </div>
   );
};

export default InstitutionsPage;
