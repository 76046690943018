import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import AddressDisplay from './AddressDisplay';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import LoaderIcon from './LoaderIcon';
import EditIcon from '@mui/icons-material/Edit';
import Avatar from '@mui/material/Avatar';
import format from 'date-fns/format';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import LTIconButton from './LTIconButton';

const ProfileWidgetBody = (props) => {
   if (props.loading)
      return (<LoaderIcon />);

   return (
      <Fragment>
         <CardHeader
            action={props.openProfileDialog ? <LTIconButton
               disabled={props.loading}
               tooltipText='Edit My Personal Details'
               ariaLabel='edit my profile'
               onClick={() => props.openProfileDialog()}
               icon={<EditIcon />}
            /> : null}
         />
         <CardContent>
            <Grid container spacing={1}>
               <Grid item xs={12}>
                  <Box display="flex" justifyContent="center" alignItems="center">
                     <Avatar
                        alt={`${props.profile.firstName} ${props.profile.lastName}`}
                        src={props.profile.avatarBase64}
                        sx={{ width: 180, height: 180 }}
                     />
                  </Box>
               </Grid>
               <Grid item xs={12}>
                  <TableContainer>
                     <Table aria-label="profile table">
                        <TableBody>
                           <TableRow>
                              <TableCell component="th" scope="row"><b>Email / Username</b></TableCell>
                              <TableCell>{props.profile.email}</TableCell>
                           </TableRow>
                           <TableRow>
                              <TableCell component="th" scope="row"><b>First Name</b></TableCell>
                              <TableCell>{props.profile.firstName}</TableCell>
                           </TableRow>
                           <TableRow>
                              <TableCell component="th" scope="row"><b>Preferred Name</b></TableCell>
                              <TableCell>{props.profile.preferredName ? props.profile.preferredName : 'Not Set'}</TableCell>
                           </TableRow>
                           <TableRow>
                              <TableCell component="th" scope="row"><b>Last Name</b></TableCell>
                              <TableCell>{props.profile.lastName}</TableCell>
                           </TableRow>
                           <TableRow>
                              <TableCell component="th" scope="row"><b>Maiden Name</b></TableCell>
                              <TableCell>{props.profile.maidenName ? props.profile.maidenName : 'Not Set'}</TableCell>
                           </TableRow>
                           <TableRow>
                              <TableCell component="th" scope="row"><b>Middle Name</b></TableCell>
                              <TableCell>{props.profile.middleName ? props.profile.middleName : 'Not Set'}</TableCell>
                           </TableRow>
                           <TableRow>
                              <TableCell component="th" scope="row"><b>City of Birth</b></TableCell>
                              <TableCell>{props.profile.cityOfBirth}</TableCell>
                           </TableRow>
                           <TableRow>
                              <TableCell component="th" scope="row"><b>Date of Birth</b></TableCell>
                              <TableCell>{props.profile.dateOfBirth ? format(props.profile.dateOfBirth, 'P') : 'Bad Date'}</TableCell>
                           </TableRow>
                           <TableRow>
                              <TableCell component="th" scope="row"><b>Gender</b></TableCell>
                              <TableCell>{props.profile.gender ? props.profile.gender.name : 'Not Set'}</TableCell>
                           </TableRow>
                           <TableRow>
                              <TableCell component="th" scope="row"><b>Marital Status</b></TableCell>
                              <TableCell>{props.profile.maritalStatus ? props.profile.maritalStatus.name : 'Not Set'}</TableCell>
                           </TableRow>
                           <TableRow>
                              <TableCell sx={{ border: 0 }} component="th" scope="row"><b>Current Address</b></TableCell>
                              <TableCell sx={{ border: 0 }}><AddressDisplay address={props.profile.address} /></TableCell>
                           </TableRow>
                           <TableRow>
                              <TableCell sx={{ border: 0 }} component="th" scope="row"><b>Active Duty or Retired Military</b></TableCell>
                              <TableCell sx={{ border: 0 }}>{props.profile.military ? 'Yes' : 'No'}</TableCell>
                           </TableRow>
                        </TableBody>
                     </Table>
                  </TableContainer>
               </Grid>
            </Grid>
         </CardContent>
      </Fragment>
   )
};

ProfileWidgetBody.propTypes = {
   loading: PropTypes.bool.isRequired,
   profile: PropTypes.object.isRequired,
   openProfileDialog: PropTypes.func,
};

export default ProfileWidgetBody;
