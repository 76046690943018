import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import PhoneNumberDisplay from '../components/PhoneNumberDisplay';
import { Link } from 'react-router-dom';

const TrusteeListItem = (props) => {
   const labelId = `trustee-list-label-${props.trustee.id}`;

   return (
      <ListItem disablePadding>
         <ListItemButton
            dense
            variant="link"
            component={Link}
            to="/contacts"
         >
            <ListItemText
               id={labelId}
               primary={`${props.trustee.firstName} ${props.trustee.lastName}`}
               secondary={<Fragment>
                  <PhoneNumberDisplay number={props.trustee.primaryPhone} blankMessage="No phone number." />
                  {` - ${props.trustee.email}`}
               </Fragment>}
            />
         </ListItemButton>
      </ListItem>
   );
};

TrusteeListItem.propTypes = {
   trustee: PropTypes.object.isRequired,
};

export default TrusteeListItem;
