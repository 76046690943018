import axiosService from './AxiosService';

export class TrusteeService {
   _resourceName = 'trustee';

   async getTrustees() {
      return await axiosService.readList(`/v1.0/${this._resourceName}`);
   }

   async deleteTrustee(id) {
      return await axiosService.delete(`/v1.0/${this._resourceName}`, id);
   }

   static get instance() { return trusteeService }
}

const trusteeService = new TrusteeService();

export default trusteeService;
