import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Tooltip from '@mui/material/Tooltip';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const YearFilter = (props) => {
   const [datePickerIsOpen, setDatePickerIsOpen] = useState(false);

   const changeYear = (newValue) => {
      const newYear = new Date(newValue).getFullYear();
      setDatePickerIsOpen(false);
      if(newYear !== props.year)
         props.onUpdateFilter(newYear);
   };

   const incrementYear = () => {
      const newYear = props.year + 1;
      props.onUpdateFilter(newYear);
   };

   const decrementYear = () => {
      const newYear = props.year - 1;
      props.onUpdateFilter(newYear);
   };

   const handleYearSelect = () => {
      setDatePickerIsOpen(true);
   };

   const handleBlur = () => {
      setDatePickerIsOpen(false);
   };

   return (
      <Fragment>
         <Tooltip title="Previous Year">
            <span>
               <IconButton aria-label="previous year" onClick={decrementYear}>
                  <ArrowBackIcon />
               </IconButton>
            </span>
         </Tooltip>
         <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
               views={['year']}
               openTo='year'
               margin="dense"
               label={props.label}
               onChange={changeYear}
               onClose={handleBlur}
               value={new Date().setYear(props.year)}
               open={datePickerIsOpen}
               renderInput={(params) => <IconButton
                  className="year-picker"
                  onClick={handleYearSelect}
                  onBlur={params.onClickOutside}
                  ref={params.inputRef}
                  >{props.year}</IconButton>}
            />
         </LocalizationProvider>
         <Tooltip title="Next Year">
            <span>
               <IconButton aria-label="next year" onClick={incrementYear}>
                  <ArrowForwardIcon />
               </IconButton>
            </span>
         </Tooltip>
      </Fragment>
   );
};

YearFilter.propTypes = {
   onUpdateFilter: PropTypes.func.isRequired,
   year: PropTypes.number.isRequired,
   label: PropTypes.string.isRequired,
};

export default YearFilter;
