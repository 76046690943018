import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import PhoneNumberDisplay from '../components/PhoneNumberDisplay';

const ImportantContactListItem = (props) => {
   const labelId = `other-contact-list-label-${props.contact.id}`;

   return (
      <ListItem disablePadding>
         <ListItemButton
            dense
            onClick={() => props.handleImportantContactClick(props.contact)}
         >
            <ListItemText
               id={labelId}
               primary={`${props.contact.firstName} ${props.contact.lastName}`}
               secondary={<Fragment>
                  <PhoneNumberDisplay number={props.contact.primaryPhone} blankMessage="No phone number." />
                  {` - ${props.contact.email}`}
               </Fragment>}
            />
         </ListItemButton>
      </ListItem>
   );
};

ImportantContactListItem.propTypes = {
   contact: PropTypes.object.isRequired,
   handleImportantContactClick: PropTypes.func.isRequired,
};

export default ImportantContactListItem;
