import React, { useState, useRef, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import AddressFormFields from './AddressFormFields';
import NumberFormat from 'react-number-format';
import TextAreaWithLimit from './TextAreaWithLimit';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';

const InstitutionDialog = forwardRef((props, ref) => {
   const addressFormRef = useRef();

   const initialState = {
      id: null,
      companyName: '',
      website: '',
      contactName: '',
      phone: '',
      email: '',
      accountIdentifier: '',
      description: ''
   };
   const [formValues, setFormValues] = useState(initialState);
   const [activeInstitutionType, setActiveInstitutionType] = useState('select-institution-type');

   // control validation, state from parent component
   useImperativeHandle(ref, () => ({
      setState(state) {
         setFormValues(state);

         if (addressFormRef.current)
            addressFormRef.current.setState(state.location);

         setActiveInstitutionType((state && state.institutionType && state.institutionType.id) || 'select-institution-type');
      }
   }));

   // validation
   const [formValidation, setFormValidation] = useState({});
   const formIsValid = () => {
      let errors = {};

      if (formValues.companyName.length < 2)
         errors.companyName = 'Company Name must be at least two characters.';
      if (formValues.website && formValues.website.length < 3)
         errors.website = 'Website must be at least three characters if provided.';
      if (formValues.contactName && formValues.contactName.length < 2)
         errors.contactName = 'Contact Name must be at least two characters if provided.';
      if (formValues.phone && formValues.phone.toString().length && formValues.phone.toString().length !== 10)
         errors.phone = 'Contact Phone Number invalid.';
      if (formValues.email && formValues.email.length < 2)
         errors.email = 'Email invalid.';
      if (formValues.accountIdentifier.toString().length !== 4)
         errors.accountIdentifier = 'Last Four of Account Number is required.';
      if (formValues.description && formValues.description.length < 2)
         errors.description = 'Description must be at least two characters if provided.';

      setFormValidation(errors);

      // validate address
      let addressValid = false;
      if (addressFormRef.current)
         addressValid = addressFormRef.current.validate();

      return Object.keys(errors).length === 0 && addressValid;
   };

   const cleanData = (formValues) => {
      return {
         ...formValues,
         phone: formValues.phone.toString(),
         location: addressFormRef.current ? addressFormRef.current.getState() : null,
         accountIdentifier: formValues.accountIdentifier.toString(),
      };
   };

   const handleAddSaveInstitutionButtonClick = () => {
      if (formIsValid()) {
         const institution = {
            id: formValues.id,
            institutionType: props.institutionTypes.find(element => element.id === activeInstitutionType),
            companyName: formValues.companyName,
            website: formValues.website,
            contactName: formValues.contactName,
            phone: formValues.phone,
            email: formValues.email,
            accountIdentifier: formValues.accountIdentifier,
            description: formValues.description
         };

         props.handleAddSaveInstitution(cleanData(institution));

         setFormValues({ ...initialState });
         // todo: make sure address re-init works - can likely use new setState once I add edit capability to this
         setActiveInstitutionType('select-institution-type');
      }
   };

   return (
      <Dialog open={props.open} onClose={props.handleClose}>
         <DialogTitle>{( formValues.id ? 'Edit' : 'Add')} Financial Info</DialogTitle>
         <DialogContent>
            <Box component="form">
               <Grid container spacing={1}>
                  <Grid item xs={12}>
                     <DialogContentText>
                        <b>Fill out financial info below</b>
                     </DialogContentText>
                  </Grid>

                  <Grid item xs={12}>
                     <FormControl fullWidth>
                        <InputLabel id="institution-type-select-label">Type*</InputLabel>
                        <Select
                           autoFocus
                           labelId="institution-type-select-label"
                           id="institution-type-select"
                           label="Type*"
                           value={activeInstitutionType}
                           onChange={event => setActiveInstitutionType(event.target.value)}
                        >
                           <MenuItem value="select-institution-type">--- Select Institution Type ---</MenuItem>
                           {props.institutionTypes.map(type => (<MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>))}
                        </Select>
                     </FormControl>
                  </Grid>

                  <Grid item md={12}>
                     <FormControl fullWidth>
                        <TextField
                           margin="dense"
                           id="company-name-input"
                           label="Company Name*"
                           type="text"
                           value={formValues.companyName}
                           onChange={event => setFormValues({ ...formValues, companyName: event.target.value })}
                           error={formValidation.companyName ? true : false}
                           helperText={formValidation.companyName}
                           fullWidth
                        />
                     </FormControl>
                  </Grid>

                  <Grid item md={12}>
                     <FormControl fullWidth>
                        <TextField
                           margin="dense"
                           id="website-input"
                           label="Website"
                           type="text"
                           value={formValues.website}
                           onChange={event => setFormValues({ ...formValues, website: event.target.value })}
                           error={formValidation.website ? true : false}
                           helperText={formValidation.website}
                           fullWidth
                        />
                     </FormControl>
                  </Grid>

                  <Grid item md={6}>
                     <FormControl fullWidth>
                        <TextField
                           margin="dense"
                           id="contact-name-input"
                           label="Contact Name"
                           type="text"
                           value={formValues.contactName}
                           onChange={event => setFormValues({ ...formValues, contactName: event.target.value })}
                           error={formValidation.contactName ? true : false}
                           helperText={formValidation.contactName}
                           fullWidth
                        />
                     </FormControl>
                  </Grid>

                  <Grid item md={6}>
                     <FormControl fullWidth>
                        <NumberFormat
                           customInput={TextField}
                           format="+1 (###) ###-####"
                           isNumericString={true}
                           mask="_"
                           margin="dense"
                           id="phone-input"
                           label="Contact Phone"
                           type="text"
                           value={formValues.phone}
                           onValueChange={({ floatValue: value }) => setFormValues({ ...formValues, phone: value })}
                           error={formValidation.phone ? true : false}
                           helperText={formValidation.phone}
                           fullWidth
                        />
                     </FormControl>
                  </Grid>

                  <Grid item md={12}>
                     <FormControl fullWidth>
                        <TextField
                           margin="dense"
                           id="email-input"
                           label="Email"
                           type="text"
                           value={formValues.email}
                           onChange={event => setFormValues({ ...formValues, email: event.target.value })}
                           error={formValidation.email ? true : false}
                           helperText={formValidation.email}
                           fullWidth
                        />
                     </FormControl>
                  </Grid>

                  <AddressFormFields
                     ref={addressFormRef}
                     label="Institution Address"
                     addressStatesProvinces={props.addressStatesProvinces}
                  />

                  <Grid item md={6}>
                     <FormControl fullWidth>
                        <NumberFormat
                           customInput={TextField}
                           format="####"
                           isNumericString={true}
                           mask="*"
                           margin="dense"
                           id="account-identifier-input"
                           label="Last Four of Account Number*"
                           type="text"
                           value={formValues.accountIdentifier}
                           onValueChange={(value) => setFormValues({ ...formValues, accountIdentifier: value.value })}
                           error={formValidation.accountIdentifier ? true : false}
                           helperText={formValidation.accountIdentifier}
                           fullWidth
                        />
                     </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                     <FormControl fullWidth>
                        <TextAreaWithLimit
                           id="description-input"
                           label="Description"
                           maxLength={255}
                           value={formValues.description}
                           onChange={event => setFormValues({ ...formValues, description: event.target.value })}
                           error={formValidation.description ? true : false}
                           helperText={formValidation.description}
                           placeholder="Add a description..."
                           rows={3}
                        />
                     </FormControl>
                  </Grid>
               </Grid>
            </Box>
         </DialogContent>
         <DialogActions>
            <Button variant="outlined" startIcon={<CancelIcon />} onClick={props.handleClose}>Cancel</Button>
            <Button variant="contained" startIcon={<CheckIcon />} disabled={activeInstitutionType === 'select-institution-type'} onClick={handleAddSaveInstitutionButtonClick}>{(formValues.id ? 'Save' : 'Add')} Financial Info</Button>
         </DialogActions>
      </Dialog>
   );
});

InstitutionDialog.propTypes = {
   open: PropTypes.bool.isRequired,
   handleClose: PropTypes.func.isRequired,
   handleAddSaveInstitution: PropTypes.func.isRequired,
   institutionTypes: PropTypes.array.isRequired,
   addressStatesProvinces: PropTypes.array.isRequired,
};

export default InstitutionDialog;
