import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import AddressDisplay from './AddressDisplay';
import PhoneNumberDisplay from './PhoneNumberDisplay';

const CareAndHealthDisplay = (props) => {
   return (<Grid container spacing={1}>
      <Grid item xs={12}>
         <Typography variant="h6" gutterBottom component="div" sx={{ borderBottom: '3px solid #00a5de' }}>
            Health Agents &amp; Decisions
         </Typography>
      </Grid>

      <Grid item xs={12} md={6}>
         <Grid container spacing={1}>
            <Grid item xs={12}>
               <Typography variant="h6" gutterBottom component="div">
               Primary
               </Typography>
            </Grid>

            <Grid item xs={6} md={4}>
               <Typography variant="body1" gutterBottom>
                  Name
               </Typography>
            </Grid>
            <Grid item xs={6} md={8}>
               <Typography variant="body1" gutterBottom>
                  {props.careAndHealth.healthAgentName || 'Not set.'}
               </Typography>
            </Grid>
            <Grid item xs={6} md={4}>
               <Typography variant="body1" gutterBottom>
                  Phone
               </Typography>
            </Grid>
            <Grid item xs={6} md={8}>
               <Typography variant="body1" gutterBottom>
                  <PhoneNumberDisplay number={props.careAndHealth.healthAgentPhone} blankMessage="Not set." />
               </Typography>
            </Grid>
            <Grid item xs={6} md={4}>
               <Typography variant="body1" gutterBottom>
                  Address
               </Typography>
            </Grid>
            <Grid item xs={6} md={8}>
               <Typography variant="body1" gutterBottom>
                  <AddressDisplay address={props.careAndHealth.healthAgentAddress} blankMessage="Not set." />
               </Typography>
            </Grid>
            <Grid item xs={6} md={4}>
               <Typography variant="body1" gutterBottom>
                  Relationship
               </Typography>
            </Grid>
            <Grid item xs={6} md={8}>
               <Typography variant="body1" gutterBottom>
                  {props.careAndHealth.healthAgentRelationshipToYou || 'Not set.'}
               </Typography>
            </Grid>
         </Grid>
      </Grid>

      <Grid item xs={12} md={6}>
         <Grid container spacing={1}>
            <Grid item xs={12}>
               <Typography variant="h6" gutterBottom component="div">
                  Secondary
               </Typography>
            </Grid>

            <Grid item xs={6} md={4}>
               <Typography variant="body1" gutterBottom>
                  Name
               </Typography>
            </Grid>
            <Grid item xs={6} md={8}>
               <Typography variant="body1" gutterBottom>
                  {props.careAndHealth.alternateHealthAgentName || 'Not set.'}
               </Typography>
            </Grid>
            <Grid item xs={6} md={4}>
               <Typography variant="body1" gutterBottom>
                  Phone
               </Typography>
            </Grid>
            <Grid item xs={6} md={8}>
               <Typography variant="body1" gutterBottom>
                  <PhoneNumberDisplay number={props.careAndHealth.alternateHealthAgentPhone} blankMessage="Not set." />
               </Typography>
            </Grid>
            <Grid item xs={6} md={4}>
               <Typography variant="body1" gutterBottom>
                  Address
               </Typography>
            </Grid>
            <Grid item xs={6} md={8}>
               <Typography variant="body1" gutterBottom>
                  <AddressDisplay address={props.careAndHealth.alternateHealthAgentAddress} blankMessage="Not set." />
               </Typography>
            </Grid>
            <Grid item xs={6} md={4}>
               <Typography variant="body1" gutterBottom>
                  Relationship
               </Typography>
            </Grid>
            <Grid item xs={6} md={8}>
               <Typography variant="body1" gutterBottom>
                  {props.careAndHealth.alternateHealthAgentRelationshipToYou || 'Not set.'}
               </Typography>
            </Grid>
         </Grid>
      </Grid>
      <Grid item xs={12}>
         <Typography variant="h6" gutterBottom component="div">
            The health agents should be able to make decisions for me:
         </Typography>
      </Grid>
      <Grid item xs={12}>
         <Typography variant="body1" gutterBottom>
            {props.careAndHealth.healthAgentDecisionPoint.name || 'Not set.'}
         </Typography>
      </Grid>

      <Grid item xs={12}>
         <Typography variant="h6" gutterBottom component="div" sx={{ borderBottom: '3px solid #00a5de' }}>
            Special Care
         </Typography>
      </Grid>

      <Grid item xs={12}>
         <Typography variant="h6" gutterBottom component="div">
            If I'm facing an irreversible condition, my end-of-life instructions are to:
         </Typography>
      </Grid>
      <Grid item xs={12}>
         <Typography variant="body1" gutterBottom>
            {props.careAndHealth.endOfLifeInstruction.id === 'specify' ? props.careAndHealth.keepAliveProcedures : props.careAndHealth.endOfLifeInstruction.name || 'Not set.'}
         </Typography>
      </Grid>

      <Grid item xs={12}>
         <Typography variant="h6" gutterBottom component="div">
            If I'm in unbearable pain:
         </Typography>
      </Grid>
      <Grid item xs={12}>
         <Typography variant="body1" gutterBottom>
            {props.careAndHealth.painManagement.name || 'Not set.'}
         </Typography>
      </Grid>

      <Grid item xs={12}>
         <Typography variant="h6" gutterBottom component="div">
            I would like to receive my end-of-life care at:
         </Typography>
      </Grid>
      <Grid item xs={12}>
         <Typography variant="body1" gutterBottom>
            {props.careAndHealth.endOfLifeCareLocationType.id === 'other' ? props.endOfLifeCareLocationDetails : props.careAndHealth.endOfLifeCareLocationType.name || 'Not set.'}
         </Typography>
      </Grid>

      <Grid item xs={12}>
         <Typography variant="h6" gutterBottom component="div" sx={{ borderBottom: '3px solid #00a5de' }}>
            Organ Donation
         </Typography>
      </Grid>

      {props.donateMyOrgans ? <Fragment>
         <Grid item xs={12}>
            <Typography variant="h6" gutterBottom component="div">
               I would like to donate the following organs, tissues, or body parts:
            </Typography>
         </Grid>
         <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
               {props.organsToDonate || 'Not set.'}
            </Typography>
         </Grid>
      </Fragment> : <Grid item xs={12}>
         <Typography variant="body1" gutterBottom>
               I do not want to donate any organs, tissues, or body parts.
            </Typography>
         </Grid>}

      <Grid item xs={12}>
         <Typography variant="h6" gutterBottom component="div" sx={{ borderBottom: '3px solid #00a5de' }}>
         Other Wishes & Directives
         </Typography>
      </Grid>
      <Grid item xs={12}>
         <Typography variant="body1" gutterBottom>
            {props.otherHealthWishes || 'Not set.'}
         </Typography>
      </Grid>
   </Grid>);
};

CareAndHealthDisplay.propTypes = {
   careAndHealth: PropTypes.object.isRequired,
};

export default CareAndHealthDisplay;
