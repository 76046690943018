import React, { useState, forwardRef, useImperativeHandle, Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import LoaderIcon from './LoaderIcon';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import TextAreaWithLimit from './TextAreaWithLimit';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';

const Input = styled('input')({
   display: 'none',
});

const DocumentDialog = forwardRef((props, ref) => {
   const initialState = {
      description: ''
   };
   const [formValues, setFormValues] = useState(initialState);
   const [activeDocumentType, setActiveDocumentType] = useState('select-document-type');

   // control validation, state from parent component
   useImperativeHandle(ref, () => ({
      setState(state) {
         setFormValues(state);

         setActiveDocumentType((state && state.documentType && state.documentType.id) || 'select-document-type');
      }
   }));

   // validation
   const [formValidation, setFormValidation] = useState({});
   const formIsValid = () => {
      let errors = {};

      if (!formValues.id && !selectedDocument)
         errors.selectedDocument = 'Document must be selected.';
      if (formValues.description && formValues.description.length < 2)
         errors.description = 'Description must be at least two characters if provided.';

      setFormValidation(errors);

      return Object.keys(errors).length === 0;
   };

   const handleAddSaveDocumentButtonClick = () => {
      if (formIsValid()) {
         props.handleAddSaveDocument({
            id: formValues.id,
            documentType: props.documentTypes.find(element => element.id === activeDocumentType),
            fileName: !formValues.id ? documentFileName : null,
            base64Data: !formValues.id ? documentBase64 : null,
            description: formValues.description,
         });

         removeDocument();
         setFormValues({ ...initialState });
         setActiveDocumentType('select-document-type');
      }
   };

   // file selector & upload
   const [selectedDocument, setSelectedDocument] = useState(null);
   const [documentBase64, setDocumentBase64] = useState(null);
   const [documentFileName, setDocumentFileName] = useState(null);

   const removeDocument = () => {
      setSelectedDocument(null);
      setDocumentBase64(null);
      const docUploaderElement = document.getElementById('document-uploader-input');
      if(docUploaderElement)
         docUploaderElement.value = null;
   };

   const selectDocumentForUpload = (event) => {
      var reader = new FileReader();
      reader.onload = () => {
         setDocumentBase64(reader.result);
      };

      if(event.target.files[0] instanceof File) {
         setSelectedDocument(event.target.files[0]);
         setDocumentFileName(event.target.files[0].name);
         reader.readAsDataURL(event.target.files[0]);
      }
   };

   return (
      <Dialog open={props.open} onClose={props.handleClose}>
         <DialogTitle>{( formValues.id ? 'Edit' : 'Add')} Document</DialogTitle>
         <DialogContent>
            {!props.loading ? <Box component="form">
               <Grid container spacing={1}>
                  <Grid item xs={12}>
                     <DialogContentText>
                        {formValues.id ? <b>Edit information about a previously uploaded document.</b> : <b>Add a new document to be made available upon your passing.</b>}
                     </DialogContentText>
                  </Grid>

                  {!formValues.id ? <Fragment>
                     <Grid item xs={12}>
                        <p>Browse for the document you'd like to upload.</p>
                     </Grid>

                     <Grid item xs={12}>
                        <label htmlFor="document-uploader-input">
                           <Input
                              accept="*"
                              id="document-uploader-input"
                              name="document-uploader-input"
                              type="file"
                              onChange={selectDocumentForUpload}
                           />
                           <Button
                              variant="outlined"
                              component="span"
                              startIcon={<CloudUploadIcon />}
                           >
                              Choose Document
                           </Button>
                        </label>
                     </Grid>
                        {documentFileName ? <Grid item xs={12}>
                        <b>Document to be uploaded:</b> {documentFileName}
                     </Grid> : null}
                  </Fragment> : null}

                  {formValidation.selectedDocument ? <Grid item xs={12}><Alert severity="error">{formValidation.selectedDocument}</Alert></Grid> : null}

                  <Grid item xs={12}>
                     <FormControl fullWidth>
                        <InputLabel id="document-type-select-label">Type*</InputLabel>
                        <Select
                           autoFocus
                           labelId="document-type-select-label"
                           id="document-type-select"
                           label="Type*"
                           value={activeDocumentType}
                           onChange={event => setActiveDocumentType(event.target.value)}
                        >
                           <MenuItem value="select-document-type">--- Select Document Type ---</MenuItem>
                           {props.documentTypes.map(type => (<MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>))}
                        </Select>
                     </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                     <FormControl fullWidth>
                        <TextAreaWithLimit
                           id="description-input"
                           label="Description"
                           maxLength={255}
                           value={formValues.description}
                           onChange={event => setFormValues({ ...formValues, description: event.target.value })}
                           error={formValidation.description ? true : false}
                           helperText={formValidation.description}
                           placeholder="Add a description..."
                           rows={3}
                        />
                     </FormControl>
                  </Grid>
               </Grid>
            </Box> : <LoaderIcon />}
         </DialogContent>
         <DialogActions>
            <Button variant="outlined" startIcon={<CancelIcon />} onClick={props.handleClose}>Cancel</Button>
            <Button variant="contained" startIcon={<CheckIcon />} disabled={activeDocumentType === 'select-document-type'} onClick={handleAddSaveDocumentButtonClick}>{(formValues.id ? 'Save' : 'Add')} Document</Button>
         </DialogActions>
      </Dialog>
   );
});

DocumentDialog.propTypes = {
   loading: PropTypes.bool.isRequired,
   open: PropTypes.bool.isRequired,
   handleClose: PropTypes.func.isRequired,
   handleAddSaveDocument: PropTypes.func.isRequired,
   documentTypes: PropTypes.array.isRequired,
};

export default DocumentDialog;
