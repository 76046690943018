import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import TextAreaWithLimit from './TextAreaWithLimit';
import LoadingButton from '@mui/lab/LoadingButton';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';

const SupportInquiryDialog = (props) => {
   const initialState = {
      description: ''
   };
   const [formValues, setFormValues] = useState(initialState);

   // validation
   const [formValidation, setFormValidation] = useState({});
   const formIsValid = () => {
      let errors = {};

      if (formValues.description.length < 2)
         errors.description = 'A support inquiry description must be at least two characters.';

      setFormValidation(errors);

      return Object.keys(errors).length === 0;
   };

   const handleSendButtonClick = () => {
      if (formIsValid()) {
         props.handleSupportInquiry(formValues);
         setFormValues({ ...initialState });
      }
   };

   return (
      <Dialog open={props.open} onClose={props.handleClose}>
         <DialogTitle>Open a Support Inquiry</DialogTitle>
         <DialogContent>
            <Box component="form">
               <Grid container spacing={1}>
                  <Grid item xs={12}>
                     <DialogContentText>
                        <b>Create a Support Inquiry for something you've encountered while using LifeTomb. This helps us improve the app.</b>
                     </DialogContentText>
                  </Grid>

                  <Grid item md={12}>
                     <FormControl fullWidth>
                        <TextAreaWithLimit
                           autoFocus
                           id="description-input"
                           label="Inquiry Description"
                           maxLength={255}
                           value={formValues.description}
                           onChange={event => setFormValues({ ...formValues, description: event.target.value })}
                           error={formValidation.description ? true : false}
                           helperText={formValidation.description}
                           placeholder="Add a description..."
                           rows={3}
                        />
                     </FormControl>
                  </Grid>
               </Grid>
            </Box>
         </DialogContent>
         <DialogActions>
            <Button variant="outlined" startIcon={<CancelIcon />} disabled={props.loading} onClick={props.handleClose}>Cancel</Button>
            <LoadingButton
               loading={props.loading}
               loadingPosition="start"
               startIcon={<CheckIcon />}
               variant="contained"
               onClick={handleSendButtonClick}
            >
               Send
            </LoadingButton>
         </DialogActions>
      </Dialog>
   );
};

SupportInquiryDialog.propTypes = {
   open: PropTypes.bool.isRequired,
   handleClose: PropTypes.func.isRequired,
   handleSupportInquiry: PropTypes.func.isRequired,
   loading: PropTypes.bool.isRequired,
};

export default SupportInquiryDialog;
