import React, { useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import LoaderIcon from './LoaderIcon';
import format from 'date-fns/format';
import TextAreaWithLimit from './TextAreaWithLimit';

const JourneyMonthSummaryDialog = forwardRef((props, ref) => {
   const initialState = {
      id: null,
      forMonth: '',
      summary: '',
   };
   const [formValues, setFormValues] = useState(initialState);

   // control validation, state from parent component
   useImperativeHandle(ref, () => ({
      setState(formValues) {
         return setFormValues({
            ...formValues,
            summary: formValues.summary ?? '',
         });
      }
   }));

   // validation
   const [formValidation, setFormValidation] = useState({});
   const formIsValid = () => {
      let errors = {};

      if (formValues.summary && formValues.summary.length < 2)
         errors.summary = 'Summary must be at least two characters if provided.';

      setFormValidation(errors);

      return Object.keys(errors).length === 0;
   };

   const cleanData = (formValues) => {
      return {
         id: formValues.id,
         summary: formValues.summary ? formValues.summary : null,
      };
   };

   const handleSaveJourneyMonthButtonClick = () => {
      if (formIsValid()) {
         props.handleSaveJourneyMonth({
            ...cleanData(formValues),
         });

         setFormValues({ ...initialState });
      }
   };

   return (
      <Dialog open={props.open} onClose={props.handleClose}>
         <DialogTitle>Edit Summary for {formValues.forMonth ? format(formValues.forMonth, 'MMMM yyyy') : ''}</DialogTitle>
         <DialogContent>
            {!props.loading ? <Box component="form">
               <Grid container spacing={1}>
                  <Grid item xs={12}>
                     <DialogContentText>
                        <b>Summarize the month's highlights.</b>
                     </DialogContentText>
                  </Grid>
                  <Grid item xs={12}>
                     <FormControl fullWidth>
                        <TextAreaWithLimit
                           id="summary-input"
                           label="Summary"
                           maxLength={4000}
                           value={formValues.summary}
                           onChange={event => setFormValues({ ...formValues, summary: event.target.value })}
                           error={formValidation.summary ? true : false}
                           helperText={formValidation.summary}
                           rows={3}
                        />
                     </FormControl>
                  </Grid>
                  
               </Grid>
            </Box> : <LoaderIcon />}
         </DialogContent>
         <DialogActions>
            <Button variant="outlined" onClick={props.handleClose}>Cancel</Button>
            <Button variant="contained" onClick={handleSaveJourneyMonthButtonClick}>Save</Button>
         </DialogActions>
      </Dialog>
   );
});

JourneyMonthSummaryDialog.propTypes = {
   loading: PropTypes.bool.isRequired,
   open: PropTypes.bool.isRequired,
   handleClose: PropTypes.func.isRequired,
   handleSaveJourneyMonth: PropTypes.func.isRequired,
};

export default JourneyMonthSummaryDialog;
