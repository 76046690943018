import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import PubSub from 'pubsub-js';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

const NonBlockingLoader = () => {
   useEffect(() => {
      async function init() {
         const nonBlockingLoaderAddSub = PubSub.subscribe('nonBlockingLoader.addMessage', addMessage);
         const nonBlockingLoaderRemoveSub = PubSub.subscribe('nonBlockingLoader.removeMessage', removeMessage);
      }
      init();
   }, []);

   const [loadingMessages, setLoadingMessages] = useState([]);

   const addMessage = (event, message) => {
      setLoadingMessages(oldArray => [...oldArray, message]);
   };

   const removeMessage = (event, id) => {
      setLoadingMessages(oldArray => {
         return [...oldArray].filter(element => element.id !== id);
      });
   };

   const SlideTransition = (props) => {
      return <Slide {...props} direction="up" />;
   };

   return (
      <Snackbar
         open={loadingMessages.length ? true : false}
         anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
         TransitionComponent={SlideTransition}
      >
         <Paper elevation={8} sx={{ p: 2 }}>
            <Box sx={{ width: '100%' }}>
               {loadingMessages.map(message => (<Stack key={message.id} direction="row" spacing={1}>
                  <CircularProgress size="1rem" />
                  <Typography variant="body2" gutterBottom>{message.text}</Typography>
               </Stack>))}
            </Box>
         </Paper>
      </Snackbar>
   )
};

export default NonBlockingLoader;
