import React, { useState, useRef, forwardRef, useImperativeHandle, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import AddressFormFields from './AddressFormFields';
import TextAreaWithLimit from './TextAreaWithLimit';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Alert from '@mui/material/Alert';
import MUILink from '@mui/material/Link';
import trusteeService from '../data/TrusteeService';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import LoaderIcon from './LoaderIcon';
import systemLookupService from '../data/SystemLookupService';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';

const FuneralArrangementsDialog = forwardRef((props, ref) => {
   const funeralServiceAddressFormRef = useRef();
   const receptionAddressFormRef = useRef();

   const history = useHistory();

   const initialState = {
      whereToFindSSN: '',
      announceDeathOnSocialMedia: false,
      announceOnFacebook: false,
      announceOnX: false,
      announceOnInstagram: false,
      otherSocialMediaPlatformToAnnounceOn: '',
      announceDeathInNewspaper: false,
      newspaperToAnnounceIn: '',
      announceDeathInOtherWay: false,
      otherWayToAnnounceIn: '',
      cremation: false,
      burialOrAshesInstructions: '',
      funeralCostPaymentDetail: '',
      // InterestedInFuneralFundingOptions: false, //todo: need to see how we want to display/handle this, needs to set a flag somewhere else so that we can work w/ a third-party
      funeralServiceToBeHeld: false,
      desiredFuneralServiceOfficiant: '',
      readEuologyWrittenByMe: false,
      eulogyText: '',
      desiredEulogyWriter: '',
      desiredEulogyReader: '',
      ceremonySongs: [],
      receptionAfterService: false,
      receptionType: '',
      openCasket: false,
      openCasketInstructions: '',
      allowFamilyToAttendCeremony: true,
      allowFriendsToAttendCeremony: true,
      allowPublicToAttendCeremony: false,
      additionalFinalWishes: '',
   };
   const [formValues, setFormValues] = useState(initialState);

   const [trustees, setTrustees] = useState({ data: [], limits: {}, loading: false });

   const [addressStatesProvinces, setAddressStatesProvinces] = useState({ data: [], loading: false });
   const [funeralCostPlanTypes, setFuneralCostPlanTypes] = useState({ data: [], loading: false });
   const [selectedFuneralCostPlanType, setSelectedFuneralCostPlanType] = useState('not-arranged');

   // control validation, state from parent component
   useImperativeHandle(ref, () => ({
      setState(state) {
         setFormValues({
            ...state,
            whereToFindSSN: state.whereToFindSSN ? state.whereToFindSSN : '',
            otherSocialMediaPlatformToAnnounceOn: state.otherSocialMediaPlatformToAnnounceOn ? state.otherSocialMediaPlatformToAnnounceOn : '',
            newspaperToAnnounceIn: state.newspaperToAnnounceIn ? state.newspaperToAnnounceIn : '',
            otherWayToAnnounceIn: state.otherWayToAnnounceIn ? state.otherWayToAnnounceIn : '',
            burialOrAshesInstructions: state.burialOrAshesInstructions ? state.burialOrAshesInstructions : '',
            funeralCostPaymentDetail: state.funeralCostPaymentDetail ? state.funeralCostPaymentDetail : '',
            desiredFuneralServiceOfficiant: state.desiredFuneralServiceOfficiant ? state.desiredFuneralServiceOfficiant : '',
            eulogyText: state.eulogyText ? state.eulogyText : '',
            desiredEulogyWriter: state.desiredEulogyWriter ? state.desiredEulogyWriter : '',
            desiredEulogyReader: state.desiredEulogyReader ? state.desiredEulogyReader : '',
            ceremonySongs: state.ceremonySongs,
            receptionType: state.receptionType ? state.receptionType : '',
            openCasketInstructions: state.openCasketInstructions ? state.openCasketInstructions : '',
            additionalFinalWishes: state.additionalFinalWishes ? state.additionalFinalWishes : '',
         });

         if (funeralServiceAddressFormRef.current)
            funeralServiceAddressFormRef.current.setState(state.funeralServiceAddress);

         if (receptionAddressFormRef.current)
         receptionAddressFormRef.current.setState(state.receptionAddress);

         setSelectedFuneralCostPlanType((state && state.funeralCostPlanType && state.funeralCostPlanType.id) || 'not-arranged');
      },

      // todo: should this be named initFuneralArrangements
      async loadFuneralArrangements () {
         await getTrustees();
         await getAddressStatesProvinces();
         await getFuneralCostPlanTypes();
      },
   }));

   // trustees
   const getTrustees = async () => {
      setTrustees({ data: [], limits: {}, loading: true });
      const trustees = await trusteeService.getTrustees();
      setTrustees({ data: trustees.data, limits: trustees.limits, loading: false });
   };

   // lookups
   const getAddressStatesProvinces = async () => {
      setAddressStatesProvinces({ data: [], loading: true });
      const addressStatesProvinces = await systemLookupService.getAddressStatesProvinces();
      setAddressStatesProvinces({ data: addressStatesProvinces, loading: false });
   };

   const getFuneralCostPlanTypes = async () => {
      setFuneralCostPlanTypes({ data: [], loading: true });
      const funeralCostPlanTypes = await systemLookupService.getFuneralCostPlanTypes();
      setFuneralCostPlanTypes({ data: funeralCostPlanTypes, loading: false });
   };

   // validation
   const [formValidation, setFormValidation] = useState({});
   const formIsValid = () => {
      let errors = {};

      if (formValues.whereToFindSSN && formValues.whereToFindSSN.length < 2)
         errors.whereToFindSSN = 'Where To Find SSN must be at least two characters if provided.';

      if (formValues.otherSocialMediaPlatformToAnnounceOn && formValues.otherSocialMediaPlatformToAnnounceOn.length < 2)
         errors.otherSocialMediaPlatformToAnnounceOn = 'Other Social Media Platform To Announce On must be at least two characters if provided.';

      if (formValues.newspaperToAnnounceIn && formValues.newspaperToAnnounceIn.length < 2)
         errors.newspaperToAnnounceIn = 'Newspaper To Announce In must be at least two characters if provided.';

      if (formValues.otherWayToAnnounceIn && formValues.otherWayToAnnounceIn.length < 2)
         errors.otherWayToAnnounceIn = 'Other Way To Announce In must be at least two characters if provided.';

      if (formValues.burialOrAshesInstructions && formValues.burialOrAshesInstructions.length < 2)
         errors.burialOrAshesInstructions = 'Burial or Ashes Instructions must be at least two characters if provided.';

      if (formValues.funeralCostPaymentDetail && formValues.funeralCostPaymentDetail.length < 2)
         errors.funeralCostPaymentDetail = 'Funeral Cost Payment Detail must be at least two characters if provided.';

      if (formValues.desiredFuneralServiceOfficiant && formValues.desiredFuneralServiceOfficiant.length < 2)
         errors.desiredFuneralServiceOfficiant = 'Desired Funeral Service Officiant must be at least two characters if provided.';

      if (formValues.eulogyText && formValues.eulogyText.length < 2)
         errors.eulogyText = 'Eulogy Text must be at least two characters if provided.';

      if (formValues.desiredEulogyWriter && formValues.desiredEulogyWriter.length < 2)
         errors.desiredEulogyWriter = 'Desired Eulogy Writer must be at least two characters if provided.';
      
      if (formValues.desiredEulogyReader && formValues.desiredEulogyReader.length < 2)
         errors.desiredEulogyReader = 'Desired Eulogy Reader must be at least two characters if provided.';

      if (formValues.receptionType && formValues.receptionType.length < 2)
         errors.receptionType = 'Reception Type must be at least two characters if provided.';

      if (formValues.openCasketInstructions && formValues.openCasketInstructions.length < 2)
         errors.openCasketInstructions = 'Open Casket Instructions must be at least two characters if provided.';

      if (formValues.additionalFinalWishes && formValues.additionalFinalWishes.length < 2)
         errors.additionalFinalWishes = 'Additional Final Wishes must be at least two characters if provided.';

      setFormValidation(errors);

      // validate addresses
      let funeralServiceAddressValid = false;
      if (funeralServiceAddressFormRef.current && formValues.funeralServiceToBeHeld)
         funeralServiceAddressValid = funeralServiceAddressFormRef.current.validate();
      else
         funeralServiceAddressValid = true;

      let receptionAddressValid = false;
      if (receptionAddressFormRef.current && formValues.receptionAfterService)
         receptionAddressValid = receptionAddressFormRef.current.validate();
      else
         receptionAddressValid = true;

      return Object.keys(errors).length === 0 && funeralServiceAddressValid && receptionAddressValid;
   };

   const cleanData = (formValues) => {
      return {
         ...formValues,
         whereToFindSSN: formValues.whereToFindSSN ? formValues.whereToFindSSN : null,
         otherSocialMediaPlatformToAnnounceOn: formValues.otherSocialMediaPlatformToAnnounceOn ? formValues.otherSocialMediaPlatformToAnnounceOn : null,
         newspaperToAnnounceIn: formValues.newspaperToAnnounceIn ? formValues.newspaperToAnnounceIn : null,
         otherWayToAnnounceIn: formValues.otherWayToAnnounceIn ? formValues.otherWayToAnnounceIn : null,
         burialOrAshesInstructions: formValues.burialOrAshesInstructions ? formValues.burialOrAshesInstructions : null,
         funeralCostPlanType: funeralCostPlanTypes.data.find(element => element.id === selectedFuneralCostPlanType),
         funeralCostPaymentDetail: formValues.funeralCostPaymentDetail ? formValues.funeralCostPaymentDetail : null,
         desiredFuneralServiceOfficiant: formValues.desiredFuneralServiceOfficiant ? formValues.desiredFuneralServiceOfficiant : null,
         eulogyText: formValues.eulogyText ? formValues.eulogyText : null,
         desiredEulogyWriter: formValues.desiredEulogyWriter ? formValues.desiredEulogyWriter : null,
         desiredEulogyReader: formValues.desiredEulogyReader ? formValues.desiredEulogyReader : null,
         ceremonySongs: formValues.ceremonySongs.map(song => ({
            id: song.id,
            artist: song.artist,
            name: song.name,
            url: song.url,
            playOrder: song.playOrder,
         })),
         receptionType: formValues.receptionType ? formValues.receptionType : null,
         openCasketInstructions: formValues.openCasketInstructions ? formValues.openCasketInstructions : null,
         additionalFinalWishes: formValues.additionalFinalWishes ? formValues.additionalFinalWishes : null,
         funeralServiceAddress: funeralServiceAddressFormRef.current ? funeralServiceAddressFormRef.current.getState() : null,
         receptionAddress: receptionAddressFormRef.current ? receptionAddressFormRef.current.getState() : null,
      };
   };

   const handleNavigateToContacts = () => {
      history.push('/contacts');
   };

   const handleSaveFuneralArrangementsButtonClick = () => {
      if (formIsValid())
         props.handleSaveFuneralArrangements(cleanData(formValues));
   };

   const renderTrusteeList = (trustees) => {
      return <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
         <nav aria-label="trustee list">
            <List>
               {trustees.map(trustee => <ListItem disablePadding key={trustee.id}>
                  <ListItemButton>
                     <ListItemText primary={`${trustee.firstName} ${trustee.lastName}`} />
                  </ListItemButton>
               </ListItem>)}
            </List>
         </nav>
      </Box>;
   };

   const onAddCeremonySong = (song) => {
      var songList = [...formValues.ceremonySongs];
      songList.push({
         ...song,
         playOrder: songList.length,
      });

      setFormValues({
         ...formValues,
         ceremonySongs: songList,
      });
   };

   const onDeleteCeremonySong = (index) => {
      var songList = [...formValues.ceremonySongs];
      songList
         .splice(index, 1)
         .sort((a, b) => a.playOrder < b.playOrder)
         .map((song, index) => ({...song, playOrder: index}));

      setFormValues({
         ...formValues,
         ceremonySongs: songList,
      });
   };

   const setCeremonySongArtist = (index, value) => {
      var songList = [...formValues.ceremonySongs];
      songList[index].artist = value;

      if (!value || value.length < 2)
         songList[index].errorArtist = 'Song Artist must be at least two characters.';
      else
         songList[index].errorArtist = null;

      setFormValues({
         ...formValues,
         ceremonySongs: songList,
      });
   };

   const setCeremonySongName = (index, value) => {
      var songList = [...formValues.ceremonySongs];
      songList[index].name = value;

      if (!value || value.length < 2)
         songList[index].errorName = 'Song Name must be at least two characters.';
      else
         songList[index].errorName = null;

      setFormValues({
         ...formValues,
         ceremonySongs: songList,
      });
   };

   const setCeremonySongURL = (index, value) => {
      var songList = [...formValues.ceremonySongs];
      songList[index].url = value;

      if (value && value.length < 5)
         songList[index].errorURL = 'Song URL must be at least five characters if provided.';
      else
         songList[index].errorURL = null;

      setFormValues({
         ...formValues,
         ceremonySongs: songList,
      });
   };

   return (
      <Dialog open={props.open} onClose={props.handleClose}>
         <DialogTitle>Plan Funeral &amp; Arrangements</DialogTitle>
         <DialogContent>
            <Box component="form">
               <Grid container spacing={1}>
                  <Grid item xs={12}>
                     <DialogContentText>
                        <b>Trustees</b>
                     </DialogContentText>
                  </Grid>
                  <Alert sx={{mb: 1}} severity="info">Your active/accepted trustees are listed below. <MUILink onClick={handleNavigateToContacts} sx={{cursor: 'pointer'}}>Invite and manage trustees from the contacts</MUILink>.</Alert>

                  {trustees.data.length ? renderTrusteeList(trustees.data) : <Alert sx={{width: '100%'}} severity="info">No active/accepted trustees yet.</Alert>}

                  {trustees.loading ? <LoaderIcon /> : null}

                  <Grid item xs={12}>
                     <DialogContentText>
                        <b>General Information</b>
                     </DialogContentText>
                  </Grid>

                  <Grid item xs={12}>
                     <FormControl fullWidth>
                        <TextAreaWithLimit
                           autoFocus={true}
                           id="where-to-find-ssn-input"
                           label="Where To Find SSN"
                           maxLength={255}
                           value={formValues.whereToFindSSN}
                           onChange={event => setFormValues({ ...formValues, whereToFindSSN: event.target.value })}
                           error={formValidation.whereToFindSSN ? true : false}
                           helperText={formValidation.whereToFindSSN}
                           placeholder="Describe where your Social Security Number can be found..."
                           rows={2}
                        />
                     </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                     <DialogContentText>
                        <b>How and Where to Announce Your Passing</b>
                     </DialogContentText>
                  </Grid>

                  <Grid item xs={12}>
                     <FormGroup>
                        <FormControlLabel control={<Switch
                           id="announce-death-on-social-media-switch"
                           checked={formValues.announceDeathOnSocialMedia}
                           onChange={(event) => setFormValues({ ...formValues, announceDeathOnSocialMedia: event.target.checked })}
                           inputProps={{ 'aria-label': 'announce-death-on-social-media-switch' }}
                        />} label="Announce Death on Social Media" />
                     </FormGroup>
                  </Grid>
                  {formValues.announceDeathOnSocialMedia ? <Fragment>
                     <Grid item xs={4}>
                        <FormGroup>
                           <FormControlLabel control={<Switch
                              id="announce-on-facebook-switch"
                              checked={formValues.announceOnFacebook}
                              onChange={(event) => setFormValues({ ...formValues, announceOnFacebook: event.target.checked })}
                              inputProps={{ 'aria-label': 'announce-on-facebook-switch' }}
                           />} label="Facebook" />
                        </FormGroup>
                     </Grid>
                     <Grid item xs={4}>
                        <FormGroup>
                           <FormControlLabel control={<Switch
                              id="announce-on-x-switch"
                              checked={formValues.announceOnX}
                              onChange={(event) => setFormValues({ ...formValues, announceOnX: event.target.checked })}
                              inputProps={{ 'aria-label': 'announce-on-x-switch' }}
                           />} label="X Platform" />
                        </FormGroup>
                     </Grid>
                     <Grid item xs={4}>
                        <FormGroup>
                           <FormControlLabel control={<Switch
                              id="announce-on-instagram-switch"
                              checked={formValues.announceOnInstagram}
                              onChange={(event) => setFormValues({ ...formValues, announceOnInstagram: event.target.checked })}
                              inputProps={{ 'aria-label': 'announce-on-instagram-switch' }}
                           />} label="Instagram" />
                        </FormGroup>
                     </Grid>

                     <Grid item md={12}>
                        <FormControl fullWidth>
                           <TextField
                              margin="dense"
                              id="other-social-media-platform-to-announce-on-input"
                              label="Other Social Media Platform To Announce On"
                              type="text"
                              value={formValues.otherSocialMediaPlatformToAnnounceOn}
                              onChange={event => setFormValues({ ...formValues, otherSocialMediaPlatformToAnnounceOn: event.target.value })}
                              error={formValidation.otherSocialMediaPlatformToAnnounceOn ? true : false}
                              helperText={formValidation.otherSocialMediaPlatformToAnnounceOn}
                              fullWidth
                           />
                        </FormControl>
                     </Grid>
                  </Fragment> : null}

                  <Grid item xs={12}>
                     <FormGroup>
                        <FormControlLabel control={<Switch
                           id="announce-death-in-newspaper-switch"
                           checked={formValues.announceDeathInNewspaper}
                           onChange={(event) => setFormValues({ ...formValues, announceDeathInNewspaper: event.target.checked })}
                           inputProps={{ 'aria-label': 'announce-death-in-newspaper-switch' }}
                        />} label="Announce Death In Newspaper" />
                     </FormGroup>
                  </Grid>
                  {formValues.announceDeathInNewspaper ? <Grid item md={12}>
                     <FormControl fullWidth>
                        <TextField
                           margin="dense"
                           id="newspaper-to-announce-in-input"
                           label="Newspaper To Announce In"
                           type="text"
                           value={formValues.newspaperToAnnounceIn}
                           onChange={event => setFormValues({ ...formValues, newspaperToAnnounceIn: event.target.value })}
                           error={formValidation.newspaperToAnnounceIn ? true : false}
                           helperText={formValidation.newspaperToAnnounceIn}
                           fullWidth
                        />
                     </FormControl>
                  </Grid> : null}

                  <Grid item xs={12}>
                     <FormGroup>
                        <FormControlLabel control={<Switch
                           id="announce-death-in-other-way-switch"
                           checked={formValues.announceDeathInOtherWay}
                           onChange={(event) => setFormValues({ ...formValues, announceDeathInOtherWay: event.target.checked })}
                           inputProps={{ 'aria-label': 'announce-death-in-other-way-switch' }}
                        />} label="Announce Death In Other Way" />
                     </FormGroup>
                  </Grid>
                  {formValues.announceDeathInOtherWay ? <Grid item md={12}>
                     <FormControl fullWidth>
                        <TextField
                           margin="dense"
                           id="other-way-to-announce-in-input"
                           label="Other Way To Announce In"
                           type="text"
                           value={formValues.otherWayToAnnounceIn}
                           onChange={event => setFormValues({ ...formValues, otherWayToAnnounceIn: event.target.value })}
                           error={formValidation.otherWayToAnnounceIn ? true : false}
                           helperText={formValidation.otherWayToAnnounceIn}
                           fullWidth
                        />
                     </FormControl>
                  </Grid> : null}

                  <Grid item xs={12}>
                     <DialogContentText>
                        <b>Body Handling &amp; Treatment</b>
                     </DialogContentText>
                  </Grid>

                  <Grid item xs={12}>
                     <FormGroup>
                        <FormControlLabel control={<Switch
                           id="cremation-switch"
                           checked={formValues.cremation}
                           onChange={(event) => setFormValues({ ...formValues, cremation: event.target.checked })}
                           inputProps={{ 'aria-label': 'cremation-switch' }}
                        />} label="Cremation" />
                     </FormGroup>
                  </Grid>

                  <Grid item xs={12}>
                     <FormControl fullWidth>
                        <TextAreaWithLimit
                           id="burial-or-ashes-instructions-input"
                           label="Burial or Ashes Instructions"
                           maxLength={4000}
                           value={formValues.burialOrAshesInstructions}
                           onChange={event => setFormValues({ ...formValues, burialOrAshesInstructions: event.target.value })}
                           error={formValidation.burialOrAshesInstructions ? true : false}
                           helperText={formValidation.burialOrAshesInstructions}
                           placeholder="Describe specifics about what should happen to your body or ashes..."
                           rows={3}
                        />
                     </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                     <DialogContentText>
                        <b>What are your funeral cost arrangements?</b>
                     </DialogContentText>
                  </Grid>

                  {funeralCostPlanTypes.loading !== true ? <Grid item xs={12}>
                     <FormControl fullWidth>
                        <Select
                           id="funeral-cost-plan-type-select"
                           value={selectedFuneralCostPlanType}
                           onChange={event => setSelectedFuneralCostPlanType(event.target.value)}
                        >
                           {funeralCostPlanTypes.data.map(pm => (<MenuItem key={pm.id} value={pm.id}>{pm.name}</MenuItem>))}
                        </Select>
                     </FormControl>
                  </Grid> : <LoaderIcon />}

                  {['prepaid-arranged', 'insurance'].includes(selectedFuneralCostPlanType) ? <Grid item xs={12}>
                     <FormControl fullWidth>
                        <TextAreaWithLimit
                           id="funeral-cost-payment-detail-input"
                           label="Funeral Cost Payment Detail"
                           maxLength={255}
                           value={formValues.funeralCostPaymentDetail}
                           onChange={event => setFormValues({ ...formValues, funeralCostPaymentDetail: event.target.value })}
                           error={formValidation.funeralCostPaymentDetail ? true : false}
                           helperText={formValidation.funeralCostPaymentDetail}
                           placeholder="Add additional detail about your funeral cost payment arrangements..."
                           rows={2}
                        />
                     </FormControl>
                  </Grid> : null}

                  <Grid item xs={12}>
                     <DialogContentText>
                        <b>Funeral Service</b>
                     </DialogContentText>
                  </Grid>

                  <Grid item xs={12}>
                     <FormGroup>
                        <FormControlLabel control={<Switch
                           id="funeral-service-to-be-held-switch"
                           checked={formValues.funeralServiceToBeHeld}
                           onChange={(event) => setFormValues({ ...formValues, funeralServiceToBeHeld: event.target.checked })}
                           inputProps={{ 'aria-label': 'announce-death-in-other-way-switch' }}
                        />} label="Funeral Service To Be Held" />
                     </FormGroup>
                  </Grid>

                  {formValues.funeralServiceToBeHeld ? <Fragment>
                     {addressStatesProvinces.loading !== true ? <AddressFormFields
                        ref={funeralServiceAddressFormRef}
                        label="Funeral Service Address"
                        addressStatesProvinces={addressStatesProvinces.data}
                     /> : <LoaderIcon />}
                     
                     <Grid item md={12}>
                        <FormControl fullWidth>
                           <TextField
                              margin="dense"
                              id="desired-funeral-service-officiant-input"
                              label="Desired Funeral Service Officiant"
                              type="text"
                              value={formValues.desiredFuneralServiceOfficiant}
                              onChange={event => setFormValues({ ...formValues, desiredFuneralServiceOfficiant: event.target.value })}
                              error={formValidation.desiredFuneralServiceOfficiant ? true : false}
                              helperText={formValidation.desiredFuneralServiceOfficiant}
                              fullWidth
                           />
                        </FormControl>
                     </Grid>

                     <Grid item xs={12}>
                        <FormGroup>
                           <FormControlLabel control={<Switch
                              id="read-euology-written-by-me-switch"
                              checked={formValues.readEuologyWrittenByMe}
                              onChange={(event) => setFormValues({ ...formValues, readEuologyWrittenByMe: event.target.checked })}
                              inputProps={{ 'aria-label': 'announce-death-in-other-way-switch' }}
                           />} label="Read Euology Written By Me" />
                        </FormGroup>
                     </Grid>

                     {formValues.readEuologyWrittenByMe ? <Grid item xs={12}>
                        <FormControl fullWidth>
                           <TextAreaWithLimit
                              id="eulogy-text-input"
                              label="Eulogy Text"
                              maxLength={4000}
                              value={formValues.eulogyText}
                              onChange={event => setFormValues({ ...formValues, eulogyText: event.target.value })}
                              error={formValidation.eulogyText ? true : false}
                              helperText={formValidation.eulogyText}
                              placeholder="Write your own euology text..."
                              rows={5}
                           />
                        </FormControl>
                     </Grid> : null}

                     {formValues.readEuologyWrittenByMe !== true ? <Grid item md={12}>
                        <FormControl fullWidth>
                           <TextField
                              margin="dense"
                              id="desired-eulogy-writer-input"
                              label="Desired Eulogy Writer"
                              type="text"
                              value={formValues.desiredEulogyWriter}
                              onChange={event => setFormValues({ ...formValues, desiredEulogyWriter: event.target.value })}
                              error={formValidation.desiredEulogyWriter ? true : false}
                              helperText={formValidation.desiredEulogyWriter}
                              fullWidth
                           />
                        </FormControl>
                     </Grid> : null}

                     <Grid item md={12}>
                        <FormControl fullWidth>
                           <TextField
                              margin="dense"
                              id="desired-eulogy-reader-input"
                              label="Desired Eulogy Reader"
                              type="text"
                              value={formValues.desiredEulogyReader}
                              onChange={event => setFormValues({ ...formValues, desiredEulogyReader: event.target.value })}
                              error={formValidation.desiredEulogyReader ? true : false}
                              helperText={formValidation.desiredEulogyReader}
                              fullWidth
                           />
                        </FormControl>
                     </Grid>

                     {/* todo: would be nice to pull this and all validation, etc. into a component */}
                     <Grid item md={12}>
                        <Card>
                           <CardHeader
                              title='Ceremony Songs'
                              action={
                                 <IconButton
                                    disabled={formValues.ceremonySongs.length >= 3}
                                    aria-label='add-ceremony-song'
                                    onClick={() => onAddCeremonySong({
                                       id: null,
                                       artist: '',
                                       name: '',
                                       url: '',
                                    })}
                                 >
                                    <AddIcon />
                                 </IconButton>
                              }
                           />
                           <CardContent>
                              <p>List up to three songs you'd like to have played at your funeral service. Music can be one of the most powerful ways in which your loved ones will remember you.</p>

                              <Grid container spacing={1}>
                                 {formValues.ceremonySongs.map((song, index) => <Grid item xs={12} key={index}>
                                    <Card elevation={2}>
                                       <CardContent>
                                          <Grid container spacing={1}>
                                             <Grid item xs={6}>
                                                <FormControl fullWidth>
                                                   <TextField
                                                      margin='dense'
                                                      id={`song-artist-input-${index}`}
                                                      label='Song Artist*'
                                                      type='text'
                                                      value={song.artist}
                                                      onChange={event => setCeremonySongArtist(index, event.target.value)}
                                                      error={song.errorArtist ? true : false}
                                                      helperText={song.errorArtist}
                                                      fullWidth
                                                   />
                                                </FormControl>
                                             </Grid>
                                             <Grid item xs={6}>
                                                <FormControl fullWidth>
                                                   <TextField
                                                      margin='dense'
                                                      id={`song-name-input-${index}`}
                                                      label='Song Name*'
                                                      type='text'
                                                      value={song.name}
                                                      onChange={event => setCeremonySongName(index, event.target.value)}
                                                      error={song.errorName ? true : false}
                                                      helperText={song.errorName}
                                                      fullWidth
                                                   />
                                                </FormControl>
                                             </Grid>
                                             <Grid item xs={12}>
                                                <FormControl fullWidth>
                                                   <TextField
                                                      margin='dense'
                                                      id={`song-url-input-${index}`}
                                                      label='Song URL'
                                                      type='text'
                                                      value={song.url}
                                                      onChange={event => setCeremonySongURL(index, event.target.value)}
                                                      error={song.errorURL ? true : false}
                                                      helperText={song.errorURL}
                                                      fullWidth
                                                   />
                                                </FormControl>
                                             </Grid>
                                          </Grid>
                                       </CardContent>
                                       <CardActions>
                                          <IconButton sx={{ marginLeft: 'auto' }} aria-label="remove song" onClick={() => onDeleteCeremonySong(index)}>
                                             <DeleteIcon />
                                          </IconButton>
                                       </CardActions>
                                    </Card>
                                 </Grid>)}
                              </Grid>
                           </CardContent>
                        </Card>
                     </Grid>

                     <Grid item xs={12}>
                        <FormGroup>
                           <FormControlLabel control={<Switch
                              id="reception-after-service-switch"
                              checked={formValues.receptionAfterService}
                              onChange={(event) => setFormValues({ ...formValues, receptionAfterService: event.target.checked })}
                              inputProps={{ 'aria-label': 'reception-after-service-switch' }}
                           />} label="Reception After Service" />
                        </FormGroup>
                     </Grid>

                     {formValues.receptionAfterService ? <Fragment>
                        {addressStatesProvinces.loading !== true ? <AddressFormFields
                           ref={receptionAddressFormRef}
                           label="Reception Address"
                           addressStatesProvinces={addressStatesProvinces.data}
                        /> : <LoaderIcon />}

                        <Grid item md={12}>
                           <FormControl fullWidth>
                              <TextField
                                 margin="dense"
                                 id="reception-type-input"
                                 label="Reception Type"
                                 type="text"
                                 value={formValues.receptionType}
                                 onChange={event => setFormValues({ ...formValues, receptionType: event.target.value })}
                                 error={formValidation.receptionType ? true : false}
                                 helperText={formValidation.receptionType}
                                 fullWidth
                              />
                           </FormControl>
                        </Grid>
                     </Fragment> : null}

                     <Grid item xs={12}>
                        <FormGroup>
                           <FormControlLabel control={<Switch
                              id="open-casket-switch"
                              checked={formValues.openCasket}
                              onChange={(event) => setFormValues({ ...formValues, openCasket: event.target.checked })}
                              inputProps={{ 'aria-label': 'open-casket-switch' }}
                           />} label="Open Casket" />
                        </FormGroup>
                     </Grid>

                     {formValues.openCasket ? <Grid item xs={12}>
                        <FormControl fullWidth>
                           <TextAreaWithLimit
                              id="open-casket-instructions-input"
                              label="Open Casket Instructions"
                              maxLength={255}
                              value={formValues.openCasketInstructions}
                              onChange={event => setFormValues({ ...formValues, openCasketInstructions: event.target.value })}
                              error={formValidation.openCasketInstructions ? true : false}
                              helperText={formValidation.openCasketInstructions}
                              placeholder="Describe open casket insructions, such as providing a viewing only for family..."
                              rows={3}
                           />
                        </FormControl>
                     </Grid> : null}

                     <Grid item xs={12}>
                        <DialogContentText>
                           <b>Ceremony Attendance</b>
                        </DialogContentText>
                     </Grid>

                     <Grid item xs={12}>
                        <FormGroup>
                           <FormControlLabel control={<Switch
                              id="allow-family-to-attend-ceremony-switch"
                              checked={formValues.allowFamilyToAttendCeremony}
                              onChange={(event) => setFormValues({ ...formValues, allowFamilyToAttendCeremony: event.target.checked })}
                              inputProps={{ 'aria-label': 'allow-family-to-attend-ceremony-switch' }}
                           />} label="Allow Family To Attend Ceremony" />
                        </FormGroup>
                     </Grid>

                     <Grid item xs={12}>
                        <FormGroup>
                           <FormControlLabel control={<Switch
                              id="allow-friends-to-attend-ceremony-switch"
                              checked={formValues.allowFriendsToAttendCeremony}
                              onChange={(event) => setFormValues({ ...formValues, allowFriendsToAttendCeremony: event.target.checked })}
                              inputProps={{ 'aria-label': 'allow-friends-to-attend-ceremony-switch' }}
                           />} label="Allow Friends To Attend Ceremony" />
                        </FormGroup>
                     </Grid>

                     <Grid item xs={12}>
                        <FormGroup>
                           <FormControlLabel control={<Switch
                              id="allow-public-to-attend-ceremony-switch"
                              checked={formValues.allowPublicToAttendCeremony}
                              onChange={(event) => setFormValues({ ...formValues, allowPublicToAttendCeremony: event.target.checked })}
                              inputProps={{ 'aria-label': 'allow-public-to-attend-ceremony-switch' }}
                           />} label="Allow Public To Attend Ceremony" />
                        </FormGroup>
                     </Grid>
                  </Fragment> : null}

                  <Grid item xs={12}>
                     <DialogContentText>
                        <b>Anything Else?</b>
                     </DialogContentText>
                  </Grid>

                  <Grid item xs={12}>
                     <FormControl fullWidth>
                        <TextAreaWithLimit
                           id="additional-final-wishes-input"
                           label="Additional Final Wishes"
                           maxLength={255}
                           value={formValues.additionalFinalWishes}
                           onChange={event => setFormValues({ ...formValues, additionalFinalWishes: event.target.value })}
                           error={formValidation.additionalFinalWishes ? true : false}
                           helperText={formValidation.additionalFinalWishes}
                           placeholder="Describe any other final wishes..."
                           rows={3}
                        />
                     </FormControl>
                  </Grid>
               </Grid>
            </Box>
         </DialogContent>
         <DialogActions>
            <Button variant="outlined" startIcon={<CancelIcon />} onClick={props.handleClose}>Close</Button>
            <Button variant="contained" startIcon={<CheckIcon />} onClick={handleSaveFuneralArrangementsButtonClick}>Save</Button>
         </DialogActions>
      </Dialog>
   );
});

FuneralArrangementsDialog.propTypes = {
   open: PropTypes.bool.isRequired,
   handleClose: PropTypes.func.isRequired,
   handleSaveFuneralArrangements: PropTypes.func.isRequired,
};

export default FuneralArrangementsDialog;
