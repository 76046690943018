import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Alert from '@mui/material/Alert';
import MUILink from '@mui/material/Link';

const LimitReachedAlert = (props) => {
   const history = useHistory();

   const handleNavigateToProfile = () => {
      history.push('/my-profile');
   };

   return (
      <Alert sx={{ mt: 1 }} severity="info">You've reached the limit of {props.resourceName} for {props.currentSubscriptionTier ? `a ${props.currentSubscriptionTier.name} profile` : 'this profile type'}.{props.currentSubscriptionTier && props.currentSubscriptionTier.id === 'free' ? ' ' : ''}{props.currentSubscriptionTier && props.currentSubscriptionTier.id === 'free' ? <MUILink onClick={handleNavigateToProfile} sx={{cursor: 'pointer'}}>Upgrade your profile!</MUILink> : ''}</Alert>
   )
};

LimitReachedAlert.propTypes = {
   resourceName: PropTypes.string.isRequired,
   currentSubscriptionTier: PropTypes.object,
};

export default LimitReachedAlert;
