import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

const TextAreaWithLimit = (props)=>{
   return (<Fragment>
      <TextField
         autoFocus={props.autoFocus}
         margin="dense"
         id={props.id}
         label={props.label}
         type="text"
         inputProps={{ maxLength: props.maxLength }}
         value={props.value}
         onChange={event => props.onChange(event)}
         error={props.error ? true : false}
         helperText={props.helperText}
         placeholder={props.placeholder || props.label}
         fullWidth
         rows={props.rows}
         multiline
         onFocus={props.onFocus}
      />
      <Grid container direction="row" justifyContent="right" alignItems="right" px={1}>
         <i align="right" style={{ color: "gray" }} px={2}><small>{props.maxLength - (props.value ? props.value.length : 0)} characters left</small></i>
      </Grid>
   </Fragment>);
};

TextAreaWithLimit.propTypes = {
   onChange: PropTypes.func.isRequired,
   id: PropTypes.string.isRequired,
   label: PropTypes.string.isRequired,
   maxLength: PropTypes.number.isRequired,
   value: PropTypes.string,
   error: PropTypes.bool,
   helperText: PropTypes.string,
   placeholder: PropTypes.string,
   rows: PropTypes.number.isRequired,
   onFocus: PropTypes.func,
   autoFocus: PropTypes.bool,
};

export default TextAreaWithLimit;
