import React, { useEffect, Fragment, useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import NumberFormat from 'react-number-format';
import { Button, FormHelperText } from '@mui/material';
import AddressDisplay from './AddressDisplay';
import addressService from '../data/AddressService';
import LoaderIcon from './LoaderIcon';

const AddressFormFields = forwardRef((props, ref) => {
   const [formValues, setFormValues] = useState({
      line1: '',
      line2: '',
      city: '',
      postalCode: '',
   });
   const [activeStateProvince, setActiveStateProvince] = useState('select-state');
   const [savedAddresses, setSavedAddresses] = useState({ loading: true });

   useEffect(() => {
      loadSavedAddresses();
   }, []);

   const loadSavedAddresses = async () => {
      if(props.showSavedAddresses)
         setSavedAddresses(Object.assign({ loading: false }, await addressService.getSavedAddresses()));
   };

   // validation
   const [formValidation, setFormValidation] = useState({});
   const formIsValid = () => {
      let errors = {};

      const validateAddress = needToValidateAddress();

      if (validateAddress && formValues.line1.length < 2)
         errors.line1 = 'Address Line 1 must be at least two characters.';
      if (validateAddress && formValues.city.length < 2)
         errors.city = 'City must be at least two characters.';
      if (validateAddress && activeStateProvince === 'select-state')
         errors.stateProvince = 'State must be selected.';
      if (validateAddress && ((formValues.postalCode && formValues.postalCode.toString().length !== 5 && formValues.postalCode.toString().length !== 9) || !formValues.postalCode))
         errors.postalCode = 'Zip Code invalid.';

      setFormValidation(errors);

      return Object.keys(errors).length === 0;
   };

   const needToValidateAddress = () => {
      if(props.isRequired)
         return true;
      else if(!props.isRequired && (formValues.line1 || formValues.city || (formValues.postalCode && formValues.postalCode.toString())))
         return true;
      else
         return false;
   };

   const setState = (state) => {
      setFormValues({
         line1: state && state.line1 || '',
         line2: state && state.line2 || '',
         city: state && state.city || '',
         postalCode: state && state.postalCode || '',
      });
      setActiveStateProvince(state && state.stateProvince.id || 'select-state');
   };

   // control validation, state from parent component
   useImperativeHandle(ref, () => ({
      validate() {
         return formIsValid();
      },

      setState(state) {
         setState(state);
      },

      getState() {
         return cleanData(formValues, activeStateProvince);
      }
   }));

   const handleLine1Change = (event) => {
      setFormValues({ ...formValues, line1: event.target.value });
   };

   const handleLine2Change = (event) => {
      setFormValues({ ...formValues, line2: event.target.value });
   };

   const handleCityChange = (event) => {
      setFormValues({ ...formValues, city: event.target.value });
   };

   const handleStateProvinceChange = (event) => {
      setActiveStateProvince(event.target.value);
   };

   const handlePostalCodeChange = (value) => {
      setFormValues({ ...formValues, postalCode: value.value });
   };

   const cleanData = (formValues, activeStateProvince) => {
      if(needToValidateAddress() && formIsValid())
         return {
            ...formValues,
            line2: formValues.line2 || null,
            stateProvince: props.addressStatesProvinces.find(element => element.id === activeStateProvince),
            postalCode: formValues.postalCode ? formValues.postalCode.toString() : null,
            saveAddressToSlot: (selectedSavedAddressSlot > 0 && selectedSavedAddressSlot < 4) ? selectedSavedAddressSlot : null,
         };
      else return null;
   };

   const [selectedSavedAddressSlot, setSelectedSavedAddressSlot] = useState(null);
   const clickSavedAddressSlot = (slotIndex) => {
      setSelectedSavedAddressSlot(slotIndex === selectedSavedAddressSlot ? null : slotIndex);

      if(slotIndex === 1 && savedAddresses.firstSavedAddress)
         setState(savedAddresses.firstSavedAddress)
      if(slotIndex === 2 && savedAddresses.secondSavedAddress)
         setState(savedAddresses.secondSavedAddress)
      if(slotIndex === 3 && savedAddresses.thirdSavedAddress)
         setState(savedAddresses.thirdSavedAddress)
   };

   return (
      <Fragment>
         {props.label ? <Grid item xs={12}>
             <b>{props.label}</b>
         </Grid> : null}

         {props.showSavedAddresses ? <Grid item xs={12}>
            <Grid container spacing={1}>
               {savedAddresses.loading ? <Grid item xs={12}><LoaderIcon /> </Grid> : <Fragment>
                  <Grid item xs={4}>
                     <Button onClick={() => clickSavedAddressSlot(1)} variant={selectedSavedAddressSlot === 1 ? 'contained' : 'outlined'} sx={{ fontSize: '0.68em', textAlign: 'left', width: '100%', height: '100%', display: 'inline' }}>
                        {savedAddresses.firstSavedAddress ? <AddressDisplay address={savedAddresses.firstSavedAddress} /> : 'Save address to this slot.'}
                     </Button>
                  </Grid>
                  <Grid item xs={4}>
                     <Button onClick={() => clickSavedAddressSlot(2)} variant={selectedSavedAddressSlot === 2 ? 'contained' : 'outlined'} sx={{ fontSize: '0.68em', textAlign: 'left', width: '100%', height: '100%', display: 'inline' }}>
                        {savedAddresses.secondSavedAddress ? <AddressDisplay address={savedAddresses.secondSavedAddress} /> : 'Save address to this slot.'}
                     </Button>
                  </Grid>
                  <Grid item xs={4}>
                     <Button onClick={() => clickSavedAddressSlot(3)} variant={selectedSavedAddressSlot === 3 ? 'contained' : 'outlined'} sx={{ fontSize: '0.68em', textAlign: 'left', width: '100%', height: '100%', display: 'inline' }}>
                        {savedAddresses.thirdSavedAddress ? <AddressDisplay address={savedAddresses.thirdSavedAddress} /> : 'Save address to this slot.'}
                     </Button>
                  </Grid>
               </Fragment>}
            </Grid>
         </Grid> : null}

         <Grid item md={12}>
            <FormControl fullWidth>
               <TextField
                  margin="dense"
                  id="line1-input"
                  label={`Address Line 1${props.isRequired ? '*' : ''}`}
                  type="text"
                  value={formValues.line1}
                  onChange={event => handleLine1Change(event)}
                  error={formValidation.line1 ? true : false}
                  helperText={formValidation.line1}
                  fullWidth
               />
            </FormControl>
         </Grid>
         <Grid item md={12}>
            <FormControl fullWidth>
               <TextField
                  margin="dense"
                  id="line2-input"
                  label="Address Line 2"
                  type="text"
                  value={formValues.line2}
                  onChange={event => handleLine2Change(event)}
                  fullWidth
               />
            </FormControl>
         </Grid>
         <Grid item md={4}>
            <FormControl fullWidth>
               <TextField
                  margin="dense"
                  id="city-input"
                  label={`City${props.isRequired ? '*' : ''}`}
                  type="text"
                  value={formValues.city}
                  onChange={event => handleCityChange(event)}
                  error={formValidation.city ? true : false}
                  helperText={formValidation.city}
                  fullWidth
               />
            </FormControl>
         </Grid>
         <Grid item md={4}>
            <FormControl fullWidth>
               <InputLabel id="state-select-label">{`State${props.isRequired ? '*' : ''}`}</InputLabel>
               <Select
                  labelId="state-select-label"
                  id="state-select"
                  label={`State${props.isRequired ? '*' : ''}`}
                  value={activeStateProvince}
                  onChange={event => handleStateProvinceChange(event)}
                  error={formValidation.stateProvince ? true : false}
                  sx={{ marginTop: '0.5em' }}
               >
                  <MenuItem value="select-state">--- Select State ---</MenuItem>
                  {props.addressStatesProvinces.map(stateProvince => (<MenuItem key={stateProvince.id} value={stateProvince.id}>{stateProvince.name}</MenuItem>))}
               </Select>
               {formValidation.stateProvince ? <FormHelperText error>{formValidation.stateProvince}</FormHelperText> : null}
            </FormControl>
         </Grid>
         <Grid item md={4}>
            <FormControl fullWidth>
               <NumberFormat
                  customInput={TextField}
                  format="#####-####"
                  isNumericString={true}
                  mask="_"
                  margin="dense"
                  id="postal-code-input"
                  label={`Zip Code${props.isRequired ? '*' : ''}`}
                  type="text"
                  value={formValues.postalCode}
                  onValueChange={(value) => handlePostalCodeChange(value)}
                  error={formValidation.postalCode ? true : false}
                  helperText={formValidation.postalCode}
                  fullWidth
               />
            </FormControl>
         </Grid>
      </Fragment>
   )
});

AddressFormFields.propTypes = {
   addressStatesProvinces: PropTypes.array.isRequired,
   label: PropTypes.string,
   isRequired: PropTypes.bool,
   showSavedAddresses: PropTypes.bool,
   // prefillDefaultAddress: PropTypes.func,
};

export default AddressFormFields;
