import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const DialogComponent = (props) => {
   return (
      <Dialog
         open={props.open}
         onClose={props.handleDismiss}
         fullWidth
         maxWidth="md"
         aria-labelledby="alert-dialog-title"
         aria-describedby="alert-dialog-description"
      >
         <DialogTitle id="alert-dialog-title">
            {props.title}
         </DialogTitle>
         <DialogContent>
            {props.children}
         </DialogContent>
         <DialogActions>
            <Button variant="text" onClick={props.handleDismiss} autoFocus>{props.dismissButtonLabel}</Button>
         </DialogActions>
      </Dialog>
   );
};

DialogComponent.propTypes = {
   open: PropTypes.bool.isRequired,
   title: PropTypes.string.isRequired,
   dismissButtonLabel: PropTypes.string.isRequired,
   handleDismiss: PropTypes.func.isRequired,
};

export default DialogComponent;
