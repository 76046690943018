import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useHistory, Link } from 'react-router-dom';
import profileService from '../data/ProfileService';
import systemLookupService from '../data/SystemLookupService';
import assetService from '../data/AssetService';
import trusteeInviteService from '../data/TrusteeInviteService';
import otherContactService from '../data/OtherContactService';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import AssetDialog from '../components/AssetDialog';
import OtherContactDialog from '../components/OtherContactDialog';
import ContactsWidgetBody from '../components/ContactsWidgetBody';
import InviteDialog from '../components/InviteDialog';
import trusteeService from '../data/TrusteeService';
import institutionService from '../data/InstitutionService';
import documentService from '../data/DocumentService';
import InstitutionDialog from '../components/InstitutionDialog';
import DocumentDialog from '../components/DocumentDialog';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import CategoryCountWidgetBody from '../components/CategoryCountWidgetBody';
import Button from '@mui/material/Button';
import ViewListIcon from '@mui/icons-material/ViewList';
import FolderIcon from '@mui/icons-material/Folder';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ContactsIcon from '@mui/icons-material/Contacts';
import WidgetContainer from '../components/WidgetContainer';
import PubSub from 'pubsub-js';
import LTIconButton from '../components/LTIconButton';
import AddIcon from '@mui/icons-material/Add';
import subscriptionService from '../data/SubscriptionService';

const MyRecordsPage = () => {
   const otherContactDialogRef = useRef();

   const [assetTypes, setAssetTypes] = useState({ data: [], loading: false });
   const [assetCounts, setAssetCounts] = useState({ data: [], limits: {}, loading: false });
   const [institutionCounts, setInstitutionCounts] = useState({ data: [], limits: {}, loading: false });
   const [institutionTypes, setInstitutionTypes] = useState({ data: [], loading: false });
   const [documentCounts, setDocumentCounts] = useState({ data: [], limits: {}, loading: false });
   const [documentTypes, setDocumentTypes] = useState({ data: [], loading: false });
   const [addressStatesProvinces, setAddressStatesProvinces] = useState({ data: [], loading: false });
   const [trusteeInvites, setTrusteeInvites] = useState({ data: [], limits: {}, loading: false });
   const [trustees, setTrustees] = useState({ data: [], limits: {}, loading: false });
   const [otherContacts, setOtherContacts] = useState({ data: [], limits: {}, loading: false });
   const [otherContactTypes, setOtherContactTypes] = useState({ data: [], loading: false });
   const [currentSubscriptionStatus, setCurrentSubscriptionStatus] = useState({ data: {}, loading: false });

   useEffect(() => {
      checkProfileExists();
   }, []);

   const history = useHistory();

   const checkProfileExists = async () => {
      const profileExists = await profileService.getProfileExists();
      if (profileExists) {
         getAssetTypes();
         getAssetCounts();
         getInstitutionTypes();
         getInstitutionCounts();
         getDocumentTypes();
         getDocumentCounts();
         getAddressStatesProvinces();
         getTrusteeInvites();
         getTrustees();
         getOtherContacts();
         getOtherContactTypes();
         getCurrentSubscriptionStatus();
      } else {
         // todo: either move this somewhere generic and/or add to other pages
         //go to "get started wizard"
         history.push('/get-started');
      }
   };

   // assets
   const addAsset = async (asset) => {
      if(asset.fileName)
         PubSub.publish('nonBlockingLoader.addMessage', { id: asset.fileName, text: `Uploading ${asset.fileName}...` });
      else
         setAssetCounts({ ...assetCounts, loading: true });

      setAssetDialogOpen(false);
      await assetService.addAsset(asset);

      if(asset.fileName)
         PubSub.publish('nonBlockingLoader.removeMessage', asset.fileName);

      getAssetCounts();
   };

   const getAssetTypes = async () => {
      setAssetTypes({ data: [], loading: true });
      const assetTypes = await systemLookupService.getAssetTypes();
      setAssetTypes({ data: assetTypes, loading: false });
   };

   const getAssetCounts = async () => {
      setAssetCounts({ data: [], limits: {}, loading: true });
      const assetCounts = await assetService.getAssetCounts();
      setAssetCounts({ data: assetCounts.data, limits: assetCounts.limits, loading: false });
   };

   const getAddressStatesProvinces = async () => {
      setAddressStatesProvinces({ data: [], loading: true });
      const addressStatesProvinces = await systemLookupService.getAddressStatesProvinces();
      setAddressStatesProvinces({ data: addressStatesProvinces, loading: false });
   };

   // institutions
   const addInstitution = async (institution) => {
      setInstitutionCounts({ data: institutionCounts.data, limits: {}, loading: true });
      await institutionService.addInstitution(institution);
      getInstitutionCounts();
      setInstitutionDialogOpen(false);
   };

   const getInstitutionTypes = async () => {
      setInstitutionTypes({ data: [], loading: true });
      const institutionTypes = await systemLookupService.getInstitutionTypes();
      setInstitutionTypes({ data: institutionTypes, loading: false });
   };

   const getInstitutionCounts = async () => {
      setInstitutionCounts({ data: [], limits: {}, loading: true });
      const institutionCounts = await institutionService.getInstitutionCounts();
      setInstitutionCounts({ data: institutionCounts.data, limits: institutionCounts.limits, loading: false });
   };

   // trustee invites
   const addTrusteeInvite = async (invite) => {
      setTrusteeInvites({ data: trusteeInvites.data, limits: trusteeInvites.limits, loading: true });
      await trusteeInviteService.addTrusteeInvite(invite);
      getTrusteeInvites();
      setTrusteeInviteDialogOpen(false);
   };

   const getTrusteeInvites = async () => {
      setTrusteeInvites({ data: [], limits: {}, loading: true });
      const trusteeInvites = await trusteeInviteService.getTrusteeInvites();
      setTrusteeInvites({ data: trusteeInvites.data, limits: trusteeInvites.limits, loading: false });
   };

   // other contacts
   const addSaveOtherContact = async (contact) => {
      if(contact.id)
         await otherContactService.putOtherContact(contact);
      else
         await otherContactService.addOtherContact(contact);

      getOtherContacts();
      setOtherContactDialogOpen(false);
   };

   const editOtherContact = async (contact) => {
      await setOtherContactDialogOpen(true);
      if (contact && otherContactDialogRef.current)
         otherContactDialogRef.current.setState(contact);
   };

   const getOtherContacts = async () => {
      setOtherContacts({ data: [], limits: {}, loading: true });
      const otherContacts = await otherContactService.getOtherContacts();
      setOtherContacts({ data: otherContacts.data, limits: otherContacts.limits, loading: false });
   };

   const getOtherContactTypes = async () => {
      setOtherContactTypes({ data: [], loading: true });
      const otherContactTypes = await systemLookupService.getOtherContactTypes();
      setOtherContactTypes({ data: otherContactTypes, loading: false });
   };

   // trustees
   const getTrustees = async () => {
      setTrustees({ data: [], limits: {}, loading: true });
      const trustees = await trusteeService.getTrustees();
      setTrustees({ data: trustees.data, limits: trustees.limits, loading: false });
   };

   // documents
   const addDocument = async (document) => {
      PubSub.publish('nonBlockingLoader.addMessage', { id: document.fileName, text: `Uploading ${document.fileName}...` });
      setDocumentDialogOpen(false);
      await documentService.addDocument(document);
      PubSub.publish('nonBlockingLoader.removeMessage', document.fileName);
      getDocumentCounts();
   };

   const getDocumentTypes = async () => {
      setDocumentTypes({ data: [], loading: true });
      const documentTypes = await systemLookupService.getDocumentTypes();
      setDocumentTypes({ data: documentTypes, loading: false });
   };

   const getDocumentCounts = async () => {
      setDocumentCounts({ data: [], limits: {}, loading: true });
      const documentCounts = await documentService.getDocumentCounts();
      setDocumentCounts({ data: documentCounts.data, limits: documentCounts.limits, loading: false });
   };

   const sumOfCounts = (counts) => {
      let sum = 0;
      for(let i = 0; i < counts.length; i++) {
         sum += counts[i].count;
      }
      return sum;
   };

   // current subscription status
   const getCurrentSubscriptionStatus = async () => {
      setCurrentSubscriptionStatus({data: {}, loading: true});
      const subscriptionStatus = await subscriptionService.getCurrentStatus();
      setCurrentSubscriptionStatus({data: subscriptionStatus, loading: false});
   };

   // dialog states
   const [assetDialogOpen, setAssetDialogOpen] = useState(false);
   const [trusteeInviteDialogOpen, setTrusteeInviteDialogOpen] = useState(false);
   const [otherContactDialogOpen, setOtherContactDialogOpen] = useState(false);
   const [institutionDialogOpen, setInstitutionDialogOpen] = useState(false);
   const [documentDialogOpen, setDocumentDialogOpen] = useState(false);

   return (
      <div>
         <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={1}>
               <Grid item xs={12} md={6} lg={3}>
                  <WidgetContainer
                     title="Assets"
                     icon={DirectionsCarIcon}
                     color="#00a5de"
                  >
                     <CategoryCountWidgetBody
                        actionButtons={<Fragment>
                           <Button
                              variant="link"
                              component={Link}
                              to="/assets"
                              startIcon={<ViewListIcon />}
                           >
                              View All
                           </Button>
                           <LTIconButton
                              disabled={assetCounts.loading || sumOfCounts(assetCounts.data) >= assetCounts.limits.asset}
                              tooltipText="Add Asset"
                              ariaLabel="add-asset"
                              onClick={() => setAssetDialogOpen(true)}
                              icon={<AddIcon />}
                           />
                        </Fragment>}
                        baseRoute="assets"
                        loading={assetCounts.loading}
                        data={assetCounts.data}
                        reachedLimit={sumOfCounts(assetCounts.data) >= assetCounts.limits.asset}
                        limitAlertResourceName="Assets"
                        currentSubscriptionTier={currentSubscriptionStatus.data}
                     />
                  </WidgetContainer>
               </Grid>
               <Grid item xs={12} md={6} lg={3}>
                  <WidgetContainer
                     title="Financial Info"
                     icon={AttachMoneyIcon}
                     color="#00a5de"
                  >
                     <CategoryCountWidgetBody
                        actionButtons={<Fragment>
                           <Button
                              variant="link"
                              component={Link}
                              to="/institutions"
                              startIcon={<ViewListIcon />}
                           >
                              View All
                           </Button>
                           <LTIconButton
                              disabled={institutionCounts.loading || sumOfCounts(institutionCounts.data) >= institutionCounts.limits.institution}
                              tooltipText="Add Institution"
                              ariaLabel="add-institution"
                              onClick={() => setInstitutionDialogOpen(true)}
                              icon={<AddIcon />}
                           />
                        </Fragment>}
                        baseRoute="institutions"
                        loading={institutionCounts.loading}
                        data={institutionCounts.data}
                        reachedLimit={sumOfCounts(institutionCounts.data) >= institutionCounts.limits.institution}
                        limitAlertResourceName="Financial Info"
                        currentSubscriptionTier={currentSubscriptionStatus.data}
                     />
                  </WidgetContainer>
               </Grid>
               <Grid item xs={12} md={6} lg={3}>
                  <WidgetContainer
                     title="Documents"
                     icon={FolderIcon}
                     color="#00a5de"
                  >
                     <CategoryCountWidgetBody
                        actionButtons={<Fragment>
                           <Button
                              variant="link"
                              component={Link}
                              to="/documents"
                              startIcon={<ViewListIcon />}
                           >
                              View All
                           </Button>
                           <LTIconButton
                              disabled={documentCounts.loading || sumOfCounts(documentCounts.data) >= documentCounts.limits.document}
                              tooltipText="Add Document"
                              ariaLabel="add-document"
                              onClick={() => setDocumentDialogOpen(true)}
                              icon={<AddIcon />}
                           />
                        </Fragment>}
                        baseRoute="documents"
                        loading={documentCounts.loading}
                        data={documentCounts.data}
                        reachedLimit={sumOfCounts(documentCounts.data) >= documentCounts.limits.document}
                        limitAlertResourceName="Documents"
                        currentSubscriptionTier={currentSubscriptionStatus.data}
                     />
                  </WidgetContainer>
               </Grid>
               <Grid item xs={12} md={6} lg={3}>
                  <WidgetContainer
                     title="Contacts"
                     icon={ContactsIcon}
                     color="#00a5de"
                  >
                     <ContactsWidgetBody
                        loading={trusteeInvites.loading || otherContacts.loading}
                        trusteeInvites={trusteeInvites.data}
                        resourceLimits={Object.assign(trusteeInvites.limits, otherContacts.limits)}
                        trustees={trustees.data}
                        otherContacts={otherContacts.data}
                        openInviteTrusteeDialog={() => setTrusteeInviteDialogOpen(true)}
                        openOtherContactDialog={(contact) => editOtherContact(contact)}
                        currentSubscriptionTier={currentSubscriptionStatus.data}
                     />
                  </WidgetContainer>
               </Grid>
            </Grid>
         </Box>

         <AssetDialog
            loading={assetCounts.loading}
            open={assetDialogOpen}
            handleClose={() => setAssetDialogOpen(false)}
            handleAddSaveAsset={addAsset}
            assetTypes={assetTypes.data}
            addressStatesProvinces={addressStatesProvinces.data}
            allowPhotoUpload={(assetCounts.limits && assetCounts.limits.assetPhoto === 1 ) ? true : false}
         />
         <InviteDialog
            dialogTitle="Invite Trustee"
            open={trusteeInviteDialogOpen}
            handleClose={() => setTrusteeInviteDialogOpen(false)}
            handleAddInvite={(invite) => addTrusteeInvite(invite)}
         />
         <OtherContactDialog
            ref={otherContactDialogRef}
            open={otherContactDialogOpen}
            handleClose={() => setOtherContactDialogOpen(false)}
            handleAddSaveOtherContact={(contact) => addSaveOtherContact(contact)}
            addressStatesProvinces={addressStatesProvinces.data}
            otherContactTypes={otherContactTypes.data}
         />
         <InstitutionDialog
            open={institutionDialogOpen}
            handleClose={() => setInstitutionDialogOpen(false)}
            handleAddSaveInstitution={addInstitution}
            institutionTypes={institutionTypes.data}
            addressStatesProvinces={addressStatesProvinces.data}
         />
         <DocumentDialog
            loading={documentCounts.loading}
            open={documentDialogOpen}
            handleClose={() => setDocumentDialogOpen(false)}
            handleAddSaveDocument={addDocument}
            documentTypes={documentTypes.data}
         />
      </div>
   );
};

export default MyRecordsPage;
