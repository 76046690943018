import React, { Fragment, useState } from 'react';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import TopAppBar from './TopAppBar';
import MobileBottomNav from './MobileBottomNav';
import NonBlockingLoader from './NonBlockingLoader';

// import Button from "@mui/material/Button";

const themeLight = createTheme({
   palette: {
      primary: {
         // light: will be calculated from palette.primary.main,
         main: '#00a5de',
         // dark: will be calculated from palette.primary.main,
         // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
         main: '#3b434c',
      },
      background: {
         default: "#e2eaee"
      }
   }
});

const themeDark = createTheme({
   palette: {
      background: {
         default: "#222"
      },
      text: {
         primary: "#fff"
      }
   }
});

const Layout = (props) => {
   const [light, setLight] = useState(true);

   return (
      <Fragment>
      <ThemeProvider theme={light ? themeLight : themeDark}>
         <CssBaseline />

         {/* don't show TopAppBar for specific routes */}
         {['/splash', '/authentication'].indexOf(props.pathName) === -1 ? <TopAppBar /> : null}

         <Container maxWidth={'xl'} sx={{ mb: 8, mt: 8 }}>
            {props.children}
         </Container>

         {/* todo: off for now, maybe add feature flags for things like this? */}
         {/* <Button onClick={() => setLight((prev) => !prev)}>Toggle Theme</Button> */}

         {/* don't show MobileBottomNav for specific routes */}
         {['/splash', '/authentication'].indexOf(props.pathName) === -1 ? <MobileBottomNav /> : null}

         <NonBlockingLoader />
      </ThemeProvider>
      </Fragment>
   );
};

export default Layout;
