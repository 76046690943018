import React, { useState, forwardRef, useRef, useImperativeHandle, Fragment } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Avatar from '@mui/material/Avatar';
import PhotoUploader from './PhotoUploader';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel } from '@mui/material';

import DateField from './DateField';
import TextAreaWithLimit from './TextAreaWithLimit';

const MausoleumForOthersForm = forwardRef((props, ref) => {
   const photoUploaderRef = useRef();

   const initialState = {
      epitaph: '',
      isPublic: false,
      dateOfBirth: null,
      dateOfDeath: null,
      cityOfBirth: '',
      avatarBase64: undefined,
      firstName: '',
      preferredName: '',
      middleName: '',
      maidenName: '',
      lastName: '',
   };
   const [formValues, setFormValues] = useState(initialState);

   // validation
   const [formValidation, setFormValidation] = useState({});
   const formIsValid = () => {
      let errors = {};

      if (formValues.firstName.length < 2)
         errors.firstName = 'First Name must be at least two characters.';
      if (formValues.preferredName && formValues.preferredName.length < 2)
         errors.preferredName = 'Preferred Name must be at least two characters if provided.';
      if (formValues.middleName && formValues.middleName.length < 2)
         errors.middleName = 'Middle Name must be at least two characters if provided.';
      if (formValues.lastName.length < 2)
         errors.lastName = 'Last Name must be at least two characters.';
      if (formValues.maidenName && formValues.maidenName.length < 2)
         errors.maidenName = 'Maiden Name must be at least two characters if provided.';
      if (!formValues.dateOfBirth)
         errors.dateOfBirth = 'Date of Birth must be provided.';
      if (formValues.cityOfBirth.length < 2)
         errors.cityOfBirth = 'City of Birth must be at least two characters.';


      setFormValidation(errors);

      return Object.keys(errors).length === 0;
   };

   // control validation, state from parent component
   useImperativeHandle(ref, () => ({
      validate() {
         return formIsValid();
      },

      setState(state) {
         setFormValues({
            epitaph: state.epitaph || '',
            isPublic: state.isPublic || false,
            dateOfBirth: state.dateOfBirth || null,
            dateOfDeath: state.dateOfDeath || null,
            cityOfBirth: state.cityOfBirth || '',
            avatarBase64: state.avatarBase64 || '',
            firstName: state.firstName || '',
            preferredName: state.preferredName || '',
            middleName: state.middleName || '',
            maidenName: state.maidenName || '',
            lastName: state.lastName || '',
         });
      },

      getState() {
         return cleanData(formValues);
      }
   }));

   const handleFirstNameChange = (event) => {
      setFormValues({ ...formValues, firstName: event.target.value });
   };

   const handlePreferredNameChange = (event) => {
      setFormValues({ ...formValues, preferredName: event.target.value });
   };

   const handleMiddleNameChange = (event) => {
      setFormValues({ ...formValues, middleName: event.target.value });
   };

   const handleLastNameChange = (event) => {
      setFormValues({ ...formValues, lastName: event.target.value });
   };

   const handleMaidenNameChange = (event) => {
      setFormValues({ ...formValues, maidenName: event.target.value });
   };

   const handleCityOfBirthChange = (event) => {
      setFormValues({ ...formValues, cityOfBirth: event.target.value });
   };

   const cleanData = (formValues) => {
      let avatarBase64;
      if(editingAvatar && photoUploaderRef.current) {
         const newAvatar = photoUploaderRef.current.getPhoto();
         avatarBase64 = newAvatar ? newAvatar.base64PhotoScaled : formValues.avatarBase64;
      } else {
         avatarBase64 = formValues.avatarBase64;
      }
      return {
         ...formValues,
         preferredName: formValues.preferredName ? formValues.preferredName : null,
         middleName: formValues.middleName ? formValues.middleName : null,
         maidenName: formValues.maidenName ? formValues.maidenName : null,
         avatarBase64: avatarBase64,
      };
   };

   const [editingAvatar, setEditingAvatar] = useState(false);

   return (
    <Dialog open={props.open} onClose={props.handleClose}>
        <DialogTitle>Create a Mausoleum for someone else</DialogTitle>
        <DialogContent>
            <Box component="form">
                <Grid container spacing={1}>
                    {!editingAvatar ? <Fragment>
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <Avatar
                                alt={`${formValues.lastName} ${formValues.lastName}`}
                                src={formValues.avatarBase64}
                                sx={{ width: 180, height: 180 }}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <Button onClick={() => setEditingAvatar(true)}>Edit Avatar</Button>
                        </Box>
                    </Grid>
                    </Fragment> : null }

                    {editingAvatar ? <Fragment>
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <PhotoUploader
                                ref={photoUploaderRef}
                                width={420}
                                height={420}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <Button onClick={() => setEditingAvatar(false)}>Cancel Edit</Button>
                        </Box>
                    </Grid>
                    </Fragment> : null}

                    <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                        <TextField
                            margin="dense"
                            id="first-name-input"
                            label="Legal First Name*"
                            type="text"
                            value={formValues.firstName}
                            onChange={event => handleFirstNameChange(event)}
                            error={formValidation.firstName ? true : false}
                            helperText={formValidation.firstName}
                            fullWidth
                        />
                    </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                        <TextField
                            margin="dense"
                            id="preferred-name-input"
                            label="Preferred Name"
                            type="text"
                            value={formValues.preferredName}
                            onChange={event => handlePreferredNameChange(event)}
                            error={formValidation.preferredName ? true : false}
                            helperText={formValidation.preferredName}
                            fullWidth
                        />
                    </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                        <TextField
                            margin="dense"
                            id="last-name-input"
                            label="Legal Last Name*"
                            type="text"
                            value={formValues.lastName}
                            onChange={event => handleLastNameChange(event)}
                            error={formValidation.lastName ? true : false}
                            helperText={formValidation.lastName}
                            fullWidth
                        />
                    </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                        <TextField
                            margin="dense"
                            id="maiden-name-input"
                            label="Maiden Name"
                            type="text"
                            value={formValues.maidenName}
                            onChange={event => handleMaidenNameChange(event)}
                            error={formValidation.maidenName ? true : false}
                            helperText={formValidation.maidenName}
                            fullWidth
                        />
                    </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                        <TextField
                            margin="dense"
                            id="middle-name-input"
                            label="Middle Name"
                            type="text"
                            value={formValues.middleName}
                            onChange={event => handleMiddleNameChange(event)}
                            error={formValidation.middleName ? true : false}
                            helperText={formValidation.middleName}
                            fullWidth
                        />
                    </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                        <TextField
                            margin="dense"
                            id="city-of-birth-input"
                            label="City of Birth*"
                            type="text"
                            value={formValues.cityOfBirth}
                            onChange={event => handleCityOfBirthChange(event)}
                            error={formValidation.cityOfBirth ? true : false}
                            helperText={formValidation.cityOfBirth}
                            fullWidth
                        />
                    </FormControl>
                    </Grid>

                    <Grid item md={12}>
                    <FormControl fullWidth>
                        <DateField
                            label="Date of Birth*"
                            value={formValues.dateOfBirth}
                            onChange={(newValue) => {
                                setFormValues({ ...formValues, dateOfBirth: newValue });
                            }}
                            error={formValidation.dateOfBirth}
                        />
                    </FormControl>
                    </Grid>
                    <Grid item md={12}>
                    <FormControl fullWidth>
                        <DateField
                            label="Date of Death"
                            value={formValues.dateOfDeath}
                            onChange={(newValue) => {
                                setFormValues({ ...formValues, dateOfDeath: newValue });
                            }}
                            error={formValidation.dateOfDeath}
                        />
                    </FormControl>
                    </Grid>
                    <Grid item md={12}>
                    <FormControl fullWidth>
                    <TextAreaWithLimit
                        autoFocus
                        id="epitaph-input"
                        label="Epitaph"
                        maxLength={255}
                        value={formValues.epitaph}
                        onChange={event => setFormValues({ ...formValues, epitaph: event.target.value })}
                        error={formValidation.epitaph ? true : false}
                        helperText={formValidation.epitaph}
                        placeholder="Add an epitaph..."
                        rows={3}
                    />
                    </FormControl>
                    </Grid>
                    <Grid item md={12}>
                    <FormControl fullWidth>
                        <FormControlLabel sx={{ mr: 0.5 }} control={<Checkbox checked={Boolean(formValues.isPublic)} onChange={(event) => setFormValues({ ...formValues, isPublic: event.target.checked})}/>} label="Should this Mausoleum be made Public?" />
                    </FormControl>
                    </Grid>
                </Grid>
            </Box>
        </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={props.handleClose}>Cancel</Button>
                <Button variant="contained" onClick={props.handleSubmit}>Submit</Button>
            </DialogActions>
        </Dialog>
   )
});

MausoleumForOthersForm.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};

export default MausoleumForOthersForm;
