import axiosService from './AxiosService';

export class TrustorService {
   _resourceName = 'trustor';

   async getIsTrustee() {
      return await axiosService.readObject(`/v1.0/${this._resourceName}`, 'is-trustee');
   }

   async getMyTrustorProfiles() {
      return await axiosService.readList(`/v1.0/${this._resourceName}`);
   }

   async getFinalWishesAvailable(trustorId) {
      return await axiosService.readObject(`/v1.0/${this._resourceName}`, `final-wishes-available/${trustorId}`);
   }

   async getTombOpen(trustorId) {
      return await axiosService.readObject(`/v1.0/${this._resourceName}`, `tomb-open/${trustorId}`);
   }

   async getTrustorProfile(trustorId) {
      return await axiosService.readObject(`/v1.0/${this._resourceName}`, `profile/${trustorId}`);
   }

   async getTrustorCareAndHealth(trustorId) {
      return await axiosService.readObject(`/v1.0/${this._resourceName}`, `care-and-health/${trustorId}`);
   }

   async getTrustorFuneralArrangements(trustorId) {
      return await axiosService.readObject(`/v1.0/${this._resourceName}`, `funeral-arrangements/${trustorId}`);
   }

   async getAssets(trustorId) {
      return await axiosService.readList(`/v1.0/${this._resourceName}`, `/assets/${trustorId}`);
   }

   async getInstitutions(trustorId) {
      return await axiosService.readList(`/v1.0/${this._resourceName}`, `/institutions/${trustorId}`);
   }

   async getDocuments(trustorId) {
      return await axiosService.readList(`/v1.0/${this._resourceName}`, `/documents/${trustorId}`);
   }

   async getContacts(trustorId) {
      return await axiosService.readList(`/v1.0/${this._resourceName}`, `/contacts/${trustorId}`);
   }

   async putReportedDeceasedAt(trustorId) {
      return await axiosService.updateFlag(`/v1.0/${this._resourceName}`, 'report-deceased', trustorId);
   }

   async uploadDeathCertificate(trustorId, deathCertificate) {
      return await axiosService.create(`/v1.0/${this._resourceName}/upload-death-certificate/${trustorId}`, deathCertificate);
   }

   static get instance() { return trustorService }
}

const trustorService = new TrustorService();

export default trustorService;
