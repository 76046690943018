import axiosService from './AxiosService';

export class JourneyFeedService {
   _resourceName = 'journey-feed';

   async getJourneyFeed(year, monthList) {
      return await axiosService.readList(`/v1.0/${this._resourceName}/search`, `/${year}${monthList ? `/${monthList}` : ''}`);
   }

   static get instance() { return journeyFeedService }
}

const journeyFeedService = new JourneyFeedService();

export default journeyFeedService;
