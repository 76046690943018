import React from 'react';
import PropTypes from 'prop-types';
import Alert from '@mui/material/Alert';

const AlertSubscriptionResult = (props) => {
   if (props.success)
      return <Alert sx={{ mt: 1 }} severity="success">Successfully subscribed. It may take some time for the changes to take effect.</Alert>;
   else
      return <Alert sx={{ mt: 1 }} severity="info">Subscription purchase cancelled.</Alert>
};

AlertSubscriptionResult.propTypes = {
   success: PropTypes.bool.isRequired,
};

export default AlertSubscriptionResult;
