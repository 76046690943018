import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import profileService from '../data/ProfileService';
import Box from '@mui/material/Box';
import LoaderIcon from '../components/LoaderIcon';
import mausoleumService from '../data/MausoleumService';
import MausoleumFeed from '../components/MausoleumFeed';
import parseISO from 'date-fns/parseISO';
import { buildQueryString } from '../utils/useQuery';
import systemLookupService from '../data/SystemLookupService';

const MausoleaSearchPage = () => {
   const [mausolea, setMausolea] = useState({ data: [], loading: false});
   const [loading, setLoading] = useState(true);
   const [featureFlags, setFeatureFlags] = useState([]);

   useEffect(() => {
      checkProfileExists();
   }, []);

   const history = useHistory();

   const checkProfileExists = async () => {
      setLoading(true);
      const profileExists = await profileService.getProfileExists();

      if (profileExists) {
         setLoading(false);
         search();
         getFeatureFlags();
      } else {
         //go to "get started wizard"
         history.push('/get-started');
      }
   };

   const onUpdateFilter = (firstName, lastName, cityOfBirth, dateOfBirth, dateOfDeath) => {
      const queryString = buildQueryString([{
         key: 'firstName',
         value: firstName
      },{
         key: 'lastName',
         value: lastName
      },{
         key: 'cityOfBirth',
         value: cityOfBirth
      },{
         key: 'dateOfBirth',
         value: dateOfBirth
      },{
         key: 'dateOfDeath',
         value: dateOfDeath
      }]);

      history.push(queryString || '/mausolea-search');
      search(firstName, lastName, cityOfBirth, dateOfBirth, dateOfDeath);
   };

   const search = (firstName, lastName, cityOfBirth, dateOfBirth, dateOfDeath) => {
      if(!firstName && !lastName && !cityOfBirth && !dateOfBirth && !dateOfDeath) {
         getPopularMausolea();
      }
      else {
         searchMausolea({
            firstName: firstName ? firstName : null,
            lastName: lastName ? lastName : null,
            cityOfBirth: cityOfBirth ? cityOfBirth : null,
            dateOfBirth: dateOfBirth ? dateOfBirth : null,
            dateOfDeath: dateOfDeath ? dateOfDeath : null,
         });
      }
   };

   const getPopularMausolea = async () => {
      setMausolea({ data: [], loading: true });
      const mausolea = await mausoleumService.getMausoleaByViews();
      setMausolea({ data: mausolea.map(mausoleum => (cleanMausoleum(mausoleum))), loading: false });
   };

   const searchMausolea = async (queryOptions) => {
      setMausolea({ data: [], loading: true });
      const mausolea = await mausoleumService.getMausolea(queryOptions);
      setMausolea({ data: mausolea.map(mausoleum => (cleanMausoleum(mausoleum))), loading: false });
   };

   // todo: fix query string for the different search modes, maybe something like:
      // mausolea/popular
      // mausolea/search?firstName=bob...
      // mausolea/managed-by-me
   const onGetMausoleaManagedByMe = async () => {
      setMausolea({ data: [], loading: true });
      const mausolea = await mausoleumService.getMausoleaManagedByMe();
      setMausolea({ data: mausolea.map(mausoleum => (cleanMausoleum(mausoleum))), loading: false });
   };

   const cleanMausoleum = (mausoleum) => {
      return {
         ...mausoleum,
         dateOfBirth: mausoleum.dateOfBirth ? parseISO(mausoleum.dateOfBirth) : null,
         dateOfDeath: mausoleum.dateOfDeath ? parseISO(mausoleum.dateOfDeath) : null
      };
   };

   const getFeatureFlags = async () => {
      setFeatureFlags(await systemLookupService.getMyFeatureFlags());
   };

   const handleSubmitOtherMausoleum = async (mausoleum) => {
      setMausolea({ data: [], loading: true });
      await mausoleumService.addOtherMausoleum(mausoleum);
      onGetMausoleaManagedByMe();
   };

   if (loading)
      return (<LoaderIcon />);

   return (
      <Box sx={{ flexGrow: 1 }}>
         <MausoleumFeed
            loading={mausolea.loading}
            mausolea={mausolea.data}
            onSearch={onUpdateFilter}
            onGetMausoleaManagedByMe={onGetMausoleaManagedByMe}
            featureFlags={featureFlags}
            onSubmitOtherMausoleum={(mausoleum) => handleSubmitOtherMausoleum(mausoleum)}
         />
      </Box>
   );
};

export default MausoleaSearchPage;
