import React from 'react';
import PropTypes from 'prop-types';
import LoaderIcon from './LoaderIcon';
import AddressDisplay from '../components/AddressDisplay';
import Grid from '@mui/material/Grid';

const AddressListWidget = (props) => {
   if (props.loading)
      return (<LoaderIcon />);

   return (
      <Grid container spacing={1}>
         <Grid item xs={12}>
            <b>First Address</b>
         </Grid>
         <Grid item xs={12}>
            {props.savedAddresses.firstSavedAddress ? <AddressDisplay address={props.savedAddresses.firstSavedAddress} /> : null}
         </Grid>

         <Grid item xs={12}>
            <b>Second Address</b>
         </Grid>
         <Grid item xs={12}>
            {props.savedAddresses.secondSavedAddress ? <AddressDisplay address={props.savedAddresses.secondSavedAddress} /> : 'Not set.'}
         </Grid>

         <Grid item xs={12}>
            <b>Third Address</b>
         </Grid>
         <Grid item xs={12}>
            {props.savedAddresses.thirdSavedAddress ? <AddressDisplay address={props.savedAddresses.thirdSavedAddress} /> : 'Not set.'}
         </Grid>
      </Grid>
   );
};

AddressListWidget.propTypes = {
   loading: PropTypes.bool.isRequired,
   savedAddresses: PropTypes.object.isRequired,
};

export default AddressListWidget;
