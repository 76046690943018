import React, { useState, useEffect, Fragment, useRef } from 'react';
import { Link } from 'react-router-dom';
import trusteeInviteService from '../data/TrusteeInviteService';
import trusteeService from '../data/TrusteeService';
import otherContactService from '../data/OtherContactService';
import Typography from '@mui/material/Typography';
import EnhancedTable from '../components/EnhancedTable';
import InviteDialog from '../components/InviteDialog';
import OtherContactDialog from '../components/OtherContactDialog';
import systemLookupService from '../data/SystemLookupService';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { TrusteeColConfig, OtherContactColConfig } from '../components/TableColumnConfigs';
import ContactsIcon from '@mui/icons-material/Contacts';
import GavelIcon from '@mui/icons-material/Gavel';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import WidgetContainer from '../components/WidgetContainer';
import LimitReachedAlert from '../components/LimitReachedAlert';
import subscriptionService from '../data/SubscriptionService';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';

const ContactsPage = () => {
   const otherContactDialogRef = useRef();

   const [trusteeInvites, setTrusteeInvites] = useState({ data: [], limits: {}, loading: true });
   const [trustees, setTrustees] = useState({ data: [], limits: {}, loading: false });
   const [otherContacts, setOtherContacts] = useState({ data: [], limits: {}, loading: true });
   const [otherContactTypes, setOtherContactTypes] = useState({ data: [], loading: false });
   const [addressStatesProvinces, setAddressStatesProvinces] = useState({ data: [], loading: false });
   const [currentSubscriptionStatus, setCurrentSubscriptionStatus] = useState({ data: {}, loading: false });

   useEffect(() => {
      getTrusteeInvites();
      getTrustees();
      getOtherContacts();
      getOtherContactTypes();
      getAddressStatesProvinces();
      getCurrentSubscriptionStatus();
   }, []);

   // trustee invites
   const addTrusteeInvite = async (invite) => {
      setTrusteeInvites({ data: trusteeInvites.data, loading: true });
      await trusteeInviteService.addTrusteeInvite(invite);
      getTrusteeInvites();
      setTrusteeInviteDialogOpen(false);
   };

   const getTrusteeInvites = async () => {
      setTrusteeInvites({ data: [], limits: {}, loading: true });
      const trusteeInvites = await trusteeInviteService.getTrusteeInvites();
      setTrusteeInvites({ data: trusteeInvites.data, limits: trusteeInvites.limits, loading: false });
   };

   const deleteTrusteeInvite = async (invite) => {
      setTrusteeInvites({ ...trusteeInvites, loading: true });
      await trusteeInviteService.deleteTrusteeInvite(invite.id);
      getTrusteeInvites();
   };

   // trustees
   const getTrustees = async () => {
      setTrustees({ data: [], limits: {}, loading: true });
      const trustees = await trusteeService.getTrustees();
      setTrustees({ data: trustees.data, limits: trustees.limits, loading: false });
   };

   const deleteTrustee = async (trustee) => {
      setTrustees({ ...otherContacts, loading: true });
      await trusteeService.deleteTrustee(trustee.id);
      getTrustees();
   };

   // other contacts
   const addSaveOtherContact = async (contact) => {
      setOtherContacts({ data: otherContacts.data, loading: true });

      if(contact.id)
         await otherContactService.putOtherContact(contact);
      else
         await otherContactService.addOtherContact(contact);

      getOtherContacts();
      setOtherContactDialogOpen(false);
   };

   const getOtherContacts = async () => {
      setOtherContacts({ data: [], limits: {}, loading: true });
      const otherContacts = await otherContactService.getOtherContacts();
      setOtherContacts({ data: otherContacts.data, limits: otherContacts.limits, loading: false });
   };

   const getOtherContactTypes = async () => {
      setOtherContactTypes({ data: [], loading: true });
      const otherContactTypes = await systemLookupService.getOtherContactTypes();
      setOtherContactTypes({ data: otherContactTypes, loading: false });
   };

   const deleteOtherContact = async (contact) => {
      setOtherContacts({ ...otherContacts, loading: true });
      await otherContactService.deleteOtherContact(contact.id);
      getOtherContacts();
   };

   const getAddressStatesProvinces = async () => {
      setAddressStatesProvinces({ data: [], loading: true });
      const addressStatesProvinces = await systemLookupService.getAddressStatesProvinces();
      setAddressStatesProvinces({ data: addressStatesProvinces, loading: false });
   };

   const editOtherContact = async (contact) => {
      await setOtherContactDialogOpen(true);
      if (otherContactDialogRef.current)
         otherContactDialogRef.current.setState(contact);
   };

   // current subscription status
   const getCurrentSubscriptionStatus = async () => {
      setCurrentSubscriptionStatus({data: {}, loading: true});
      const subscriptionStatus = await subscriptionService.getCurrentStatus();
      setCurrentSubscriptionStatus({data: subscriptionStatus, loading: false});
   };

   // dialog states
   const [trusteeInviteDialogOpen, setTrusteeInviteDialogOpen] = useState(false);
   const [otherContactDialogOpen, setOtherContactDialogOpen] = useState(false);

   return (
      <Fragment>
         <Grid container spacing={3}>
            <Grid item xs={12}>
               <Button
                  variant="text"
                  component={Link}
                  to="/my-records"
                  startIcon={<ArrowBackIcon />}
               >
                  Back
               </Button>
            </Grid>
            <Grid item xs={12}>
               <WidgetContainer
                  title="Trustees"
                  icon={GavelIcon}
                  color="#00a5de"
               >
                  <Alert severity="info">
                     Your trustees accept the responsibility to handle your affairs and are verified users of the platform. Add trustees by sending them an invite.
                  </Alert>

                  <EnhancedTable
                     loading={trustees.loading}
                     emptyMessage={(trustees.data.length || trusteeInvites.data.length) ? null : "No trustees have been added yet. Send an invite to add one."}
                     data={trustees.data}
                     colConfig={TrusteeColConfig}
                     handleItemAdd={() => setTrusteeInviteDialogOpen(true)}
                     itemAddLabel="Invite"
                     itemAddDisabled={(trustees.data.length + trusteeInvites.data.length) >= trustees.limits.trustee}
                     rowActions={[{
                        toolTip: 'Delete',
                        icon: <DeleteIcon />,
                        confirmDialogConfig: {
                           title: "Delete Trustee?",
                           description: "Are you sure you want to delete this trustee?",
                           dismissButtonLabel: "No",
                           affirmButtonLabel: "Yes",
                        },
                        onClick: deleteTrustee,
                     }]}
                  />

                  {(trustees.data.length + trusteeInvites.data.length) >= trustees.limits.trustee ? <LimitReachedAlert
                     resourceName="Trustees/Invites"
                     currentSubscriptionTier={currentSubscriptionStatus.data}
                  /> : null}

                  {trusteeInvites.data.length ? <Fragment>
                     <Typography
                        variant="h6"
                        component="div"
                        sx={{ m: 2 }}
                     >
                        Outstanding Trustee Invites
                     </Typography>
                     <EnhancedTable
                        loading={trusteeInvites.loading}
                        emptyMessage="There are no outstanding trustees invited."
                        data={trusteeInvites.data}
                        colConfig={[
                           {
                              id: 'email',
                              rightAlign: false,
                              disablePadding: false,
                              label: 'Email',
                           },
                        ]}
                        rowActions={[{
                           toolTip: 'Cancel',
                           icon: <CancelIcon />,
                           confirmDialogConfig: {
                              title: "Cancel Invite?",
                              description: "Are you sure you want to cancel this trustee invite?",
                              dismissButtonLabel: "No",
                              affirmButtonLabel: "Yes",
                           },
                           onClick: deleteTrusteeInvite,
                        }]}
                     />
                  </Fragment> : null}
               </WidgetContainer>
            </Grid>
            <Grid item xs={12}>
               <WidgetContainer
                  title="Important Contacts"
                  icon={ContactsIcon}
                  color="#00a5de"
               >
                  <Alert severity="info">
                     Important contacts are any contacts that you want to list, other than trustees. Use the notes field to describe their relationship, importance, function, etc.
                  </Alert>

                  {otherContacts.limits && otherContacts.data.length >= otherContacts.limits.otherContact ? <LimitReachedAlert
                     resourceName="Important Contacts"
                     currentSubscriptionTier={currentSubscriptionStatus.data}
                  /> : null}

                  <EnhancedTable
                     loading={otherContacts.loading}
                     title="Important Contacts"
                     emptyMessage="There are no important contacts yet."
                     data={otherContacts.data}
                     colConfig={OtherContactColConfig}
                     handleItemAdd={() => setOtherContactDialogOpen(true)}
                     itemAddDisabled={!otherContacts.limits || otherContacts.data.length >= otherContacts.limits.otherContact}
                     rowActions={[{
                        toolTip: 'Edit',
                        icon: <EditIcon />,
                        onClick: editOtherContact,
                     },{
                        toolTip: 'Delete',
                        icon: <DeleteIcon />,
                        confirmDialogConfig: {
                           title: "Delete Important Contact?",
                           description: "Are you sure you want to delete this important contact?",
                           dismissButtonLabel: "No",
                           affirmButtonLabel: "Yes",
                        },
                        onClick: deleteOtherContact,
                     }]}
                  />
               </WidgetContainer>
            </Grid>
         </Grid>

         <InviteDialog
            dialogTitle="Invite Trustee"
            open={trusteeInviteDialogOpen}
            handleClose={() => setTrusteeInviteDialogOpen(false)}
            handleAddInvite={(invite) => addTrusteeInvite(invite)}
         />

         <OtherContactDialog
            ref={otherContactDialogRef}
            open={otherContactDialogOpen}
            handleClose={() => setOtherContactDialogOpen(false)}
            handleAddSaveOtherContact={(contact) => addSaveOtherContact(contact)}
            addressStatesProvinces={addressStatesProvinces.data}
            otherContactTypes={otherContactTypes.data}
         />
      </Fragment>
   );
};

export default ContactsPage;
