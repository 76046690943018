import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import LoaderIcon from './LoaderIcon';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import MUILink from '@mui/material/Link';
import CancelIcon from '@mui/icons-material/Cancel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Button } from '@mui/material';

const CreateFamilyProfileWidgetBody = (props) => {
   if (props.loading || !props.subscriptionStatus)
      return (<LoaderIcon />);

   return (
      <CardContent>
         <Grid container spacing={2}>
            <Grid item xs={12}>
               {props.subscriptionStatus.id !== 'family' ? <Alert severity="info">You currently have an individual profile. You can {props.subscriptionStatus.isLocked ? 'upgrade to a family profile' : <MUILink onClick={props.handleUpgradeToFamily} sx={{cursor: 'pointer'}}>upgrade to a family profile</MUILink> } and invite somebody to join your profile. This will allow you to share your records and also make you trustees to each other.</Alert> : null}

               {props.subscriptionStatus.id !== 'family' ? <Button
                  variant="contained"
                  onClick={props.handleUpgradeToFamily}
                  sx={{ mt: 1 }}
                  disabled={props.subscriptionStatus.isLocked}
               >Upgrade to Family Profile</Button> : null}

               {props.subscriptionStatus.id === 'family' && !props.familyProfileInvites.length ? <Button
                  variant="contained"
                  onClick={() => props.handleInviteUserToProfile()}
                  aria-label="invite user"
                  disabled={props.loading}
               >Invite Somebody</Button> : null}

               {props.subscriptionStatus.id === 'family' && props.familyProfileInvites.length ? <Fragment>
                  <b>Invited To Join Your Profile</b>
                  <nav aria-label="family profile invites">
                     <List>
                        {props.familyProfileInvites.map((invite => (<ListItem
                           disablePadding
                           key={invite.id}
                           secondaryAction={
                              <Tooltip title="Cancel Invite">
                                 <span>
                                 <IconButton edge="end" aria-label="cancel invite" onClick={() => props.deleteFamilyProfileInvite(invite.id)}>
                                    <CancelIcon />
                                 </IconButton>
                                 </span>
                              </Tooltip>
                           }>
                           <ListItemText primary={invite.email} />
                        </ListItem>)))}
                     </List>
                  </nav>
               </Fragment> : null}
            </Grid>
         </Grid>
      </CardContent>
   )
};

CreateFamilyProfileWidgetBody.propTypes = {
   loading: PropTypes.bool.isRequired,
   subscriptionStatus: PropTypes.object,
   familyProfileInvites: PropTypes.array.isRequired,
   handleInviteUserToProfile: PropTypes.func,
   handleUpgradeToFamily: PropTypes.func,
   deleteFamilyProfileInvite: PropTypes.func,
};

export default CreateFamilyProfileWidgetBody;
