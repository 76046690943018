import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import LoaderIcon from './LoaderIcon';
import PhotoBrowser from './PhotoBrowser';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';

const PhotoPickerDialog = (props) => {
   const [photoList, setPhotoList] = useState([]);

   useEffect(() => {
      setPhotoList(props.photos);
   }, [props.photos]);

   const handleSelectPhoto = (id) => {
      if(props.allowMultiSelect)
         setPhotoList(photoList.map((photo) => photo.id === id ? {...photo, selected: !photo.selected} : photo));
      else
         setPhotoList(photoList.map((photo) => photo.id === id ? {...photo, selected: true} : {...photo, selected: false}));
   };

   const handleSaveClick = () => {
      props.onConfirmSelection(photoList.filter((el) => el.selected === true));
   };

   return (
      <Dialog open={props.open} onClose={props.handleClose}>
         <DialogTitle>{props.title}</DialogTitle>
         <DialogContent>
            <Grid container spacing={1}>
               {props.alert ? <Grid item xs={12}>
                  {props.alert}
               </Grid> : null}
               {!props.loading ? <Grid item xs={12}>
                  <PhotoBrowser
                     photos={photoList}
                     emptyMessage="No photos to display."
                     selectPhoto={handleSelectPhoto}
                  />
               </Grid> : <LoaderIcon />}
            </Grid>
         </DialogContent>
         <DialogActions>
            <Button variant="outlined" startIcon={<CancelIcon />} onClick={props.handleClose}>Cancel</Button>
            <Button variant="contained" startIcon={<CheckIcon />} onClick={handleSaveClick}>Save</Button>
         </DialogActions>
      </Dialog>
   );
};

PhotoPickerDialog.propTypes = {
   loading: PropTypes.bool.isRequired,
   photos: PropTypes.array.isRequired,
   title: PropTypes.string.isRequired,
   alert: PropTypes.element,
   allowMultiSelect: PropTypes.bool,
   open: PropTypes.bool.isRequired,
   handleClose: PropTypes.func.isRequired,
   onConfirmSelection: PropTypes.func.isRequired,
};

export default PhotoPickerDialog;
