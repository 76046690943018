import React, { useState, useRef, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ProfileForm from '../components/ProfileForm';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';

const ProfileDialog = forwardRef((props, ref) => {
   const profileFormRef = useRef();
   const [open, setOpen] = useState(false);

   // control validation, state from parent component
   useImperativeHandle(ref, () => ({
      async setState(state) {
         await setOpen(true);

         if (profileFormRef.current)
            profileFormRef.current.setState(state);
      },
   }));

   const handleSaveProfileButtonClick = () => {
      // validate form
      let formValid = false;
      if (profileFormRef.current)
         formValid = profileFormRef.current.validate();

      if (formValid)
         props.handleSaveProfile(profileFormRef.current.getState());
   };

   return (
      <Dialog open={open} onClose={() => setOpen(false)}>
         <DialogTitle>My Profile</DialogTitle>
         <DialogContent>
            <ProfileForm
               ref={profileFormRef}
               genders={props.genders}
               maritalStatuses={props.maritalStatuses}
               addressStatesProvinces={props.addressStatesProvinces}
               includeGenderField={true}
            />
         </DialogContent>
         <DialogActions>
            <Button variant="outlined" startIcon={<CancelIcon />} onClick={() => setOpen(false)}>Cancel</Button>
            <Button variant="contained" startIcon={<CheckIcon />} onClick={handleSaveProfileButtonClick}>Save</Button>
         </DialogActions>
      </Dialog>
   );
});

ProfileDialog.propTypes = {
   handleSaveProfile: PropTypes.func.isRequired,
   genders: PropTypes.array.isRequired,
   maritalStatuses: PropTypes.array.isRequired,
   addressStatesProvinces: PropTypes.array.isRequired,
};

export default ProfileDialog;
