import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Alert from '@mui/material/Alert';
import LoaderIcon from './LoaderIcon';
import Grid from '@mui/material/Grid';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import WidgetContainer from './WidgetContainer';
import YearFilter from './YearFilter';
import LTIconButton from './LTIconButton';
import JourneyMonth from './JourneyMonth';
import { Button } from '@mui/material';
import Link from '@mui/material/Link';
import AddIcon from '@mui/icons-material/Add';
import LimitReachedAlert from '../components/LimitReachedAlert';

const JourneyFeed = (props) => {
   const renderFeed = (journeyMonths, loading) => {
      if (loading)
         return (<LoaderIcon />);

      if (!journeyMonths.length)
         return (<Alert severity="info">Your journey has no photos for {props.year}.</Alert>);

      return (
         <Fragment>
            <Grid container spacing={1}>
               {journeyMonths.map((journeyMonth, index) => (
                  <Grid item xs={12} key={journeyMonth.id}>
                     <JourneyMonth
                        key={journeyMonth.id}
                        journeyMonth={journeyMonth}
                        handleAddJourneyPhoto={props.handleAddJourneyPhoto}
                        handleEditMonthSummary={props.handleEditMonthSummary}
                        handleDeleteJourneyMonth={props.handleDeleteJourneyMonth}
                        handleEditJourneyPhoto={props.handleEditJourneyPhoto}
                        handleDeleteJourneyPhoto={props.handleDeleteJourneyPhoto}
                        onToggleShowInMausoleum={(id, j) => props.onToggleShowInMausoleum(id, index, j)}
                        resourceLimits={props.resourceLimits}
                     />
                  </Grid>
               ))}
            </Grid>
         </Fragment>
      );
   };

   return (
      <WidgetContainer
         title="Journey Feed"
         icon={DynamicFeedIcon}
         color="#00a5de"
      >
         <CardHeader
            action={
               <Fragment>
                  {props.loading !== true && props.records.length === 0 && props.year === new Date().getFullYear() ? <Link href='https://www.youtube.com/@LifeTomb/featured' target="_blank" rel="noreferrer">Not sure where to start?</Link> : null}
                  <YearFilter
                     onUpdateFilter={props.onUpdateFilter}
                     year={props.year}
                     label="Journey Year"
                  />
                  {props.loading !== true && props.records.length === 0 && props.year === new Date().getFullYear() ? <Button
                     disabled={props.loading}
                     onClick={() => props.handleAddJourneyMonth()}
                     variant='contained'
                     sx={{color: '#ffffff'}}
                  >Add your first month +</Button> :
                  <LTIconButton
                     disabled={props.loading}
                     tooltipText="Add Month"
                     ariaLabel="add-journey-month"
                     onClick={() => props.handleAddJourneyMonth()}
                     icon={<AddIcon />}
                  />}
               </Fragment>
            }
         />
         <CardContent sx={{ height: '60vh', display: 'block', overflowY: 'auto' }}>
            {props.resourceUsage.mausoleumPhotoResourceUsage === props.resourceLimits.mausoleumPhoto ? <LimitReachedAlert
               resourceName="Mausoleum Photos"
               currentSubscriptionTier={props.currentSubscriptionTier}
            /> : null}

            {renderFeed(props.records, props.loading)}
         </CardContent>
      </WidgetContainer>
   );
};

JourneyFeed.propTypes = {
   loading: PropTypes.bool.isRequired,
   records: PropTypes.array.isRequired,
   handleAddJourneyPhoto: PropTypes.func.isRequired,
   handleEditMonthSummary: PropTypes.func.isRequired,
   handleDeleteJourneyMonth: PropTypes.func.isRequired,
   handleAddJourneyMonth: PropTypes.func.isRequired,
   handleEditJourneyPhoto: PropTypes.func.isRequired,
   handleDeleteJourneyPhoto: PropTypes.func.isRequired,
   onToggleShowInMausoleum: PropTypes.func.isRequired,
   onUpdateFilter: PropTypes.func.isRequired,
   resourceLimits: PropTypes.object.isRequired,
   year: PropTypes.number.isRequired,
   resourceUsage: PropTypes.object.isRequired,
   currentSubscriptionTier: PropTypes.object,
};

export default JourneyFeed;
