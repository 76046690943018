import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

const LTIconButton = (props) => {
   const StyledIconButton = styled(IconButton)(({ theme }) => ({
      color: '#fff',
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
         backgroundColor: theme.palette.primary.dark,
      },
   }));

   return (
      <Tooltip title={props.tooltipText}>
         <span>
            <StyledIconButton
               disabled={props.disabled}
               aria-label={props.ariaLabel}
               onClick={props.onClick}
               sx={{ boxShadow: 3 }}
            >
               {props.icon}
            </StyledIconButton>
         </span>
      </Tooltip>
   );
};

LTIconButton.propTypes = {
   disabled: PropTypes.bool,
   tooltipText: PropTypes.string.isRequired,
   ariaLabel: PropTypes.string.isRequired,
   onClick: PropTypes.func.isRequired,
   icon: PropTypes.node.isRequired,
};

export default LTIconButton;
