import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import LoaderIcon from './LoaderIcon';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Chip from '@mui/material/Chip';
import LimitReachedAlert from './LimitReachedAlert';

const CategoryCountWidgetBody = (props) => {
   if (props.loading)
      return (<LoaderIcon />);

   return (
      <Fragment>
         <CardHeader
            action={props.actionButtons || null}
         />
         <CardContent>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
               {props.data.map(record => {
                  const labelId = `category-count-list-label-${record.id}`;
                  return (
                  <ListItem
                     key={record.id}
                     disablePadding
                  >
                     <ListItemButton
                        // role={undefined}
                        component={ props.disableLink ? null : Link }
                        to={`/${props.baseRoute}/${record.id}`}
                        dense
                     >
                        <ListItemText id={labelId} primary={record.name} />
                        <Chip label={record.count} color="primary" sx={{ color: '#fff', fontWeight: 'bold' }} />
                     </ListItemButton>
                  </ListItem>
                  );
               })}
            </List>
            {props.reachedLimit ? <LimitReachedAlert
               resourceName={props.limitAlertResourceName}
               currentSubscriptionTier={props.currentSubscriptionTier}
            /> : null}
         </CardContent>
      </Fragment>
   );
};

CategoryCountWidgetBody.propTypes = {
   actionButtons: PropTypes.element,
   baseRoute: PropTypes.string.isRequired,
   loading: PropTypes.bool.isRequired,
   data: PropTypes.array.isRequired,
   disableLink: PropTypes.bool,
   reachedLimit: PropTypes.bool,
   limitAlertResourceName: PropTypes.string,
   currentSubscriptionTier: PropTypes.object,
};

export default CategoryCountWidgetBody;
