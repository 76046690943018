import axiosService from './AxiosService';

export class InstitutionService {
   _resourceName = 'institution';

   async addInstitution(institution) {
      return await axiosService.create(`/v1.0/${this._resourceName}`, institution);
   }

   async getInstitutions(institutionTypeId) {
      return await axiosService.readList(`/v1.0/${this._resourceName}/search${(institutionTypeId ? `/${institutionTypeId}` : '')}`);
   }

   async deleteInstitution(id) {
      return await axiosService.delete(`/v1.0/${this._resourceName}`, id);
   }

   async getInstitutionCounts() {
      return await axiosService.readList(`/v1.0/${this._resourceName}/count`);
   }

   async putInstitution(institution) {
      return await axiosService.update(`/v1.0/${this._resourceName}`, institution);
   }

   static get instance() { return institutionService }
}

const institutionService = new InstitutionService();

export default institutionService;
