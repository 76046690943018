import axiosService from './AxiosService';

export class FirebaseMessageRegistrationService {
   _resourceName = 'firebase-message-registration';

   async registerToken(token) {
      return await axiosService.create(`/v1.0/${this._resourceName}`, { token });
   }

   static get instance() { return firebaseMessageRegistrationService }
}

const firebaseMessageRegistrationService = new FirebaseMessageRegistrationService();

export default firebaseMessageRegistrationService;
