import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CheckIcon from '@mui/icons-material/Check';

const AlertDialog = (props) => {
   return (
      <Dialog
         open={props.open}
         onClose={props.handleDismiss}
         aria-labelledby="alert-dialog-title"
         aria-describedby="alert-dialog-description"
      >
         <DialogTitle id="alert-dialog-title">
            {props.title}
         </DialogTitle>
         <DialogContent>
            <DialogContentText id="alert-dialog-description">
               {props.description}
            </DialogContentText>
         </DialogContent>
         <DialogActions>
            <Button variant="text" startIcon={<CheckIcon />} onClick={props.handleAffirm} autoFocus>{props.affirmButtonLabel}</Button>
         </DialogActions>
      </Dialog>
   );
};

AlertDialog.propTypes = {
   open: PropTypes.bool.isRequired,
   title: PropTypes.string.isRequired,
   description: PropTypes.string.isRequired,
   affirmButtonLabel: PropTypes.string.isRequired,
   handleAffirm: PropTypes.func.isRequired,
};

export default AlertDialog;
