import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import profileService from '../data/ProfileService';
import addressService from '../data/AddressService';
import LoaderIcon from '../components/LoaderIcon';
import systemLookupService from '../data/SystemLookupService';
import ProfileWidgetBody from '../components/ProfileWidgetBody';
import AccountDetailWidgetBody from '../components/AccountDetailWidgetBody';
import ProfileDialog from '../components/ProfileDialog';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import PubSub from 'pubsub-js';
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import familyProfileInviteService from '../data/FamilyProfileInviteService';
import InviteDialog from '../components/InviteDialog';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import WidgetContainer from '../components/WidgetContainer';
import CreateFamilyProfileWidgetBody from '../components/CreateFamilyProfileWidgetBody';
import parseISO from 'date-fns/parseISO';
import subscriptionService from '../data/SubscriptionService';
import { useQuery } from '../utils/useQuery';
import AlertSubscriptionResult from '../components/AlertSubscriptionResult';
import DialogComponent from '../components/DialogComponent';
import TierComparisonTable from '../components/TierComparisonTable';
import generatePDFService from '../data/GeneratePDFService';
import AlertDialog from '../components/AlertDialog';
import { ApplicationPaths } from '../components/api-authorization/ApiAuthorizationConstants';

const MyProfilePage = () => {
   const profileDialogRef = useRef();
   const query = useQuery();
   const history = useHistory();

   const [profile, setProfile] = useState({ data: {}, loading: false });
   const [familyUser, setFamilyUser] = useState({ data: null, loading: false });
   const [genders, setGenders] = useState({ data: [], loading: false });
   const [maritalStatuses, setMaritalStatuses] = useState({ data: [], loading: false });
   const [addressStatesProvinces, setAddressStatesProvinces] = useState({ data: [], loading: false });
   const [subscriptionTiers, setSubscriptionTiers] = useState({ data: [], loading: false });
   const [profileDialogOpen, setProfileDialogOpen] = useState(false);
   const [savedAddresses, setSavedAddresses] = useState({ data: {}, loading: false });
   const [familyProfileInvites, setFamilyProfileInvites] = useState({ data: [], loading: true });
   const [tierComparisonDialogOpen, setTierComparisonDialogOpen] = useState(false);
   const [subscriptionSessionLoading, setSubscriptionSessionLoading] = useState(false);
   const [featureFlags, setFeatureFlags] = useState([]);

   useEffect(() => {
      checkProfileExists();
   }, []);

   // todo: temporarily disbabled since this is causing more harm than good
   // useEffect(() => {
   //    const reloadStripeSubscriptionStatusSub = PubSub.subscribe('profile.reload-stripe-subscription-status', getSubscriptionStatus);
   //    return () => PubSub.unsubscribe(reloadStripeSubscriptionStatusSub);
   // }, [profile, subscriptionTiers, subscriptionSessionLoading, profileDialogOpen]);

   const checkProfileExists = async () => {
      setProfile({ data: {}, loading: true });

      const profileExists = await profileService.getProfileExists();
      if (profileExists) {
         getProfile();
         getFamilyProfileInvites();
         getGenders();
         getMaritalStatuses();
         getAddressStatesProvinces();
         getSubscriptionTiers();
         getSavedAddresses();
         getFeatureFlags();
      } else {
         // todo: either move this somewhere generic and/or add to other pages
         //go to "get started wizard"
         history.push('/get-started');
      }
   };

   // const getSubscriptionStatus = (profileDialogOpen) => {
   //    // pause polling while loading, or while editing profile (todo: this can be removed once we split out to just reload the subscription status) - this also doesn't work

   //    if(profile.loading || subscriptionTiers.loading || subscriptionSessionLoading || profileDialogOpen)
   //       return;

   //    history.push('/my-profile');
      
   //    // todo: this shouldn't be needed, but it is - there is something else messed up here - change it to just reload the subscription status and not the profile (then can also remove the || profileDialogOpen condition above)
   //    getProfile();
   //    getCurrentSubscriptionStatus();
   // };

   // family profile invites
   const addFamilyProfileInvite = async (invite) => {
      setFamilyProfileInvites({ data: familyProfileInvites.data, loading: true });
      await familyProfileInviteService.addFamilyProfileInvite(invite);
      getFamilyProfileInvites();
      setFamilyProfileInviteDialogOpen(false);
   };

   const getFamilyProfileInvites = async () => {
      setFamilyProfileInvites({ data: [], loading: true });
      const familyProfileInvites = await familyProfileInviteService.getFamilyProfileInvites();
      setFamilyProfileInvites({ data: familyProfileInvites, loading: false });
   };

   const deleteFamilyProfileInvite = async (id) => {
      setFamilyProfileInvites({ ...familyProfileInvites, loading: true });
      await familyProfileInviteService.deleteFamilyProfileInvite(id);
      getFamilyProfileInvites();
   };

   const [familyProfileInviteDialogOpen, setFamilyProfileInviteDialogOpen] = useState(false);

   // profile
   const getProfile = async () => {
      const profile = await profileService.getProfile();
      const subscriptionStatus = await subscriptionService.getCurrentStatus();

      profile.dateOfBirth = parseISO(profile.dateOfBirth);
      setProfile({
         data: {
            ...profile,
            subscriptionTier: subscriptionStatus
         },
         loading: false
      });
      if(subscriptionStatus.id === 'family')
         getFamilyUser();
   };

   const updateProfile = async (updatedProfile) => {
      setProfile({ ...profile, loading: true });
      await profileService.putProfile(updatedProfile);
      getProfile();
      PubSub.publish('profile.updated', null);
      setProfileDialogOpen(false);
   };

   const handleEditProfile = () => {
      if (profileDialogRef.current)
         profileDialogRef.current.setState(profile.data);
   };

   // const getCurrentSubscriptionStatus = async () => {
   //    setProfile({data: profile.data, loading: true});
   //    const subscriptionStatus = await subscriptionService.getCurrentStatus();
   //    setProfile({data: {...profile, subscriptionTier: subscriptionStatus}, loading: false});
   // };

   const getFamilyUser = async () => {
      setFamilyUser({ data: null, loading: true });
      const familyUser = await profileService.getFamilyUser();

      if(!familyUser)
         return;

      familyUser.dateOfBirth = parseISO(familyUser.dateOfBirth);
      setFamilyUser({ data: familyUser, loading: false });
   };

   const getGenders = async () => {
      setGenders({ data: [], loading: true });
      const genders = await systemLookupService.getGenders();
      setGenders({ data: genders, loading: false });
   };

   const getMaritalStatuses = async () => {
      setMaritalStatuses({ data: [], loading: true });
      const maritalStatuses = await systemLookupService.getMaritalStatuses();
      setMaritalStatuses({ data: maritalStatuses, loading: false });
   };

   const getAddressStatesProvinces = async () => {
      setAddressStatesProvinces({ data: [], loading: true });
      const addressStatesProvinces = await systemLookupService.getAddressStatesProvinces();
      setAddressStatesProvinces({ data: addressStatesProvinces, loading: false });
   };

   const getSubscriptionTiers = async () => {
      setSubscriptionTiers({ data: [], loading: true });
      const subscriptionTiers = await systemLookupService.getSubscriptionTiers();
      setSubscriptionTiers({ data: subscriptionTiers, loading: false });
   };

   // saved addresses
   const getSavedAddresses = async () => {
      setSavedAddresses({ data: {}, loading: true });
      const savedAddresses = await addressService.getSavedAddresses();
      setSavedAddresses({ data: savedAddresses, loading: false });
   };

   const handleNewSubscriptionClick = async (subscriptionTierId) => {
      setSubscriptionSessionLoading(true);
      const subscriptionSession = await subscriptionService.postCheckoutSession(subscriptionTierId, 'my-profile');
      window.location.href = subscriptionSession.checkoutURL;
   };

   const handleBillingPortalClick = async () => {
      setSubscriptionSessionLoading(true);
      const subscriptionSession = await subscriptionService.postPortalSession();
      window.location.href = subscriptionSession.portalURL;
   };

   const handleChangeSubscriptionClick = async (subscriptionTierId) => {
      setSubscriptionSessionLoading(true);
      // todo: could return profile instead of reloading - but that's not really useful either
      await subscriptionService.putChangeSubscription(subscriptionTierId);
      setSubscriptionSessionLoading(false);
   };

   const handleCancelSubscriptionClick = async () => {
      setSubscriptionSessionLoading(true);
      await subscriptionService.deleteCancelSubscription();
      setSubscriptionSessionLoading(false);
   };

   const handleUpgradeToFamilyClick = () => {
      if(profile.data.isStripeCustomer)
         handleChangeSubscriptionClick('family');
      else
         handleNewSubscriptionClick('family');
   };

   const getFeatureFlags = async () => {
      setFeatureFlags(await systemLookupService.getMyFeatureFlags());
   };

   const handleGeneratePDFclick = async () => {
      setProfile({ ...profile, loading: true });
      await generatePDFService.getMyCompleteTomb();
      setProfile({ ...profile, loading: false });
      setPdfGenerationDialogOpen(true);
   };

   // PDF generation complete dialog
   const [pdfGenerationDialogOpen, setPdfGenerationDialogOpen] = useState(false);

   const handleDeleteProfile = async () => {
      setProfile({ ...profile, loading: true });
      await profileService.deleteProfile();

      // log user out
      history.push({ pathname: ApplicationPaths.LogOut, state: { local: true } });
   }

   if(profile.loading)
      return (<LoaderIcon />)

   return (
      <div>
         <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={1}>
               <Grid item xs={12} md={6}>
                  <WidgetContainer
                     title="My Personal Details"
                     icon={PersonIcon}
                     color="#00a5de"
                  >
                     <ProfileWidgetBody
                        loading={profile.loading}
                        profile={profile.data}
                        openProfileDialog={handleEditProfile}
                     />
                  </WidgetContainer>
               </Grid>
               <Grid item xs={12} md={6}>
                  <WidgetContainer
                     title={familyUser.data ? 'Family Member Details' : 'Create a Family Profile'}
                     icon={GroupIcon}
                     color="#00a5de"
                  >
                     {familyUser.data ? <ProfileWidgetBody
                        loading={familyUser.loading}
                        profile={familyUser.data}
                     /> : <CreateFamilyProfileWidgetBody
                        loading={profile.loading}
                        subscriptionStatus={profile.data.subscriptionTier}
                        familyProfileInvites={familyProfileInvites.data}
                        handleInviteUserToProfile={() => setFamilyProfileInviteDialogOpen(true)}
                        handleUpgradeToFamily={handleUpgradeToFamilyClick}
                        deleteFamilyProfileInvite={deleteFamilyProfileInvite}
                     />}
                  </WidgetContainer>
               </Grid>
               <Grid item xs={12}>
                  {/* todo: may want to combine call to get my person details, family person details, account, etc.  */}
                  <WidgetContainer
                     title="Account Settings"
                     icon={ManageAccountsIcon}
                     color="#00a5de"
                  >
                     <AccountDetailWidgetBody
                        loading={profile.loading || subscriptionTiers.loading || subscriptionSessionLoading}
                        profile={profile.data}
                        savedAddresses={savedAddresses}
                        subscriptionTiers={subscriptionTiers.data}
                        onNewSubscription={handleNewSubscriptionClick}
                        onChangeSubscription={handleChangeSubscriptionClick}
                        onCancelSubscription={handleCancelSubscriptionClick}
                        onBillingPortalClick={handleBillingPortalClick}
                        onSubscriptionInfoClick={() => setTierComparisonDialogOpen(true)}
                        onGeneratePDFclick={handleGeneratePDFclick}
                        onDeleteProfile={handleDeleteProfile}
                        featureFlags={featureFlags}
                     />

                     {query.get('subscription-purchase-result') === "success" ? <AlertSubscriptionResult success={true} /> : null}

                     {query.get('subscription-purchase-result') === "cancelled" ? <AlertSubscriptionResult success={false} />: null}
                  </WidgetContainer>
               </Grid>
            </Grid>
         </Box>

         <ProfileDialog
            ref={profileDialogRef}
            open={profileDialogOpen}
            handleClose={() => setProfileDialogOpen(false)}
            handleSaveProfile={(profile) => updateProfile(profile)}
            genders={genders.data}
            maritalStatuses={maritalStatuses.data}
            addressStatesProvinces={addressStatesProvinces.data}
         />

         {/* subscription tier explanations */}
         <DialogComponent
            open={tierComparisonDialogOpen}
            title="LifeTomb Subscription Tiers"
            dismissButtonLabel="Close"
            handleDismiss={() => setTierComparisonDialogOpen(false)}
         >
            <TierComparisonTable />
         </DialogComponent>

         <InviteDialog
            dialogTitle="Invite User to Family Profile"
            open={familyProfileInviteDialogOpen}
            handleClose={() => setFamilyProfileInviteDialogOpen(false)}
            handleAddInvite={addFamilyProfileInvite}
         />

         <AlertDialog
            open={pdfGenerationDialogOpen}
            title="Generation Requested"
            description="Your request to generate a PDF has been submitted. Once the PDF is ready, it will be emailed to you."
            affirmButtonLabel="Ok"
            handleAffirm={() => setPdfGenerationDialogOpen(false)}
         />
      </div>
   );
};

export default MyProfilePage;
