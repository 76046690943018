import React, { useState, useEffect, useRef } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import systemLookupService from '../data/SystemLookupService';
import documentService from '../data/DocumentService';
import DocumentDialog from '../components/DocumentDialog';
import EnhancedTable from '../components/EnhancedTable';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { DocumentColConfig } from '../components/TableColumnConfigs';
import FolderIcon from '@mui/icons-material/Folder';
import WidgetContainer from '../components/WidgetContainer';
import PubSub from 'pubsub-js';
import parseISO from 'date-fns/parseISO';
import LimitReachedAlert from '../components/LimitReachedAlert';
import subscriptionService from '../data/SubscriptionService';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const DocumentsPage = () => {
   const documentDialogRef = useRef();

   let { documentTypeId } = useParams();

   const [documents, setDocuments] = useState({ data: [], limits: {}, loading: false });
   const [documentTypes, setDocumentTypes] = useState({ data: [], loading: false });
   const [currentSubscriptionStatus, setCurrentSubscriptionStatus] = useState({ data: {}, loading: false });

   useEffect(() => {
      getDocuments(documentTypeId);
      getDocumentTypes();
      getCurrentSubscriptionStatus();
   }, []);

   const history = useHistory();

   const getDocuments = async (documentTypeId) => {
      setDocuments({ data: [], limits: {}, loading: true });
      const documents = await documentService.getDocuments(documentTypeId);
      setDocuments({ data: documents.data.map(document => ({...document, uploadedAt: parseISO(document.uploadedAt)})), limits: documents.limits, loading: false });
   };

   const getDocumentTypes = async () => {
      setDocumentTypes({ data: [], loading: true });
      const documentTypes = await systemLookupService.getDocumentTypes();
      setDocumentTypes({ data: documentTypes, loading: false });
   };

   const addSaveDocument = async (doc) => {
      if(doc.id) {
         setDocuments({ ...documents, loading: true });
         await documentService.putDocument(doc);
         setDocumentDialogOpen(false);
      } else {
         PubSub.publish('nonBlockingLoader.addMessage', { id: doc.fileName, text: `Uploading ${doc.fileName}...` });
         setDocumentDialogOpen(false);
         await documentService.addDocument(doc);
         PubSub.publish('nonBlockingLoader.removeMessage', doc.fileName);
      }

      getDocuments(documentTypeId);
   };

   const deleteDocument = async (doc) => {
      setDocuments({ ...documents, loading: true });
      await documentService.deleteDocument(doc.id);
      getDocuments(documentTypeId);
   };

   const handleAddClick = () => {
      setDocumentDialogOpen(true);
      if (documentDialogRef.current)
         documentDialogRef.current.setState({});
   };

   const editDocument = async (document) => {
      await setDocumentDialogOpen(true);
      if (documentDialogRef.current)
         documentDialogRef.current.setState(document);
   };

   const [documentDialogOpen, setDocumentDialogOpen] = useState(false);

   const handleSeeAllClick = async () => {
      await getDocuments();
      history.push('/documents');
   };

   const handleDownloadFile = async (doc) => {
      const response = await documentService.downloadDocument(doc.id);
      const fileURL = URL.createObjectURL(new Blob([response]));

      const anchor = document.createElement('a');
      anchor.href = fileURL;
      anchor.download = doc.fileName;

      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);

      URL.revokeObjectURL(fileURL);
   };

   // current subscription status
   const getCurrentSubscriptionStatus = async () => {
      setCurrentSubscriptionStatus({data: {}, loading: true});
      const subscriptionStatus = await subscriptionService.getCurrentStatus();
      setCurrentSubscriptionStatus({data: subscriptionStatus, loading: false});
   };

   return (
      <div>
         <Button
            variant="text"
            component={Link}
            to="/my-records"
            startIcon={<ArrowBackIcon />}
         >
            Back
         </Button>

         { documentTypeId ? <Button
            variant="text"
            onClick={handleSeeAllClick}
         >See All</Button> : null }

         <WidgetContainer
            title={`Documents`}
            icon={FolderIcon}
            color="#00a5de"
         >
            <EnhancedTable
               loading={documents.loading}
               emptyMessage="No documents have been uploaded yet."
               data={documents.data}
               colConfig={DocumentColConfig}
               handleItemAdd={handleAddClick}
               itemAddDisabled={documents.data.length >= documents.limits.document}
               rowActions={[{
                  toolTip: 'Download',
                  icon: <CloudDownloadIcon />,
                  onClick: handleDownloadFile,
               },{
                  toolTip: 'Edit',
                  icon: <EditIcon />,
                  onClick: editDocument,
               },{
                  toolTip: 'Delete',
                  icon: <DeleteIcon />,
                  confirmDialogConfig: {
                     title: "Delete Document?",
                     description: "Are you sure you want to delete this document?",
                     dismissButtonLabel: "No",
                     affirmButtonLabel: "Yes",
                  },
                  onClick: deleteDocument,
               }]}
            />

            {documents.data.length >= documents.limits.document ? <LimitReachedAlert
               resourceName="Documents"
               currentSubscriptionTier={currentSubscriptionStatus.data}
            /> : null}
         </WidgetContainer>

         <DocumentDialog
            ref={documentDialogRef}
            loading={documents.loading}
            open={documentDialogOpen}
            handleClose={() => setDocumentDialogOpen(false)}
            handleAddSaveDocument={addSaveDocument}
            documentTypes={documentTypes.data}
         />
      </div>
   );
};

export default DocumentsPage;
