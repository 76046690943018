import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Box from '@mui/material/Box';
import TextDisplay from './TextDisplay';
// import MausoleumIconWhite from './MausoleumIconWhite';
import Tooltip from '@mui/material/Tooltip';
import MuseumIcon from '@mui/icons-material/Museum';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';

const JourneyPhoto = (props) => {
   const handleEditJourneyPhotoClick = (journeyPhoto) => {
      props.handleEditJourneyPhoto(journeyPhoto);
   };

   const styles = {
      overlay: {
         position: 'absolute',
         top: '10px',
         left: '10px',
         color: '#fff',
      }
   };

   return (
      <Fragment>
         <Card variant="outlined" sx={{ height: '100%', position: 'relative' }}>
            <CardMedia
               component="img"
               height="180"
               image={props.journeyPhoto.base64PhotoScaled}
               onClick={() => handleEditJourneyPhotoClick(props.journeyPhoto)}
               sx={{ cursor: 'pointer' }}
            />

            <Tooltip title={props.journeyPhoto.showInMausoleum ? 'Remove from Mausoleum' : 'Add to Mausoleum'}>
               <Box sx={styles.overlay} onClick={() => props.onToggleShowInMausoleum(props.journeyPhoto.id)}>
                  <Box sx={{ backgroundColor: '#000000aa', pl: '7px', pr: '7px', pt: '5px', pb: '2px', borderRadius: '100%', border: props.journeyPhoto.showInMausoleum ? '2px solid #00a5de' : '2px solid #00000000', cursor: 'pointer' }}>
                     {/* <MausoleumIconWhite sx={{ height: '20px' }} /> */}
                     {props.journeyPhoto.loading ? <HourglassEmptyIcon /> : <MuseumIcon sx={{color: props.journeyPhoto.showInMausoleum ? '#ffffffff' : '#ffffffaa'}} />}
                  </Box>
               </Box>
            </Tooltip>
            <CardActions disableSpacing>
               <Box>
                  <Typography sx={{whiteSpace: 'pre-wrap'}} variant="body2" color="text.secondary" gutterBottom>
                     <TextDisplay
                        text={props.journeyPhoto.caption}
                        maxChars={80}
                     />
                  </Typography>
               </Box>
            </CardActions>
         </Card>
      </Fragment>
   );
};

JourneyPhoto.propTypes = {
   journeyPhoto: PropTypes.object.isRequired,
   handleEditJourneyPhoto: PropTypes.func.isRequired,
   handleDeleteJourneyPhoto: PropTypes.func.isRequired,
   onToggleShowInMausoleum: PropTypes.func.isRequired,
};

export default JourneyPhoto;
