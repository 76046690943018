import axiosService from './AxiosService';

export class OtherContactService {
   _resourceName = 'other-contact';

   async addOtherContact(contact) {
      return await axiosService.create(`/v1.0/${this._resourceName}`, contact);
   }

   async getOtherContacts() {
      return await axiosService.readList(`/v1.0/${this._resourceName}`);
   }

   async deleteOtherContact(id) {
      return await axiosService.delete(`/v1.0/${this._resourceName}`, id);
   }

   async putOtherContact(contact) {
      return await axiosService.update(`/v1.0/${this._resourceName}`, contact);
   }

   static get instance() { return otherContactService }
}

const otherContactService = new OtherContactService();

export default otherContactService;
