import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import format from 'date-fns/format';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import Card from '@mui/material/Card';
import TextDisplay from './TextDisplay';
import YesNoDialog from './YesNoDialog';

const DiaryEntry = (props) => {
   const [diaryEntryActionsMenuAnchorEl, setDiaryEntryActionsMenuAnchorEl] = useState(null);
   const handleDiaryEntryActionsMenuOpen = (event) => {
      setDiaryEntryActionsMenuAnchorEl(event.currentTarget);
   };

   const handleDiaryEntryActionsMenuClose = () => {
      setDiaryEntryActionsMenuAnchorEl(null);
   };

   const handleEditDiaryEntryClick = (id) => {
      props.handleEditDiaryEntry(id);
      handleDiaryEntryActionsMenuClose();
   };

   const handleDeleteDiaryEntryClick = () => {
      handleDiaryEntryActionsMenuClose();
      setDeleteDiaryEntryDialogOpen(true);
   };

   const [deleteDiaryEntryDialogOpen, setDeleteDiaryEntryDialogOpen] = useState(false);

   return (
      <Fragment>
         <Card variant="outlined">
            <CardHeader
               action={
                  <Fragment>
                     <Tooltip title="Diary Entry Actions">
                        <span>
                           <IconButton
                              aria-label="diary-entry-actions-menu"
                              onClick={handleDiaryEntryActionsMenuOpen}
                           >
                              <MoreVertIcon />
                           </IconButton>
                        </span>
                     </Tooltip>
                     <Menu
                        id="menu-diary-entry-actions"
                        anchorEl={diaryEntryActionsMenuAnchorEl}
                        anchorOrigin={{
                           vertical: 'bottom',
                           horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                           vertical: 'top',
                           horizontal: 'right',
                        }}
                        open={Boolean(diaryEntryActionsMenuAnchorEl)}
                        onClose={handleDiaryEntryActionsMenuClose}
                     >
                        <MenuItem aria-label="edit-diary-entry" onClick={() => handleEditDiaryEntryClick(props.diaryEntry.id)}>
                           <ListItemIcon>
                              <EditIcon fontSize="small" />
                           </ListItemIcon>
                           Edit Diary Entry
                        </MenuItem>
                        <MenuItem aria-label="delete-diary-entry" onClick={handleDeleteDiaryEntryClick}>
                           <ListItemIcon>
                              <DeleteIcon fontSize="small" />
                           </ListItemIcon>
                           Delete Diary Entry
                        </MenuItem>
                     </Menu>
                  </Fragment>
               }
               title={<TextDisplay
                  text={props.diaryEntry.title}
                  maxChars={36}
               />}
               subheader={format(props.diaryEntry.forDate, 'MMMM d, yyyy')}
            />
            <CardContent sx={{ maxHeight: '300px', overflowY: 'auto' }}>
               <Typography sx={{whiteSpace: 'pre-wrap'}} variant="body2" color="text.secondary">
                  <TextDisplay
                     text={props.diaryEntry.text}
                     maxChars={120}
                  />
               </Typography>
            </CardContent>
         </Card>
         <YesNoDialog
            open={deleteDiaryEntryDialogOpen}
            title="Delete Diary Entry?"
            description="Are you sure you want to delete this entry from your diary?"
            dismissButtonLabel="No"
            affirmButtonLabel="Yes"
            handleDismiss={() => setDeleteDiaryEntryDialogOpen(false)}
            handleAffirm={() => props.handleDeleteDiaryEntry(props.diaryEntry.id)}
         />
      </Fragment>
   );
};

DiaryEntry.propTypes = {
   diaryEntry: PropTypes.object.isRequired,
   handleEditDiaryEntry: PropTypes.func.isRequired,
   handleDeleteDiaryEntry: PropTypes.func.isRequired,
};

export default DiaryEntry;
