import React, { useState, forwardRef, useImperativeHandle, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import LoaderIcon from './LoaderIcon';
import PhotoUploader from './PhotoUploader';
import format from 'date-fns/format';
import CachedIcon from '@mui/icons-material/Cached';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FormControl from '@mui/material/FormControl';
import YesNoDialog from './YesNoDialog';
import TextAreaWithLimit from './TextAreaWithLimit';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';

const JourneyPhotoDialog = forwardRef((props, ref) => {
   const photoUploaderRef = useRef();
   const initialState = {
      id: null,
      base64PhotoScaled: null,
      forMonth: null,
      journeyMonthId: null,
      photosInMonth: 0,
      caption: null,
      showInMausoleum: false,
   };
   const [formValues, setFormValues] = useState(initialState);

   // control validation, state from parent component
   useImperativeHandle(ref, () => ({
      setState(formValues) {
         return setFormValues(formValues);
      }
   }));

   // validation
   const [formValidation, setFormValidation] = useState({});
   const formIsValid = () => {
      let errors = {};

      if (!formValues.id && !formValues.journeyMonthId)
         errors.forMonth = 'For Month ID must be provided.';

      setFormValidation(errors);

      return Object.keys(errors).length === 0;
   };

   const handleAddSaveJourneyPhotoButtonClick = () => {
      if (formIsValid()) {
         let selectedPhoto;
         if(replacePhotoMode)
            selectedPhoto = photoUploaderRef.current.getPhoto();
         else
            selectedPhoto = formValues;

         if(formValues.id) {
            props.handleSaveJourneyPhoto({
               id: formValues.id,
               caption: selectedPhoto ? selectedPhoto.caption : null,
               showInMausoleum: selectedPhoto ? selectedPhoto.showInMausoleum : null,
               fileName: selectedPhoto ? selectedPhoto.fileName : null,
               base64PhotoFullSize: selectedPhoto ? selectedPhoto.base64PhotoFullSize : null,
               base64PhotoScaled: selectedPhoto ? selectedPhoto.base64PhotoScaled : null,
            });
            props.handleClose();
         } else if(formValues.journeyMonthId) {
            let photosToAdd = photoUploaderRef.current.getPhotos();
            
            // todo: move this validation logic into the PhotoUploader maybe with a minimum requirement number of photos (defaults to 0 if not provided)
            if(photosToAdd.length < 1) {
               setFormValidation({
                  photos: 'At least one photo must be selected.'
               });
               
               return;
            }

            props.handleAddJourneyPhotos(formValues.journeyMonthId, photosToAdd);
         }
         
         if(replacePhotoMode)
            photoUploaderRef.current.reset();
         setReplacePhotoMode(false);
         setFormValues({ ...initialState });
      }
   };

   const handleDeleteJourneyPhotoClick = () => {
      setDeletePhotoDialogOpen(true);
   };

   const handleDeleteJourneyPhoto = () => {
      setDeletePhotoDialogOpen(false);
      props.handleDeleteJourneyPhoto(formValues.id);
   };

   const [deletePhotoDialogOpen, setDeletePhotoDialogOpen] = useState(false);

   const [replacePhotoMode, setReplacePhotoMode] = useState(false);
   const maxJourneyPhotosToUpload = formValues.id ? 1 : props.resourceLimits.journeyPhoto - formValues.photosInMonth;

   return (
      <Dialog open={props.open} onClose={props.handleClose}>
         <DialogTitle>{!formValues.id ? `Add Photo${maxJourneyPhotosToUpload > 1 ? 's' : ''} for ${formValues.forMonth ? format(formValues.forMonth, 'MMMM yyyy') : ''}` : 'Edit Photo'}</DialogTitle>
         <DialogContent>
            {!props.loading ? <Box component="form">
               <Grid container spacing={1}>
                  {formValues.id ? <Grid item xs={12}>
                     {formValues.base64PhotoScaled && !replacePhotoMode ? <Fragment>
                        <img style={{ width: 420 }} src={formValues.base64PhotoScaled} alt="Current Journey" />
                        <Button onClick={() => setReplacePhotoMode(true)} variant="outlined" startIcon={<CachedIcon />}>Replace Photo</Button><br></br>
                        <FormControlLabel control={<Switch
                           id={`show-on-mausoleum-switch`}
                           checked={formValues.showInMausoleum}
                           onChange={event => setFormValues({ ...formValues, showInMausoleum: event.target.checked })}
                           inputProps={{ 'aria-label': 'show-on-mausoleum-switch' }}
                        />} label="Show on Mausoleum" />
                        <FormControl fullWidth>
                           <TextAreaWithLimit
                              id={"caption-input"}
                              label="Caption"
                              maxLength={255}
                              value={formValues.caption}
                              onChange={event => setFormValues({ ...formValues, caption: event.target.value })}
                              placeholder="Add a caption..."
                              rows={3}
                           />
                        </FormControl>
                     </Fragment> : <Button onClick={() => setReplacePhotoMode(false)} variant="outlined">Cancel Photo Replacement</Button>}
                  </Grid> : null}

                  {(!formValues.id || replacePhotoMode) ? <Fragment><Grid item xs={12}>
                     <DialogContentText>
                        <b>{formValues.id ? 'Replace this photo in your journey.' : `Add up to ${maxJourneyPhotosToUpload} photo memories to this month.`}</b>
                     </DialogContentText>
                  </Grid>

                  <Grid item xs={12}>
                     <PhotoUploader
                        ref={photoUploaderRef}
                        width={420}
                        height={420}
                        buttonText={`Select Photo${(maxJourneyPhotosToUpload > 1 ? 's' : '')}`}
                        multiple={maxJourneyPhotosToUpload}
                        enableCaptions={true}
                        enableMausoleumSwitch={true}
                     />
                  </Grid>
                  </Fragment> : null}

                  {formValidation.photos ? <Grid item xs={12}><Alert severity="error">{formValidation.photos}</Alert></Grid> : null }
               </Grid>
            </Box> : <LoaderIcon />}
         </DialogContent>
         <DialogActions>
            <Button variant="outlined" startIcon={<CancelIcon />} onClick={props.handleClose}>Cancel</Button>
            <Button variant="contained" startIcon={<CheckIcon />} onClick={handleAddSaveJourneyPhotoButtonClick}>{formValues.id ? 'Save' : 'Add'} Photo{maxJourneyPhotosToUpload > 1 ? 's' : ''}</Button>
            {formValues.id && !replacePhotoMode ? <Button variant="outlined" startIcon={<DeleteIcon />} color="error" onClick={handleDeleteJourneyPhotoClick}>Delete Photo</Button> : null}
         </DialogActions>
         <YesNoDialog
            open={deletePhotoDialogOpen}
            title="Delete Photo?"
            description="Are you sure you want to delete this photo from your journey?"
            dismissButtonLabel="No"
            affirmButtonLabel="Yes"
            handleDismiss={() => setDeletePhotoDialogOpen(false)}
            handleAffirm={handleDeleteJourneyPhoto}
         />
      </Dialog>
   );
});

JourneyPhotoDialog.propTypes = {
   loading: PropTypes.bool.isRequired,
   open: PropTypes.bool.isRequired,
   handleClose: PropTypes.func.isRequired,
   handleAddJourneyPhotos: PropTypes.func.isRequired,
   handleSaveJourneyPhoto: PropTypes.func.isRequired,
   handleDeleteJourneyPhoto: PropTypes.func.isRequired,
   resourceLimits: PropTypes.object.isRequired,
};

export default JourneyPhotoDialog;
