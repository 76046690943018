import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import AddressDisplay from './AddressDisplay';

const FuneralArrangementsDisplay = (props) => {
   return (<Grid container spacing={1}>
      <Grid item xs={12}>
         <Typography variant="h6" gutterBottom component="div" sx={{ borderBottom: '3px solid #00a5de' }}>
            General Information
         </Typography>
      </Grid>

      <Grid item xs={12}>
         <Typography variant="h6" gutterBottom component="div">
            Where To Find SSN
         </Typography>
      </Grid>
      <Grid item xs={12}>
         <Typography variant="body1" gutterBottom>
            {props.funeralArrangements.whereToFindSSN || 'Not set.'}
         </Typography>
      </Grid>

      <Grid item xs={12}>
         <Typography variant="h6" gutterBottom component="div" sx={{ borderBottom: '3px solid #00a5de' }}>
            How and Where to Announce My Passing
         </Typography>
      </Grid>

      {props.funeralArrangements.announceDeathOnSocialMedia ? <Fragment>
         <Grid item xs={12}>
            <Typography variant="h6" gutterBottom component="div">
               Announce My Death on the Following Social Media Platforms
            </Typography>
         </Grid>

         <Grid item xs={12}>
            <ul>
               {props.funeralArrangements.announceOnFacebook ? <li>Facebook</li> : null}
               {props.funeralArrangements.announceOnX ? <li>X Platform</li> : null}
               {props.funeralArrangements.announceOnInstagram ? <li>Instagram</li> : null}
               {props.funeralArrangements.OtherSocialMediaPlatformToAnnounceOn ? <li>{props.funeralArrangements.OtherSocialMediaPlatformToAnnounceOn}</li> : null}
            </ul>
         </Grid>
      </Fragment> : null}

      {props.funeralArrangements.announceDeathInNewspaper ? <Fragment>
         <Grid item xs={12}>
            <Typography variant="h6" gutterBottom component="div">
               Announce My Death in the Following Newspaper
            </Typography>
         </Grid>
         <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
               {props.funeralArrangements.newspaperToAnnounceIn || 'Not set.'}
            </Typography>
         </Grid>
      </Fragment> : null}

      {props.funeralArrangements.announceDeathInOtherWay ? <Fragment>
         <Grid item xs={12}>
            <Typography variant="h6" gutterBottom component="div">
               Announce My Death in the Following Way
            </Typography>
         </Grid>
         <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
               {props.funeralArrangements.otherWayToAnnounceIn || 'Not set.'}
            </Typography>
         </Grid>
      </Fragment> : null}

      <Grid item xs={12}>
         <Typography variant="h6" gutterBottom component="div" sx={{ borderBottom: '3px solid #00a5de' }}>
            Body Handling &amp; Treatment
         </Typography>
      </Grid>

      <Grid item xs={12}>
         <Typography variant="body1" gutterBottom>
            {props.funeralArrangements.cremation ? 'Do not c' : 'C'}remate me.
         </Typography>
      </Grid>

      <Grid item xs={12}>
         <Typography variant="h6" gutterBottom component="div">
            Burial or Ashes Instructions
         </Typography>
      </Grid>
      <Grid item xs={12}>
         <Typography variant="body1" gutterBottom>
            {props.funeralArrangements.burialOrAshesInstructions || 'Not set.'}
         </Typography>
      </Grid>

      <Grid item xs={12}>
         <Typography variant="h6" gutterBottom component="div" sx={{ borderBottom: '3px solid #00a5de' }}>
            Funeral Costs
         </Typography>
      </Grid>

      <Grid item xs={12}>
         <Typography variant="body1" gutterBottom>
            {props.funeralArrangements.funeralCostPlanType.id === 'not-arranged' ? props.funeralArrangements.funeralCostPlanType.name : props.funeralArrangements.funeralCostPaymentDetail}
         </Typography>
      </Grid>

      <Grid item xs={12}>
         <Typography variant="h6" gutterBottom component="div" sx={{ borderBottom: '3px solid #00a5de' }}>
            Funeral Service
         </Typography>
      </Grid>

      <Grid item xs={12}>
         <Typography variant="body1" gutterBottom>
            {props.funeralArrangements.funeralServiceToBeHeld ? 'I want a funeral service to be held, according to the following wishes' : 'I do not want a funeral service to be held'}.
         </Typography>
      </Grid>

      {props.funeralArrangements.funeralServiceToBeHeld ? <Fragment>
         <Grid item xs={12}>
            <Typography variant="h6" gutterBottom component="div">
               Funeral Service Address
            </Typography>
         </Grid>
         <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
               <AddressDisplay address={props.funeralArrangements.funeralServiceAddress} blankMessage="Not set." />
            </Typography>
         </Grid>

         <Grid item xs={12}>
            <Typography variant="h6" gutterBottom component="div">
               Desired Funeral Service Officiant
            </Typography>
         </Grid>
         <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
               {props.funeralArrangements.desiredFuneralServiceOfficiant || 'Not set.'}
            </Typography>
         </Grid>

         <Grid item xs={12}>
            <Typography variant="h6" gutterBottom component="div">
               Eulogy
            </Typography>
         </Grid>
         <Grid item xs={12}>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }} gutterBottom>
               {props.funeralArrangements.readEuologyWrittenByMe ? 'I want the following euology, composed by me, to be read' : 'I want the following person to write my euology'}:
            </Typography>
         </Grid>

         <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
               {props.funeralArrangements.readEuologyWrittenByMe ? props.funeralArrangements.eulogyText : props.funeralArrangements.desiredEulogyWriter || 'Not set.'}
            </Typography>
         </Grid>

         <Grid item xs={12}>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }} gutterBottom>
               I want the following person to read my euology:
            </Typography>
         </Grid>
         <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
               {props.funeralArrangements.desiredEulogyReader || 'Not set.'}
            </Typography>
         </Grid>

         <Grid item xs={12}>
            <Typography variant="h6" gutterBottom component="div">
               Ceremony Songs
            </Typography>
         </Grid>
         {props.funeralArrangements.ceremonySongs && props.funeralArrangements.ceremonySongs.length ? <Fragment>
            <Grid item xs={12}>
               <Typography variant="body1" gutterBottom>
                  I want the following songs to be played at my ceremony:
               </Typography>
            </Grid>

            <Grid item xs={12}>
               <ul>
                  {props.funeralArrangements.ceremonySongs.map(song => <li key={song.id}>{song.name}</li>)}
               </ul>
            </Grid>
         </Fragment> : <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
               I don't have any specific songs which should be played at my ceremony.
            </Typography>
         </Grid>}

         <Grid item xs={12}>
            <Typography variant="h6" gutterBottom component="div">
               Reception After Service
            </Typography>
         </Grid>
         <Grid item xs={12}>
            {props.funeralArrangements.receptionAfterService ? <Typography variant="body1" sx={{ fontWeight: 'bold' }} gutterBottom>
               I want a reception after the service:
            </Typography> : <Typography variant="body1" gutterBottom>
               I do not want a reception after the service.
            </Typography>}
         </Grid>

         {props.funeralArrangements.receptionAfterService ? <Fragment>
            <Grid item xs={12}>
               <Typography variant="body1" gutterBottom>
                  {props.funeralArrangements.ReceptionType || 'Not set.'}
               </Typography>
            </Grid>

            <Grid item xs={12}>
               <Typography variant="body1" sx={{ fontWeight: 'bold' }} gutterBottom>
                  The reception should be held at the following address:
               </Typography>
            </Grid>

            <Grid item xs={12}>
               <Typography variant="body1" gutterBottom>
                  <AddressDisplay address={props.funeralArrangements.receptionAddress} blankMessage="I don't have a specific address where the reception should be held." />
               </Typography>
            </Grid>
         </Fragment> : null}

         <Grid item xs={12}>
            <Typography variant="h6" gutterBottom component="div">
               Casket
            </Typography>
         </Grid>
         <Grid item xs={12}>
            {props.funeralArrangements.openCasket ? <Fragment>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }} gutterBottom>
                  I want an open casket, according to the following instructions:
               </Typography>
               <Typography variant="body1" gutterBottom>
                  {props.funeralArrangements.openCasketInstructions || 'Not set.'}
               </Typography>
            </Fragment> : <Typography variant="body1" gutterBottom>
               I do not want an open casket.
            </Typography>}
         </Grid>

         <Grid item xs={12}>
            <Typography variant="h6" gutterBottom component="div">
               Attendance
            </Typography>
         </Grid>

         {/* todo: in future, pull lists of other contacts matching the type */}
         <Grid item xs={12}>
            <ul sx={{ mt: 0 }}>
               {props.funeralArrangements.allowFamilyToAttendCeremony ? <li>Family is allowed to attend.</li> : null}
               {props.funeralArrangements.allowFriendsToAttendCeremony ? <li>Friends are allowed to attend.</li> : null}
               {props.funeralArrangements.allowPublicToAttendCeremony ? <li>The public is allowed to attend.</li> : null}
            </ul>
         </Grid>
      </Fragment> : null}

      <Grid item xs={12}>
         <Typography variant="h6" gutterBottom component="div" sx={{ borderBottom: '3px solid #00a5de' }}>
            Additional Final Wishes
         </Typography>
      </Grid>
      <Grid item xs={12}>
         <Typography variant="body1" gutterBottom>
            {props.funeralArrangements.additionalFinalWishes || 'Not set.'}
         </Typography>
      </Grid>
   </Grid>);
};

FuneralArrangementsDisplay.propTypes = {
   funeralArrangements: PropTypes.object.isRequired,
};

export default FuneralArrangementsDisplay;
