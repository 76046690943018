import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import ListItemIcon from '@mui/material/ListItemIcon';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import YesNoDialog from './YesNoDialog';

const BioQuestionCard = (props) => {
   const [bioQuestionActionMenuAnchorEl, setBioQuestionActionMenuAnchorEl] = useState(null);

   const bioQuestionActionMenuOpen = Boolean(bioQuestionActionMenuAnchorEl);

   const handleClickListItem = (event) => {
      setBioQuestionActionMenuAnchorEl(event.currentTarget);
   };

   const handleEditClick = () => {
      props.handleEdit(props.answer);
      setBioQuestionActionMenuAnchorEl(null);
   };

   const handleDeleteBioQuestionClick = () => {
      handleBioQuestionActionMenuClose();
      setDeleteBioQuestionDialogOpen(true);
   };

   const handleBioQuestionActionMenuClose = () => {
      setBioQuestionActionMenuAnchorEl(null);
   };

   const [deleteBioQuestionDialogOpen, setDeleteBioQuestionDialogOpen] = useState(false);

   return (
      <Fragment>
         <Card sx={{
            bgcolor: '#FAF9F6',
            height: '100%'
         }}>
         <CardHeader
            action={
               props.handleEdit && typeof props.handleEdit === 'function' ? <IconButton aria-label="settings" onClick={handleClickListItem}>
                  <MoreVertIcon />
               </IconButton> : null
            }
            titleTypographyProps={{ variant:'h6' }}
            title={ props.answer.question.name }
         />
         <CardContent>
            <Typography variant="body3" color="text.secondary">
            {props.answer.answer}
            </Typography>
            {props.handleEdit && typeof props.handleEdit === 'function' ? <Menu
               id="lock-menu"
               anchorEl={bioQuestionActionMenuAnchorEl}
               open={bioQuestionActionMenuOpen}
               onClose={handleBioQuestionActionMenuClose}
               MenuListProps={{
               'aria-labelledby': 'lock-button',
               role: 'listbox',
               }}
            >
               <MenuItem
                  key={1}
                  onClick={(event) => handleEditClick(event)}
               >
                  <ListItemIcon>
                     <EditIcon fontSize="small" />
                  </ListItemIcon>
                  Edit Answer
               </MenuItem>
               <MenuItem
                  key={2}
                  onClick={(event) => handleDeleteBioQuestionClick(event)}
               >
                  <ListItemIcon>
                     <DeleteIcon fontSize="small" />
                  </ListItemIcon>
                  Delete Answer
               </MenuItem>
            </Menu> : null }
         </CardContent>
         </Card>
         <YesNoDialog
            open={deleteBioQuestionDialogOpen}
            title="Delete Bio Question?"
            description="Are you sure you want to delete this question?"
            dismissButtonLabel="No"
            affirmButtonLabel="Yes"
            handleDismiss={() => setDeleteBioQuestionDialogOpen(false)}
            handleAffirm={() => props.handleDelete(props.answer)}
         />
      </Fragment>
   );
};

BioQuestionCard.propTypes = {
   answer: PropTypes.object.isRequired,
   handleEdit: PropTypes.func,
   handleDelete: PropTypes.func
};

export default BioQuestionCard;
