import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import format from 'date-fns/format';
import JourneyPhoto from './JourneyPhoto';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import TextDisplay from './TextDisplay';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import YesNoDialog from './YesNoDialog';
import LTIconButton from './LTIconButton';

const JourneyMonth = (props) => {
   const [journeyMonthActionsMenuAnchorEl, setJourneyMonthActionsMenuAnchorEl] = useState(null);
   const handleJourneyMonthActionsMenuOpen = (event) => {
      setJourneyMonthActionsMenuAnchorEl(event.currentTarget);
   };

   const handleJourneyMonthActionsMenuClose = () => {
      setJourneyMonthActionsMenuAnchorEl(null);
   };

   const handleEditJourneyMonthSummaryClick = (journeyMonth) => {
      props.handleEditMonthSummary(journeyMonth);
      handleJourneyMonthActionsMenuClose();
   };

   const handleDeleteJourneyMonthClick = () => {
      handleJourneyMonthActionsMenuClose();
      setDeleteJourneyMonthDialogOpen(true);
   };

   const [deleteJourneyMonthDialogOpen, setDeleteJourneyMonthDialogOpen] = useState(false);

   return (
      <Fragment>
         <Card variant='outlined'>
            <CardHeader
               action={
                  <Fragment>
                     <Tooltip title='Journey Month Actions'>
                        <span>
                           <IconButton
                              aria-label='journey=month-actions-menu'
                              onClick={handleJourneyMonthActionsMenuOpen}
                           >
                              <MoreVertIcon />
                           </IconButton>
                        </span>
                     </Tooltip>
                     <Menu
                        id='menu-journey-month-actions'
                        anchorEl={journeyMonthActionsMenuAnchorEl}
                        anchorOrigin={{
                           vertical: 'bottom',
                           horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                           vertical: 'top',
                           horizontal: 'right',
                        }}
                        open={Boolean(journeyMonthActionsMenuAnchorEl)}
                        onClose={handleJourneyMonthActionsMenuClose}
                     >
                        <MenuItem
                           aria-label='edit-month-summary'
                           onClick={() =>
                              handleEditJourneyMonthSummaryClick(
                                 props.journeyMonth,
                              )
                           }
                        >
                           <ListItemIcon>
                              <EditIcon fontSize='small' />
                           </ListItemIcon>
                           Edit Month Summary
                        </MenuItem>
                        <MenuItem
                           aria-label='delete-journey-month'
                           onClick={() => handleDeleteJourneyMonthClick()}
                        >
                           <ListItemIcon>
                              <DeleteIcon fontSize='small' />
                           </ListItemIcon>
                           Delete Month
                        </MenuItem>
                     </Menu>

                     <LTIconButton
                        disabled={
                           props.journeyMonth.journeyPhotos.length &&
                           props.journeyMonth.journeyPhotos.length >=
                              props.resourceLimits.journeyPhoto
                              ? true
                              : false
                        }
                        tooltipText={
                           props.journeyMonth.journeyPhotos.length &&
                           props.journeyMonth.journeyPhotos.length >=
                              props.resourceLimits.journeyPhoto
                              ? 'Limit reached for this account tier.'
                              : 'Add Photo'
                        }
                        ariaLabel='add photo to month'
                        onClick={() =>
                           props.handleAddJourneyPhoto(props.journeyMonth)
                        }
                        icon={<AddAPhotoIcon />}
                     />
                  </Fragment>
               }
               title={
                  <Typography variant='h5' gutterBottom component='div'>
                     {format(props.journeyMonth.forMonth, 'MMMM yyyy')}
                  </Typography>
               }
               subheader={
                  props.journeyMonth.summary ? (
                     <Typography
                        variant='body1'
                        sx={{ whiteSpace: 'pre-wrap' }}
                        color='text.secondary'
                        gutterBottom
                     >
                        <b>Month Summary:</b>{' '}
                        <TextDisplay
                           text={props.journeyMonth.summary}
                           maxChars={120}
                        />
                     </Typography>
                  ) : null
               }
            />
            <CardContent>
               <Grid container spacing={1}>
                  {props.journeyMonth.journeyPhotos.map(
                     (journeyPhoto, index) => (
                        <Grid item xs={6} md={4} key={journeyPhoto.id}>
                           <JourneyPhoto
                              journeyPhoto={journeyPhoto}
                              handleEditJourneyPhoto={
                                 props.handleEditJourneyPhoto
                              }
                              handleDeleteJourneyPhoto={
                                 props.handleDeleteJourneyPhoto
                              }
                              onToggleShowInMausoleum={(id) =>
                                 props.onToggleShowInMausoleum(id, index)
                              }
                           />
                        </Grid>
                     ),
                  )}
               </Grid>
            </CardContent>
         </Card>
         <YesNoDialog
            open={deleteJourneyMonthDialogOpen}
            title='Delete Journey Month?'
            description='Are you sure you want to delete this month from your journey?'
            dismissButtonLabel='No'
            affirmButtonLabel='Yes'
            handleDismiss={() => setDeleteJourneyMonthDialogOpen(false)}
            handleAffirm={() => props.handleDeleteJourneyMonth(props.journeyMonth.id)}
         />
      </Fragment>
   );
};

JourneyMonth.propTypes = {
   journeyMonth: PropTypes.object.isRequired,
   handleAddJourneyPhoto: PropTypes.func.isRequired,
   handleEditMonthSummary: PropTypes.func.isRequired,
   handleEditJourneyPhoto: PropTypes.func.isRequired,
   onToggleShowInMausoleum: PropTypes.func.isRequired,
   handleDeleteJourneyMonth: PropTypes.func.isRequired,
   resourceLimits: PropTypes.object.isRequired,
};

export default JourneyMonth;
