import axiosService from './AxiosService';

export class GeneratePDFService {
   _resourceName = 'generate-pdf';

   async getMyCompleteTomb() {
      return await axiosService.fireAndForget(`/v1.0/${this._resourceName}/complete-tomb`);
   }

   static get instance() { return generatePDFService }
}

const generatePDFService = new GeneratePDFService();

export default generatePDFService;
