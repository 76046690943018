import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import MUILink from '@mui/material/Link';

const TextDisplay = (props) => {
   const [moreVisible, setMoreVisible] = useState(false);

   const toggleMoreVisible = () => setMoreVisible(!moreVisible);

   if (!props.text && props.blankMessage)
      return <Fragment>{props.blankMessage}</Fragment>;

   if(!props.text)
      return null;

   if(props.maxChars && props.maxChars < props.text.length)
      return (<Fragment>
         {moreVisible ? props.text : `${props.text.substring(0, props.maxChars)}...`}&nbsp;
         <MUILink onClick={toggleMoreVisible} sx={{cursor: 'pointer'}}>
            {moreVisible ? 'less' : 'more'}
         </MUILink>
      </Fragment>);
   else
      return (<Fragment>{props.text}</Fragment>);
};

TextDisplay.propTypes = {
   text: PropTypes.string,
   maxChars: PropTypes.number,
   blankMessage: PropTypes.string,
};

export default TextDisplay;
