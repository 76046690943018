import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import CardContent from '@mui/material/CardContent';
import { Paper } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const WidgetContainer = (props) => {
   const theme = useTheme();
   const smOrHigher = useMediaQuery(theme.breakpoints.up('sm'));

   const StyledCardHeader = styled(CardContent)({
      height: '6.15em',
      backgroundColor: props.color,
      borderBottomRightRadius: '0.9em',
      borderBottomLeftRadius: '0.9em',
   });

   let StyledIcon;
   if (typeof props.icon === 'function') {
      StyledIcon = styled(props.icon)({
         paddingTop: '0.6em',
         paddingLeft: '0.3em',
         height: '48px',
      });
   } else {
      StyledIcon = styled(props.icon)({
         fontSize: '3.8em',
         color: props.color,
         display: 'flex',
         margin: 'auto',
         paddingTop: '0.1em',
      });
   }

   return (
      <Card
         sx={{ height: '100%', borderRadius: '0.9em', marginBottom: '10px' }}
         elevation={3}
      >
         <StyledCardHeader>
            <Stack direction='row' spacing={1}>
               <Paper
                  variant='outlined'
                  sx={{
                     display: 'inline-block',
                     borderRadius: '0.9em',
                     border: 'none',
                     width: '4.3em',
                     height: '4.3em',
                     backgroundColor: '#e2eee5',
                  }}
               >
                  <StyledIcon />
               </Paper>

               <Typography
                  variant='h6'
                  component='div'
                  sx={{
                     color: '#e2eee5',
                     fontSize: smOrHigher ? '1.9em' : '1.5em',
                     paddingTop: '0.4em',
                  }}
               >
                  {props.title}
               </Typography>
            </Stack>
         </StyledCardHeader>
         {props.children}
      </Card>
   );
};

WidgetContainer.propTypes = {
   title: PropTypes.string.isRequired,
   icon: PropTypes.oneOfType([
      PropTypes.object.isRequired,
      PropTypes.func.isRequired,
   ]),
   color: PropTypes.string.isRequired,
};

export default WidgetContainer;
