import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LoaderIcon from './LoaderIcon';
import PhotoUploader from './PhotoUploader';
import CancelIcon from '@mui/icons-material/Cancel';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const MausoleumImageUploadDialog = (props) => {
   const photoUploaderRef = useRef();

   const handleSaveMausoleumImageClick = () => {
      let photoValid = false;
      if(photoUploaderRef.current)
         photoValid = photoUploaderRef.current.validate();

      if(photoValid)
         props.onSaveMausoleumImage(photoUploaderRef.current.getPhoto());
   };

   return (
      <Dialog
         open={props.open}
         onClose={props.handleClose}
         fullWidth={true}
         maxWidth='md'
      >
         <DialogTitle>Upload Mausoleum Image</DialogTitle>
         <DialogContent>
            {!props.loading ? <Box component="form">
               <Grid container spacing={1}>
                  <Grid item xs={12}>
                     <PhotoUploader
                        ref={photoUploaderRef}
                        width={640}
                        height={228}
                        buttonText="Select Image"
                     />
                  </Grid>
               </Grid>
            </Box> : <LoaderIcon />}
         </DialogContent>
         <DialogActions>
            <Button variant="outlined" startIcon={<CancelIcon />} onClick={props.handleClose}>Cancel</Button>
            <Button variant="contained" startIcon={<CloudUploadIcon />} onClick={handleSaveMausoleumImageClick}>Upload Image</Button>
         </DialogActions>
      </Dialog>
   );
};

MausoleumImageUploadDialog.propTypes = {
   loading: PropTypes.bool.isRequired,
   open: PropTypes.bool.isRequired,
   handleClose: PropTypes.func.isRequired,
   onSaveMausoleumImage: PropTypes.func.isRequired,
};

export default MausoleumImageUploadDialog;
