import axiosService from './AxiosService';

export class NotificationService {
   _resourceName = 'notification';

   async getMyNotifications() {
      return await axiosService.readList(`/v1.0/${this._resourceName}`);
   }

   async deleteNotification(id) {
      return await axiosService.delete(`/v1.0/${this._resourceName}`, id);
   }

   async deleteTrusteeInvite(id) {
      return await axiosService.delete(`/v1.0/${this._resourceName}/trustee-invite`, id);
   }

   async deleteFamilyProfileInvite(id) {
      return await axiosService.delete(`/v1.0/${this._resourceName}/family-profile-invite`, id);
   }

   async refuteDeceasedReport(notificationId){
      return await axiosService.updateFlag(`/v1.0/${this._resourceName}`, 'refute-deceased-report', notificationId);
   }

   static get instance() { return notificationService }
}

const notificationService = new NotificationService();

export default notificationService;
