import axiosService from './AxiosService';

export class SystemLookupService {
   _resourceName = 'system-lookup';

   async getCurrentTermsAndConditions() {
      return await axiosService.readObject(`/v1.0/${this._resourceName}/current-terms-and-conditions`);
   }

   async getGenders() {
      return await axiosService.readList(`/v1.0/${this._resourceName}/genders`);
   }

   async getMaritalStatuses() {
      return await axiosService.readList(`/v1.0/${this._resourceName}/marital-statuses`);
   }

   async getAssetTypes() {
      return await axiosService.readList(`/v1.0/${this._resourceName}/asset-types`);
   }

   async getInstitutionTypes() {
      return await axiosService.readList(`/v1.0/${this._resourceName}/institution-types`);
   }

   async getDocumentTypes() {
      return await axiosService.readList(`/v1.0/${this._resourceName}/document-types`);
   }

   async getAddressStatesProvinces() {
      return await axiosService.readList(`/v1.0/${this._resourceName}/address-states-provinces`);
   }

   async getSubscriptionTiers() {
      return await axiosService.readList(`/v1.0/${this._resourceName}/subscription-tiers`);
   }

   async getMausoleumBannerImages() {
      return await axiosService.readList(`/v1.0/${this._resourceName}/mausoleum-banner-images`);
   }

   async getBioQuestions() {
      return await axiosService.readList(`/v1.0/${this._resourceName}/bio-questions`);
   }

   async getFilteredBioQuestions() {
      return await axiosService.readList(`/v1.0/${this._resourceName}/filtered-bio-questions`);
   }

   async getFuneralCostPlanTypes() {
      return await axiosService.readList(`/v1.0/${this._resourceName}/select-funeral-cost-plan-types`);
   }

   async getHealthAgentDecisionPoints() {
      return await axiosService.readList(`/v1.0/${this._resourceName}/health-agent-decision-points`);
   }

   async getEndOfLifeInstructions() {
      return await axiosService.readList(`/v1.0/${this._resourceName}/end-of-life-instructions`);
   }

   async getPainManagement() {
      return await axiosService.readList(`/v1.0/${this._resourceName}/pain-management`);
   }

   async getEndOfLifeCareLocationTypes() {
      return await axiosService.readList(`/v1.0/${this._resourceName}/end-of-life-care-location-types`);
   }

   async getOtherContactTypes() {
      return await axiosService.readList(`/v1.0/${this._resourceName}/other-contact-types`);
   }

   async getMyFeatureFlags() {
      return await axiosService.readList(`/v1.0/${this._resourceName}/my-feature-flags`);
   }

   static get instance() { return systemLookupService }
}

const systemLookupService = new SystemLookupService();

export default systemLookupService;
