import axios from 'axios';
import authService from '../components/api-authorization/AuthorizeService';
import PubSub from 'pubsub-js';

export class AxiosService {
   _token = null;
   _http = null;

   async init() {
      this._token = await authService.getAccessToken();

      this._http = axios.create({
         baseURL: '/api',
         // timeout: 1000,
         headers: !this._token ? {} : {
            'Authorization': `Bearer ${this._token}`
         }
      });

      this._http.interceptors.request.use(function (config) {
         // Do something before request is sent
         return config;
      }, function (error) {
         // Do something with request error
         return Promise.reject(error);
      });

      this._http.interceptors.response.use(function (response) {
         // Any status code that lie within the range of 2xx cause this function to trigger
         // Do something with response data
         return response;
      }, function (error) {
         if (error.response.status >= 500 && error.response.status <= 599) {
            PubSub.publish('notification.show-snackbar', {
               open: true,
               autoHideDuration: 5000,
               severity: 'error',
               message: error.message || `An error occurred (${error.response.status}).`
            });
         } else if (error.response.status === 401) {
            PubSub.publish('auth.session-expired');
            return Promise.reject(error);
         } else if (error.response.status >= 400 && error.response.status <= 499) {
               PubSub.publish('notification.show-snackbar', {
                  open: true,
                  autoHideDuration: 5000,
                  severity: 'warning',
                  message: error.message || `An error occurred (${error.response.status}).`
               });
         } else {
            return Promise.reject(error);
         } 
      });
   }

   async ensureAxiosInitialized() {
      if (this._http !== null)
         return;

      return this.init();
   }

   async create(resourcePath, item) {
      await this.ensureAxiosInitialized();

      const response = await this._http.post(resourcePath, item);

      return response.data;
   }

   async update(resourcePath, item) {
      await this.ensureAxiosInitialized();

      const response = await this._http.put(`${resourcePath}${(item.id ? `/${item.id}` : '')}`, item);

      return response;
   }

   async updateFlag(resourcePath, flagName, id) {
      await this.ensureAxiosInitialized();

      const response = await this._http.put(`${resourcePath}/${flagName}${(id ? `/${id}` : '')}`);

      return response;
   }

   async readObject(resourcePath, id) {
      await this.ensureAxiosInitialized();

      const response = await this._http.get(`${resourcePath}${(id ? `/${id}` : '')}`);

      return response.data;
   }

   async readFile(resourcePath, id) {
      await this.ensureAxiosInitialized();

      const response = await this._http.get(`${resourcePath}${(id ? `/${id}` : '')}`, {
         responseType: 'blob'
      });

      return response.data;
   }

   async fireAndForget(resourcePath, id) {
      await this.ensureAxiosInitialized();

      const response = await this._http.get(resourcePath);

      return response;
   }

   async readList(resourcePath, queryString) {
      await this.ensureAxiosInitialized();

      const response = await this._http.get(`${resourcePath}${(queryString ? `${queryString}` : '')}`);

      if (response.status === 204)
         return [];
      else
         return response.data;
   }

   async delete(resourcePath, id) {
      await this.ensureAxiosInitialized();

      return await this._http.delete(`${resourcePath}${(id ? `/${id}` : '')}`);
   }

   static get instance() { return axiosService }
}

const axiosService = new AxiosService();

export default axiosService;
