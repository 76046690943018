import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';

const AvatarInitials = (props) => {
   return (
      <Avatar
         sx={{ ml: 0.5, bgcolor: '#00a5de', border: '2px solid #fff' }}
         alt={props.basicProfile.firstName}
         src={props.basicProfile.avatarBase64}
      >
         {props.basicProfile.avatarBase64 ? null : (props.basicProfile.firstName ? `${props.basicProfile.firstName.substring(0, 1)}${props.basicProfile.lastName.substring(0, 1)}` : null)}
      </Avatar>
   )
};

AvatarInitials.propTypes = {
   basicProfile: PropTypes.object.isRequired,
};

export default AvatarInitials;
