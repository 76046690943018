import React, { useState, useRef, forwardRef, useImperativeHandle, Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import finalWishesService from '../data/FinalWishesService';
import LTIconButton from './LTIconButton';
import PetWishForm from './PetWishForm';
import EnhancedTable from '../components/EnhancedTable';
import { PetWishColConfig } from '../components/TableColumnConfigs';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const PetWishesDialog = forwardRef((props, ref) => {
   const petWishFormRef = useRef();

   const [petWishes, setPetWishes] = useState({ data: [], loading: true });
   const [editMode, setEditMode] = useState(false);

   // control validation, state from parent component
   useImperativeHandle(ref, () => ({
      async loadMyPetWishes () {
         await getMyPetWishes();
      },
   }));

   const getMyPetWishes = async () => {
      setPetWishes({ data: [], loading: true });
      const petWishes = await finalWishesService.getMyPetWishes();
      setEditMode(petWishes.length ? false : true);
      setPetWishes({ data: petWishes, loading: false });
   };

   const handleAddPetWishClick = () => {
      if(petWishFormRef.current)
         petWishFormRef.current.setState({
            name: '',
            animalType: '',
            futureGuardianName: '',
            specialAccommodations: '',
         });

      setEditMode(true);
   }

   const handleAddSavePetWish = async () => {
      if(!petWishFormRef.current)
         return;

      let petWish = petWishFormRef.current.getState();
      console.log(petWish);
      if(!petWish)
         return;

      setPetWishes({ ...petWishes, loading: true });

      if(petWish.id)
         await finalWishesService.patchPetWish(petWish);
      else
         await finalWishesService.postPetWish(petWish);

      petWishFormRef.current.setState({
         name: '',
         animalType: '',
         futureGuardianName: '',
         specialAccommodations: '',
      });

      setEditMode(false);

      getMyPetWishes();
   };

   const handleEditPetWish = async (pet) => {
      await setEditMode(true);
      if(petWishFormRef.current)
         petWishFormRef.current.setState(pet);
   };

   const handleDeletePetWish = async (pet) => {
      setPetWishes({ ...petWishes, loading: true });
      await finalWishesService.deletePetWish(pet.id);
      getMyPetWishes();
   }

   const handleCancelAddSave = () => {
      setEditMode(false);
   };

   return (
      <Dialog
         open={props.open}
         onClose={props.handleClose}
         fullWidth={true}
         // maxWidth={DialogProps['maxWidth']}
         maxWidth={'md'}
         scroll="body"
      >
         <DialogTitle>Define Pet Wishes</DialogTitle>
         {!editMode ? <DialogContent>
            <LTIconButton
               disabled={petWishes.loading}
               tooltipText="Add Pet Wish"
               ariaLabel="add-pet-wish"
               onClick={handleAddPetWishClick}
               icon={<AddIcon />}
            />
         </DialogContent> : null }
         {editMode ? <Fragment>
            <DialogContent>
               <Grid container spacing={1}>
                  <PetWishForm ref={petWishFormRef} />
               </Grid>
            </DialogContent>
            <DialogActions>
               <Button variant="outlined" startIcon={<CancelIcon />} disabled={props.loading} onClick={handleCancelAddSave}>Cancel</Button>

               <Button variant="contained" startIcon={<CheckIcon />} onClick={handleAddSavePetWish}>Save</Button>
            </DialogActions>
         </Fragment> : null}
         {editMode === false ? <Fragment>
            <DialogContent>
               <Box component="form" sx={{
               display: 'flex',
               flexDirection: 'column',
               //   m: 'auto',
               //   width: 'fit-content',
               }}>
                  <EnhancedTable
                     loading={petWishes.loading}
                     emptyMessage="No pets have been added yet."
                     data={petWishes.data}
                     colConfig={PetWishColConfig}
                     itemAddDisabled={petWishes.loading}
                     rowActions={[{
                        toolTip: 'Edit',
                        icon: <EditIcon />,
                        onClick: handleEditPetWish,
                     },{
                        toolTip: 'Delete',
                        icon: <DeleteIcon />,
                        confirmDialogConfig: {
                           title: "Delete Pet?",
                           description: "Are you sure you want to delete this pet?",
                           dismissButtonLabel: "No",
                           affirmButtonLabel: "Yes",
                        },
                        onClick: handleDeletePetWish,
                     }]}
                  />
               </Box>
            </DialogContent>
            <DialogActions>
               <Button variant="outlined" startIcon={<CheckIcon />} onClick={props.handleClose}>Close</Button>
            </DialogActions>
         </Fragment> : null}
      </Dialog>
   );
});

PetWishesDialog.propTypes = {
   open: PropTypes.bool.isRequired,
   handleClose: PropTypes.func.isRequired,
};

export default PetWishesDialog;
