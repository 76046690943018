import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

const PhoneNumberDisplay = (props) => {
   if (!props.number)
      return <Fragment>{props.blankMessage || 'Invalid phone number.'}</Fragment>;

   return (
      <NumberFormat value={props.number} displayType={'text'} format="+1 (###) ###-####" />
   )
};

PhoneNumberDisplay.propTypes = {
   number: PropTypes.string,
   blankMessage: PropTypes.string,
};

export default PhoneNumberDisplay;
