import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

const AddressDisplay = (props) => {
   if (!props.address)
      return <Fragment>{props.blankMessage || 'Invalid address.'}</Fragment>;

   return (
      <Fragment>
         {props.address.line1}
            {props.address.line2 ? <Fragment><br />{props.address.line2}</Fragment> : null}
         <br />{props.address.city} {props.address.stateProvince.name} {props.address.postalCode.length === 5 ? <NumberFormat value={props.address.postalCode} displayType={'text'} format="#####" /> : <NumberFormat value={props.address.postalCode} displayType={'text'} format="#####-####" />}
      </Fragment>
   )
};

AddressDisplay.propTypes = {
   address: PropTypes.object,
   blankMessage: PropTypes.string,
};

export default AddressDisplay;
