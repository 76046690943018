import axiosService from './AxiosService';

export class ProfileService {
   _resourceName = 'profile';

   async getProfileExists() {
      return await axiosService.readObject(`/v1.0/${this._resourceName}`, 'profile-exists');
   }

   async getBasicProfile() {
      return await axiosService.readObject(`/v1.0/${this._resourceName}`, 'basic-profile');
   }

   async getProfile() {
      return await axiosService.readObject(`/v1.0/${this._resourceName}`);
   }

   async getFamilyUser() {
      return await axiosService.readObject(`/v1.0/${this._resourceName}`, 'family-profile');
   }

   async postProfile(profile) {
      return await axiosService.create(`/v1.0/${this._resourceName}`, profile);
   }

   async putProfile(profile) {
      return await axiosService.update(`/v1.0/${this._resourceName}`, profile);
   }

   async deleteProfile() {
      return await axiosService.delete(`/v1.0/${this._resourceName}`);
   }

   async getProfilesImInvitedTo() {
      return await axiosService.readList(`/v1.0/${this._resourceName}/profiles-im-invited-to`);
   }

   async syncStripeSubscription() {
      return await axiosService.readObject(`/v1.0/${this._resourceName}/sync-stripe-subscription`);
   }

   async getExternalIdpProfilePrefill() {
      return await axiosService.readObject(`/v1.0/${this._resourceName}`, 'profile-prefill');
   }

   static get instance() { return profileService }
}

const profileService = new ProfileService();

export default profileService;
