import axiosService from './AxiosService';

export class ResourceUsageService {
   _resourceName = 'resource-usage';

   async getResourceUsage() {
      return await axiosService.readObject(`/v1.0/${this._resourceName}`);
   }

   static get instance() { return resourceUsageService }
}

const resourceUsageService = new ResourceUsageService();

export default resourceUsageService;
