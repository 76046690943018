import axiosService from './AxiosService';

export class TrusteeInviteService {
   _resourceName = 'trustee-invite';

   async addTrusteeInvite(trusteeInvite) {
      return await axiosService.create(`/v1.0/${this._resourceName}`, trusteeInvite);
   }

   async getTrusteeInvites() {
      return await axiosService.readList(`/v1.0/${this._resourceName}`);
   }

   async acceptTrusteeInvite(acceptInviteId) {
      return await axiosService.create(`/v1.0/${this._resourceName}/${acceptInviteId}`, acceptInviteId);
   }

   async deleteTrusteeInvite(id) {
      return await axiosService.delete(`/v1.0/${this._resourceName}`, id);
   }

   static get instance() { return trusteeInviteService }
}

const trusteeInviteService = new TrusteeInviteService();

export default trusteeInviteService;
