import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Alert from '@mui/material/Alert';
import LoaderIcon from './LoaderIcon';
import DiaryEntry from './DiaryEntry';
import Stack from '@mui/material/Stack';
import YearFilter from './YearFilter';
import LTIconButton from './LTIconButton';
import AddIcon from '@mui/icons-material/Add';

const DiaryWidgetBody = (props) => {
   const renderList = (diaryEntries, loading) => {
      if (loading)
         return (<LoaderIcon />);

      if (!diaryEntries.length)
         return (<Alert severity="info">Your diary has no entries for {props.year}.</Alert>);

      return (diaryEntries.map(diaryEntry => <DiaryEntry
         key={diaryEntry.id}
         diaryEntry={diaryEntry}
         handleEditDiaryEntry={props.handleEditDiaryEntry}
         handleDeleteDiaryEntry={props.handleDeleteDiaryEntry}
      />));
   };
   return (
      <Fragment>
         <CardHeader
            action={
               <Fragment>
                  <YearFilter
                     onUpdateFilter={props.onUpdateFilter}
                     year={props.year}
                     label="Diary Year"
                  />
                  <LTIconButton
                     disabled={props.loading}
                     tooltipText="Add Diary Entry"
                     ariaLabel="add-diary-entry"
                     onClick={() => props.handleAddDiaryEntry()}
                     icon={<AddIcon />}
                  />
               </Fragment>
            }
         />
         <CardContent>
            <Stack spacing={1} sx={{ height: '60vh', overflowY: 'auto', display: 'block' }}>
               {renderList(props.diaryEntries, props.loading)}
            </Stack>
         </CardContent>
      </Fragment>
   );
};

DiaryWidgetBody.propTypes = {
   loading: PropTypes.bool.isRequired,
   diaryEntries: PropTypes.array.isRequired,
   handleAddDiaryEntry: PropTypes.func.isRequired,
   handleEditDiaryEntry: PropTypes.func.isRequired,
   handleDeleteDiaryEntry: PropTypes.func.isRequired,
   onUpdateFilter: PropTypes.func.isRequired,
   year: PropTypes.number.isRequired,
};

export default DiaryWidgetBody;
