import React, { useState, useEffect, Fragment } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import profileService from '../data/ProfileService';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import trustorService from '../data/TrustorService';
import CareAndHealthDisplay from '../components/CareAndHealthDisplay';
import FuneralArrangementsDisplay from '../components/FuneralArrangementsDisplay';
import LoaderIcon from '../components/LoaderIcon';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import ProfileWidgetBody from '../components/ProfileWidgetBody';
import EnhancedTable from '../components/EnhancedTable';
import { AssetColConfig, InstitutionColConfig, DocumentColConfig, OtherContactColConfig} from '../components/TableColumnConfigs';
import PersonIcon from '@mui/icons-material/Person';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import FolderIcon from '@mui/icons-material/Folder';
import ContactsIcon from '@mui/icons-material/Contacts';
import WidgetContainer from '../components/WidgetContainer';
import Alert from '@mui/material/Alert';
import GavelIcon from '@mui/icons-material/Gavel';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import PubSub from 'pubsub-js';
import parseISO from 'date-fns/parseISO';

const Input = styled('input')({
   display: 'none',
});

const TombPage = () => {
   let { trustorId } = useParams();

   const [profile, setProfile] = useState({ data: {}, loading: true });
   const [finalWishesAvailable, setFinalWishesAvailable] = useState(false);
   const [careAndHealth, setCareAndHealth] = useState({ data: {}, loading: true });
   const [funeralArrangements, setFuneralArrangements] = useState({ data: {}, loading: true });

   const [tombOpen, setTombOpen] = useState(false);
   const [assets, setAssets] = useState({ data: [], loading: true });
   const [institutions, setInstitutions] = useState({ data: [], loading: true });
   const [documents, setDocuments] = useState({ data: [], loading: true });
   const [contacts, setContacts] = useState({ data: [], loading: true });

   useEffect(() => {
      initPage();
   }, [trustorId]);

   const history = useHistory();

   const initPage = async () => {
      const profileExists = await profileService.getProfileExists();
      if (profileExists) {
         if(trustorId)
            getTrustorProfile(trustorId);
         else
            return; //todo: show message that no trustorId is provided

         loadDeceasedStatus();
      } else {
         // todo: either move this somewhere generic and/or add to other pages
         //go to "get started wizard"
         history.push('/get-started');
      }
   };

   const loadDeceasedStatus = async () => {
      const _finalWishesAvailable = await trustorService.getFinalWishesAvailable(trustorId);
      await setFinalWishesAvailable(_finalWishesAvailable);

      // if(_finalWishesAvailable.finalWishesAvailable)
      getTrustorCareAndHealth(trustorId);
      getTrustorFuneralArrangements(trustorId);
      // else
         // setFinalWishes({ data: {}, loading: false });

      const _tombOpen = await trustorService.getTombOpen(trustorId);
      await setTombOpen(_tombOpen);
      if(_finalWishesAvailable.finalWishesAvailable && _tombOpen) {
         getAssets(trustorId);
         getInstitutions(trustorId);
         getDocuments(trustorId);
         getContacts(trustorId);
      } else {
         setAssets({ ...assets, loading: false })
      }
   };

   // trustor profile
   const getTrustorProfile = async (trustorId) => {
      setProfile({ data: {}, loading: true });
      const profile = await trustorService.getTrustorProfile(trustorId);
      profile.dateOfBirth = parseISO(profile.dateOfBirth);
      setProfile({ data: profile, loading: false });
   };

   // trustor care and health
   const getTrustorCareAndHealth = async (trustorId) => {
      setCareAndHealth({ data: null, loading: true });
      const careAndHealth = await trustorService.getTrustorCareAndHealth(trustorId);
      setCareAndHealth({ data: careAndHealth, loading: false });
   };

   // trustor funeral arrangements
   const getTrustorFuneralArrangements = async (trustorId) => {
      setFuneralArrangements({ data: null, loading: true });
      const funeralArrangements = await trustorService.getTrustorFuneralArrangements(trustorId);
      setFuneralArrangements({ data: funeralArrangements, loading: false });
   };

   const handleReportDeceasedClick = async (trustorId) => {
      setCareAndHealth({ ...careAndHealth, loading: true});
      await trustorService.putReportedDeceasedAt(trustorId);
      await loadDeceasedStatus();
      setCareAndHealth({ ...careAndHealth, loading: false});
   };

   // full tomb details
   const getAssets = async (trustorId) => {
      setAssets({ data: [], loading: true });
      const assets = await trustorService.getAssets(trustorId);
      setAssets({ data: assets, loading: false });
   };

   const getInstitutions = async (trustorId) => {
      setInstitutions({ data: [], loading: true });
      const institutions = await trustorService.getInstitutions(trustorId);
      setInstitutions({ data: institutions, loading: false });
   };

   const getDocuments = async (trustorId) => {
      setDocuments({ data: [], loading: true });
      const documents = await trustorService.getDocuments(trustorId);
      setDocuments({ data: documents, loading: false });
   };

   const getContacts = async (trustorId) => {
      setContacts({ data: [], loading: true });
      const contacts = await trustorService.getContacts(trustorId);
      setContacts({ data: contacts, loading: false });
   };

   // todo: pull these items into a component and fix DocumentDialog.js as well
   // file selector & upload
   const [selectedDocument, setSelectedDocument] = useState(null);
   const [documentBase64, setDocumentBase64] = useState(null);
   const [documentFileName, setDocumentFileName] = useState(null);

   const removeDocument = () => {
      setSelectedDocument(null);
      setDocumentBase64(null);
      document.getElementById('document-uploader-input').value = null;
   };

   const selectDocumentForUpload = (event) => {
      var reader = new FileReader();
      reader.onload = () => {
         setDocumentBase64(reader.result);
      };

      if(event.target.files[0] instanceof File) {
         setSelectedDocument(event.target.files[0]);
         setDocumentFileName(event.target.files[0].name);
         reader.readAsDataURL(event.target.files[0]);
      }
   };

   // validation
   const [formValidation, setFormValidation] = useState({});
   const formIsValid = () => {
      let errors = {};

      if (!selectedDocument)
         errors.selectedDocument = 'Document must be selected.';

      setFormValidation(errors);

      return Object.keys(errors).length === 0;
   };

   const handleUploadDeathCertificateButtonClick = () => {
      if (formIsValid()) {
         uploadDeathCertificate({
            fileName: documentFileName,
            base64Data: documentBase64,
         });

         removeDocument();
      }
   };

   const uploadDeathCertificate = async (deathCertificate) => {
      PubSub.publish('nonBlockingLoader.addMessage', { id: deathCertificate.fileName, text: `Uploading ${deathCertificate.fileName}...` });
      await trustorService.uploadDeathCertificate(trustorId, deathCertificate);
      PubSub.publish('nonBlockingLoader.removeMessage', deathCertificate.fileName);
   };

   return (
      // remove trustor pieces from the final wishes page

      <Box sx={{ flexGrow: 1 }}>
         <Grid container spacing={3}>
            {/* {finalWishesAvailable.reportedDeceased ? <Grid item xs={12}>
               <Alert sx={{ mt: 1 }} severity="info">{profile.data.firstName} {profile.data.lastName} has been reported deceased.
               After 24 hours pass without action of the deceased, the final wishes will be made available.
               </Alert>
            </Grid> :  */}

            {!finalWishesAvailable.reportedDeceased ? <Grid item xs={12}>
               <WidgetContainer
                  title="Trustee Responsibilities"
                  icon={GavelIcon}
                  color="#00a5de"
               >
                  <CardContent>
                     <Grid container spacing={1}>
                        <Grid item xs={12}>
                           <Alert sx={{ mt: 1 }} severity="info">
                              <ul>
                                 <li>As a trustee, you have the responsibility and ability to report {profile.data.firstName} {profile.data.lastName} as deceased, if you become aware of their passing.</li>
                                 {/* <li>Once {profile.data.firstName} {profile.data.lastName} is reported as deceased and the waiting period has elapsed, their final wishes become available to you and all other trustees. This information will help you plan their ceremony according to their wishes.</li> */}
                                 <li>Their profile and final wishes are visible to trustees below in order to help plan their ceremony according to their wishes.</li>
                                 <li>When their passing is confirmed, the rest of their LifeTomb information will be made available, including their Journey, Assets, Financial Info, Documents and Contacts. Use this information to coordinate and fulfill any remaining desires {profile.data.firstName} {profile.data.lastName} has requested.</li>
                                 <li>Finally, {profile.data.firstName} {profile.data.lastName}'s Mausoleum will live on, showing special photos curated by them from their Journey and their story. People will be able to share their memories and thoughts to honor and celebrate {profile.data.firstName} {profile.data.lastName}.</li>
                              </ul>
                           </Alert>
                        </Grid>
                        <Grid item xs={12}>
                           <Button
                              variant="outlined"
                              onClick={() => handleReportDeceasedClick(trustorId)}>
                                 Report Deceased
                           </Button>
                        </Grid>
                     </Grid>
                  </CardContent>
               </WidgetContainer>
            </Grid> : null}

            {!tombOpen && finalWishesAvailable.finalWishesAvailable ? <Grid item xs={12}>
               <WidgetContainer
                  title="Upload Death Certificate"
                  icon={CardMembershipIcon}
                  color="#00a5de"
               >
                  <CardContent>
                     <Grid container spacing={1}>
                        <Grid item xs={12}>
                           <Alert sx={{ mt: 1 }} severity="info">{profile.data.firstName} {profile.data.lastName} has been reported as deceased by one of their trustees.

                           {/* Their final wishes have been made available to help plan their ceremony.  */}

                           In order for the rest of their LifeTomb to be made available to all trustees, a death certificate needs to be uploaded for verification.</Alert>
                        </Grid>
                     </Grid>

                     <Grid item xs={12}>
                        <p>Browse for the death certificate to upload.</p>
                     </Grid>

                     <Grid item xs={12}>
                        <label htmlFor="document-uploader-input">
                           <Input
                              accept="*"
                              id="document-uploader-input"
                              name="document-uploader-input"
                              type="file"
                              onChange={selectDocumentForUpload}
                           />
                           <Button
                              variant="outlined"
                              component="span"
                              startIcon={<CloudUploadIcon />}
                           >
                              Choose Document
                           </Button>
                        </label>
                     </Grid>

                     {formValidation.selectedDocument ? <Grid item xs={12}><Alert severity="error">{formValidation.selectedDocument}</Alert></Grid> : null}

                     {documentFileName ? <Fragment>
                        <Grid item xs={12}>
                           <b>Document to be uploaded:</b> {documentFileName}
                        </Grid>
                        <Grid item xs={12}>
                           <Button
                              variant="outlined"
                              onClick={handleUploadDeathCertificateButtonClick}>Upload Document</Button>
                        </Grid>
                     </Fragment> : null}
                  </CardContent>
               </WidgetContainer>
            </Grid> : null}

            <Grid item xs={12}>
               {!profile.loading ? <WidgetContainer
                  title={`${profile.data.firstName} ${profile.data.lastName}'s Profile`}
                  icon={PersonIcon}
                  color="#00a5de"
               >
                  <ProfileWidgetBody
                     loading={profile.loading}
                     profile={profile.data}
                  />
               </WidgetContainer> : <LoaderIcon />}
            </Grid>

            <Grid item xs={12}>
               {/* {finalWishesAvailable.finalWishesAvailable ?  */}
               <WidgetContainer
                  title={`${profile.data.firstName} ${profile.data.lastName}'s Special Care & Health Decisions`}
                  icon={MedicalInformationIcon}
                  color="#00a5de"
               >
                  <CardContent>
                     {careAndHealth.loading ? <LoaderIcon /> :<CareAndHealthDisplay careAndHealth={careAndHealth.data} />}
                  </CardContent>
               </WidgetContainer>
            </Grid>
            <Grid item xs={12}>
               <WidgetContainer
                  title={`${profile.data.firstName} ${profile.data.lastName}'s Funeral & Arrangements`}
                  icon={AccountBalanceIcon}
                  color="#00a5de"
               >
                  <CardContent>
                     {funeralArrangements.loading ? <LoaderIcon /> :<FuneralArrangementsDisplay funeralArrangements={funeralArrangements.data} />}
                  </CardContent>
               </WidgetContainer>
            </Grid>

            {tombOpen && finalWishesAvailable.finalWishesAvailable ? <Fragment>
               <Grid item xs={12}>
                  <WidgetContainer
                     title="Assets"
                     icon={DirectionsCarIcon}
                     color="#00a5de"
                  >
                     {tombOpen ? <EnhancedTable
                        loading={assets.loading}
                        emptyMessage="No assets have been added."
                        data={assets.data}
                        colConfig={AssetColConfig}
                     /> : null}
                  </WidgetContainer>
               </Grid>

               <Grid item xs={12}>
                  <WidgetContainer
                     title="Financial Information"
                     icon={AttachMoneyIcon}
                     color="#00a5de"
                  >
                     {tombOpen ? <EnhancedTable
                        loading={institutions.loading}
                        emptyMessage="No finantcial info has been added."
                        data={institutions.data}
                        colConfig={InstitutionColConfig}
                     /> : null}
                  </WidgetContainer>
               </Grid>

               <Grid item xs={12}>
                  <WidgetContainer
                     title="Documents"
                     icon={FolderIcon}
                     color="#00a5de"
                  >
                     {tombOpen ? <EnhancedTable
                        loading={documents.loading}
                        emptyMessage="No documents have been uploaded."
                        data={documents.data}
                        colConfig={DocumentColConfig}
                     /> : null}
                  </WidgetContainer>
               </Grid>

               <Grid item xs={12}>
                  <WidgetContainer
                     title="Important Contacts"
                     icon={ContactsIcon}
                     color="#00a5de"
                  >
                     {tombOpen ? <EnhancedTable
                        loading={contacts.loading}
                        emptyMessage="There are no important contacts."
                        data={contacts.data}
                        colConfig={OtherContactColConfig}
                     /> : null}
                  </WidgetContainer>
               </Grid>
            </Fragment> : null}

            {/* todo: add journey, diary, etc. */}
         </Grid>
      </Box>
   );
};

export default TombPage;
