import React from 'react';
import { Route, useLocation } from 'react-router';
import Layout from './components/Layout';

import './firebase';

import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';

import MyJourneyPage from './pages/MyJourneyPage';
import MyRecordsPage from './pages/MyRecordsPage';
import AssetsPage from './pages/AssetsPage';
import ContactsPage from './pages/ContactsPage';
import InstitutionsPage from './pages/InstitutionsPage';
import DocumentsPage from './pages/DocumentsPage';
import GetStartedPage from './pages/GetStartedPage';
import GetStartedOptionalPage from './pages/GetStartedOptionalPage';
import StartSubscriptionPage from './pages/StartSubscriptionPage';
import FinalWishesPage from './pages/FinalWishesPage';
import MausoleumPage from './pages/MausoleumPage';
import MyProfilePage from './pages/MyProfilePage';
import TombPage from './pages/TombPage';
import MausoleaSearchPage from './pages/MausoleaSearchPage';
import MausoleumDetailPage from './pages/MausoleumDetailPage';
import SplashPage from './pages/SplashPage';
import './custom.css';

const App = () => {
   const location = useLocation();

   return (
      <Layout pathName={location.pathname}>
         <AuthorizeRoute exact path='/' component={MyJourneyPage} />
         <AuthorizeRoute exact path='/my-records' component={MyRecordsPage} />
         <AuthorizeRoute exact path='/final-wishes' component={FinalWishesPage} />
         <AuthorizeRoute exact path='/mausoleum' component={MausoleumPage} />
         <AuthorizeRoute exact path='/tomb/:trustorId?' component={TombPage} />
         <AuthorizeRoute exact path='/get-started' component={GetStartedPage} />
         <AuthorizeRoute exact path='/get-started-optional' component={GetStartedOptionalPage} />
         <AuthorizeRoute exact path='/start-subscription' component={StartSubscriptionPage} />
         <AuthorizeRoute path='/assets/:assetTypeId?' component={AssetsPage} />
         <AuthorizeRoute path='/contacts' component={ContactsPage} />
         <AuthorizeRoute path='/institutions/:institutionTypeId?' component={InstitutionsPage} />
         <AuthorizeRoute path='/documents/:documentTypeId?' component={DocumentsPage} />
         <AuthorizeRoute exact path='/my-profile' component={MyProfilePage} />
         <AuthorizeRoute exact path='/mausolea-search' component={MausoleaSearchPage} />
         <AuthorizeRoute exact path='/mausoleum-detail/:mausoleumId?' component={MausoleumDetailPage} />
         <Route exact path='/splash' component={SplashPage} />
         <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
      </Layout>
   );
};

export default App;
