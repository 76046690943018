import axiosService from './AxiosService';

export class FinalWishesService {
   _resourceName = 'final-wishes';

   async getMyFuneralArrangements() {
      return await axiosService.readObject(`/v1.0/${this._resourceName}/funeral-arrangements`);
   }

   async putMyFuneralArrangements(funeralArrangements) {
      return await axiosService.update(`/v1.0/${this._resourceName}/funeral-arrangements`, funeralArrangements);
   }

   async getMyCareAndHealth() {
      return await axiosService.readObject(`/v1.0/${this._resourceName}/care-and-health`);
   }

   async putMyCareAndHealth(careAndHealth) {
      return await axiosService.update(`/v1.0/${this._resourceName}/care-and-health`, careAndHealth);
   }

   async getMyPetWishes() {
      return await axiosService.readList(`/v1.0/${this._resourceName}/pet-wish`);
   }

   async postPetWish(petWish) {
      return await axiosService.create(`/v1.0/${this._resourceName}/pet-wish`, petWish);
   }

   async patchPetWish(petWish) {
      return await axiosService.update(`/v1.0/${this._resourceName}/pet-wish`, petWish);
   }

   async deletePetWish(id) {
      return await axiosService.delete(`/v1.0/${this._resourceName}/pet-wish`, id);
   }

   static get instance() { return finalWishesService }
}

const finalWishesService = new FinalWishesService();

export default finalWishesService;
