import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import format from 'date-fns/format';
import Typography from '@mui/material/Typography';
import TextDisplay from '../components/TextDisplay';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { Stack } from '@mui/material';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import LTIconButton from './LTIconButton';
import CardMedia from '@mui/material/CardMedia';

const MausoleumHeader = (props) => {
   return (<Fragment>
      <Box sx={{ position: 'relative' }}>
         {props.mausoleum.imageBase64 ? <CardContent
            sx={{
               height: '480px',
               backgroundImage: `url(${props.mausoleum.imageBase64})`,
               backgroundRepeat: 'no-repeat',
               backgroundSize: '100%',
            }}
         /> : <CardMedia
            component='img'
            height='480'
            image={props.mausoleum.imagePath}
            alt='Mausoleum Banner'
         />}

         {(props.onFavoriteToggle || props.onEditImage) ? <CardContent
            sx={{
               position: 'absolute',
               display: 'flex',
               justifyContent: 'space-between',
               width: '100%',
               bottom: 0,
            }}
         >
            <Stack>
               <Box sx={{
                  color: '#fff',
                  textShadow: '2px 2px 3px #000',
               }}>
                  {props.mausoleum.owner ? <Fragment>
                     <Box sx={{ display: 'inline-block' }}>
                        <Stack>
                           <Avatar
                              alt={`${props.mausoleum.owner.firstName} ${props.mausoleum.owner.lastName}`}
                              src={props.mausoleum.owner.avatarBase64}
                              sx={{ width: 180, height: 180, border: '3px solid #fff' }}
                           />
                           {props.mausoleum.isVerified ? <Box sx={{ position: 'absolute', left: 148, top: 148 }}>
                              <Tooltip title='Verified'>
                                 <CheckCircleOutlineIcon sx={{ color: '#fff' }} />
                              </Tooltip>
                           </Box> : null}
                        </Stack>
                     </Box>

                     <Typography variant='h2' component='h2'>
                        {props.mausoleum.owner.firstName}
                        {props.mausoleum.middleName ? ` ${props.mausoleum.middleName} ` : ' '}
                        {props.mausoleum.owner.lastName}
                        {props.mausoleum.maidenName ? ` nee ${props.mausoleum.maidenName}` : ''}
                     </Typography>
                  </Fragment> : null}
               </Box>
               <Box sx={{
                  color: '#fff',
                  textShadow: '2px 2px 3px #000',
               }}>
                  <Typography variant='body1' component='p' sx={{ fontSize: '1.6em' }}>
                     {props.mausoleum.dateOfDeath ? `${format(props.mausoleum.dateOfBirth, 'P')} - ` : ''}{(props.mausoleum.dateOfDeath ? format(props.mausoleum.dateOfDeath, 'P') : 'Alive')}
                  </Typography>
                  <Typography variant='body1' component='p' sx={{ fontSize: '1.6em', fontStyle: 'italic' }}>
                     <TextDisplay
                        text={props.mausoleum.epitaph}
                        maxChars={90}
                     />
                  </Typography>
               </Box>
            </Stack>

            <Stack
               direction='row'
               spacing={2}
               sx={{ mt: 'auto' }}
            >
               {/* public mausoleum actions */}
               {props.onFavoriteToggle ? <LTIconButton
                  disabled={props.loading}
                  tooltipText={`${props.mausoleum.isFavorite ? 'Unfavorite': 'Favorite'} Mausoleum`}
                  ariaLabel='toggle favorite mausoleum'
                  onClick={props.onFavoriteToggle}
                  icon={props.mausoleum.isFavorite ? <StarIcon /> : <StarBorderIcon />}
                  sx={{ display: 'flex', ml: 'auto' }}
               /> : null}

               {/* mausoleum editor actions */}
               {props.onPickImage && !props.allowEditBanner ?  <LTIconButton
                  disabled={props.loading}
                  tooltipText='Change Image'
                  ariaLabel='change mausoleum image'
                  onClick={props.onPickImage}
                  icon={<PhotoLibraryIcon />}
               /> : null}

               {props.onEditImage && props.allowEditBanner ? <LTIconButton
                  disabled={props.loading}
                  tooltipText='Upload Image'
                  ariaLabel='upload mausoleum image'
                  onClick={props.onEditImage}
                  icon={<AddAPhotoIcon />}
               /> : null}

               {props.onMausoleumEdit ? <LTIconButton
                  disabled={props.loading}
                  tooltipText='Edit Details'
                  ariaLabel='edit mausoleum details'
                  onClick={props.onMausoleumEdit}
                  icon={<ModeEditOutlineOutlinedIcon />}
               /> : null}
            </Stack>
         </CardContent> : null}
      </Box>
   </Fragment>);
};

MausoleumHeader.propTypes = {
   loading: PropTypes.bool.isRequired,
   mausoleum: PropTypes.object.isRequired,
   onFavoriteToggle: PropTypes.func,
   onPickImage: PropTypes.func,
   onEditImage: PropTypes.func,
   allowEditBanner: PropTypes.bool.isRequired,
   onMausoleumEdit: PropTypes.func,
};

export default MausoleumHeader;
