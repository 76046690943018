import React, { useState, Fragment, useRef, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import AddressFormFields from './AddressFormFields';
import LoaderIcon from './LoaderIcon';
import PhotoUploader from './PhotoUploader';
import { Alert } from '@mui/material';
import TextAreaWithLimit from './TextAreaWithLimit';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';

// todo: I like this pattern of exporting this so that the parent can init via setState, need to add this to the others
export const initialState = {
   id: null,
   identifier: '',
   make: '',
   model: '',
   year: new Date().getFullYear(),
   description: ''
};

const AssetDialog = forwardRef((props, ref) => {
   const addressFormRef = useRef();
   const photoUploaderRef = useRef();

   const [formValues, setFormValues] = useState(initialState);
   const [activeAssetType, setActiveAssetType] = useState('select-asset-type');

   // control validation, state from parent component
   useImperativeHandle(ref, () => ({
      setState(state) {
         setFormValues(state);

         if (addressFormRef.current)
            addressFormRef.current.setState(state.location);

         setActiveAssetType((state && state.assetType && state.assetType.id) || 'select-asset-type');
      }
   }));

   // validation
   const [formValidation, setFormValidation] = useState({});
   const formIsValid = () => {
      let errors = {};

      // validate address
      let addressValid = false;
      if (addressFormRef.current)
         addressValid = addressFormRef.current.validate();

      // validate photos to be uploaded
      let photosValid = true;
      if(photoUploaderRef.current)
         photosValid = photoUploaderRef.current.validate();

      setFormValidation(errors);

      return Object.keys(errors).length === 0 && addressValid && photosValid;
   };

   const cleanData = () => {
      let selectedPhoto;
      if(photoUploaderRef.current)
         selectedPhoto = photoUploaderRef.current.getPhoto();

      return {
         ...formValues,
         identifier: formValues.identifier ? formValues.identifier : null,
         make: formValues.make ? formValues.make : null,
         model: formValues.model ? formValues.model : null,
         description: formValues.description ? formValues.description : null,
         assetType: props.assetTypes.find(element => element.id === activeAssetType),
         fileName: selectedPhoto && selectedPhoto.fileName,
         base64PhotoFullSize: selectedPhoto && selectedPhoto.base64PhotoFullSize,
         base64PhotoScaled: selectedPhoto && selectedPhoto.base64PhotoScaled,
         x: selectedPhoto && selectedPhoto.x,
         y: selectedPhoto && selectedPhoto.y,
         mx: selectedPhoto && selectedPhoto.mx || 0,
         my: selectedPhoto && selectedPhoto.my || 0,
         scale: selectedPhoto && selectedPhoto.scale,
         rotate: selectedPhoto && selectedPhoto.rotate,
         width: selectedPhoto && selectedPhoto.width,
         height: selectedPhoto && selectedPhoto.height,
         location: addressFormRef.current ? addressFormRef.current.getState() : null,
      };
   };

   const handleAddAssetButtonClick = () => {
      if (formIsValid()) {
         props.handleAddSaveAsset(cleanData());

         if(photoUploaderRef.current)
            photoUploaderRef.current.reset();

         setFormValues({ ...initialState });
         // todo: make sure address re-init works - can likely use new setState once I add edit capability to this
         setActiveAssetType('select-asset-type');
      }
   };

   const standardFieldLabels = {
      identifier: 'Identifier',
      make: 'Make',
      model: 'Model',
      year: 'Year',
      location: 'Where is this item located?',
      description: 'Description',
   };

   // customization per asset type / default overrides
   let fieldLabelsByType = {};
   fieldLabelsByType['property'] = {
      ...standardFieldLabels,
      identifier: 'Name/Identifier',
      make: undefined,
      model: undefined,
      year: 'Year Purchased',
      location: 'Where is this property located?',
   };
   fieldLabelsByType['vehicle-auto'] = {
      ...standardFieldLabels,
      identifier: 'VIN - Vehicle Identification Number',
      location: 'Where is this vehicle parked?',
   };
   fieldLabelsByType['vehicle-motorcycle'] = {
      ...standardFieldLabels,
      identifier: 'VIN - Vehicle Identification Number',
      location: 'Where is this vehicle parked?',
   };
   fieldLabelsByType['vehicle-watercraft'] = {
      ...standardFieldLabels,
      identifier: 'HIN - Hull Identification Number',
      location: 'Where is this watercraft docked/stored?',
   };
   fieldLabelsByType['vehicle-rv'] = {
      ...standardFieldLabels,
      identifier: 'VIN - Vehicle Identification Number',
      location: 'Where is this vehicle parked?',
   };
   fieldLabelsByType['art'] = {
      ...standardFieldLabels,
      make: 'Artist',
      model: undefined,
      year: 'Year Acquired',
      location: 'Where is this artwork located?',
   };
   fieldLabelsByType['equipment'] = {
      ...standardFieldLabels,
      identifier: 'Serial Number',
   };
   fieldLabelsByType['furniture'] = {
      ...standardFieldLabels,
      identifier: 'Name/Identifier',
      make: 'Make/Manufacturer',
   };
   fieldLabelsByType['heirloom'] = {
      ...standardFieldLabels,
      identifier: 'Name/Identifier',
      make: 'Manufacturer',
   };
   fieldLabelsByType['firearm'] = {
      ...standardFieldLabels,
      identifier: 'Serial Number',
      make: 'Make/Manufacturer',
   };
   fieldLabelsByType['other'] = {...standardFieldLabels};

   return (
      <Dialog open={props.open} onClose={props.handleClose}>
         <DialogTitle>{(formValues.id ? 'Edit' : 'Add')} Asset</DialogTitle>
         <DialogContent>
            {!props.loading ? <Box component="form">
               <Grid container spacing={1}>
                  <Grid item xs={12}>
                     <DialogContentText>
                        <b>What asset would you like to add?</b>
                     </DialogContentText>
                  </Grid>

                  <Grid item xs={12}>
                     <FormControl fullWidth>
                        <InputLabel id="asset-type-select-label">Type*</InputLabel>
                        <Select
                           autoFocus
                           labelId="asset-type-select-label"
                           id="asset-type-select"
                           label="Type*"
                           value={activeAssetType}
                           onChange={event => setActiveAssetType(event.target.value)}
                        >
                           <MenuItem value="select-asset-type">--- Select Asset Type ---</MenuItem>
                           {props.assetTypes.map(type => (<MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>))}
                        </Select>
                     </FormControl>
                  </Grid>

                  {props.allowPhotoUpload && activeAssetType !== 'select-asset-type' ? <Fragment>
                     <Grid item xs={12}>
                        <p>Optionally add a photo of your asset.</p>
                     </Grid>

                     {formValidation.assetPhoto ? <Grid item xs={12}><Alert severity="error">{formValidation.assetPhoto}</Alert></Grid> : null}

                     <Grid item xs={12}>
                        <PhotoUploader
                           ref={photoUploaderRef}
                           width={420}
                           height={420}
                           buttonText="Select Photo"
                        />
                     </Grid>
                  </Fragment> : null}

                  {fieldLabelsByType[activeAssetType] && fieldLabelsByType[activeAssetType].make ? <Grid item md={6}>
                     <FormControl fullWidth>
                        <TextField
                           margin="dense"
                           id="make-input"
                           label={fieldLabelsByType[activeAssetType].make}
                           type="text"
                           value={formValues.make}
                           onChange={event => setFormValues({ ...formValues, make: event.target.value })}
                           fullWidth
                        />
                     </FormControl>
                  </Grid> : null}
                  {fieldLabelsByType[activeAssetType] && fieldLabelsByType[activeAssetType].model ? <Grid item md={6}>
                     <FormControl fullWidth>
                        <TextField
                           margin="dense"
                           id="model-input"
                           label={fieldLabelsByType[activeAssetType].model}
                           type="text"
                           value={formValues.model}
                           onChange={event => setFormValues({ ...formValues, model: event.target.value })}
                           fullWidth
                        />
                     </FormControl>
                  </Grid> : null }
                  {fieldLabelsByType[activeAssetType] && fieldLabelsByType[activeAssetType].identifier ? <Grid item md={9}>
                     <FormControl fullWidth>
                        <TextField
                           margin="dense"
                           id="identifier-input"
                           label={fieldLabelsByType[activeAssetType].identifier}
                           type="text"
                           value={formValues.identifier}
                           onChange={event => setFormValues({ ...formValues, identifier: event.target.value })}
                           fullWidth
                        />
                     </FormControl>
                  </Grid> : null}
                  {fieldLabelsByType[activeAssetType] && fieldLabelsByType[activeAssetType].year ? <Grid item md={3}>
                     <FormControl fullWidth>
                        <TextField
                           margin="dense"
                           id="year-input"
                           label={fieldLabelsByType[activeAssetType].year}
                           type="number"
                           value={formValues.year}
                           onChange={event => setFormValues({ ...formValues, year: event.target.value })}
                           fullWidth
                        />
                     </FormControl>
                  </Grid> : null}

                  <AddressFormFields
                     ref={addressFormRef}
                     sx={{ display: (fieldLabelsByType[activeAssetType] && fieldLabelsByType[activeAssetType].location) ? 'inline' : 'none' }}
                     label={fieldLabelsByType[activeAssetType] ? fieldLabelsByType[activeAssetType].location : 'Location'}
                     addressStatesProvinces={props.addressStatesProvinces}
                     isRequired={true}
                     showSavedAddresses={true}
                  />

                  {fieldLabelsByType[activeAssetType] && fieldLabelsByType[activeAssetType].description ? <Grid item xs={12}>
                     <FormControl fullWidth>
                        <TextAreaWithLimit
                           id="description-input"
                           label={fieldLabelsByType[activeAssetType].description}
                           maxLength={255}
                           value={formValues.description}
                           onChange={event => setFormValues({ ...formValues, description: event.target.value })}
                           placeholder="Describe your asset..."
                           rows={3}
                        />
                     </FormControl>
                  </Grid> : null}
               </Grid>
            </Box> : <LoaderIcon />}
         </DialogContent>
         <DialogActions>
            <Button variant="outlined" startIcon={<CancelIcon />} onClick={props.handleClose}>Cancel</Button>
            <Button variant="contained" startIcon={<CheckIcon />} disabled={activeAssetType === 'select-asset-type'} onClick={handleAddAssetButtonClick}>{(formValues.id ? 'Save' : 'Add')} Asset</Button>
         </DialogActions>
      </Dialog>
   );
});

AssetDialog.propTypes = {
   loading: PropTypes.bool.isRequired,
   open: PropTypes.bool.isRequired,
   handleClose: PropTypes.func.isRequired,
   handleAddSaveAsset: PropTypes.func.isRequired,
   assetTypes: PropTypes.array.isRequired,
   addressStatesProvinces: PropTypes.array.isRequired,
   allowPhotoUpload: PropTypes.bool.isRequired,
};

export default AssetDialog;
