import axiosService from './AxiosService';

export class SubscriptionService {
   _resourceName = 'subscription';

   async postCheckoutSession(subscriptionTierId, callBackKey) {
      return await axiosService.create(`/v1.0/${this._resourceName}/create-checkout-session/${subscriptionTierId}/${callBackKey}`);
   }

   async postPortalSession() {
      return await axiosService.create(`/v1.0/${this._resourceName}/create-portal-session`);
   }

   async putChangeSubscription(subscriptionTierId) {
      return await axiosService.update(`/v1.0/${this._resourceName}/change-subscription`, { id: subscriptionTierId });
   }

   async deleteCancelSubscription() {
      return await axiosService.delete(`/v1.0/${this._resourceName}`, 'cancel-subscription');
   }

   async getCurrentStatus() {
      return await axiosService.readObject(`/v1.0/${this._resourceName}/current-status`);
   }

   static get instance() { return subscriptionService }
}

const subscriptionService = new SubscriptionService();

export default subscriptionService;
