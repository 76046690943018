import React, { useState, useEffect, useRef, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import profileService from '../data/ProfileService';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import JourneyFeed from '../components/JourneyFeed';
import JourneyPhotoDialog from '../components/JourneyPhotoDialog';
import journeyPhotoService from '../data/JourneyPhotoService';
import journeyFeedService from '../data/JourneyFeedService';
import journeyMonthService from '../data/JourneyMonthService';
import DiaryWidgetBody from '../components/DiaryWidgetBody';
import DiaryEntryDialog from '../components/DiaryEntryDialog';
import diaryEntryService from '../data/DiaryEntryService';
import parseISO from 'date-fns/parseISO';
import BookIcon from '@mui/icons-material/Book';
import LoaderIcon from '../components/LoaderIcon';
import JourneyMonthDialog from '../components/JourneyMonthDialog';
import PubSub from 'pubsub-js';
import JourneyMonthSummaryDialog from '../components/JourneyMonthSummaryDialog';
import WidgetContainer from '../components/WidgetContainer';
import { useQuery } from '../utils/useQuery';
import RsvpIcon from '@mui/icons-material/Rsvp';
import CardContent from '@mui/material/CardContent';
import { Button, Skeleton } from '@mui/material';
import InviteDialog from '../components/InviteDialog';
import platformInviteService from '../data/PlatformInviteService';
import Snackbar from '@mui/material/Snackbar';
import resourceUsageService from '../data/ResourceUsageService';
import subscriptionService from '../data/SubscriptionService';

const MyJourneyPage = () => {
   const diaryEntryDialogRef = useRef();
   const journeyPhotoDialogRef = useRef();
   const journeyMonthSummaryDialogRef = useRef();
   const query = useQuery();

   const [loading, setLoading] = useState(true);
   const [feedEntries, setFeedEntries] = useState({ data: [], limits: {}, loading: false });
   const [diaryEntries, setDiaryEntries] = useState({ data: [], loading: false });
   const [resourceUsage, setResourceUsage] = useState({ data: {}, loading: false });
   const [currentSubscriptionStatus, setCurrentSubscriptionStatus] = useState({ data: {}, loading: false });
   const [forMonth, setForMonth] = useState(new Date());

   const journeyYear = Number.parseInt(query.get('journeyYear')) || new Date().getFullYear();
   let diaryYear = Number.parseInt(query.get('diaryYear')) || new Date().getFullYear();

   const buildQueryString = (journeyYear, diaryYear) => (`?journeyYear=${journeyYear}&diaryYear=${diaryYear}`);

   useEffect(() => {
      checkProfileExists();
   }, []);

   const history = useHistory();

   const checkProfileExists = async () => {
      setLoading(true);
      const profileExists = await profileService.getProfileExists();

      if (profileExists) {
         setLoading(false);
         getJourneyFeed(journeyYear);
         getDiaryEntries(diaryYear);
         getResourceUsage();
         getCurrentSubscriptionStatus();
      } else {
         // go to "get started wizard"
         history.push('/get-started');
      }
   };

   const handleSaveJourneyPhoto = async (journeyPhoto) => {
      setFeedEntries({ data: feedEntries.data, limits: feedEntries.limits, loading: true });
      await journeyPhotoService.putJourneyPhoto(journeyPhoto);
      setJourneyPhotoDialogOpen(false);

      getJourneyFeed();
   };

   const handleAddJourneyPhotos = async (journeyMonthId, photos) => {
      setFeedEntries({ data: feedEntries.data, limits: feedEntries.limits, loading: true });
      setJourneyPhotoDialogOpen(false);

      photos.forEach(async photo => {
         PubSub.publish('nonBlockingLoader.addMessage', { id: photo.fileName, text: `Uploading ${photo.fileName}...` });
         await journeyPhotoService.postJourneyPhoto({...photo, journeyMonthId: journeyMonthId});
         PubSub.publish('nonBlockingLoader.removeMessage', photo.fileName);
         getJourneyFeed();
      });
   };

   // journey month & photos
   const addJourneyMonth = async (month, photos) => {
      setFeedEntries({ data: feedEntries.data, limits: feedEntries.limits, loading: true });

      const journeyMonth = await journeyMonthService.addJourneyMonth(month);
      const year = Number.parseInt(new Date(journeyMonth.forMonth).getFullYear());
      getJourneyFeed(year);
      setJourneyMonthDialogOpen(false);

      photos.forEach(async photo => {
         PubSub.publish('nonBlockingLoader.addMessage', { id: photo.fileName, text: `Uploading ${photo.fileName}...` });
         await journeyPhotoService.postJourneyPhoto({...photo, journeyMonthId: journeyMonth.id});
         PubSub.publish('nonBlockingLoader.removeMessage', photo.fileName);

         // todo: see if we can reload only that month (or even do optimistic UI only update if we get success back)
         getJourneyFeed(year);
      });
   };

   const deleteJourneyPhoto = async (id) => {
      setJourneyPhotoDialogOpen(false)
      setFeedEntries({ ...feedEntries, loading: true });
      await journeyPhotoService.deleteJourneyPhoto(id);
      getJourneyFeed();
   };

   const handleToggleShowInMausoleum = async(id, i, j) => {
      var newFeedEntries = {...feedEntries};
      newFeedEntries.data[i].journeyPhotos[j].loading = true;
      setFeedEntries({ ...newFeedEntries });

      var journeyPhotoResponse = await journeyPhotoService.toggleShowInMausoleum(id);

      newFeedEntries = {...feedEntries};
      newFeedEntries.data[i].journeyPhotos[j] = cleanJourneyPhoto(journeyPhotoResponse.data)
      newFeedEntries.data[i].journeyPhotos[j].loading = false;
      setFeedEntries({ ...newFeedEntries });

      getResourceUsage();
   };

   const getJourneyFeed = async (year) => {
      year = year || journeyYear;
      var date = new Date();
      date.setFullYear(year);
      setForMonth(date);
      history.push(buildQueryString(year, diaryYear));
      setFeedEntries({ data: [], limits: {}, loading: true });

      const feedEntries = await journeyFeedService.getJourneyFeed(year, null);

      setFeedEntries({
         data: feedEntries.map(entry => ({
            ...entry,
            forMonth: parseISO(entry.forMonth),
            journeyPhotos: entry.journeyPhotos.data.map(cleanJourneyPhoto)
         })),
         limits: feedEntries.length ? feedEntries[0].journeyPhotos.limits : {},
         loading: false
      });
   };

   const cleanJourneyPhoto = (journeyPhoto) => ({
      ...journeyPhoto,
      forDate: parseISO(journeyPhoto.forDate),
      loading: false,
   });

   const onUpdateFilter = (year) => {
      var date = new Date();
      date.setFullYear(year);
      setForMonth(date);
      getJourneyFeed(year);
   };

   const onUpdateDiaryFilter = (year) => {
      getDiaryEntries(year);
   };

   const getResourceUsage = async () => {
      setResourceUsage({ data: {}, loading: true });
      const resourceUsage = await resourceUsageService.getResourceUsage();
      setResourceUsage({ data: resourceUsage, loading: false });
   };

   // diary entries
   const [diaryEntryDialogState, setDiaryEntryDialogState] = useState({
      open: false,
      id: null,
   });

   const getDiaryEntries = async (year) => {
      year = year || diaryYear;
      history.push(buildQueryString(journeyYear, year));
      setDiaryEntries({ data: [], loading: true });
      const diaryEntries = await diaryEntryService.getDiaryEntries(year);
      setDiaryEntries({ data: diaryEntries.map(entry => ({...entry, forDate: parseISO(entry.forDate) })), loading: false });
   };

   const addSaveDiaryEntry = async (entry) => {
      setDiaryEntries({ data: diaryEntries.data, loading: true });
      var year = Number.parseInt(new Date(entry.forDate).getFullYear());
      if(entry.id)
         await diaryEntryService.putDiaryEntry(entry);
      else
         await diaryEntryService.postDiaryEntry(entry);

      getDiaryEntries(year);
      setDiaryEntryDialogState({...diaryEntryDialogState, open: false});
   };

   const handleAddDiaryEntry = () => {
      if (diaryEntryDialogRef.current)
         diaryEntryDialogRef.current.setState({
            forDate: new Date(),
            title: '',
            text: '',
         });

      setDiaryEntryDialogState({
         open: true,
         id: null,
      });
   };

   const handleEditDiaryEntry = (id) => {
      const currentEntry = diaryEntries.data.find(element => element.id === id)

      if (diaryEntryDialogRef.current)
         diaryEntryDialogRef.current.setState({
            forDate: currentEntry.forDate,
            title: currentEntry.title,
            text: currentEntry.text,
         });

      setDiaryEntryDialogState({
         open: true,
         id: id,
      });
   };

   const deleteDiaryEntry = async (id) => {
      setDiaryEntries({ ...diaryEntries, loading: true });
      await diaryEntryService.deleteDiaryEntry(id);
      getDiaryEntries();
   };

   // current subscription status
   const getCurrentSubscriptionStatus = async () => {
      setCurrentSubscriptionStatus({data: {}, loading: true});
      const subscriptionStatus = await subscriptionService.getCurrentStatus();
      setCurrentSubscriptionStatus({data: subscriptionStatus, loading: false});
   };

   // dialog states
   const [journeyPhotoDialogOpen, setJourneyPhotoDialogOpen] = useState(false);
   const [journeyMonthDialogOpen, setJourneyMonthDialogOpen] = useState(false);
   const [journeyMonthSummaryDialogOpen, setJourneyMonthSummaryDialogOpen] = useState(false);

   const handleOpenJourneyPhotoDialog = (feedEntry) => {
      setJourneyPhotoDialogOpen(true);

      if (journeyPhotoDialogRef.current)
         journeyPhotoDialogRef.current.setState({
            id: null,
            forMonth: feedEntry.forMonth,
            journeyMonthId: feedEntry.id,
            showInMausoleum: false,
            caption: '',
            photosInMonth: feedEntry.journeyPhotos.length,
         });
   };

   const handleEditJourneyPhoto = (journeyPhoto) => {
      setJourneyPhotoDialogOpen(true);

      if (journeyPhotoDialogRef.current)
         journeyPhotoDialogRef.current.setState({
            id: journeyPhoto.id,
            base64PhotoScaled: journeyPhoto.base64PhotoScaled,
            forMonth: null,
            journeyMonthId: null,
            showInMausoleum: journeyPhoto.showInMausoleum || false,
            caption: journeyPhoto.caption || '',
            photosInMonth: 0,
         });
   };

   const handleEditMonthSummary = (journeyMonth) => {
      setJourneyMonthSummaryDialogOpen(true);

      if (journeyMonthSummaryDialogRef.current)
         journeyMonthSummaryDialogRef.current.setState({
            id: journeyMonth.id,
            forMonth: journeyMonth.forMonth,
            summary: journeyMonth.summary,
         });
   };

   const handleDeleteJourneyMonth = async (id) => {
      setFeedEntries({ data: feedEntries.data, limits: feedEntries.limits, loading: true });
      await journeyMonthService.deleteJourneyMonth(id);
      checkProfileExists();
   };

   const handleSaveJourneyMonth = async (journeyMonth) => {
      setFeedEntries({ data: feedEntries.data, limits: feedEntries.limits, loading: true });
      await journeyMonthService.putJourneyMonth(journeyMonth);
      getJourneyFeed();
      setJourneyMonthSummaryDialogOpen(false);
   };

   const [platformInviteLoading, setPlatformInviteLoading] = useState(false);
   const [platformInviteDialogOpen, setPlatformInviteDialogOpen] = useState(false);
   const [platformInviteSnackbarOpen, setPlatformInviteSnackbarOpen] = React.useState(false);
   const handlePlatformInviteSnackbarClose = (event, reason) => {
      if (reason === 'clickaway')
         return;
  
         setPlatformInviteSnackbarOpen(false);
    };

   const addPlatformInvite = async (invite) => {
      setPlatformInviteLoading(true);
      // todo: handle inviting same email multiple times (there's already an improved response coming back from the API)
      await platformInviteService.postInvite(invite);
      setPlatformInviteSnackbarOpen(true);
      setPlatformInviteDialogOpen(false);
      setPlatformInviteLoading(false);
   };

   if (loading)
      return (<LoaderIcon />);

   return (
      <Fragment>
         <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={1}>
               <Grid item xs={12} md={8}>
                  {
                     feedEntries.data ? 
                     <JourneyFeed
                        loading={feedEntries.loading}
                        records={feedEntries.data}
                        handleAddJourneyPhoto={handleOpenJourneyPhotoDialog}
                        handleAddJourneyMonth={() => setJourneyMonthDialogOpen(true)}
                        handleEditMonthSummary={handleEditMonthSummary}
                        handleDeleteJourneyMonth={handleDeleteJourneyMonth}
                        handleEditJourneyPhoto={handleEditJourneyPhoto}
                        handleDeleteJourneyPhoto={(id) => deleteJourneyPhoto(id)}
                        onToggleShowInMausoleum={handleToggleShowInMausoleum}
                        onUpdateFilter={onUpdateFilter}
                        resourceLimits={feedEntries.limits}
                        year={journeyYear}
                        resourceUsage={resourceUsage.data}
                        currentSubscriptionTier={currentSubscriptionStatus.data}
                     /> : 
                     <Skeleton variant='rectangular' height={'100%'} />
                  }
                  
               </Grid>
               <Grid item xs={12} md={4}>
                  <WidgetContainer
                     title="My Diary"
                     icon={BookIcon}
                     color="#00a5de"
                  >
                     {diaryEntries.data ? 
                     <DiaryWidgetBody
                        loading={diaryEntries.loading}
                        diaryEntries={diaryEntries.data}
                        handleAddDiaryEntry={handleAddDiaryEntry}
                        handleEditDiaryEntry={handleEditDiaryEntry}
                        handleDeleteDiaryEntry={deleteDiaryEntry}
                        onUpdateFilter={onUpdateDiaryFilter}
                        year={diaryYear}
                     /> : 
                     <Skeleton variant='rectangular' height={'100%'} />
                     }
                     
                  </WidgetContainer>
               </Grid>
               <Grid item xs={12}>
                  <WidgetContainer
                     title="Invite Somebody to LifeTomb!"
                     icon={RsvpIcon}
                     color="#00a5de"
                  >
                     <CardContent>
                        <Alert severity="info">LifeTomb is brand new and we'd love to have your help spreading the word! Inviting users helps grow the platform which in turn lets us build new features for you.</Alert>
                     </CardContent>
                     <CardContent>
                        <Button disabled={platformInviteLoading} onClick={() => setPlatformInviteDialogOpen(true)}>Invite a Friend!</Button>
                     </CardContent>
                  </WidgetContainer>
               </Grid>
            </Grid>
         </Box>

         <JourneyPhotoDialog
            ref={journeyPhotoDialogRef}
            loading={feedEntries.loading}
            open={journeyPhotoDialogOpen}
            handleClose={() => setJourneyPhotoDialogOpen(false)}
            handleAddJourneyPhotos={handleAddJourneyPhotos}
            handleSaveJourneyPhoto={handleSaveJourneyPhoto}
            handleDeleteJourneyPhoto={(id) => deleteJourneyPhoto(id)}
            resourceLimits={feedEntries.limits}
         />

         <JourneyMonthDialog
            forMonth={forMonth}
            // forMonth={new Date().setFullYear(journeyYear)}
            loading={feedEntries.loading}
            open={journeyMonthDialogOpen}
            handleClose={() => setJourneyMonthDialogOpen(false)}
            handleAddJourneyMonth={addJourneyMonth}
            resourceLimits={feedEntries.limits}
         />

         <JourneyMonthSummaryDialog
            ref={journeyMonthSummaryDialogRef}
            loading={feedEntries.loading}
            open={journeyMonthSummaryDialogOpen}
            handleClose={() => setJourneyMonthSummaryDialogOpen(false)}
            handleSaveJourneyMonth={handleSaveJourneyMonth}
         />

         <DiaryEntryDialog
            ref={diaryEntryDialogRef}
            id={diaryEntryDialogState.id}
            open={diaryEntryDialogState.open}
            handleClose={() => setDiaryEntryDialogState({...diaryEntryDialogState, open: false})}
            handleAddSaveDiaryEntry={(entry) => addSaveDiaryEntry(entry)}
         />

         <InviteDialog
            dialogTitle="Invite a Friend to LifeTomb!"
            open={platformInviteDialogOpen}
            handleClose={() => setPlatformInviteDialogOpen(false)}
            handleAddInvite={addPlatformInvite}
         />
         <Snackbar open={platformInviteSnackbarOpen} autoHideDuration={4000} onClose={handlePlatformInviteSnackbarClose}>
            <Alert onClose={handlePlatformInviteSnackbarClose} severity="success" sx={{ width: '100%' }}>
               Invitation sent. Thanks!
            </Alert>
         </Snackbar>
      </Fragment>
   );
};

export default MyJourneyPage;
