import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import EmailIcon from '@mui/icons-material/Email';
import CancelIcon from '@mui/icons-material/Cancel';

const InviteDialog = (props) => {
   const initialState = {
      email: ''
   };
   const [formValues, setFormValues] = useState(initialState);

   // validation
   const [formValidation, setFormValidation] = useState({});
   const formIsValid = () => {
      let errors = {};

      if (!formValues.email || formValues.email.indexOf('@') < 1 || formValues.email.indexOf('.') < 0)
         errors.email = 'Email invalid.';

      setFormValidation(errors);

      return Object.keys(errors).length === 0;
   };

   const handleAddInviteButtonClick = () => {
      if (formIsValid()) {
         props.handleAddInvite(formValues);
         setFormValues({ ...initialState });
      }
   };

   return (
      <Dialog open={props.open} onClose={props.handleClose}>
         <DialogTitle>{props.dialogTitle}</DialogTitle>
         <DialogContent>
            <Box component="form">
               <Grid container spacing={1}>
                  <Grid item xs={12}>
                     <DialogContentText>
                        <b>Who would you like to invite?</b>
                     </DialogContentText>
                  </Grid>

                  <Grid item md={12}>
                     <FormControl fullWidth>
                        <TextField
                           autoFocus
                           margin="dense"
                           id="email-input"
                           label="Email"
                           type="email"
                           value={formValues.email}
                           onChange={event => setFormValues({ ...formValues, email: event.target.value })}
                           error={formValidation.email ? true : false}
                           helperText={formValidation.email}
                           fullWidth
                        />
                     </FormControl>
                  </Grid>
               </Grid>
            </Box>
         </DialogContent>
         <DialogActions>
            <Button variant="outlined" startIcon={<CancelIcon />} onClick={props.handleClose}>Cancel</Button>
            <Button variant="contained" startIcon={<EmailIcon />} onClick={handleAddInviteButtonClick}>Invite</Button>
         </DialogActions>
      </Dialog>
   );
};

InviteDialog.propTypes = {
   dialogTitle: PropTypes.string.isRequired,
   open: PropTypes.bool.isRequired,
   handleClose: PropTypes.func.isRequired,
   handleAddInvite: PropTypes.func.isRequired,
};

export default InviteDialog;
