import PropTypes from 'prop-types';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TextField } from '@mui/material';
import { useState } from 'react';

const DateField = (props) => {
   const [errorMessage, setErrorMessage] = useState(props.error);

   const setFormattedDate = (date) => {
      if(date) {
         let formattedDate =  date.getFullYear() + '-';
         if((date.getMonth() + 1).toString().length === 1)
            formattedDate += '0' + (date.getMonth() + 1) + '-';
         else
            formattedDate += (date.getMonth() + 1) + '-';

         if(date.getDate().toString().length === 1)
            formattedDate += '0' + (date.getDate());
         else
            formattedDate += (date.getDate());

         const date_regex = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/gm;
         if (!(date_regex.test(formattedDate))) {
            setErrorMessage("Date format: 'mm/dd/yyyy'");
            return;
         }
      }
      setErrorMessage();
      props.onChange(date);
   };

   const getRenderInput = (styling) => {
      switch (styling) {
         case 'small':
            return (params) => <TextField {...params} sx={{ ml: 1 }} size="small" error={props.error || errorMessage ? true : false} helperText={errorMessage || props.error}/>
         default:
            return (params) => <TextField {...params} error={props.error || errorMessage ? true : false} helperText={errorMessage || props.error}/>
      }
   };

   return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
         <DatePicker
            label={props.label}
            value={props.value}
            onChange={(event) => setFormattedDate(event)}
            renderInput={getRenderInput(props.styling)}
            disabled={props.disabled}
            error={props.error}
            helperText={props.error}
            views={props.views}
         />
      </LocalizationProvider>
   );
};
 
DateField.propTypes = {
   label: PropTypes.string.isRequired,
   onChange: PropTypes.func.isRequired,
   error: PropTypes.string,
   value: PropTypes.instanceOf(Date),
   disabled: PropTypes.bool,
   styling: PropTypes.string,
   views: PropTypes.array
};

DateField.defaultProps = {
   value: null,
   disabled: false
};
 
export default DateField;
