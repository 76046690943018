// My Mausoleum

import React, { useState, useEffect, useRef, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import profileService from '../data/ProfileService';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LoaderIcon from '../components/LoaderIcon';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import mausoleumService, {
   parseMausoleumDates,
} from '../data/MausoleumService';
import MausoleumForm from '../components/MausoleumForm';
import Typography from '@mui/material/Typography';
import PhotoBrowser from '../components/PhotoBrowser';
import MausoleumHeader from '../components/MausoleumHeader';
import Card from '@mui/material/Card';
import MausoleumImageUploadDialog from '../components/MausoleumImageUploadDialog';
import PhotoPickerDialog from '../components/PhotoPickerDialog';
import Alert from '@mui/material/Alert';
import PubSub from 'pubsub-js';
import systemLookupService from '../data/SystemLookupService';
import BioQuestionWidget from '../components/BioQuestionWidget';
import BioQuestionDialog from '../components/BioQuestionDialog';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import {
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
} from '@mui/material';

const MausoleumPage = () => {
   const mausoleumFormFormRef = useRef();
   const bioQuestionDialogRef = useRef();

   const [mausoleumEditMode, setMausoleumEditMode] = useState(false);
   const [mausoleum, setMausoleum] = useState({
      data: {},
      limits: {},
      loading: true,
   });
   const [mausoleumPhotos, setMausoleumPhotos] = useState({
      data: [],
      loading: true,
   });

   useEffect(() => {
      checkProfileExists();
   }, []);

   const history = useHistory();

   const checkProfileExists = async () => {
      const profileExists = await profileService.getProfileExists();
      if (profileExists) {
         getMyMausoleum();
         getMyMausoleumPhotos();
         getBioAnswers();
      } else {
         // todo: either move this somewhere generic and/or add to other pages
         //go to 'get started wizard'
         history.push('/get-started');
      }
   };

   // mausoleum
   const getMyMausoleum = async () => {
      setMausoleum({ data: {}, limits: {}, loading: true });
      const mausoleum = await mausoleumService.getMyMausoleum();
      setMausoleum({
         data: parseMausoleumDates(mausoleum.data),
         limits: mausoleum.limits,
         loading: false,
      });
   };

   const saveMausoleum = async () => {
      if (
         mausoleumFormFormRef.current &&
         mausoleumFormFormRef.current.validate()
      ) {
         setMausoleumEditMode(false);

         setMausoleum({ ...mausoleum, loading: true });
         await mausoleumService.putMyMausoleum(
            mausoleumFormFormRef.current.getState(),
         );
         getMyMausoleum();
      }
   };

   const handleEditMausoleumClick = async () => {
      await setMausoleumEditMode(true);

      if (mausoleumFormFormRef.current)
         await mausoleumFormFormRef.current.setState(mausoleum.data);
   };

   // mausoleum photos
   const getMyMausoleumPhotos = async () => {
      setMausoleumPhotos({ data: [], loading: true });
      const mausoleumPhotos = await mausoleumService.getMyMausoleumPhotos();
      setMausoleumPhotos({ data: mausoleumPhotos, loading: false });
   };

   // bio questions & answers
   const [bioAnswers, setBioAnswers] = useState({ data: [], loading: true });
   const [bioQuestionDialogOpen, setBioQuestionDialogOpen] = useState(false);

   const getBioAnswers = async () => {
      setBioAnswers({ data: [], loading: true });
      const bioAnswers = await mausoleumService.getMyMausoleumBioQuestions();
      setBioAnswers({ data: bioAnswers, loading: false });
   };

   const onAddEditBioQuestionClick = async (question) => {
      if (bioQuestionDialogRef.current)
         await bioQuestionDialogRef.current.loadQuestions(question);

      if (question) bioQuestionDialogRef.current.setState(question);
      else bioQuestionDialogRef.current.setState({ id: null, answer: '' });

      setBioQuestionDialogOpen(true);
   };

   const addSaveBioQuestionAnswer = async (question) => {
      setBioAnswers({ ...bioAnswers, loading: true });

      if (question.id) await mausoleumService.putMausoleumBioQuestion(question);
      else await mausoleumService.addMausoleumBioQuestion(question);

      getBioAnswers();
      setBioQuestionDialogOpen(false);
   };

   const onDeleteBioQuestionClick = async (question) => {
      await mausoleumService.deleteMausoleumBioQuestion(question);
      getBioAnswers();
   };

   // mausoleum image
   const [mausoleumImages, setMausoleumImages] = useState({
      data: [],
      loading: false,
   });
   const [mausoleumImagePickerDialogOpen, setMausoleumImagePickerDialogOpen] =
      useState(false);

   const handleChangeImageClick = async () => {
      setMausoleumImages({ data: [], loading: true });
      setMausoleumImagePickerDialogOpen(true);
      const mausoleumImages =
         await systemLookupService.getMausoleumBannerImages();
      setMausoleumImages({
         data: mausoleumImages.map((photo) => ({
            id: photo.id,
            caption: photo.name,
            base64PhotoScaled: photo.thumbnailImageBase64,
            selected: false,
         })),
         loading: false,
      });
   };

   const handleBannerImageSelection = async (selectedImages) => {
      setMausoleum({ ...mausoleum, loading: true });
      setMausoleumImagePickerDialogOpen(false);
      await mausoleumService.putMausoleumBannerById(selectedImages[0].id);
      getMyMausoleum();
   };

   const [mausoleumImageUploadDialogOpen, setMausoleumImageUploadDialogOpen] =
      useState(false);

   const handleSaveMausoleumImage = async (image) => {
      setMausoleumImageUploadDialogOpen(false);

      PubSub.publish('nonBlockingLoader.addMessage', {
         id: image.fileName,
         text: `Uploading ${image.fileName}...`,
      });
      await mausoleumService.putMausoleumImage(image);
      PubSub.publish('nonBlockingLoader.removeMessage', image.fileName);
      getMyMausoleum();
   };

   if (mausoleum.loading) return <LoaderIcon />;

   return (
      <Fragment>
         <Box sx={{ flexGrow: 1 }}>
            <Card sx={{ borderRadius: '0.9em' }} elevation={3}>
               <MausoleumHeader
                  loading={mausoleum.loading}
                  mausoleum={mausoleum.data}
                  onPickImage={handleChangeImageClick}
                  onEditImage={() => setMausoleumImageUploadDialogOpen(true)}
                  allowEditBanner={
                     mausoleum.limits.mausoleumBanner ||
                     mausoleum.limits.mausoleumBanner.length
                        ? true
                        : false
                  }
                  onMausoleumEdit={handleEditMausoleumClick}
               />
               <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                     <CardContent>
                        <Grid container spacing={1}>
                           <Grid item xs={12}>
                              <Typography
                                 variant='h6'
                                 gutterBottom
                                 component='div'
                                 sx={{ borderBottom: '3px solid #00a5de' }}
                              >
                                 Mausoleum Photos
                              </Typography>
                           </Grid>
                           <Grid item xs={12}>
                              <PhotoBrowser
                                 photos={mausoleumPhotos.data}
                                 emptyMessage="Your mausoleum doesn't have any photos. Add photos in My Journey, and choose which ones to include on your mausoleum."
                              />
                           </Grid>
                        </Grid>
                     </CardContent>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                     <CardContent>
                        <Grid container spacing={1}>
                           <Grid item xs={12}>
                              <Typography
                                 variant='h6'
                                 gutterBottom
                                 component='div'
                                 sx={{ borderBottom: '3px solid #00a5de' }}
                              >
                                 More About Me
                              </Typography>
                           </Grid>
                           <Grid
                              item
                              xs={12}
                              maxHeight={'30em'}
                              sx={{ overflowY: 'auto' }}
                           >
                              <BioQuestionWidget
                                 answers={bioAnswers.data}
                                 loading={bioAnswers.loading}
                                 onAddEditQuestion={onAddEditBioQuestionClick}
                                 onDeleteQuestion={onDeleteBioQuestionClick}
                                 columns={2}
                              />
                           </Grid>
                        </Grid>
                     </CardContent>
                  </Grid>
               </Grid>
            </Card>
         </Box>

         <MausoleumImageUploadDialog
            loading={mausoleum.loading}
            open={mausoleumImageUploadDialogOpen}
            handleClose={() => setMausoleumImageUploadDialogOpen(false)}
            onSaveMausoleumImage={handleSaveMausoleumImage}
         />

         <PhotoPickerDialog
            loading={mausoleumImages.loading}
            photos={mausoleumImages.data}
            title='Select Mausoleum Image'
            alert={
               <Alert severity='info'>
                  Upgrade to LifeTomb Premium to customize your mausoleum with
                  your own image.
               </Alert>
            }
            allowMultiSelect={false}
            open={mausoleumImagePickerDialogOpen}
            handleClose={() => setMausoleumImagePickerDialogOpen(false)}
            onConfirmSelection={handleBannerImageSelection}
         />

         <BioQuestionDialog
            ref={bioQuestionDialogRef}
            open={bioQuestionDialogOpen}
            handleClose={() => setBioQuestionDialogOpen(false)}
            handleAddSaveBioQuestion={addSaveBioQuestionAnswer}
         />

         {/* todo: pull this out into a component so it can be re-used */}
         <Dialog
            open={mausoleumEditMode}
            onClose={() => setMausoleumEditMode(false)}
            fullWidth={true}
            maxWidth='md'
         >
            <DialogTitle>Edit Mausoleum</DialogTitle>
            <DialogContent>
               <Grid container spacing={2}>
                  <MausoleumForm ref={mausoleumFormFormRef} />
               </Grid>
            </DialogContent>
            <DialogActions>
               <Button
                  variant='outlined'
                  startIcon={<CancelIcon />}
                  onClick={() => setMausoleumEditMode(false)}
               >
                  Cancel
               </Button>
               <Button
                  variant='contained'
                  startIcon={<CheckIcon />}
                  onClick={saveMausoleum}
               >
                  Save
               </Button>
            </DialogActions>
         </Dialog>
      </Fragment>
   );
};

export default MausoleumPage;
