import axiosService from './AxiosService';

export class JourneyMonthService {
   _resourceName = 'journey-month';

   async getJourneyMonthExists(forMonth) {
      return await axiosService.readObject(`/v1.0/${this._resourceName}`, `journey-month-exists/${forMonth}`);
   }

   async addJourneyMonth(month) {
      return await axiosService.create(`/v1.0/${this._resourceName}`, month);
   }

   async putJourneyMonth(month) {
      return await axiosService.update(`/v1.0/${this._resourceName}`, month);
   }

   async deleteJourneyMonth(id) {
      return await axiosService.delete(`/v1.0/${this._resourceName}`, id);
   }

   static get instance() { return journeyMonthService }
}

const journeyMonthService = new JourneyMonthService();

export default journeyMonthService;
