import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import LoaderIcon from '../components/LoaderIcon';
import LTIconButton from './LTIconButton';
import BioQuestionCard from './BioQuestionCard';
import AddIcon from '@mui/icons-material/Add';

const BioQuestionWidget = (props) => {
   if(props.loading)
      return (<LoaderIcon />)

   return (
      <Fragment>
         <Grid container spacing={2}>
            {props.onAddEditQuestion && typeof props.onAddEditQuestion === 'function' ? <Grid xs={12} item sx={{ display: 'flex', justifyContent: 'end' }}>
               <LTIconButton
                  disabled={false}
                  tooltipText='Add Bio Question'
                  ariaLabel='open add bio question dialog'
                  onClick={() => props.onAddEditQuestion(null)}
                  icon={<AddIcon />}
               />
            </Grid> : null }
            {props.answers.length === 0 ?
               <Grid item><Alert sx={{ mt: 1 }} severity="info">No questions have been answered</Alert></Grid> : props.answers.map(answer => (
               <Grid item key={answer.id} xs={12/props.columns} columns={props.columns}>
                  <BioQuestionCard
                     answer={answer}
                     handleEdit={props.onAddEditQuestion || null}
                     handleDelete={props.onDeleteQuestion || null}
                  />
               </Grid>
            ))}
         </Grid>
      </Fragment>
   );
};

BioQuestionWidget.propTypes = {
   answers: PropTypes.array.isRequired,
   loading: PropTypes.bool.isRequired,
   onAddEditQuestion: PropTypes.func,
   onDeleteQuestion: PropTypes.func,
   columns: PropTypes.number,
};

export default BioQuestionWidget;
