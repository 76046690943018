import React from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const TierComparisonTable = (props)=>{
   const createData = (
      feature,
      free,
      premium,
      family,
   ) => {
      return { feature, free, premium, family };
   };
   
   const rows = [
      createData('Pricing', 'Free', '$5.99 per Month', '$9.99 per Month'),
      createData('Journey Photos', '2 per Month', '6 per Month', '12 per Month (6 per Person)'),
      createData('Share Records w/ Family', 'No', 'No', 'Yes'),
      createData('Assets', '3', '100', '200'),
      createData('Asset Photo', 'No', '1 per Asset', '1 per Asset'),
      createData('Financial Info', '3', '25', '50'),
      createData('Documents', '3', '50', '100'),
      createData('Important Contacts', '2', '25', '50'),
      createData('Trustees', '1', '10', '20'),
      createData('Mausoleum Banner', 'Choose From 5', 'Upload Your Own', 'Upload Your Own'),
      createData('Commenting on Mausoleum', 'No', 'Yes', 'Yes'),
      createData('Diary Entries', 'Yes', 'Yes', 'Yes'),
      createData('Mausoleum Photos', '12', '225', '225 per Person'),
      // createData('Mausoleum Bio / my story / maybe w/ 5 or so question', 'No', 'Yes', 'Yes'),
      createData('Final Wishes', 'Yes', 'Yes', 'Yes'),
      createData('Trustor View', 'Yes', 'Yes', 'Yes'),
      createData('Historical Journey Entries', 'No', 'Yes', 'Yes'),
   ];

   return (
      <TableContainer component={Paper}>
         <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
               <TableRow>
                  <TableCell>Feature</TableCell>
                  <TableCell align="center" sx={{ backgroundColor: props.highlightTier === 'free' ? '#00a5de' : 'inherit', color: props.highlightTier === 'free' ? '#fff' : 'inherit' }}>Free</TableCell>
                  <TableCell align="center" sx={{ backgroundColor: props.highlightTier === 'premium' ? '#00a5de' : 'inherit', color: props.highlightTier === 'premium' ? '#fff' : 'inherit' }}>Premium</TableCell>
                  <TableCell align="center" sx={{ backgroundColor: props.highlightTier === 'family' ? '#00a5de' : 'inherit', color: props.highlightTier === 'family' ? '#fff' : 'inherit' }}>Family</TableCell>
               </TableRow>
            </TableHead>
            <TableBody>
               {rows.map((row) => (
                  <TableRow
                     key={row.feature}
                     sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                     <TableCell component="th" scope="row">
                        {row.feature}
                     </TableCell>
                     <TableCell align="center" sx={{ backgroundColor: props.highlightTier === 'free' ? '#00a5de' : 'inherit', color: props.highlightTier === 'free' ? '#fff' : 'inherit' }}>{row.free}</TableCell>
                     <TableCell align="center" sx={{ backgroundColor: props.highlightTier === 'premium' ? '#00a5de' : 'inherit', color: props.highlightTier === 'premium' ? '#fff' : 'inherit' }}>{row.premium}</TableCell>
                     <TableCell align="center" sx={{ backgroundColor: props.highlightTier === 'family' ? '#00a5de' : 'inherit', color: props.highlightTier === 'family' ? '#fff' : 'inherit' }}>{row.family}</TableCell>
                  </TableRow>
               ))}
            </TableBody>
         </Table>
      </TableContainer>
   );
};

TierComparisonTable.propTypes = {
   highlightTier: PropTypes.string,
};

export default TierComparisonTable;
