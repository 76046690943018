import React, { Fragment, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import WidgetContainer from './WidgetContainer';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import { useQuery } from '../utils/useQuery';
import MuseumIcon from '@mui/icons-material/Museum';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import StarIcon from '@mui/icons-material/Star';
import format from 'date-fns/format';
import AvatarInitials from './AvatarInitials';
import TextDisplay from './TextDisplay';
import LoaderIcon from './LoaderIcon';
import Alert from '@mui/material/Alert';
import DateField from './DateField';
import ClearIcon from '@mui/icons-material/Clear';
import Tooltip from '@mui/material/Tooltip';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AddIcon from '@mui/icons-material/Add';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Stack } from '@mui/material';
import MausoleumForOthersForm from './MausoleumForOthersForm';
import LTIconButton from './LTIconButton';

const MausoleumFeed = (props) => {
   const query = useQuery();
   const [firstName, setFirstName] = useState(query.get('firstName') || '');
   const [lastName, setLastName] = useState(query.get('lastName') || '');
   const [cityOfBirth, setCityOfBirth] = useState(query.get('cityOfBirth') || '');
   const [dateOfBirth, setDateOfBirth] = useState(query.get('dateOfBirth') ? new Date(query.get('dateOfBirth')) : null);
   const [dateOfDeath, setDateOfDeath] = useState(query.get('dateOfDeath') ? new Date(query.get('dateOfDeath')) : null);
   const [formValidation, setFormValidation] = useState({});
   const [searchMode, setSearchMode] = React.useState('popular');
   const [mausoleumForOthersFormOpen, setMausoleumForOthersFormOpen] = useState(false);
   const mausoleumForOthersFormRef = useRef();

   const handleSearchModeChange = (event, newSearchMode) => {
      switch (newSearchMode) {
         case 'managed-by-me':
            getMausoleaManagedByMe();
            break;
         default: // case 'popular':
            getPopularMausolea();
            break;
      }

      setSearchMode(newSearchMode);
   };

   const otherMausoleumFormValid = () => {
      let formValid = false;
      if (mausoleumForOthersFormRef.current)
         formValid = mausoleumForOthersFormRef.current.validate();

      return formValid;
   };

   const handleSubmitMausoleumForOther = () => {
      if (otherMausoleumFormValid() && mausoleumForOthersFormRef.current) {
         props.onSubmitOtherMausoleum(
            mausoleumForOthersFormRef.current.getState(),
         );
      }
      setMausoleumForOthersFormOpen(false);
   };

   const formIsValid = () => {
      let errors = {};

      if (firstName && firstName.length < 2) {
         errors.firstName = 'First Name must be 2 or more characters long';
      } else if (!firstName) {
         errors.firstName = 'First Name is required';
      }
      if (lastName && lastName.length < 2) {
         errors.lastName = 'Last Name must be 2 or more characters long';
      } else if (!lastName) {
         errors.lastName = 'Last Name is required';
      }
      if (cityOfBirth && cityOfBirth.length < 3) {
         errors.cityOfBirth = 'City of Birth must be 3 or more characters long';
      }
      if (firstName && lastName && !cityOfBirth && !dateOfBirth && !dateOfDeath) {
         errors.cityOfBirth = 'One more field is required';
         errors.dateOfBirth = 'One more field is required';
         errors.dateOfDeath = 'One more field is required';
      }

      setFormValidation(errors);

      return Object.keys(errors).length === 0;
   };

   const getPopularMausolea = () => {
      clearFilter();
      props.onSearch();
   };

   const search = () => {
      if (formIsValid())
         props.onSearch(firstName, lastName, cityOfBirth, dateOfBirth, dateOfDeath);
   };

   const getMausoleaManagedByMe = () => {
      clearFilter();
      props.onGetMausoleaManagedByMe();
   };

   const clearFilter = () => {
      setFirstName('');
      setLastName('');
      setCityOfBirth('');
      setDateOfBirth(null);
      setDateOfDeath(null);
      setFormValidation({});
   };

   const renderCards = (mausolea, loading) => {
      if (loading) return <LoaderIcon />;

      if (
         !mausolea.length &&
         (firstName || lastName || cityOfBirth || dateOfBirth || dateOfDeath)
      )
         return (
            <Grid item xs={12}>
               <Alert severity='info'>
                  Your search parameters did not yield any results.
               </Alert>
            </Grid>
         );

      return (
         <Fragment>
            {mausolea.map((mausoleum) => (
               <Grid item xs={12} md={6} lg={4} key={mausoleum.id}>
                  <Card
                     variant='outlined'
                     sx={{ borderRadius: 3, height: '100%' }}
                  >
                     <CardHeader
                        sx={{ backgroundColor: '#3b434c', color: '#fff' }}
                        title={
                           <Grid container wrap='nowrap' spacing={1}>
                              <Grid item>
                                 <AvatarInitials
                                    basicProfile={mausoleum.owner}
                                 />
                              </Grid>
                              <Grid item>
                                 <Typography
                                    variant='h5'
                                    gutterBottom
                                    component='div'
                                 >
                                    {mausoleum.owner.firstName}{' '}
                                    {mausoleum.owner.lastName}
                                 </Typography>
                              </Grid>
                              <Grid item>
                                 {mausoleum.isFavorite ? (
                                    <Tooltip title='Favorite'>
                                       <StarIcon />
                                    </Tooltip>
                                 ) : null}

                                 {mausoleum.isVerified ? (
                                    <Tooltip title='Verified'>
                                       <CheckCircleOutlineIcon />
                                    </Tooltip>
                                 ) : null}
                              </Grid>
                           </Grid>
                        }
                        subheader={
                           <Typography component='p'>
                              (
                              {mausoleum.dateOfBirth
                                 ? `${format(mausoleum.dateOfBirth, 'u')} - `
                                 : ''}
                              {mausoleum.dateOfDeath
                                 ? format(mausoleum.dateOfDeath, 'u')
                                 : 'Alive'}
                              )
                           </Typography>
                        }
                     />
                     <CardContent>
                        <TextDisplay text={mausoleum.epitaph} maxChars={120} />
                     </CardContent>
                     <CardActions>
                        <Button
                           variant='outlined'
                           component={Link}
                           to={`/mausoleum-detail/${mausoleum.id}`}
                        >
                           Visit
                        </Button>
                     </CardActions>
                  </Card>
               </Grid>
            ))}
         </Fragment>
      );
   };

   return (
      <WidgetContainer
         title='Mausolea'
         // icon={MausoleumIconBlue}
         icon={MuseumIcon}
         color='#00a5de'
      >
         <CardContent sx={{ display: 'flex', justifyContent: 'end' }}>
            <ToggleButtonGroup
               color='primary'
               value={searchMode}
               exclusive
               onChange={handleSearchModeChange}
               aria-label='search mode select'
            >
               <ToggleButton value='popular'>Popular</ToggleButton>
               <ToggleButton value='search'>Search</ToggleButton>
               {props.featureFlags &&
               props.featureFlags &&
               props.featureFlags.find(
                  (el) => el.id === 'mausoleum-editor-for-others',
               ) ? (
                  <ToggleButton value='managed-by-me'>
                     Managed by Me
                  </ToggleButton>
               ) : null}
            </ToggleButtonGroup>
         </CardContent>

         <CardContent sx={{ display: 'flex', justifyContent: 'end' }}>
            {searchMode === 'search' ? (
               <Fragment>
                  <TextField
                     className='text'
                     label='First Name'
                     variant='outlined'
                     placeholder='Search first name...'
                     size='small'
                     value={firstName}
                     onChange={(event) => setFirstName(event.target.value)}
                     inputProps={{ minLength: 2 }}
                     error={formValidation.firstName ? true : false}
                     helperText={formValidation.firstName}
                     required
                  />
                  <TextField
                     className='text'
                     label='Last Name'
                     variant='outlined'
                     placeholder='Search last name...'
                     size='small'
                     value={lastName}
                     onChange={(e) => setLastName(e.target.value)}
                     sx={{ ml: 1 }}
                     inputProps={{ minLength: 2 }}
                     error={formValidation.lastName ? true : false}
                     helperText={formValidation.lastName}
                     required
                  />
                  <TextField
                     className='text'
                     label='City of Birth'
                     variant='outlined'
                     placeholder='Search city of birth...'
                     size='small'
                     value={cityOfBirth}
                     onChange={(e) => setCityOfBirth(e.target.value)}
                     sx={{ ml: 1 }}
                     inputProps={{ minLength: 3 }}
                     error={formValidation.cityOfBirth ? true : false}
                     helperText={formValidation.cityOfBirth}
                  />
                  <DateField
                     value={dateOfBirth}
                     onChange={setDateOfBirth}
                     label='Date of Birth'
                     error={formValidation.dateOfBirth}
                     styling='small'
                  />
                  <DateField
                     value={dateOfDeath}
                     onChange={setDateOfDeath}
                     label='Date of Death'
                     error={formValidation.dateOfDeath}
                     styling='small'
                  />
                  <Stack
                     direction='row'
                     spacing={1}
                     sx={{ display: 'inline', ml: 1 }}
                  >
                     <LTIconButton
                        tooltipText='Search'
                        ariaLabel='search public mausolea'
                        onClick={search}
                        icon={<SearchIcon />}
                     />
                     <LTIconButton
                        disabled={
                           !firstName &&
                           !lastName &&
                           !dateOfBirth &&
                           !dateOfDeath
                        }
                        tooltipText='Clear Filter'
                        ariaLabel='clear filter'
                        onClick={clearFilter}
                        icon={<ClearIcon />}
                     />
                  </Stack>
               </Fragment>
            ) : null}
            {searchMode === 'managed-by-me' ? (
               <Fragment>
                  <LTIconButton
                     tooltipText='Add Mausoleum'
                     ariaLabel='add mausoleum'
                     onClick={() => setMausoleumForOthersFormOpen(true)}
                     icon={<AddIcon />}
                  />
               </Fragment>
            ) : null}
         </CardContent>
         <CardContent>
            <Grid container spacing={1}>
               {renderCards(props.mausolea, props.loading)}
            </Grid>
         </CardContent>
         <MausoleumForOthersForm
            ref={mausoleumForOthersFormRef}
            open={mausoleumForOthersFormOpen}
            handleClose={() => setMausoleumForOthersFormOpen(false)}
            handleSubmit={handleSubmitMausoleumForOther}
         />
      </WidgetContainer>
   );
};

MausoleumFeed.propTypes = {
   loading: PropTypes.bool.isRequired,
   mausolea: PropTypes.array.isRequired,
   onSearch: PropTypes.func.isRequired,
   onGetMausoleaManagedByMe: PropTypes.func.isRequired,
   featureFlags: PropTypes.array,
   onSubmitOtherMausoleum: PropTypes.func.isRequired,
};

export default MausoleumFeed;
