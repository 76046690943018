import React, { useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import formatISO from 'date-fns/formatISO'
import TextAreaWithLimit from './TextAreaWithLimit';
import DateField from './DateField';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';

const DiaryEntryDialog = forwardRef((props, ref) => {
   const [formValues, setFormValues] = useState({
      forDate: null,
      title: '',
      text: ''
   });

   // control validation, state from parent component
   useImperativeHandle(ref, () => ({
      setState(formValues) {
         return setFormValues(formValues);
      }
   }));

   // validation
   const [formValidation, setFormValidation] = useState({});
   const formIsValid = () => {
      let errors = {};

      if (!formValues.forDate)
         errors.forDate = 'For Date must be provided.';
      if (formValues.title.length < 2)
         errors.title = 'Title must be at least two characters.';
      if (formValues.text.length < 2)
         errors.text = 'Text must be at least two characters.';

      setFormValidation(errors);

      return Object.keys(errors).length === 0;
   };

   const handleAddSaveDiaryEntryButtonClick = () => {
      if (formIsValid())
         props.handleAddSaveDiaryEntry({
            ...formValues,
            forDate: formatISO(new Date(formValues.forDate.getFullYear(), formValues.forDate.getMonth(), formValues.forDate.getDate())),
            id: props.id
         });
   };

   return (
      <Dialog open={props.open} onClose={props.handleClose}>
         <DialogTitle>{( props.id ? 'Edit' : 'Add')} Diary Entry</DialogTitle>
         <DialogContent>
            <Box component="form">
               <Grid container spacing={1}>
                  <Grid item xs={12}>
                     <DialogContentText>
                        <b>Capture memories, thoughts, or something important that happened in your life today.</b>
                     </DialogContentText>
                  </Grid>

                  <Grid item md={12}>
                     <FormControl fullWidth>
                        <DateField
                           label="For Date*"
                           onChange={(newValue) => {
                              setFormValues({ ...formValues, forDate: newValue });
                           }}
                           error={formValidation.forDate}
                           value={formValues.forDate}
                        />
                     </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                     <FormControl fullWidth>
                        <TextField
                           margin="dense"
                           id="title-input"
                           label="Title*"
                           type="text"
                           value={formValues.title}
                           onChange={event => setFormValues({ ...formValues, title: event.target.value })}
                           error={formValidation.title ? true : false}
                           helperText={formValidation.title}
                           fullWidth
                        />
                     </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                     <FormControl fullWidth>
                        <TextAreaWithLimit
                           id="text-input"
                           label="Text*"
                           maxLength={20000}
                           value={formValues.text}
                           onChange={event => setFormValues({ ...formValues, text: event.target.value })}
                           error={formValidation.text ? true : false}
                           helperText={formValidation.text}
                           placeholder="Add text..."
                           rows={3}
                        />
                     </FormControl>
                  </Grid>
               </Grid>
            </Box>
         </DialogContent>
         <DialogActions>
            <Button variant="outlined" startIcon={<CancelIcon />} onClick={props.handleClose}>Cancel</Button>
            <Button variant="contained" startIcon={<CheckIcon />} onClick={handleAddSaveDiaryEntryButtonClick}>{( props.id ? 'Save' : 'Add')} Diary Entry</Button>
         </DialogActions>
      </Dialog>
   );
});

DiaryEntryDialog.propTypes = {
   id: PropTypes.string,
   open: PropTypes.bool.isRequired,
   handleClose: PropTypes.func.isRequired,
   handleAddSaveDiaryEntry: PropTypes.func.isRequired,
};

export default DiaryEntryDialog;
