import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useQuery = () => {
   const { search } = useLocation();
   return useMemo(() => new URLSearchParams(search), [search]);
};

export const buildQueryString = (paramDictionary) => {
   const queryParams = paramDictionary.filter((element) => (element.value ? true : false));

   if(queryParams.length)
      return `?${queryParams.map((param) => (`${param.key}=${encodeURIComponent(param.value)}`)).join('&')}`;
   else
      return null;
};
