import React, { useState, useRef, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import AddressFormFields from './AddressFormFields';
import NumberFormat from 'react-number-format';
import TextAreaWithLimit from './TextAreaWithLimit';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Alert from '@mui/material/Alert';
import systemLookupService from '../data/SystemLookupService';
import LoaderIcon from './LoaderIcon';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';

const CareAndHealthDialog = forwardRef((props, ref) => {
   const healthAgentAddressFormRef = useRef();
   const alternateHealthAgentAddressFormRef = useRef();

   const initialState = {
      healthAgentName: '',
      healthAgentPhone: '',
      healthAgentRelationshipToYou: '',
      alternateHealthAgentName: '',
      alternateHealthAgentPhone: '',
      alternateHealthAgentRelationshipToYou: '',
      keepAliveProcedures: '',
      endOfLifeCareLocationDetails: '',
      donateMyOrgans: false,
      organsToDonate: '',
      otherHealthWishes: '',
   };
   const [formValues, setFormValues] = useState(initialState);

   const [addressStatesProvinces, setAddressStatesProvinces] = useState({ data: [], loading: false });

   const [healthAgentDecisionPoints, setHealthAgentDecisionPoints] = useState({ data: [], loading: false });
   const [selectedHealthAgentDecisionPoint, setSelectedHealthAgentDecisionPoint] = useState('anytime');

   const [endOfLifeInstructions, setEndOfLifeInstructions] = useState({ data: [], loading: false });
   const [selectedEndOfLifeInstruction, setSelectedEndOfLifeInstruction] = useState('keep-alive');

   const [painManagement, setPainManagement] = useState({ data: [], loading: false });
   const [selectedPainManagement, setSelectedPainManagement] = useState('max-drugs');

   const [endOfLifeCareLocationTypes, setEndOfLifeCareLocationTypes] = useState({ data: [], loading: false });
   const [selectedEndOfLifeCareLocationType, setSelectedEndOfLifeCareLocationType] = useState('home');

   // control validation, state from parent component
   useImperativeHandle(ref, () => ({
      setState(state) {
         setFormValues({
            ...state,
            healthAgentName: state.healthAgentName ? state.healthAgentName : '',
            healthAgentPhone: state.healthAgentPhone ? state.healthAgentPhone : '',
            healthAgentRelationshipToYou: state.healthAgentRelationshipToYou ? state.healthAgentRelationshipToYou : '',
            alternateHealthAgentName: state.alternateHealthAgentName ? state.alternateHealthAgentName : '',
            alternateHealthAgentPhone: state.alternateHealthAgentPhone ? state.alternateHealthAgentPhone : '',
            alternateHealthAgentRelationshipToYou: state.alternateHealthAgentRelationshipToYou ? state.alternateHealthAgentRelationshipToYou : '',
            keepAliveProcedures: state.keepAliveProcedures ? state.keepAliveProcedures : '',
            endOfLifeCareLocationDetails: state.endOfLifeCareLocationDetails ? state.endOfLifeCareLocationDetails : '',
            organsToDonate: state.organsToDonate ? state.organsToDonate : '',
            otherHealthWishes: state.otherHealthWishes ? state.otherHealthWishes : '',
         });

         if (healthAgentAddressFormRef.current)
            healthAgentAddressFormRef.current.setState(state.healthAgentAddress);

         if (alternateHealthAgentAddressFormRef.current)
            alternateHealthAgentAddressFormRef.current.setState(state.alternateHealthAgentAddress);

         setSelectedHealthAgentDecisionPoint((state && state.healthAgentDecisionPoint && state.healthAgentDecisionPoint.id) || 'anytime');
         setSelectedEndOfLifeInstruction((state && state.endOfLifeInstruction && state.endOfLifeInstruction.id) || 'keep-alive');
         setSelectedPainManagement((state && state.painManagement && state.painManagement.id) || 'max-drugs');
         setSelectedEndOfLifeCareLocationType((state && state.endOfLifeCareLocationType && state.endOfLifeCareLocationType.id) || 'home');
      },
      async loadCareAndHealth () {
         await getAddressStatesProvinces();
         await getHealthAgentDecisionPoints();
         await getEndOfLifeInstructions();
         await getPainManagement();
         await getEndOfLifeCareLocationTypes();
      },
   }));

   // lookups
   const getAddressStatesProvinces = async () => {
      setAddressStatesProvinces({ data: [], loading: true });
      const addressStatesProvinces = await systemLookupService.getAddressStatesProvinces();
      setAddressStatesProvinces({ data: addressStatesProvinces, loading: false });
   };

   const getHealthAgentDecisionPoints = async () => {
      setHealthAgentDecisionPoints({ data: [], loading: true });
      const healthAgentDecisionPoints = await systemLookupService.getHealthAgentDecisionPoints();
      setHealthAgentDecisionPoints({ data: healthAgentDecisionPoints, loading: false });
   };

   const getEndOfLifeInstructions = async () => {
      setEndOfLifeInstructions({ data: [], loading: true });
      const endOfLifeInstructions = await systemLookupService.getEndOfLifeInstructions();
      setEndOfLifeInstructions({ data: endOfLifeInstructions, loading: false });
   };

   const getPainManagement = async () => {
      setPainManagement({ data: [], loading: true });
      const painManagement = await systemLookupService.getPainManagement();
      setPainManagement({ data: painManagement, loading: false });
   };

   const getEndOfLifeCareLocationTypes = async () => {
      setEndOfLifeCareLocationTypes({ data: [], loading: true });
      const endOfLifeCareLocationTypes = await systemLookupService.getEndOfLifeCareLocationTypes();
      setEndOfLifeCareLocationTypes({ data: endOfLifeCareLocationTypes, loading: false });
   };

   // validation
   const [formValidation, setFormValidation] = useState({});
   const formIsValid = () => {
      let errors = {};

      if (formValues.healthAgentName && formValues.healthAgentName.length < 2)
         errors.healthAgentName = 'Health Agent Name must be at least two characters if provided.';

      if (formValues.healthAgentPhone && formValues.healthAgentPhone.toString().length && formValues.healthAgentPhone.toString().length !== 10)
         errors.healthAgentPhone = 'Health Agent Phone Number invalid.';

      if (formValues.healthAgentRelationshipToYou && formValues.healthAgentRelationshipToYou.length < 2)
         errors.healthAgentRelationshipToYou = 'Health Agent Relationship To You must be at least two characters if provided.';

      if (formValues.alternateHealthAgentName && formValues.alternateHealthAgentName.length < 2)
         errors.alternateHealthAgentName = 'Alternate Health Agent Name must be at least two characters if provided.';

      if (formValues.alternateHealthAgentPhone && formValues.alternateHealthAgentPhone.toString().length && formValues.alternateHealthAgentPhone.toString().length !== 10)
         errors.alternateHealthAgentPhone = 'Alternate Health Agent Phone Number invalid.';

      if (formValues.alternateHealthAgentRelationshipToYou && formValues.alternateHealthAgentRelationshipToYou.length < 2)
         errors.alternateHealthAgentRelationshipToYou = 'Alternate Health Agent Relationship To You must be at least two characters if provided.';

      if (formValues.keepAliveProcedures && formValues.keepAliveProcedures.length < 2)
         errors.keepAliveProcedures = 'End of Life Procedures must be at least two characters if provided.';

      if (formValues.endOfLifeCareLocationDetails && formValues.endOfLifeCareLocationDetails.length < 2)
         errors.endOfLifeCareLocationDetails = 'End of Life Care Location Details must be at least two characters if provided.';

      if (formValues.organsToDonate && formValues.organsToDonate.length < 2)
         errors.organsToDonate = 'Organs To Donate must be at least two characters if provided.';

      if (formValues.otherHealthWishes && formValues.otherHealthWishes.length < 2)
         errors.otherHealthWishes = 'Other Health Wishes must be at least two characters if provided.';

      setFormValidation(errors);

      // validate addresses
      let healthAgentAddressValid = false;
      if (healthAgentAddressFormRef.current && formValues.funeralServiceToBeHeld)
         healthAgentAddressValid = healthAgentAddressFormRef.current.validate();
      else
         healthAgentAddressValid = true;

      let alternateHealthAgentAddressValid = false;
      if (alternateHealthAgentAddressFormRef.current && formValues.receptionAfterService)
         alternateHealthAgentAddressValid = alternateHealthAgentAddressFormRef.current.validate();
      else
         alternateHealthAgentAddressValid = true;

      return Object.keys(errors).length === 0 && healthAgentAddressValid && alternateHealthAgentAddressValid;
   };

   const cleanData = (formValues) => {
      return {
         ...formValues,
         healthAgentName: formValues.healthAgentName ? formValues.healthAgentName : null,
         healthAgentPhone: formValues.healthAgentPhone ? formValues.healthAgentPhone.toString() : null,
         healthAgentRelationshipToYou: formValues.healthAgentRelationshipToYou ? formValues.healthAgentRelationshipToYou : null,
         alternateHealthAgentName: formValues.alternateHealthAgentName ? formValues.alternateHealthAgentName : null,
         alternateHealthAgentPhone: formValues.alternateHealthAgentPhone ? formValues.alternateHealthAgentPhone.toString() : null,
         alternateHealthAgentRelationshipToYou: formValues.alternateHealthAgentRelationshipToYou ? formValues.alternateHealthAgentRelationshipToYou : null,
         keepAliveProcedures: formValues.keepAliveProcedures ? formValues.keepAliveProcedures : null,
         endOfLifeCareLocationDetails: formValues.endOfLifeCareLocationDetails ? formValues.endOfLifeCareLocationDetails : null,
         organsToDonate: formValues.organsToDonate ? formValues.organsToDonate : null,
         otherHealthWishes: formValues.otherHealthWishes ? formValues.otherHealthWishes : null,
         healthAgentAddress: healthAgentAddressFormRef.current ? healthAgentAddressFormRef.current.getState() : null,
         alternateHealthAgentAddress: alternateHealthAgentAddressFormRef.current ? alternateHealthAgentAddressFormRef.current.getState() : null,
      };
   };

   const handleSaveCareAndHealthButtonClick = () => {
      if (formIsValid()) {
         props.handleSaveCareAndHealth(cleanData({
            ...formValues,
            healthAgentDecisionPoint: healthAgentDecisionPoints.data.find(element => element.id === selectedHealthAgentDecisionPoint),
            endOfLifeInstruction: endOfLifeInstructions.data.find(element => element.id === selectedEndOfLifeInstruction),
            painManagement: painManagement.data.find(element => element.id === selectedPainManagement),
            endOfLifeCareLocationType: endOfLifeCareLocationTypes.data.find(element => element.id === selectedEndOfLifeCareLocationType),
         }));
      }
   };

   const [healthAgentPopoverAnchorEl, setHealthAgentPopoverAnchorEl] = React.useState(null);
   const handleHealthAgentPopoverClick = (event) => {
      setHealthAgentPopoverAnchorEl(event.currentTarget);
   };
   const handleHealthAgentPopoverClose = () => {
      setHealthAgentPopoverAnchorEl(null);
   };
   const healthAgentPopoverOpen = Boolean(healthAgentPopoverAnchorEl);
   const healthAgentPopoverId = healthAgentPopoverOpen ? 'health-agent-popover' : undefined;

   const [organDonationPopoverAnchorEl, setOrganDonationPopoverAnchorEl] = React.useState(null);
   const handleOrganDonationPopoverClick = (event) => {
      setOrganDonationPopoverAnchorEl(event.currentTarget);
   };
   const handleOrganDonationPopoverClose = () => {
      setOrganDonationPopoverAnchorEl(null);
   };
   const organPopoverOpen = Boolean(organDonationPopoverAnchorEl);
   const organPopoverId = organPopoverOpen ? 'organ-popover' : undefined;

   const [otherWishesPopoverAnchorEl, setOtherWishesPopoverAnchorEl] = React.useState(null);
   const handleOtherWishesPopoverClick = (event) => {
      setOtherWishesPopoverAnchorEl(event.currentTarget);
   };
   const handleOtherWishesPopoverClose = () => {
      setOtherWishesPopoverAnchorEl(null);
   };
   const otherWishesPopoverOpen = Boolean(otherWishesPopoverAnchorEl);
   const otherWishesPopoverId = otherWishesPopoverOpen ? 'other-wisehs-popover' : undefined;

   return (
      <Dialog open={props.open} onClose={props.handleClose}>
         <DialogTitle>Special Care &amp; Health Decisions</DialogTitle>
         <DialogContent>
            <Box component="form">
               <Grid container spacing={1}>
                  <Grid item xs={12}>
                     <DialogContentText>
                        <IconButton aria-describedby={healthAgentPopoverId} onClick={handleHealthAgentPopoverClick}>
                           <InfoIcon />
                        </IconButton>
                        <b>Who is Your Health Agent</b>
                     </DialogContentText>
                  </Grid>
                  <Popover
                     id={healthAgentPopoverId}
                     open={healthAgentPopoverOpen}
                     anchorEl={healthAgentPopoverAnchorEl}
                     onClose={handleHealthAgentPopoverClose}
                     anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                     }}
                     sx={{ maxWidth: 600 }}
                  >
                     <Alert sx={{ minWidth: 520 }} severity="info">
                        Your Health Care Agent should be someone:
                        <ul>
                           <li>You trust</li>
                           <li>Who knows you well</li>
                           <li>Who is familiar with your values and beliefs</li>
                        </ul>
                     </Alert>
                  </Popover>

                  <Grid item xs={6}>
                     <FormControl fullWidth>
                        <TextField
                           autoFocus
                           margin="dense"
                           id="health-agent-name-input"
                           label="Name"
                           type="text"
                           value={formValues.healthAgentName}
                           onChange={event => setFormValues({ ...formValues, healthAgentName: event.target.value })}
                           error={formValidation.healthAgentName ? true : false}
                           helperText={formValidation.healthAgentName}
                           fullWidth
                        />
                     </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                     <FormControl fullWidth>
                        <NumberFormat
                           customInput={TextField}
                           format="+1 (###) ###-####"
                           isNumericString={true}
                           mask="_"
                           margin="dense"
                           id="health-agent-phone-input"
                           label="Phone"
                           type="text"
                           value={formValues.healthAgentPhone}
                           onValueChange={({ floatValue: value }) => setFormValues({ ...formValues, healthAgentPhone: value })}
                           error={formValidation.healthAgentPhone ? true : false}
                           helperText={formValidation.healthAgentPhone}
                           fullWidth
                        />
                     </FormControl>
                  </Grid>

                  {addressStatesProvinces.loading !== true ? <AddressFormFields
                     ref={healthAgentAddressFormRef}
                     label="Health Agent Address"
                     addressStatesProvinces={addressStatesProvinces.data}
                  /> : <LoaderIcon />}

                  <Grid item xs={12}>
                     <FormControl fullWidth>
                        <TextField
                           margin="dense"
                           id="health-agent-relationship-to-you-input"
                           label="Relationship To You"
                           type="text"
                           value={formValues.healthAgentRelationshipToYou}
                           onChange={event => setFormValues({ ...formValues, healthAgentRelationshipToYou: event.target.value })}
                           error={formValidation.healthAgentRelationshipToYou ? true : false}
                           helperText={formValidation.healthAgentRelationshipToYou}
                           fullWidth
                        />
                     </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                     <DialogContentText>
                        <b>Who is Your Secondary Health Agent</b>
                     </DialogContentText>
                  </Grid>

                  <Grid item xs={6}>
                     <FormControl fullWidth>
                        <TextField
                           margin="dense"
                           id="alternate-health-agent-name-input"
                           label="Name"
                           type="text"
                           value={formValues.alternateHealthAgentName}
                           onChange={event => setFormValues({ ...formValues, alternateHealthAgentName: event.target.value })}
                           error={formValidation.alternateHealthAgentName ? true : false}
                           helperText={formValidation.alternateHealthAgentName}
                           fullWidth
                        />
                     </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                     <FormControl fullWidth>
                        <NumberFormat
                           customInput={TextField}
                           format="+1 (###) ###-####"
                           isNumericString={true}
                           mask="_"
                           margin="dense"
                           id="alternate-health-agent-phone-input"
                           label="Phone"
                           type="text"
                           value={formValues.alternateHealthAgentPhone}
                           onValueChange={({ floatValue: value }) => setFormValues({ ...formValues, alternateHealthAgentPhone: value })}
                           error={formValidation.alternateHealthAgentPhone ? true : false}
                           helperText={formValidation.alternateHealthAgentPhone}
                           fullWidth
                        />
                     </FormControl>
                  </Grid>

                  {addressStatesProvinces.loading !== true ? <AddressFormFields
                     ref={alternateHealthAgentAddressFormRef}
                     label="Secondary Health Agent Address"
                     addressStatesProvinces={addressStatesProvinces.data}
                  /> : <LoaderIcon />}

                  <Grid item xs={12}>
                     <FormControl fullWidth>
                        <TextField
                           margin="dense"
                           id="alternate-health-agent-relationship-to-you-input"
                           label="Relationship To You"
                           type="text"
                           value={formValues.alternateHealthAgentRelationshipToYou}
                           onChange={event => setFormValues({ ...formValues, alternateHealthAgentRelationshipToYou: event.target.value })}
                           error={formValidation.alternateHealthAgentRelationshipToYou ? true : false}
                           helperText={formValidation.alternateHealthAgentRelationshipToYou}
                           fullWidth
                        />
                     </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                     <DialogContentText>
                        <b>When should they be able to make decisions for you?</b>
                     </DialogContentText>
                  </Grid>

                  {healthAgentDecisionPoints.loading !== true ? <Grid item xs={12}>
                     <FormControl fullWidth>
                        <Select
                           id="health-agent-decision-point-select"
                           value={selectedHealthAgentDecisionPoint}
                           onChange={event => setSelectedHealthAgentDecisionPoint(event.target.value)}
                        >
                           {healthAgentDecisionPoints.data.map(hadp => (<MenuItem key={hadp.id} value={hadp.id}>{hadp.name}</MenuItem>))}
                        </Select>
                     </FormControl>
                  </Grid> : <LoaderIcon />}

                  <Grid item xs={12}>
                     <DialogContentText>
                        <b>If you faced an irreversible condition, what would be your end-of-life instructions?</b>
                     </DialogContentText>
                  </Grid>

                  {endOfLifeInstructions.loading !== true ? <Grid item xs={12}>
                     <FormControl fullWidth>
                        <Select
                           id="end-of-life-instruction-select"
                           value={selectedEndOfLifeInstruction}
                           onChange={event => setSelectedEndOfLifeInstruction(event.target.value)}
                        >
                           {endOfLifeInstructions.data.map(eoli => (<MenuItem key={eoli.id} value={eoli.id}>{eoli.name}</MenuItem>))}
                        </Select>
                     </FormControl>
                  </Grid> : <LoaderIcon />}

                  {selectedEndOfLifeInstruction === 'specify' ? <Grid item md={12}>
                     <FormControl fullWidth>
                        <TextField
                           margin="dense"
                           id="keep-alive-procedures-input"
                           label="Specify Procedures"
                           type="text"
                           value={formValues.keepAliveProcedures}
                           onChange={event => setFormValues({ ...formValues, keepAliveProcedures: event.target.value })}
                           error={formValidation.keepAliveProcedures ? true : false}
                           helperText={formValidation.keepAliveProcedures}
                           fullWidth
                        />
                     </FormControl>
                  </Grid> : null}

                  <Grid item xs={12}>
                     <DialogContentText>
                        <b>Would you want relief from unbearable pain?</b>
                     </DialogContentText>
                  </Grid>

                  {painManagement.loading !== true ? <Grid item xs={12}>
                     <FormControl fullWidth>
                        <Select
                           id="pain-management-select"
                           value={selectedPainManagement}
                           onChange={event => setSelectedPainManagement(event.target.value)}
                        >
                           {painManagement.data.map(pm => (<MenuItem key={pm.id} value={pm.id}>{pm.name}</MenuItem>))}
                        </Select>
                     </FormControl>
                  </Grid> : <LoaderIcon />}

                  <Grid item xs={12}>
                     <DialogContentText>
                        <b>Where would you like to receive your end-of-life care?</b>
                     </DialogContentText>
                  </Grid>

                  {endOfLifeCareLocationTypes.loading !== true ? <Grid item xs={12}>
                     <FormControl fullWidth>
                        <Select
                           id="end-of-life-care-location-type-select"
                           value={selectedEndOfLifeCareLocationType}
                           onChange={event => setSelectedEndOfLifeCareLocationType(event.target.value)}
                        >
                           {endOfLifeCareLocationTypes.data.map(pm => (<MenuItem key={pm.id} value={pm.id}>{pm.name}</MenuItem>))}
                        </Select>
                     </FormControl>
                  </Grid> : <LoaderIcon />}

                  {selectedEndOfLifeCareLocationType === 'other' ? <Grid item md={12}>
                     <FormControl fullWidth>
                        <TextField
                           margin="dense"
                           id="end-of-life-care-location-details-input"
                           label="Specify Other"
                           type="text"
                           value={formValues.endOfLifeCareLocationDetails}
                           onChange={event => setFormValues({ ...formValues, endOfLifeCareLocationDetails: event.target.value })}
                           error={formValidation.endOfLifeCareLocationDetails ? true : false}
                           helperText={formValidation.endOfLifeCareLocationDetails}
                           fullWidth
                        />
                     </FormControl>
                  </Grid> : null}

                  <Grid item xs={12}>
                     <DialogContentText>
                        <IconButton aria-describedby={organPopoverId} onClick={handleOrganDonationPopoverClick}>
                           <InfoIcon />
                        </IconButton>
                        <b>Would you like to donate any of your organs, tissues, or body parts upon your death?</b>
                     </DialogContentText>
                  </Grid>
                  <Popover
                     id={organPopoverId}
                     open={organPopoverOpen}
                     anchorEl={organDonationPopoverAnchorEl}
                     onClose={handleOrganDonationPopoverClose}
                     anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                     }}
                     sx={{ maxWidth: 600 }}
                  >
                     <Alert sx={{ minWidth: 520 }} severity="info">You can donate specific organs such as kidneys, liver, lungs, heart to be for use in medically acceptable transplantation. Different forms of eye-related anatomical gifts include cornea donation or whole-eye donations. Tissues such as tendons, heart valves and bone can be donated for use in medical treatments. The brain and spinal cord can be donated for medical research.</Alert>
                  </Popover>

                  <Grid item xs={12}>
                     <FormGroup>
                        <FormControlLabel control={<Switch
                           id="donate-my-organs-switch"
                           checked={formValues.donateMyOrgans}
                           onChange={(event) => setFormValues({ ...formValues, donateMyOrgans: event.target.checked })}
                           inputProps={{ 'aria-label': 'donate-my-organs-switch' }}
                        />} label="Donate Any Organs?" />
                     </FormGroup>
                  </Grid>
                  {formValues.donateMyOrgans ? <Grid item md={12}>
                     <FormControl fullWidth>
                        <TextField
                           margin="dense"
                           id="organs-to-donate-input"
                           label="Organs To Donate"
                           type="text"
                           value={formValues.organsToDonate}
                           onChange={event => setFormValues({ ...formValues, organsToDonate: event.target.value })}
                           error={formValidation.organsToDonate ? true : false}
                           helperText={formValidation.organsToDonate}
                           fullWidth
                        />
                     </FormControl>
                  </Grid> : null}

                  <Grid item xs={12}>
                     <DialogContentText>
                        <IconButton aria-describedby={otherWishesPopoverId} onClick={handleOtherWishesPopoverClick}>
                           <InfoIcon />
                        </IconButton>
                        <b>Are there any other wishes you would like to include in this directive?</b>
                     </DialogContentText>
                  </Grid>
                  <Popover
                     id={otherWishesPopoverId}
                     open={otherWishesPopoverOpen}
                     anchorEl={otherWishesPopoverAnchorEl}
                     onClose={handleOtherWishesPopoverClose}
                     anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                     }}
                     sx={{ maxWidth: 600 }}
                  >
                     <Alert sx={{ minWidth: 520 }} severity="info">You can write other important preferences for your health care that aren’t described somewhere else in this document. For example, these might be social, cultural, or faith-based preferences for care, or preferences about treatments such as feeding tubes, blood transfusions, or pain medications.</Alert>
                  </Popover>

                  <Grid item xs={12}>
                     <FormControl fullWidth>
                        <TextAreaWithLimit
                           id="other-health-wishes-input"
                           label="Other Health Wishes"
                           maxLength={255}
                           value={formValues.otherHealthWishes}
                           onChange={event => setFormValues({ ...formValues, otherHealthWishes: event.target.value })}
                           error={formValidation.otherHealthWishes ? true : false}
                           helperText={formValidation.otherHealthWishes}
                           placeholder="Other Health Wishes"
                           rows={4}
                        />
                     </FormControl>
                  </Grid>
               </Grid>
            </Box>
         </DialogContent>
         <DialogActions>
            <Button variant="outlined" startIcon={<CancelIcon />} onClick={props.handleClose}>Close</Button>
            <Button variant="contained" startIcon={<CheckIcon />} onClick={handleSaveCareAndHealthButtonClick}>Save</Button>
         </DialogActions>
      </Dialog>
   );
});

CareAndHealthDialog.propTypes = {
   open: PropTypes.bool.isRequired,
   handleClose: PropTypes.func.isRequired,
   handleSaveCareAndHealth: PropTypes.func.isRequired,
};

export default CareAndHealthDialog;
