import axiosService from './AxiosService';
import parseISO from 'date-fns/parseISO';

export const parseMausoleumDates = (mausoleum) => ({
   ...mausoleum,
   dateOfBirth: mausoleum.dateOfBirth ? parseISO(mausoleum.dateOfBirth) : null,
   dateOfDeath: mausoleum.dateOfDeath ? parseISO(mausoleum.dateOfDeath) : null
});

export class MausoleumService {
   _resourceName = 'mausoleum';

   async getMyMausoleum() {
      return await axiosService.readObject(`/v1.0/${this._resourceName}`);
   }

   async getMyMausoleumPhotos() {
      return await axiosService.readList(`/v1.0/${this._resourceName}/photos`);
   }

   async putMyMausoleum(mausoleum) {
      return await axiosService.update(`/v1.0/${this._resourceName}`, mausoleum);
   }

   async getMausolea(queryOptions) {
      return await axiosService.create(`/v1.0/${this._resourceName}/search`, queryOptions);
   }

   async getMausoleaByViews() {
      return await axiosService.readList(`/v1.0/${this._resourceName}/search-by-views`);
   }

   async getMausoleaManagedByMe() {
      return await axiosService.readList(`/v1.0/${this._resourceName}/managed-by-me`);
   }

   async getMausoleum(mausoleumId) {
      return await axiosService.readObject(`/v1.0/${this._resourceName}`, mausoleumId);
   }

   async favorite(mausoleumId){
      return await axiosService.updateFlag(`/v1.0/${this._resourceName}/${mausoleumId}`, 'favorite');
   }

   async unfavorite(mausoleumId){
      return await axiosService.updateFlag(`/v1.0/${this._resourceName}/${mausoleumId}`, 'un-favorite');
   }

   async getMausoleumComments(mausoleumId) {
      return await axiosService.readList(`/v1.0/${this._resourceName}/${mausoleumId}/comments`);
   }

   async getMausoleumPhotos(mausoleumId) {
      return await axiosService.readList(`/v1.0/${this._resourceName}/${mausoleumId}/photos`);
   }

   async addComment(mausoleumId, comment) {
      return await axiosService.create(`/v1.0/${this._resourceName}/${mausoleumId}/comment`, { text: comment });
   }

   async reportComment(commentId) {
      return await axiosService.updateFlag(`/v1.0/${this._resourceName}`, 'report-comment', commentId);
   }

   async putMausoleumImage(image) {
      return await axiosService.update(`/v1.0/${this._resourceName}/image`, image);
   }

   async putMausoleumBannerById(id) {
      return await axiosService.updateFlag(`/v1.0/${this._resourceName}`, 'banner-by-id', id);
   }

   async getMyMausoleumBioQuestions() {
      return await axiosService.readList(`/v1.0/${this._resourceName}/bio-questions`);
   }

   async getMausoleumBioAnswers(mausoleumId) {
      return await axiosService.readList(`/v1.0/${this._resourceName}/${mausoleumId}/bio-questions`);
   }

   async addMausoleumBioQuestion(question) {
      return await axiosService.create(`/v1.0/${this._resourceName}/bio-questions`, question);
   }

   async putMausoleumBioQuestion(question) {
      return await axiosService.update(`/v1.0/${this._resourceName}/bio-questions`, question);
   }

   async deleteMausoleumBioQuestion(question) {
      return await axiosService.delete(`/v1.0/${this._resourceName}/bio-questions`, question.id);
   }

   async addOtherMausoleum(mausoleum) {
      return await axiosService.create(`/v1.0/${this._resourceName}/other`, mausoleum);
   }

   static get instance() { return mausoleumService }
}

const mausoleumService = new MausoleumService();

export default mausoleumService;
