export const AssetColConfig = [
   {
      id: 'assetType',
      rightAlign: false,
      disablePadding: false,
      label: 'Type',
      specialRenderer: 'lookupValue',
   },
   {
      id: 'base64PhotoScaled',
      rightAlign: false,
      disablePadding: false,
      label: 'Photo',
      specialRenderer: 'image',
   },
   {
      id: 'identifier',
      rightAlign: false,
      disablePadding: false,
      label: 'Identifier',
   },
   {
      id: 'make',
      rightAlign: false,
      disablePadding: false,
      label: 'Make',
   },
   {
      id: 'model',
      rightAlign: false,
      disablePadding: false,
      label: 'Model',
   },
   {
      id: 'year',
      rightAlign: false,
      disablePadding: false,
      label: 'Year',
   },
   {
      id: 'location',
      rightAlign: false,
      disablePadding: false,
      label: 'Location',
      specialRenderer: 'address',
   },
   {
      id: 'description',
      rightAlign: false,
      disablePadding: false,
      label: 'Description',
      specialRenderer: 'longText',
   },
];

export const InstitutionColConfig = [
   {
      id: 'institutionType',
      rightAlign: false,
      disablePadding: false,
      label: 'Type',
      specialRenderer: 'lookupValue',
   },
   {
      id: 'companyName',
      rightAlign: false,
      disablePadding: false,
      label: 'Company Name',
   },
   {
      id: 'website',
      rightAlign: false,
      disablePadding: false,
      label: 'Website',
      blankMessage: 'Not Set.'
   },
   {
      id: 'contactName',
      rightAlign: false,
      disablePadding: false,
      label: 'Contact Name',
      blankMessage: 'Not Set.'
   },
   {
      id: 'phone',
      rightAlign: false,
      disablePadding: false,
      label: 'Phone',
      specialRenderer: 'phone',
   },
   {
      id: 'email',
      rightAlign: false,
      disablePadding: false,
      label: 'Email',
      specialRenderer: 'email',
   },
   {
      id: 'location',
      rightAlign: false,
      disablePadding: false,
      label: 'Location',
      specialRenderer: 'address',
   },
   {
      id: 'accountIdentifier',
      rightAlign: false,
      disablePadding: false,
      label: 'Account Identifier',
   },
   {
      id: 'description',
      rightAlign: false,
      disablePadding: false,
      label: 'Description',
      specialRenderer: 'longText',
   },
];

export const DocumentColConfig = [
   {
      id: 'documentType',
      rightAlign: false,
      disablePadding: false,
      label: 'Type',
      specialRenderer: 'lookupValue',
   },
   {
      id: 'fileName',
      rightAlign: false,
      disablePadding: false,
      label: 'File Name',
   },
   {
      id: 'description',
      rightAlign: false,
      disablePadding: false,
      label: 'Description',
      specialRenderer: 'longText',
   },
   {
      id: 'uploadedAt',
      rightAlign: false,
      disablePadding: false,
      label: 'Uploaded At',
      specialRenderer: 'dateTime',
   },
];

export const TrusteeColConfig = [
   {
      id: 'firstName',
      rightAlign: false,
      disablePadding: false,
      label: 'First Name',
   },
   {
      id: 'lastName',
      rightAlign: false,
      disablePadding: false,
      label: 'Last Name',
   },
   // {
   //    id: 'primaryPhone',
   //    rightAlign: false,
   //    disablePadding: false,
   //    label: 'Primary Phone',
   // },
   // {
   //    id: 'secondaryPhone',
   //    rightAlign: false,
   //    disablePadding: false,
   //    label: 'Secondary Phone',
   // },
   {
      id: 'email',
      rightAlign: false,
      disablePadding: false,
      label: 'Email',
      specialRenderer: 'email',
   },
   {
      id: 'address',
      rightAlign: false,
      disablePadding: false,
      label: 'Address',
      specialRenderer: 'address',
   },
];

export const OtherContactColConfig = [
   {
      id: 'otherContactType',
      rightAlign: false,
      disablePadding: false,
      label: 'Type',
      specialRenderer: 'lookupValue',
   },
   {
      id: 'firstName',
      rightAlign: false,
      disablePadding: false,
      label: 'First Name',
   },
   {
      id: 'lastName',
      rightAlign: false,
      disablePadding: false,
      label: 'Last Name',
   },
   {
      id: 'primaryPhone',
      rightAlign: false,
      disablePadding: false,
      label: 'Primary Phone',
      specialRenderer: 'phone',
   },
   // {
   //    id: 'secondaryPhone',
   //    rightAlign: false,
   //    disablePadding: false,
   //    label: 'Secondary Phone',
   // },
   {
      id: 'email',
      rightAlign: false,
      disablePadding: false,
      label: 'Email',
      specialRenderer: 'email',
   },
   {
      id: 'address',
      rightAlign: false,
      disablePadding: false,
      label: 'Address',
      specialRenderer: 'address',
   },
   {
      id: 'notes',
      rightAlign: false,
      disablePadding: false,
      label: 'Notes',
      emptyValue: 'N/A',
      specialRenderer: 'longText',
   },
];

export const PropertyColConfig = [
   {
      id: 'assetType',
      rightAlign: false,
      disablePadding: false,
      label: 'Type',
      specialRenderer: 'lookupValue',
   },
   {
      id: 'base64PhotoScaled',
      rightAlign: false,
      disablePadding: false,
      label: 'Photo',
      specialRenderer: 'image',
   },
   {
      id: 'identifier',
      rightAlign: false,
      disablePadding: false,
      label: 'Name',
      blankMessage: 'Not Set.'
   },
   {
      id: 'year',
      rightAlign: false,
      disablePadding: false,
      label: 'Year Purchased',
   },
   {
      id: 'location',
      rightAlign: false,
      disablePadding: false,
      label: 'Location',
      specialRenderer: 'address',
   },
   {
      id: 'description',
      rightAlign: false,
      disablePadding: false,
      label: 'Description',
      specialRenderer: 'longText',
   },
];

export const AutoColConfig = [
   {
      id: 'assetType',
      rightAlign: false,
      disablePadding: false,
      label: 'Type',
      specialRenderer: 'lookupValue',
   },
   {
      id: 'base64PhotoScaled',
      rightAlign: false,
      disablePadding: false,
      label: 'Photo',
      specialRenderer: 'image',
   },
   {
      id: 'identifier',
      rightAlign: false,
      disablePadding: false,
      label: 'VIN',
      blankMessage: 'Not Set.'
   },
   {
      id: 'make',
      rightAlign: false,
      disablePadding: false,
      label: 'Make',
      blankMessage: 'Not Set.'
   },
   {
      id: 'model',
      rightAlign: false,
      disablePadding: false,
      label: 'Model',
      blankMessage: 'Not Set.'
   },
   {
      id: 'year',
      rightAlign: false,
      disablePadding: false,
      label: 'Year',
   },
   {
      id: 'location',
      rightAlign: false,
      disablePadding: false,
      label: 'Location',
      specialRenderer: 'address',
   },
   {
      id: 'description',
      rightAlign: false,
      disablePadding: false,
      label: 'Description',
      specialRenderer: 'longText',
   },
];

export const MotoColConfig = [
   {
      id: 'assetType',
      rightAlign: false,
      disablePadding: false,
      label: 'Type',
      specialRenderer: 'lookupValue',
   },
   {
      id: 'base64PhotoScaled',
      rightAlign: false,
      disablePadding: false,
      label: 'Photo',
      specialRenderer: 'image',
   },
   {
      id: 'identifier',
      rightAlign: false,
      disablePadding: false,
      label: 'VIN',
   },
   {
      id: 'make',
      rightAlign: false,
      disablePadding: false,
      label: 'Make',
   },
   {
      id: 'model',
      rightAlign: false,
      disablePadding: false,
      label: 'Model',
   },
   {
      id: 'year',
      rightAlign: false,
      disablePadding: false,
      label: 'Year',
   },
   {
      id: 'location',
      rightAlign: false,
      disablePadding: false,
      label: 'Location',
      specialRenderer: 'address',
   },
   {
      id: 'description',
      rightAlign: false,
      disablePadding: false,
      label: 'Description',
      specialRenderer: 'longText',
   },
];

export const WatercraftColConfig = [
   {
      id: 'assetType',
      rightAlign: false,
      disablePadding: false,
      label: 'Type',
      specialRenderer: 'lookupValue',
   },
   {
      id: 'base64PhotoScaled',
      rightAlign: false,
      disablePadding: false,
      label: 'Photo',
      specialRenderer: 'image',
   },
   {
      id: 'identifier',
      rightAlign: false,
      disablePadding: false,
      label: 'HIN',
   },
   {
      id: 'make',
      rightAlign: false,
      disablePadding: false,
      label: 'Make',
   },
   {
      id: 'model',
      rightAlign: false,
      disablePadding: false,
      label: 'Model',
   },
   {
      id: 'year',
      rightAlign: false,
      disablePadding: false,
      label: 'Year',
   },
   {
      id: 'location',
      rightAlign: false,
      disablePadding: false,
      label: 'Location',
      specialRenderer: 'address',
   },
   {
      id: 'description',
      rightAlign: false,
      disablePadding: false,
      label: 'Description',
      specialRenderer: 'longText',
   },
];

export const RVColConfig = [
   {
      id: 'assetType',
      rightAlign: false,
      disablePadding: false,
      label: 'Type',
      specialRenderer: 'lookupValue',
   },
   {
      id: 'base64PhotoScaled',
      rightAlign: false,
      disablePadding: false,
      label: 'Photo',
      specialRenderer: 'image',
   },
   {
      id: 'identifier',
      rightAlign: false,
      disablePadding: false,
      label: 'VIN',
   },
   {
      id: 'make',
      rightAlign: false,
      disablePadding: false,
      label: 'Make',
   },
   {
      id: 'model',
      rightAlign: false,
      disablePadding: false,
      label: 'Model',
   },
   {
      id: 'year',
      rightAlign: false,
      disablePadding: false,
      label: 'Year',
   },
   {
      id: 'location',
      rightAlign: false,
      disablePadding: false,
      label: 'Location',
      specialRenderer: 'address',
   },
   {
      id: 'description',
      rightAlign: false,
      disablePadding: false,
      label: 'Description',
      specialRenderer: 'longText',
   },
];

export const ArtColConfig = [
   {
      id: 'assetType',
      rightAlign: false,
      disablePadding: false,
      label: 'Type',
      specialRenderer: 'lookupValue',
   },
   {
      id: 'base64PhotoScaled',
      rightAlign: false,
      disablePadding: false,
      label: 'Photo',
      specialRenderer: 'image',
   },
   {
      id: 'identifier',
      rightAlign: false,
      disablePadding: false,
      label: 'Identifier',
   },
   {
      id: 'make',
      rightAlign: false,
      disablePadding: false,
      label: 'Artist',
   },
   {
      id: 'year',
      rightAlign: false,
      disablePadding: false,
      label: 'Year Acquired',
   },
   {
      id: 'location',
      rightAlign: false,
      disablePadding: false,
      label: 'Location',
      specialRenderer: 'address',
   },
   {
      id: 'description',
      rightAlign: false,
      disablePadding: false,
      label: 'Description',
      specialRenderer: 'longText',
   },
];

export const EquipmentColConfig = [
   {
      id: 'assetType',
      rightAlign: false,
      disablePadding: false,
      label: 'Type',
      specialRenderer: 'lookupValue',
   },
   {
      id: 'base64PhotoScaled',
      rightAlign: false,
      disablePadding: false,
      label: 'Photo',
      specialRenderer: 'image',
   },
   {
      id: 'identifier',
      rightAlign: false,
      disablePadding: false,
      label: 'Serial Number',
   },
   {
      id: 'make',
      rightAlign: false,
      disablePadding: false,
      label: 'Manufacturer',
   },
   {
      id: 'model',
      rightAlign: false,
      disablePadding: false,
      label: 'Model',
   },
   {
      id: 'year',
      rightAlign: false,
      disablePadding: false,
      label: 'Year',
   },
   {
      id: 'location',
      rightAlign: false,
      disablePadding: false,
      label: 'Location',
      specialRenderer: 'address',
   },
   {
      id: 'description',
      rightAlign: false,
      disablePadding: false,
      label: 'Description',
      specialRenderer: 'longText',
   },
];

export const FurnitureColConfig = [
   {
      id: 'assetType',
      rightAlign: false,
      disablePadding: false,
      label: 'Type',
      specialRenderer: 'lookupValue',
   },
   {
      id: 'base64PhotoScaled',
      rightAlign: false,
      disablePadding: false,
      label: 'Photo',
      specialRenderer: 'image',
   },
   {
      id: 'identifier',
      rightAlign: false,
      disablePadding: false,
      label: 'Name',
   },
   {
      id: 'make',
      rightAlign: false,
      disablePadding: false,
      label: 'Manufacturer',
   },
   {
      id: 'model',
      rightAlign: false,
      disablePadding: false,
      label: 'Model',
   },
   {
      id: 'year',
      rightAlign: false,
      disablePadding: false,
      label: 'Year',
   },
   {
      id: 'location',
      rightAlign: false,
      disablePadding: false,
      label: 'Location',
      specialRenderer: 'address',
   },
   {
      id: 'description',
      rightAlign: false,
      disablePadding: false,
      label: 'Description',
      specialRenderer: 'longText',
   },
];

export const HeirloomColConfig = [
   {
      id: 'assetType',
      rightAlign: false,
      disablePadding: false,
      label: 'Type',
      specialRenderer: 'lookupValue',
   },
   {
      id: 'base64PhotoScaled',
      rightAlign: false,
      disablePadding: false,
      label: 'Photo',
      specialRenderer: 'image',
   },
   {
      id: 'identifier',
      rightAlign: false,
      disablePadding: false,
      label: 'Name',
   },
   {
      id: 'make',
      rightAlign: false,
      disablePadding: false,
      label: 'Manufacturer',
   },
   {
      id: 'model',
      rightAlign: false,
      disablePadding: false,
      label: 'Model',
   },
   {
      id: 'year',
      rightAlign: false,
      disablePadding: false,
      label: 'Year',
   },
   {
      id: 'location',
      rightAlign: false,
      disablePadding: false,
      label: 'Location',
      specialRenderer: 'address',
   },
   {
      id: 'description',
      rightAlign: false,
      disablePadding: false,
      label: 'Description',
      specialRenderer: 'longText',
   },
];

export const FirearmColConfig = [
   {
      id: 'assetType',
      rightAlign: false,
      disablePadding: false,
      label: 'Type',
      specialRenderer: 'lookupValue',
   },
   {
      id: 'base64PhotoScaled',
      rightAlign: false,
      disablePadding: false,
      label: 'Photo',
      specialRenderer: 'image',
   },
   {
      id: 'identifier',
      rightAlign: false,
      disablePadding: false,
      label: 'Serial Number',
   },
   {
      id: 'make',
      rightAlign: false,
      disablePadding: false,
      label: 'Make',
   },
   {
      id: 'model',
      rightAlign: false,
      disablePadding: false,
      label: 'Model',
   },
   {
      id: 'year',
      rightAlign: false,
      disablePadding: false,
      label: 'Year',
   },
   {
      id: 'location',
      rightAlign: false,
      disablePadding: false,
      label: 'Location',
      specialRenderer: 'address',
   },
   {
      id: 'description',
      rightAlign: false,
      disablePadding: false,
      label: 'Description',
      specialRenderer: 'longText',
   },
];

export const PetWishColConfig = [
   {
      id: 'name',
      rightAlign: false,
      disablePadding: false,
      label: 'Name',
   },
   {
      id: 'animalType',
      rightAlign: false,
      disablePadding: false,
      label: 'Animal Type',
   },
   {
      id: 'futureGuardianName',
      rightAlign: false,
      disablePadding: false,
      label: 'Future Guardian Name',
   },
   {
      id: 'specialAccommodations',
      rightAlign: false,
      disablePadding: false,
      label: 'Special Accommodations',
      specialRenderer: 'longText',
   },
];

export const BeneficiaryColConfig = [
   {
      id: 'firstName',
      rightAlign: false,
      disablePadding: false,
      label: 'First Name',
   },
   {
      id: 'lastName',
      rightAlign: false,
      disablePadding: false,
      label: 'Last Name',
   },
];
