import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { ApplicationPaths } from './ApiAuthorizationConstants';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import SecurityIcon from '@mui/icons-material/Security';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import AvatarInitials from '../AvatarInitials';

const LoginMenu = (props) => {
   const [anchorElUser, setAnchorElUser] = useState(null);

   const handleOpenUserMenu = (event) => {
      setAnchorElUser(event.currentTarget);
   };

   const handleCloseUserMenu = () => {
      setAnchorElUser(null);
   };

   const authenticatedView = (userName, basicProfile, profilePath, logoutPath) => {
      return (<Fragment>
         <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Account Settings">
               <Button
                  onClick={handleOpenUserMenu}
                  sx={{ p: 0 }}
                  color="inherit"
               >
                  <AvatarInitials basicProfile={basicProfile} />
               </Button>
            </Tooltip>
            <Menu
               sx={{ mt: '45px' }}
               id="menu-appbar"
               anchorEl={anchorElUser}
               anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
               }}
               keepMounted
               transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
               }}
               open={Boolean(anchorElUser)}
               onClose={handleCloseUserMenu}
            >
               {props.basicProfile && props.basicProfile.id ? <MenuItem
                  onClick={handleCloseUserMenu}
                  component={Link}
                  to="/my-profile"
               >
                  <ListItemIcon>
                     <PersonIcon fontSize="small" />
                  </ListItemIcon>
                  My Profile
               </MenuItem> : null}
               {props.basicProfile && props.basicProfile.id ? <MenuItem
                  onClick={handleCloseUserMenu}
                  component={Link}
                  to={profilePath}
               >
                  <ListItemIcon>
                     <SecurityIcon fontSize="small" />
                  </ListItemIcon>
                  Security
               </MenuItem> : null}
               <MenuItem
                  onClick={handleCloseUserMenu}
                  component={Link}
                  to={logoutPath}
               >
                  <ListItemIcon>
                     <LogoutIcon fontSize="small" />
                  </ListItemIcon>
                  Sign Out
               </MenuItem>
            </Menu>
         </Box>
      </Fragment>);
   }

   const anonymousView = (registerPath, loginPath) => {
      return (<Fragment>
         <Button
            component={Link}
            to={registerPath}
            color="inherit"
         >Sign Up</Button>
         <Button
            component={Link}
            to={loginPath}
            color="inherit"
         >Sign In</Button>
      </Fragment>);
   }

   if (!props.isAuthenticated) {
      const registerPath = `${ApplicationPaths.Register}`;
      const loginPath = `${ApplicationPaths.Login}`;
      return anonymousView(registerPath, loginPath);
   } else {
      const profilePath = `${ApplicationPaths.Profile}`;
      const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
      return authenticatedView(props.userName, props.basicProfile, profilePath, logoutPath);
   }
};

LoginMenu.propTypes = {
   isAuthenticated: PropTypes.bool.isRequired,
   userName: PropTypes.string,
   basicProfile: PropTypes.object,
};

export default LoginMenu;
