import axiosService from './AxiosService';

export class DocumentService {
   _resourceName = 'document';

   async addDocument(document) {
      return await axiosService.create(`/v1.0/${this._resourceName}`, document);
   }

   async putDocument(document) {
      return await axiosService.update(`/v1.0/${this._resourceName}`, document);
   }

   async getDocuments(documentTypeId) {
      return await axiosService.readList(`/v1.0/${this._resourceName}/search${(documentTypeId ? `/${documentTypeId}` : '')}`);
   }

   async deleteDocument(id) {
      return await axiosService.delete(`/v1.0/${this._resourceName}`, id);
   }

   async getDocumentCounts() {
      return await axiosService.readList(`/v1.0/${this._resourceName}/count`);
   }

   async downloadDocument(id) {
      return await axiosService.readFile(`/v1.0/${this._resourceName}/download`, id);
   }

   static get instance() { return documentService }
}

const documentService = new DocumentService();

export default documentService;
