import React, { useState, forwardRef, useImperativeHandle, Fragment } from 'react';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
// import FormGroup from '@mui/material/FormGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Switch from '@mui/material/Switch';
import TextAreaWithLimit from './TextAreaWithLimit';

const MausoleumForm = forwardRef((props, ref) => {
   const [formState, setFormState] = useState({
      epitaph: '',
      isPublic: true, //currently not user editable, but may be again in future
   });

   // validation
   const [formValidation, setFormValidation] = useState({});
   const formIsValid = () => {
      let errors = {};

      if (formState.epitaph && formState.epitaph.length < 2)
         errors.epitaph = 'Epitaph must be at least two characters if provided.';

      setFormValidation(errors);

      return Object.keys(errors).length === 0;
   };

   // control validation, state from parent component
   useImperativeHandle(ref, () => ({
      validate() {
         return formIsValid();
      },

      setState(state) {
         setFormState({
            epitaph: state.epitaph ||  '',
            isPublic: state.isPublic,
         });
      },

      getState() {
         return formState;
      }
   }));

   return (<Fragment>
      <Grid item xs={12}>
         <FormControl fullWidth>
            <TextAreaWithLimit
               autoFocus
               id="epitaph-input"
               label="Epitaph"
               maxLength={255}
               value={formState.epitaph}
               onChange={event => setFormState({ ...formState, epitaph: event.target.value })}
               error={formValidation.epitaph ? true : false}
               helperText={formValidation.epitaph}
               placeholder="Add an epitaph..."
               rows={3}
            />
         </FormControl>
      </Grid>
      {/* currently not user editable, but may be again in future */}
      {/* <Grid item xs={12}>
         <FormGroup>
            <FormControlLabel control={<Switch
               id="is-public-switch"
               checked={formState.isPublic}
               onChange={(event) => setFormState({ ...formState, isPublic: event.target.checked })}
               inputProps={{ 'aria-label': 'is-public-switch' }}
            />} label="Make Public" />
         </FormGroup>
      </Grid> */}
   </Fragment>);
});

export default MausoleumForm;
