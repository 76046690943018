import React, { useState, useEffect, useRef, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { Button, Card, CardContent, CardHeader, Grid, List, ListItem, ListItemText, Paper, Tooltip } from '@mui/material';
import LoaderIcon from '../components/LoaderIcon';
import { Box } from '@mui/system';
import otherContactService from '../data/OtherContactService';
import OtherContactDialog from '../components/OtherContactDialog';
import systemLookupService from '../data/SystemLookupService';
import PhoneNumberDisplay from '../components/PhoneNumberDisplay';
import InviteDialog from '../components/InviteDialog';
import trusteeInviteService from '../data/TrusteeInviteService';
import trusteeService from '../data/TrusteeService';
import PubSub from 'pubsub-js';
import assetService from '../data/AssetService';
import AssetDialog from '../components/AssetDialog';
import { CSSTransition } from 'react-transition-group';
import { Link } from 'react-router-dom';
import { useQuery } from '../utils/useQuery';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import TextDisplay from '../components/TextDisplay';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';
import AlertSubscriptionResult from '../components/AlertSubscriptionResult';
import familyProfileInviteService from '../data/FamilyProfileInviteService';
import CreateFamilyProfileWidgetBody from '../components/CreateFamilyProfileWidgetBody';
import subscriptionService from '../data/SubscriptionService';

const WizardPages = Object.freeze([
   {'value': 'family-invite', 'label': 'Family Invite'},
   {'value': 'other-contact', 'label': 'Contacts'},
   {'value': 'invite-trustee', 'label': 'Trustees'},
   {'value': 'add-assets', 'label': 'Assets'},
]);

const GetStartedOptionalPage = () => {
   const query = useQuery();

   let wizardPage = WizardPages[Number.parseInt(query.get('page'))] || WizardPages[0];
   let subscriptionPurchaseResult = query.get('subscription-purchase-result') || null;
   let subscriptionTier = query.get('subscription-tier') || null;

   const [otherContactDialogOpen, setOtherContactDialogOpen] = useState(false);
   const [trusteeInviteDialogOpen, setTrusteeInviteDialogOpen] = useState(false);
   const [assetDialogOpen, setAssetDialogOpen] = useState(false);
   const [otherContacts, setOtherContacts] = useState({ data: [], limits: {}, loading: false });
   const [otherContactTypes, setOtherContactTypes] = useState({ data: [], loading: false });
   const [trusteeInvites, setTrusteeInvites] = useState({ data: [], limits: {}, loading: false });
   const [trustees, setTrustees] = useState({ data: [], limits: {}, loading: false });
   const [assetTypes, setAssetTypes] = useState({ data: [], loading: false });
   const [assets, setAssets] = useState({ data: [], limits: {}, loading: true });
   const [addressStatesProvinces, setAddressStatesProvinces] = useState({ data: [], loading: false });
   const [familyProfileInviteDialogOpen, setFamilyProfileInviteDialogOpen] = useState(false);
   const [familyProfileInvites, setFamilyProfileInvites] = useState({ data: [], loading: true });
   const [currentSubscriptionStatus, setCurrentSubscriptionStatus] = useState({data: null, loading: false});

   const otherContactDialogRef = useRef();

   useEffect(() => {
      getCurrentSubscriptionStatus();
      getFamilyProfileInvites();
      getAddressStatesProvinces();
      getOtherContacts();
      getOtherContactTypes();
      getTrusteeInvites();
      getTrustees();
      getAssetTypes();
      getAssets();
   }, [subscriptionPurchaseResult, subscriptionTier]);
   const history = useHistory();

   const incrementWizardPage = () => {
      if(WizardPages[WizardPages.length - 1] === wizardPage) {
         history.push('/');
      } else {
         var pos = WizardPages.indexOf(wizardPage);
         wizardPage = WizardPages[pos + 1];
         history.push('/get-started-optional?page=' + (pos + 1));
      }
   };

   const decrementWizardPage = () => {
      var pos = WizardPages.indexOf(wizardPage);
      wizardPage = WizardPages[pos - 1];
      history.push('/get-started-optional?page=' + (pos - 1));
   };

   const renderWizardPage = () => {
      return (<Fragment>
         <CSSTransition in={wizardPage.value === 'family-invite'} timeout={100} classNames="wizard-transition" unmountOnExit component={null}>
            <Paper sx={{ width: '60vw', borderRadius: '0.9em' }} elevation={3}>
               <Grid container spacing={0}>
                  <Grid item xs={12} sx={{height: '55vh'}}>
                     <Typography variant="subtitle1">
                        With your family subscription, you can invite another user to share a profile with. You can also skip this step for now and invite them later from My Profile.
                     </Typography>

                     <CreateFamilyProfileWidgetBody
                        loading={currentSubscriptionStatus.loading || familyProfileInvites.loading}
                        subscriptionStatus={subscriptionPurchaseResult === 'success' && subscriptionTier === 'family' ? {id: 'family', name: 'family'} : currentSubscriptionStatus.data}
                        familyProfileInvites={familyProfileInvites.data}
                        handleInviteUserToProfile={() => setFamilyProfileInviteDialogOpen(true)}
                        handleUpgradeToFamily={handleUpgradeToFamily}
                        deleteFamilyProfileInvite={deleteFamilyProfileInvite}
                     />
                  </Grid>
                  <Grid item xs={2} sx={{ display: 'flex' }}>
                     {WizardPages[0] !== wizardPage ? <Button onClick={decrementWizardPage} startIcon={<ArrowBackIcon />} variant="outlined">
                        Back
                     </Button>: null}
                  </Grid>
                  <Grid item xs={7} sx={{ display: 'flex' }} />
                  <Grid item xs={3} sx={{ display: 'flex' }} justifyContent="right">
                     <Button
                        variant="outlined"
                        component={Link}
                        to="/"
                     >
                        Skip
                     </Button>
                     <Button
                        onClick={incrementWizardPage}
                        startIcon={WizardPages[WizardPages.length - 1] !== wizardPage ? <ArrowForwardIcon /> : <CheckIcon />}
                        variant="contained"
                        sx={{ ml: 1 }}
                     >
                        {WizardPages[WizardPages.length - 1] !== wizardPage ? 'Continue' : 'Finish'}
                     </Button>
                  </Grid>
               </Grid>
            </Paper>
         </CSSTransition>
         <CSSTransition in={wizardPage.value === 'other-contact'} timeout={100} classNames="wizard-transition" unmountOnExit component={null}>
            <Paper sx={{ width: '60vw', borderRadius: '0.9em' }} elevation={3}>
               <Grid container spacing={0}>
                  <Grid item xs={12} sx={{height: '55vh'}}>
                     <Typography variant="subtitle1">
                        LifeTomb allows you to list important contacts, such as family members, friends or other people who'll play a role.
                     </Typography>
                     <Grid container>
                        <Grid item xs={4} sx={{ display: 'flex' }}/>
                        <Grid item xs={4} display="grid" alignItems="center" justifyContent="center">
                           <Button
                              variant="contained"
                              onClick={() => setOtherContactDialogOpen(true)}
                           >
                              Add Important Contact
                           </Button>
                        </Grid>
                        <Grid item xs={4} sx={{ display: 'flex' }}/>
                     </Grid>
                     <Fragment>
                        <b>Important Contacts</b>
                        <List sx={{ width: '100%', bgcolor: 'background.paper', maxHeight: '20em', overflowY: 'auto' }}>
                           {otherContacts.data.map(contact => renderContact(contact))}
                        </List>
                     </Fragment>
                  </Grid>
                  <Grid item xs={2} sx={{ display: 'flex' }}>
                     {WizardPages[0] !== wizardPage && currentSubscriptionStatus.data.id === 'family' ? <Button onClick={decrementWizardPage} startIcon={<ArrowBackIcon />} variant="outlined">
                        Back
                     </Button>: null}
                  </Grid>
                  <Grid item xs={7} sx={{ display: 'flex' }} />
                  <Grid item xs={3} sx={{ display: 'flex' }} justifyContent="right">
                     <Button
                        variant="outlined"
                        component={Link}
                        to="/"
                     >
                        Skip
                     </Button>
                     <Button
                        onClick={incrementWizardPage}
                        startIcon={WizardPages[WizardPages.length - 1] !== wizardPage ? <ArrowForwardIcon /> : <CheckIcon />}
                        variant="contained"
                        sx={{ ml: 1 }}
                     >
                        {WizardPages[WizardPages.length - 1] !== wizardPage ? 'Continue' : 'Finish'}
                     </Button>
                  </Grid>
               </Grid>
            </Paper>
         </CSSTransition>
         <CSSTransition in={wizardPage.value === 'invite-trustee'} timeout={100} classNames="wizard-transition" unmountOnExit>
            <Paper sx={{ width: '60vw', borderRadius: '0.9em' }} elevation={3}>
               <Grid container spacing={0}>
                  <Grid item xs={12} sx={{height: '55vh'}}>
                     <Typography variant="subtitle1">
                        Invite trustees to your LifeTomb. Trustees serve a critical function; they will gain access to your journey, records and information upon your passing.
                     </Typography>
                     <Grid container>
                        <Grid item xs={4} sx={{ display: 'flex' }}/>
                        <Grid item xs={4} display="grid" alignItems="center" justifyContent="center">
                           <Button
                              variant="contained"
                              onClick={() => setTrusteeInviteDialogOpen(true)}
                           >
                              Invite Trustee
                           </Button>
                        </Grid>
                        <Grid item xs={4} sx={{ display: 'flex' }}/>
                     </Grid>
                     <Fragment>
                        <b>Trustees</b>
                        <Grid container spacing={1} sx={{ maxHeight: '20em', overflowY: 'auto' }}>
                           {trustees.data.map(trustee => renderContact(trustee))}

                           {trusteeInvites.data.map(invite => renderTrusteeInvite(invite))}
                        </Grid>
                     </Fragment>
                  </Grid>
                  <Grid item xs={2} sx={{ display: 'flex' }}>
                     {WizardPages[0] !== wizardPage ? <Button onClick={decrementWizardPage} variant="outlined" startIcon={<ArrowBackIcon />}>
                        Back
                     </Button>: null}
                  </Grid>
                  <Grid item xs={7} sx={{ display: 'flex' }} />
                  <Grid item xs={3} sx={{ display: 'flex' }} justifyContent="right">
                     <Button
                        variant="outlined"
                        component={Link}
                        to="/"
                     >
                        Skip
                     </Button>
                     <Button
                        onClick={incrementWizardPage}
                        startIcon={WizardPages[WizardPages.length - 1] !== wizardPage ? <ArrowForwardIcon /> : <CheckIcon />}
                        variant="contained"
                        sx={{ ml: 1 }}
                     >
                        {WizardPages[WizardPages.length - 1] !== wizardPage ? 'Continue' : 'Finish'}
                     </Button>
                  </Grid>
               </Grid>
            </Paper>
         </CSSTransition>
         <CSSTransition in={wizardPage.value === 'add-assets'} timeout={100} classNames="wizard-transition" unmountOnExit>
            <Paper sx={{ width: '60vw', borderRadius: '0.9em' }} elevation={3}>
               <Grid container spacing={0}>
                  <Grid item xs={12} sx={{height: '55vh'}}>
                     <Typography variant="subtitle1">
                        List some of your assets.
                     </Typography>
                     <Grid container>
                        <Grid item xs={4} sx={{ display: 'flex' }}/>
                        <Grid item xs={4} display="grid" alignItems="center" justifyContent="center">
                           {assets.data.length === assets.limits.asset ? <Tooltip title="Asset limit reached" placement="top">
                              <span>
                              <Button
                                 disabled
                                 style={{ pointerEvents: 'none' }}
                                 variant="contained"
                                 onClick={() => setAssetDialogOpen(true)}
                                 
                              >
                                 Add Asset
                              </Button>
                              </span>
                           </Tooltip> :
                           <Button
                              variant="contained"
                              onClick={() => setAssetDialogOpen(true)}
                           >
                              Add Asset
                           </Button>}
                        </Grid>
                        <Grid item xs={4} sx={{ display: 'flex' }}/>
                     </Grid>
                     <Fragment>
                        <Box sx={{ flexGrow: 1, display: 'flex', overflowY: 'auto'}}>
                        {assets.data.map((asset => (
                           <Grid item xs={4} key={asset.id}>
                              <Card variant="outlined" sx={{ borderRadius: 3, height: '30vh' }}>
                                 <CardHeader
                                    sx={{ backgroundColor: '#3b434c', color: '#fff' }}
                                    title={<Grid container wrap="nowrap" spacing={1}>
                                       <Grid item>
                                          <Typography variant="h5" gutterBottom component="div">
                                             {asset.identifier}
                                          </Typography>
                                       </Grid>
                                    </Grid>}
                                    subheader={<Typography variant="" component="p">{asset.assetType.name}</Typography>}
                                 />
                                 <CardContent>
                                    {asset.base64PhotoScaled ? <img style={{ width: 90 }} src={asset.base64PhotoScaled} alt={asset.identifier}/> : null}
                                    <TextDisplay
                                       text={asset.description}
                                       maxChars={120}
                                    />
                                 </CardContent>
                              </Card>
                           </Grid>
                        )))}
                        </Box>
                     </Fragment>
                  </Grid>
                  <Grid item xs={2} sx={{ display: 'flex' }}>
                     {WizardPages[0] !== wizardPage ? <Button onClick={decrementWizardPage} variant="outlined" startIcon={<ArrowBackIcon />}>
                        Back
                     </Button>: null}
                  </Grid>
                  <Grid item xs={7} sx={{ display: 'flex' }} />
                  <Grid item xs={3} sx={{ display: 'flex' }} justifyContent="right">
                     <Button
                        variant="outlined"
                        component={Link}
                        to="/"
                     >
                        Skip
                     </Button>
                     <Button
                        onClick={incrementWizardPage}
                        startIcon={WizardPages[WizardPages.length - 1] !== wizardPage ? <ArrowForwardIcon /> : <CheckIcon />}
                        variant="contained"
                        sx={{ ml: 1 }}
                     >
                        {WizardPages[WizardPages.length - 1] !== wizardPage ? 'Continue' : 'Finish'}
                     </Button>
                  </Grid>
               </Grid>
            </Paper>
         </CSSTransition>
      </Fragment>);
   };

   // subscription
   const getCurrentSubscriptionStatus = async () => {
      setCurrentSubscriptionStatus({data: null, loading: true});
      const subscriptionStatus = await subscriptionService.getCurrentStatus();
      setCurrentSubscriptionStatus({data: subscriptionStatus, loading: false});
   };

   const handleUpgradeToFamily = () => {
      history.push('/start-subscription?subscription-tier=family');
   };

   // family profile invites
   const addFamilyProfileInvite = async (invite) => {
      setFamilyProfileInvites({ data: familyProfileInvites.data, loading: true });
      await familyProfileInviteService.addFamilyProfileInvite(invite);
      getFamilyProfileInvites();
      setFamilyProfileInviteDialogOpen(false);
   };

   const getFamilyProfileInvites = async () => {
      setFamilyProfileInvites({ data: [], loading: true });
      const familyProfileInvites = await familyProfileInviteService.getFamilyProfileInvites();
      setFamilyProfileInvites({ data: familyProfileInvites, loading: false });
   };

   const deleteFamilyProfileInvite = async (id) => {
      setFamilyProfileInvites({ ...familyProfileInvites, loading: true });
      await familyProfileInviteService.deleteFamilyProfileInvite(id);
      getFamilyProfileInvites();
   };

   const renderContact = (contact) => {
      const labelId = `other-contact-list-label-${contact.id}`;
      return (
         <ListItem
            key={contact.id}
            disablePadding
         >
            <ListItemText
               id={labelId}
               primary={`${contact.firstName} ${contact.lastName}`}
               secondary={<Fragment>
                  <PhoneNumberDisplay number={contact.primaryPhone} blankMessage="No phone number." />
                  {` - ${contact.email}`}
               </Fragment>}
            />
         </ListItem>
      );
   };

   const renderTrusteeInvite = (invite) => {
      const labelId = `trustee-invite-list-label-${invite.id}`;
      return (
         <ListItem key={invite.id}>
            <ListItemText
               id={labelId}
               primary={`${invite.email}`}
            />
         </ListItem>
      );
   };

   const addSaveOtherContact = async (contact) => {
      if(contact.id)
         await otherContactService.putOtherContact(contact);
      else
         await otherContactService.addOtherContact(contact);

      getOtherContacts();
      setOtherContactDialogOpen(false);
   };

   const getOtherContacts = async () => {
      setOtherContacts({ data: [], limits: {}, loading: true });
      const otherContacts = await otherContactService.getOtherContacts();
      setOtherContacts({ data: otherContacts.data, limits: otherContacts.limits, loading: false });
   };

   const getOtherContactTypes = async () => {
      setOtherContactTypes({ data: [], loading: true });
      const otherContactTypes = await systemLookupService.getOtherContactTypes();
      setOtherContactTypes({ data: otherContactTypes, loading: false });
   };

   // trustee invites
   const addTrusteeInvite = async (invite) => {
      setTrusteeInvites({ data: trusteeInvites.data, limits: trusteeInvites.limits, loading: true });
      await trusteeInviteService.addTrusteeInvite(invite);
      getTrusteeInvites();
      setTrusteeInviteDialogOpen(false);
   };

   const getTrusteeInvites = async () => {
      setTrusteeInvites({ data: [], limits: {}, loading: true });
      const trusteeInvites = await trusteeInviteService.getTrusteeInvites();
      setTrusteeInvites({ data: trusteeInvites.data, limits: trusteeInvites.limits, loading: false });
   };

   // trustees
   const getTrustees = async () => {
      setTrustees({ data: [], limits: {}, loading: true });
      const trustees = await trusteeService.getTrustees();
      setTrustees({ data: trustees.data, limits: trustees.limits, loading: false });
   };

   const getAddressStatesProvinces = async () => {
      setAddressStatesProvinces({ data: [], loading: true });
      const addressStatesProvinces = await systemLookupService.getAddressStatesProvinces();
      setAddressStatesProvinces({ data: addressStatesProvinces, loading: false });
   };

   // assets
   const addAsset = async (asset) => {
      PubSub.publish('nonBlockingLoader.addMessage', { id: asset.id, text: `Uploading ${asset.fileName}...` });
      setAssetDialogOpen(false);
      await assetService.addAsset(asset);
      PubSub.publish('nonBlockingLoader.removeMessage', asset.id);
      getAssets();
   };

   const getAssetTypes = async () => {
      setAssetTypes({ data: [], loading: true });
      const assetTypes = await systemLookupService.getAssetTypes();
      setAssetTypes({ data: assetTypes, loading: false });
   };

   const getAssets = async (assetTypeId) => {
      setAssets({ data: [], limits: {}, loading: true });
      const assets = await assetService.getAssets(assetTypeId);
      setAssets({ data: assets.data, limits: assets.limits, loading: false });
   };

   return (
      <Fragment>
         <Box m={'auto'}>
            <Typography variant="h4" component="h4" sx={{ textAlign: 'center' }}>
               Got your information handy? Fill it out below, or come back to it later.
            </Typography>

            {otherContacts.loading || trusteeInvites.loading || trustees.loading || assetTypes.loading || assets.loading || addressStatesProvinces.loading ? <LoaderIcon /> : <Box
               alignItems="center"
               justifyContent="center"
               sx={{
                  display: 'grid',
                  flexWrap: 'wrap',
                  margin: 'auto',
                  '& > :not(style)': {
                     p: 2,
                  },
               }}
            >
               <Stepper activeStep={WizardPages.indexOf(wizardPage)} alternativeLabel>
                  {WizardPages.map((step) => (
                     <Step key={step.value}>
                        <StepLabel>{step.label}</StepLabel>
                     </Step>
                  ))}
               </Stepper>

               {subscriptionPurchaseResult && subscriptionPurchaseResult === 'success' ? <Grid item xs={12}>
                  <AlertSubscriptionResult success={true} />
               </Grid> : null}

               {renderWizardPage()}
            </Box>
            }
         </Box>
         <OtherContactDialog
            ref={otherContactDialogRef}
            open={otherContactDialogOpen}
            handleClose={() => setOtherContactDialogOpen(false)}
            handleAddSaveOtherContact={(contact) => addSaveOtherContact(contact)}
            addressStatesProvinces={addressStatesProvinces.data}
            otherContactTypes={otherContactTypes.data}
         />
         <InviteDialog
            dialogTitle="Invite Trustee"
            open={trusteeInviteDialogOpen}
            handleClose={() => setTrusteeInviteDialogOpen(false)}
            handleAddInvite={(invite) => addTrusteeInvite(invite)}
         />
         <AssetDialog
            loading={assets.loading}
            open={assetDialogOpen}
            handleClose={() => setAssetDialogOpen(false)}
            handleAddSaveAsset={addAsset}
            assetTypes={assetTypes.data}
            addressStatesProvinces={addressStatesProvinces.data}
            allowPhotoUpload={false}
         />
         <InviteDialog
            dialogTitle="Invite User to Family Profile"
            open={familyProfileInviteDialogOpen}
            handleClose={() => setFamilyProfileInviteDialogOpen(false)}
            handleAddInvite={addFamilyProfileInvite}
         />
      </Fragment>
   );
};

export default GetStartedOptionalPage;
