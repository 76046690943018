import axiosService from './AxiosService';

export class PlatformInviteService {
   _resourceName = 'platform-invite';

   async postInvite(invite) {
      return await axiosService.create(`/v1.0/${this._resourceName}`, invite);
   }

   static get instance() { return platformInviteService }
}

const platformInviteService = new PlatformInviteService();

export default platformInviteService;
