import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import ViewListIcon from '@mui/icons-material/ViewList';
import Alert from '@mui/material/Alert';
import LoaderIcon from './LoaderIcon';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ListItemIcon from '@mui/material/ListItemIcon';
import SendIcon from '@mui/icons-material/Send';
import MUILink from '@mui/material/Link';
import LimitReachedAlert from '../components/LimitReachedAlert';
import Chip from '@mui/material/Chip';
import ImportantContactListItem from '../components/ImportantContactListItem';
import TrusteeListItem from '../components/TrusteeListItem';

const ContactsWidgetBody = (props) => {
   const [addContactMenuAnchorEl, setAddContactMenuAnchorEl] = useState(null);
   const handleAddContactMenuOpen = (event) => {
      setAddContactMenuAnchorEl(event.currentTarget);
   };
   const handleAddContactMenuClose = () => {
      setAddContactMenuAnchorEl(null);
   };

   const handleInviteTrusteeClick = () => {
      props.openInviteTrusteeDialog();
      handleAddContactMenuClose();
   };

   const handleAddOtherContactClick = () => {
      props.openOtherContactDialog(null);
      handleAddContactMenuClose();
   };

   const handleEditOtherContactClick = (contact) => {
      props.openOtherContactDialog(contact);
      handleAddContactMenuClose();
   };

   const renderTrusteeInvite = (invite) => {
      const labelId = `trustee-invite-list-label-${invite.id}`;
      return (
         <ListItem
            key={invite.id}
            disablePadding
         >
            <ListItemButton
               dense
               variant="link"
               component={Link}
               to="/contacts"
            >
               <ListItemText
                  id={labelId}
                  primary={`${invite.email}`}
               />
               <Chip label="Pending" />
            </ListItemButton>
         </ListItem>
      );
   };

   const renderTrusteeList = (trustees, trusteeInvites, loading) => {
      if (loading)
         return (<LoaderIcon />);

      if (!trustees.length && !trusteeInvites.length)
         return (<Alert severity="info">No trustees have been added yet. <MUILink onClick={handleInviteTrusteeClick} sx={{cursor: 'pointer'}}>Send an invite</MUILink> to add one.</Alert>);

      return (
         <Fragment>
            <b>Trustees</b>
            <List sx={{ width: '100%', bgcolor: 'background.paper', maxHeight: '20em', overflowY: 'auto' }}>
               {trustees.map(trustee => <TrusteeListItem key={trustee.id} trustee={trustee} />)}

               {trusteeInvites.map(invite => renderTrusteeInvite(invite))}
            </List>
            {(trustees.length + trusteeInvites.length) >= props.resourceLimits.trustee ? <LimitReachedAlert
               resourceName="Trustees/Invites"
               currentSubscriptionTier={props.currentSubscriptionTier}
            /> : null}
         </Fragment>
      );
   };

   const renderOtherContactsList = (otherContacts, loading) => {
      if (loading)
         return (<LoaderIcon />);

      if (!otherContacts.length)
         return (<Alert sx={{ mt: 1 }} severity="info">No important contacts have been added yet. <MUILink onClick={handleAddOtherContactClick} sx={{cursor: 'pointer'}}>Add one</MUILink>.</Alert>);

      return (
         <Fragment>
            <b>Important Contacts</b>
            <List sx={{ width: '100%', bgcolor: 'background.paper', maxHeight: '20em', overflowY: 'auto' }}>
               {otherContacts.map(contact => <ImportantContactListItem
                  key={contact.id}
                  contact={contact}
                  handleImportantContactClick={handleEditOtherContactClick}
               />)}
            </List>
            {otherContacts.length >= props.resourceLimits.otherContact ? <LimitReachedAlert
               resourceName="Important Contacts"
               currentSubscriptionTier={props.currentSubscriptionTier}
            /> : null}
         </Fragment>
      );
   };

   return (
      <Fragment>
         <CardHeader
            action={
               <Fragment>
                  <Button
                     variant="link"
                     component={Link}
                     to="/contacts"
                     startIcon={<ViewListIcon />}
                  >
                     View All
                  </Button>

                  <Tooltip title="Invite Trustee / Add Contact">
                     <span>
                        <IconButton
                           disabled={props.loading}
                           aria-label="add-contact-menu"
                           onClick={handleAddContactMenuOpen}
                        >
                           <MoreVertIcon />
                        </IconButton>
                     </span>
                  </Tooltip>
                  <Menu
                     id="menu-add-contact"
                     anchorEl={addContactMenuAnchorEl}
                     anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                     }}
                     keepMounted
                     transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                     }}
                     open={Boolean(addContactMenuAnchorEl)}
                     onClose={handleAddContactMenuClose}
                  >
                     <MenuItem onClick={handleInviteTrusteeClick} disabled={(props.trustees.length + props.trusteeInvites.length) >= props.resourceLimits.trustee}>
                        <ListItemIcon>
                           <SendIcon fontSize="small" />
                        </ListItemIcon>
                        Invite Trustee
                     </MenuItem>
                     <MenuItem onClick={handleAddOtherContactClick} disabled={props.otherContacts.length >= props.resourceLimits.otherContact}>
                        <ListItemIcon>
                           <AddIcon fontSize="small" />
                        </ListItemIcon>
                        Add Important Contact
                     </MenuItem>
                  </Menu>
               </Fragment>
            }
         />
         <CardContent>
            {renderTrusteeList(props.trustees, props.trusteeInvites, props.loading)}
            {renderOtherContactsList(props.otherContacts, props.loading)}
         </CardContent>
      </Fragment>
   );
};

ContactsWidgetBody.propTypes = {
   loading: PropTypes.bool.isRequired,
   trusteeInvites: PropTypes.array.isRequired,
   resourceLimits: PropTypes.object.isRequired,
   trustees: PropTypes.array.isRequired,
   otherContacts: PropTypes.array.isRequired,
   openInviteTrusteeDialog: PropTypes.func.isRequired,
   openOtherContactDialog: PropTypes.func.isRequired,
   currentSubscriptionTier: PropTypes.object,
};

export default ContactsWidgetBody;
