import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import LoaderIcon from './LoaderIcon';
import AddIcon from '@mui/icons-material/Add';
import { Badge, Button } from '@mui/material';
import Alert from '@mui/material/Alert';
import AddressDisplay from './AddressDisplay';
import PhoneNumberDisplay from './PhoneNumberDisplay';
import TextDisplay from './TextDisplay';
import YesNoDialog from './YesNoDialog';
import format from 'date-fns/format';
import EmailDisplay from './EmailDisplay';
import MailIcon from '@mui/icons-material/Mail';

function descendingComparator(a, b, orderBy) {
   if (b[orderBy] < a[orderBy]) {
      return -1;
   }
   if (b[orderBy] > a[orderBy]) {
      return 1;
   }
   return 0;
}

function getComparator(order, orderBy) {
   return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
   const stabilizedThis = array.map((el, index) => [el, index]);
   stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
         return order;
      }
      return a[1] - b[1];
   });
   return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
   const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
      props;
   const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
   };

   return (
      <TableHead>
         <TableRow>
            {/* todo: re-enable bulk actions if needed */}
            {/* <TableCell padding="checkbox">
               <Checkbox
                  color="primary"
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={onSelectAllClick}
                  inputProps={{
                     'aria-label': 'select all desserts',
                  }}
               />
            </TableCell> */}
            {props.colConfig.map((col) => (
               <TableCell
                  key={col.id}
                  align={col.rightAlign ? 'right' : 'left'}
                  padding={col.disablePadding ? 'none' : 'normal'}
                  sortDirection={orderBy === col.id ? order : false}
               >
                  <TableSortLabel
                     active={orderBy === col.id}
                     direction={orderBy === col.id ? order : 'asc'}
                     onClick={createSortHandler(col.id)}
                  >
                     {col.label}
                     {orderBy === col.id ? (
                        <Box component="span" sx={visuallyHidden}>
                           {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                     ) : null}
                  </TableSortLabel>
               </TableCell>
            ))}
            {props.showActionsCol ? <TableCell
               align="right"
               padding="normal"
            >
               Actions
            </TableCell> : null}
         </TableRow>
      </TableHead>
   );
}

EnhancedTableHead.propTypes = {
   colConfig: PropTypes.array.isRequired,
   numSelected: PropTypes.number.isRequired,
   onRequestSort: PropTypes.func.isRequired,
   onSelectAllClick: PropTypes.func.isRequired,
   order: PropTypes.oneOf(['asc', 'desc']).isRequired,
   orderBy: PropTypes.string.isRequired,
   rowCount: PropTypes.number.isRequired,
   showActionsCol: PropTypes.bool.isRequired,
};

const EnhancedTableToolbar = (props) => {
   const { numSelected } = props;

   return (
      <Toolbar
         sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
            ...(numSelected > 0 && {
               bgcolor: (theme) =>
                  alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
            }),
         }}
      >
         {numSelected > 0 ? (
            <Typography
               sx={{ flex: '1 1 100%' }}
               color="inherit"
               variant="subtitle1"
               component="div"
            >
               {numSelected} selected
            </Typography>
         ) : (
            null
            // <Typography
            //    sx={{ flex: '1 1 100%' }}
            //    variant="h6"
            //    id="tableTitle"
            //    component="div"
            // >
            //    {props.title}
            // </Typography>
         )}

         {numSelected > 0 ? (
            <Tooltip title="Delete">
               <IconButton>
                  <DeleteIcon />
               </IconButton>
            </Tooltip>
         ) : (
            <Fragment>
               {typeof props.handleItemAdd === 'function' ? <Button
                  variant="contained"
                  size="small"
                  disabled={props.itemAddDisabled}
                  startIcon={<AddIcon />}
                  onClick={props.handleItemAdd}
                  sx={{ml: 'auto'}}
               >
                  {props.itemAddLabel || 'Add'}
               </Button> : null}
            </Fragment>
         )}
      </Toolbar>
   );
};

EnhancedTableToolbar.propTypes = {
   numSelected: PropTypes.number.isRequired,
   handleItemAdd: PropTypes.func,
   itemAddDisabled: PropTypes.bool,
   itemAddLabel: PropTypes.string,
};

const RenderCellValue = (props) => {
   if (!props.value && props.emptyValue)
      return (<Fragment>{props.emptyValue}</Fragment>);

   switch (props.specialRenderer) {
      case 'address':
         return (<AddressDisplay
            address={props.value}
            blankMessage={props.blankMessage ?? 'Not Set.'}
         />);
      case 'lookupValue':
         return <Fragment>{props.value.name}</Fragment>;
      case 'phone':
         return <PhoneNumberDisplay
            number={props.value}
            blankMessage={props.blankMessage ?? 'Not Set.'}
         />;
      case 'image':
         return <img style={{ width: 200 }} src={props.value} />;
      case 'email':
         <EmailDisplay email={props.value} blankMessage={props.blankMessage || 'Not set.'} />
      case 'longText':
         return <TextDisplay
            text={props.value}
            maxChars={80}
            blankMessage={props.blankMessage ?? 'Not Set.'}
         />;
      case 'dateTime':
         return (<Fragment>{format(props.value, 'PPpp')}</Fragment>);
      default:
         return (<Fragment>{props.value ? props.value : (props.blankMessage ? props.blankMessage : '')}</Fragment>);
   }
};

RenderCellValue.propTypes = {
   value: PropTypes.any,
   emptyValue: PropTypes.string,
   specialRenderer: PropTypes.string,
   blankMessage: PropTypes.string,
};

const EnhancedTableRow = (props) => {
   const [deleteItemDialogOpen, setDeleteItemDialogOpen] = useState(false);
   const labelId = `enhanced-table-checkbox-${props.index}`;

   const confirmDialogInitialState = {
      open: false,
      title: null,
      description: null,
      dismissButtonLabel: null,
      affirmButtonLabel: null,
      onAffirm: null,
   };
   const [confirmDialogState, setConfirmDialogState] = useState(confirmDialogInitialState);

   return <Fragment>
      <TableRow
         hover
         aria-checked={props.isItemSelected}
         tabIndex={-1}
         selected={props.isItemSelected}
      >
         {/* todo: re-enable bulk actions if needed */}
         {/* <TableCell padding="checkbox">
            <Checkbox
               color="primary"
               onClick={(event) => handleClick(event, props.row.id)}
               role="checkbox"
               checked={props.isItemSelected}
               inputProps={{
                  'aria-labelledby': labelId,
               }}
            />
         </TableCell> */}

         {props.colConfig.map((col, colIndex) => {
            if (colIndex === 0)
               return (<TableCell
                  key={`${col.id}-${props.row.id}`}
                  component="th"
                  id={labelId}
                  scope="row"
                  align={col.rightAlign ? 'right' : 'left'}
                  padding={col.disablePadding ? 'none' : 'normal'}
               >
                  <RenderCellValue
                     value={props.row[col.id]}
                     emptyValue={col.emptyValue}
                     specialRenderer={col.specialRenderer}
                     blankMessage={col.blankMessage}
                  />
               </TableCell>);
            else
               return (<TableCell
                  key={`${col.id}-${props.row.id}`}
                  align={col.rightAlign ? 'right' : 'left'}
                  padding={col.disablePadding ? 'none' : 'normal'}>
                  <RenderCellValue
                     value={props.row[col.id]}
                     emptyValue={col.emptyValue}
                     specialRenderer={col.specialRenderer}
                     blankMessage={col.blankMessage}
                  />
               </TableCell>);
         })}

         {props.rowActions && props.rowActions.length ? <TableCell align={'right'} style={{ whiteSpace: 'nowrap' }}>
            {props.rowActions.map((action, index) => <Tooltip key={index} title={action.toolTip}>
               <IconButton onClick={() => {
                  if(action.confirmDialogConfig) {
                     setConfirmDialogState({
                        ...action.confirmDialogConfig,
                        open: true,
                        onAffirm: () => action.onClick(props.row)
                     });
                  } else {
                     action.onClick(props.row);
                  }
               }}>
                  {action.badgeProp ? <Badge badgeContent={props.row[action.badgeProp]} color="primary">{action.icon}</Badge> : action.icon}
               </IconButton>
            </Tooltip>)}
         </TableCell> : null}
      </TableRow>

      <YesNoDialog
         open={confirmDialogState.open}
         title={confirmDialogState.title || "Delete Item?"}
         description={confirmDialogState.description || "Are you sure you want to delete this item?"}
         dismissButtonLabel={confirmDialogState.dismissButtonLabel || "No"}
         affirmButtonLabel={confirmDialogState.affirmButtonLabel || "Yes"}
         handleDismiss={() => setConfirmDialogState(confirmDialogInitialState)}
         handleAffirm={() => confirmDialogState.onAffirm(props.row)}
      />
   </Fragment>;
};

EnhancedTableRow.propTypes = {
   isItemSelected: PropTypes.bool,
   row: PropTypes.object,
   colConfig: PropTypes.array,
   index: PropTypes.number,
   rowActions: PropTypes.array,
};

const EnhancedTable = (props) => {
   const [order, setOrder] = React.useState('asc');
   const [orderBy, setOrderBy] = React.useState('calories');
   const [selected, setSelected] = React.useState([]);
   const [page, setPage] = React.useState(0);
   const [rowsPerPage, setRowsPerPage] = React.useState(25);

   const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
   };

   const handleSelectAllClick = (event) => {
      if (event.target.checked) {
         const newSelecteds = props.data.map((n) => n.id);
         setSelected(newSelecteds);
         return;
      }
      setSelected([]);
   };

   const handleClick = (event, name) => {
      const selectedIndex = selected.indexOf(name);
      let newSelected = [];

      if (selectedIndex === -1) {
         newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
         newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
         newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
         newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
         );
      }

      setSelected(newSelected);
   };

   const handleChangePage = (event, newPage) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
   };

   const isSelected = (id) => selected.indexOf(id) !== -1;

   // Avoid a layout jump when reaching the last page with empty rows.
   const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.data.length) : 0;

   if (props.loading)
      return (<LoaderIcon />);

   return (
      <Fragment>
         <EnhancedTableToolbar
            numSelected={selected.length}
            handleItemAdd={props.handleItemAdd}
            itemAddDisabled={props.itemAddDisabled}
            itemAddLabel={props.itemAddLabel}
         />
         {!props.data.length ? <Fragment>{props.emptyMessage ? <Alert severity="info">{props.emptyMessage}</Alert> : null}</Fragment> : <Fragment><TableContainer>
            <Table
               sx={{ minWidth: 750 }}
               aria-labelledby="tableTitle"
               size={'medium'}
            >
               <EnhancedTableHead
                  colConfig={props.colConfig}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={props.data.length}
                  showActionsCol={(props.rowActions && props.rowActions.length ? true : false)}
               />
               <TableBody>
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                        rows.slice().sort(getComparator(order, orderBy)) */}
                  {stableSort(props.data, getComparator(order, orderBy))
                     .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                     .map((row, index) => {
                        const isItemSelected = isSelected(row.id);

                        return (
                           <EnhancedTableRow
                              key={row.id}
                              isItemSelected={isItemSelected}
                              row={row}
                              colConfig={props.colConfig}
                              index={index}
                              rowActions={props.rowActions}
                           />
                        );
                     })}
                  {emptyRows > 0 && (
                     <TableRow
                        style={{
                           height: 53 * emptyRows,
                        }}
                     >
                        <TableCell colSpan={6} />
                     </TableRow>
                  )}
               </TableBody>
            </Table>
         </TableContainer>
            {/* <TablePagination
               rowsPerPageOptions={[5, 10, 25]}
               component="div"
               count={props.data.length}
               rowsPerPage={rowsPerPage}
               page={page}
               onPageChange={handleChangePage}
               onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
         </Fragment>}
      </Fragment>
   );
}

EnhancedTable.propTypes = {
   loading: PropTypes.bool,
   emptyMessage: PropTypes.string,
   data: PropTypes.array.isRequired,
   colConfig: PropTypes.array.isRequired,
   handleItemAdd: PropTypes.func,
   itemAddDisabled: PropTypes.bool,
   itemAddLabel: PropTypes.string,
   rowActions: PropTypes.array,
};

export default EnhancedTable;
