import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HouseIcon from '@mui/icons-material/House';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import SnowshoeingIcon from '@mui/icons-material/Snowshoeing';
import MausoleumIconBlue from './MausoleumIconBlue';
import SearchIcon from '@mui/icons-material/Search';

const MobileBottomNav = () => {
   const [value, setValue] = useState(0);

   return (
      <AppBar position="fixed" color="primary" sx={{ display: { xs: 'flex', md: 'none' }, top: 'auto', bottom: 0 }}>
         <BottomNavigation
            value={value}
            onChange={(event, newValue) => {
               setValue(newValue);
            }}
         >
            <BottomNavigationAction
               label="My Journey"
               icon={<SnowshoeingIcon />}
               component={Link}
               to="/"
            />
            <BottomNavigationAction
               label="My Records"
               icon={<HouseIcon />}
               component={Link}
               to="/my-records"
            />
            <BottomNavigationAction
               label="Final Wishes"
               icon={<FavoriteBorderIcon />}
               component={Link}
               to="/final-wishes"
            />
            <BottomNavigationAction
               label="Mausoleum"
               icon={<MausoleumIconBlue sx={{ height: '20px' }} />}
               component={Link}
               to="/mausoleum"
            />
            <BottomNavigationAction
               label="Search Mausolea"
               icon={<SearchIcon />}
               component={Link}
               to="/mausolea-search"
            />
         </BottomNavigation>
      </AppBar>
   );
};

export default MobileBottomNav;
