import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

const LoaderIcon = (props) => {
   return (
      <Stack spacing={1}>
         <Box sx={{ display: 'flex' }}>
            <Box sx={{ m: 'auto', backgroundImage: `url(${'/logo_token_gray_solid_30.png'})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'top 0.3em left 0.3em' }}>
               <CircularProgress />
            </Box>
         </Box>
         {props.text ? <Box sx={{ display: 'flex' }}>
            <Box sx={{ m: 'auto' }}>
               <Chip label={props.text} />
            </Box>
         </Box> : null}
      </Stack>
   )
};

LoaderIcon.propTypes = {
   text: PropTypes.string,
};

export default LoaderIcon;
