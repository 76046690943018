import React, { Fragment, useState, forwardRef, useImperativeHandle } from 'react';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import TextAreaWithLimit from './TextAreaWithLimit';

const PetWishForm = forwardRef((props, ref) => {
   const [formValues, setFormValues] = useState({
      id: null,
      name: '',
      animalType: '',
      futureGuardianName: '',
      specialAccommodations: '',
   });

   // validation
   const [formValidation, setFormValidation] = useState({});
   const formIsValid = () => {
      let errors = {};

      if (formValues.name.length < 2)
         errors.name = 'Name must be at least two characters.';
      if (formValues.animalType.length < 2)
         errors.animalType = 'Animal Type Line 1 must be at least two characters.';
      if (formValues.futureGuardianName && formValues.futureGuardianName.length < 2)
         errors.futureGuardianName = 'Future Guardian Name must be at least two characters if provided.';
      if (formValues.specialAccommodations && formValues.specialAccommodations.length < 2)
         errors.specialAccommodations = 'Special Accommodations must be at least two characters if provided.';

      setFormValidation(errors);

      return Object.keys(errors).length === 0;
   };

   const setState = (state) => {
      setFormValues({
         id: state && state.id || null,
         name: state && state.name || '',
         animalType: state && state.animalType || '',
         futureGuardianName: state && state.futureGuardianName || '',
         specialAccommodations: state && state.specialAccommodations || '',
      });
   };

   // control validation, state from parent component
   useImperativeHandle(ref, () => ({
      validate() {
         return formIsValid();
      },

      setState(state) {
         setState(state);
      },

      getState() {
         return cleanData(formValues);
      }
   }));

   const handleNameChange = (event) => {
      setFormValues({ ...formValues, name: event.target.value });
   };

   const handleAnimalTypeChange = (event) => {
      setFormValues({ ...formValues, animalType: event.target.value });
   };

   const handleFutureGuardianNameChange = (event) => {
      setFormValues({ ...formValues, futureGuardianName: event.target.value });
   };

   const handleSpecialAccommodationsChange = (event) => {
      setFormValues({ ...formValues, specialAccommodations: event.target.value });
   };

   const cleanData = (formValues) => {
      if(formIsValid())
         return {
            ...formValues,
            futureGuardianName: formValues.futureGuardianName || null,
            specialAccommodations: formValues.specialAccommodations || null,
         };
      else return null;
   };

   return (
      <Fragment>
         <Grid item md={4}>
            <FormControl fullWidth>
               <TextField
                  margin="dense"
                  id="name-input"
                  label="Name*"
                  type="text"
                  value={formValues.name}
                  onChange={event => handleNameChange(event)}
                  error={formValidation.name ? true : false}
                  helperText={formValidation.name}
                  fullWidth
               />
            </FormControl>
         </Grid>
         <Grid item md={4}>
            <FormControl fullWidth>
               <TextField
                  margin="dense"
                  id="animal-type-input"
                  label="Animal Type*"
                  type="text"
                  value={formValues.animalType}
                  onChange={event => handleAnimalTypeChange(event)}
                  error={formValidation.animalType ? true : false}
                  helperText={formValidation.animalType}
                  fullWidth
               />
            </FormControl>
         </Grid>
         <Grid item md={4}>
            <FormControl fullWidth>
               <TextField
                  margin="dense"
                  id="future-guardian-name-input"
                  label="Future Guardian Name"
                  type="text"
                  value={formValues.futureGuardianName}
                  onChange={event => handleFutureGuardianNameChange(event)}
                  error={formValidation.futureGuardianName ? true : false}
                  helperText={formValidation.futureGuardianName}
                  fullWidth
               />
            </FormControl>
         </Grid>
         <Grid item xs={12}>
            <FormControl fullWidth>
               <TextAreaWithLimit
                  id="special-accommodations-input"
                  label="Special Accommodations"
                  maxLength={255}
                  value={formValues.specialAccommodations}
                  onChange={event => handleSpecialAccommodationsChange(event)}
                  error={formValidation.specialAccommodations ? true : false}
                  helperText={formValidation.specialAccommodations}
                  placeholder="Describe special accommodations for your pet, such as foods and medicines..."
                  rows={3}
               />
            </FormControl>
         </Grid>
      </Fragment>
   )
});

export default PetWishForm;
