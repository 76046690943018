import React, { useState, useRef, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import AddressFormFields from './AddressFormFields';
import NumberFormat from 'react-number-format';
import TextAreaWithLimit from './TextAreaWithLimit';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';

const OtherContactDialog = forwardRef((props, ref) => {
   const addressFormRef = useRef();

   const initialState = {
      id: null,
      firstName: '',
      lastName: '',
      primaryPhone: '',
      secondaryPhone: '',
      email: '',
      notes: '',
   };
   const [formValues, setFormValues] = useState(initialState);

   const [activeOtherContactType, setActiveOtherContactType] = useState('select-other-contact-type');

   // control validation, state from parent component
   useImperativeHandle(ref, () => ({
      setState(state) {
         setFormValues(state);

         if (addressFormRef.current)
            addressFormRef.current.setState(state.address);

         setActiveOtherContactType((state && state.otherContactType && state.otherContactType.id) || 'select-other-contact-type');
      }
   }));

   // validation
   const [formValidation, setFormValidation] = useState({});
   const formIsValid = () => {
      let errors = {};

      if (!formValues.firstName || formValues.firstName.length < 2)
         errors.firstName = 'First Name must be at least two characters.';
      if (!formValues.lastName || formValues.lastName.length < 2)
         errors.lastName = 'Last Name must be at least two characters.';
      if (!formValues.primaryPhone || formValues.primaryPhone.toString().length !== 10)
         errors.primaryPhone = 'Primary Phone invalid.';
      if (formValues.secondaryPhone && formValues.secondaryPhone.toString().length !== 10)
         errors.secondaryPhone = 'Secondary Phone invalid.';
      if (!formValues.email || formValues.email.indexOf('@') < 1 || formValues.email.indexOf('.') < 0)
         errors.email = 'Email invalid.';
      if (formValues.notes && formValues.notes.length < 2)
         errors.notes = 'Notes must be at least two characters if provided.';
      
      setFormValidation(errors);

      // validate address
      let addressValid = false;
      if (addressFormRef.current)
         addressValid = addressFormRef.current.validate();

      return Object.keys(errors).length === 0 && addressValid;
   };

   const cleanData = (formValues) => {
      return {
         ...formValues,
         primaryPhone: formValues.primaryPhone.toString(),
         secondaryPhone: formValues.secondaryPhone ? formValues.secondaryPhone.toString() : null,
         address: addressFormRef.current ? addressFormRef.current.getState() : null,
         otherContactType: props.otherContactTypes.find(element => element.id === activeOtherContactType),
      };
   };

   const handleAddSaveOtherContactButtonClick = () => {
      if (formIsValid()) {
         props.handleAddSaveOtherContact(cleanData(formValues));
         setFormValues({ ...initialState });
         setActiveOtherContactType('select-other-contact-type');
      }
   };
   
   const handleCancel = () => {
      props.handleClose();
      setFormValues(initialState);
      setFormValidation({});
   };

   return (
      <Dialog open={props.open} onClose={props.handleClose}>
         <DialogTitle>{( formValues.id ? 'Edit' : 'Add')} Important Contact</DialogTitle>
         <DialogContent>
            <Box component="form">
               <Grid container spacing={1}>
                  <Grid item xs={12}>
                     <DialogContentText>
                        <b>Add details for your important contact.</b>
                     </DialogContentText>
                  </Grid>

                  <Grid item xs={12}>
                     <FormControl fullWidth>
                        <InputLabel id="other-contact-type-select-label">Type*</InputLabel>
                        <Select
                           autoFocus
                           labelId="other-contact-type-select-label"
                           id="other-contact-type-select"
                           label="Type*"
                           value={activeOtherContactType}
                           onChange={event => setActiveOtherContactType(event.target.value)}
                        >
                           <MenuItem value="select-other-contact-type">--- Select Important Contact Type ---</MenuItem>
                           {props.otherContactTypes.map(type => (<MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>))}
                        </Select>
                     </FormControl>
                  </Grid>

                  <Grid item md={6}>
                     <FormControl fullWidth>
                        <TextField
                           autoFocus
                           margin="dense"
                           id="first-name-input"
                           label="First Name*"
                           type="text"
                           value={formValues.firstName}
                           onChange={event => setFormValues({ ...formValues, firstName: event.target.value })}
                           error={formValidation.firstName ? true : false}
                           helperText={formValidation.firstName}
                           fullWidth                        
                        />
                     </FormControl>
                  </Grid>
                  <Grid item md={6}>
                     <FormControl fullWidth>
                        <TextField
                           margin="dense"
                           id="last-name-input"
                           label="Last Name*"
                           type="text"
                           value={formValues.lastName}
                           onChange={event => setFormValues({ ...formValues, lastName: event.target.value })}
                           error={formValidation.lastName ? true : false}
                           helperText={formValidation.lastName}
                           fullWidth
                        />
                     </FormControl>
                  </Grid>
                  <Grid item md={6}>
                     <FormControl fullWidth>
                        <NumberFormat
                           customInput={TextField}
                           format="+1 (###) ###-####"
                           isNumericString={true}
                           // allowEmptyFormatting
                           mask="_"
                           margin="dense"
                           id="primary-phone-input"
                           label="Primary Phone*"
                           type="text"
                           value={formValues.primaryPhone}
                           onValueChange={({ floatValue: value }) => setFormValues({ ...formValues, primaryPhone: value })}
                           error={formValidation.primaryPhone ? true : false}
                           helperText={formValidation.primaryPhone}
                           fullWidth
                        />
                     </FormControl>
                  </Grid>
                  <Grid item md={6}>
                     <FormControl fullWidth>
                        <NumberFormat
                           customInput={TextField}
                           format="+1 (###) ###-####"
                           isNumericString={true}
                           mask="_"
                           margin="dense"
                           id="secondary-phone-input"
                           label="Secondary Phone"
                           type="text"
                           value={formValues.secondaryPhone}
                           onValueChange={({ floatValue: value }) => setFormValues({ ...formValues, secondaryPhone: value })}
                           error={formValidation.secondaryPhone ? true : false}
                           helperText={formValidation.secondaryPhone}
                           fullWidth
                        />
                     </FormControl>
                  </Grid>
                  <Grid item md={12}>
                     <FormControl fullWidth>
                        <TextField
                           margin="dense"
                           id="email-input"
                           label="Email*"
                           type="email"
                           value={formValues.email}
                           onChange={event => setFormValues({ ...formValues, email: event.target.value })}
                           error={formValidation.email ? true : false}
                           helperText={formValidation.email}
                           fullWidth
                        />
                     </FormControl>
                  </Grid>

                  <AddressFormFields
                     ref={addressFormRef}
                     label="Address"
                     addressStatesProvinces={props.addressStatesProvinces}
                  />

                  <Grid item xs={12}>
                     <FormControl fullWidth>
                        <TextAreaWithLimit
                           id="notes-input"
                           label="Notes*"
                           maxLength={255}
                           value={formValues.notes}
                           onChange={event => setFormValues({ ...formValues, notes: event.target.value })}
                           error={formValidation.notes ? true : false}
                           helperText={formValidation.notes}
                           placeholder="Add notes..."
                           rows={3}
                        />
                     </FormControl>
                  </Grid>
               </Grid>
            </Box>
         </DialogContent>
         <DialogActions>
            <Button variant="outlined" startIcon={<CancelIcon />} onClick={handleCancel}>Cancel</Button>
            <Button variant="contained" startIcon={<CheckIcon />} disabled={activeOtherContactType === 'select-other-contact-type'} onClick={handleAddSaveOtherContactButtonClick}>{( formValues.id ? 'Save' : 'Add')} Contact</Button>
         </DialogActions>
      </Dialog>
   );
});

OtherContactDialog.propTypes = {
   open: PropTypes.bool.isRequired,
   handleClose: PropTypes.func.isRequired,
   handleAddSaveOtherContact: PropTypes.func.isRequired,
   addressStatesProvinces: PropTypes.array.isRequired,
   otherContactTypes: PropTypes.array.isRequired,
};

export default OtherContactDialog;
