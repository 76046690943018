import React from 'react';

import Alert from '@mui/material/Alert';

const SplashPage = () => {
   return (
      <Alert severity="info">
         To continue on LifeTomb mobile, you may need to click "OPEN".
      </Alert>
   );
};

export default SplashPage;
